export class ContactInfo {



    ID:number=0;  
    EMAIL_ID:string='';
    MOBILE_NO:number= null;
    INTAGRAM_LINK:string='';
    FACEBOOK_LINK:string='';
    TWITTER_LINK:string='';
    OTHER_LINK_1:string='';
    OTHER_LINK_2:string='';
    ADDRESS:string='';
    STATUS:boolean=true;
    SEQUENCE_NO:number=0;
    TITLE:string='';
    SUBTITLE:string='';



    
}