import { Component, OnInit, ViewChild } from "@angular/core";
import { DatePipe } from "@angular/common";
import { NzDatePickerComponent, NzNotificationService } from "ng-zorro-antd";
import { UpdateAttendanceRegister } from "src/app/Models/UpdateAttendanceRegister";
import { ApiService } from "src/app/Service/api.service";
import { differenceInCalendarDays, setHours } from 'date-fns';
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-attendanceregister",
  templateUrl: "./attendanceregister.component.html",
  styleUrls: ["./attendanceregister.component.css"],
  providers: [DatePipe],
})

export class AttendanceregisterComponent implements OnInit {
  monthFormat = "yyyy-MMM-dd";
  formTitle = "Attendance Register";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  startValue: Date | null = null;
  startValue1 = undefined;
  endValue: Date | null = null;
  endOpen = false;
  startOpen = false;
  isFilterApplied: string = "default";
  columns: string[][] = [
    ["MONTH", "Month"],
    ["YEAR", "Year"],
    ["WORKING", "Working Days"],
    ["PRESENT", "Present"],
    ["LEAVE_COUNT", "Leave"],
    ["ABSENT", "Absent"],
    ["LATEMARK", "Latemark"],
    ["HALFDAY", "Halfday"],
    ["HOLIDAY", "Holiday"],
    ["1", "1"],
    ["2", "2"],
    ["3", "3"],
    ["4", "4"],
    ["5", "5"],
    ["6", "6"],
    ["7", "7"],
    ["8", "8"],
    ["9", "9"],
    ["10", "10"],
    ["11", "11"],
    ["12", "12"],
    ["13", "13"],
    ["14", "14"],
    ["15", "15"],
    ["16", "16"],
    ["17", "17"],
    ["18", "18"],
    ["19", "19"],
    ["20", "20"],
    ["21", "21"],
    ["22", "22"],
    ["23", "23"],
    ["24", "24"],
    ["25", "25"],
    ["26", "26"],
    ["27", "27"],
    ["28", "28"],
    ["29", "29"],
    ["30", "30"],
    ["31", "31"],
  ];
  today = new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString() + "-" + new Date().getDate().toString();
  current = new Date();
  filterClass: string = "filter-invisible";
  initFilter = true;
  month = this.today;
  isSpinning = false;
  month1;
  empId: number;
  drawerVisible: boolean;
  drawerData: UpdateAttendanceRegister = new UpdateAttendanceRegister();
  drawerTitle: string;
  excelDataList = [];

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService, private cookie: CookieService) { }

  ngOnInit() {
    this.getBranch();
    this.getDepartment();
    this.search(true);
    this.startValue = new Date(this.current.getFullYear() + "-" + (this.current.getMonth() + 1) + "-01");
    this.endValue = this.current;
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue) {
      return false;
    }

    return startValue.getTime() > this.current.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }

    return (
      endValue.getTime() < this.startValue.getTime() ||
      endValue.getTime() >= this.current.getTime()
    );
  };

  onStartChange(date: Date): void {
    this.startValue = date;
  }

  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }

    console.log("handleStartOpenChange", open, this.endOpen);
  }

  handleEndOpenChange(open: boolean): void {
    console.log(open);
    this.endOpen = open;
    this.startOpen = open;
  }

  @ViewChild("moduleEndDatePicker", { static: true })
  moduleEndDatePickerVar: NzDatePickerComponent;

  moduleStartDateHandle(open: boolean) {
    if (!open) {
      this.endOpen = true;
    }
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      likeQuery += " EMPLOYEE_NAME like ('%" + this.searchText + "%') OR";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var monthFilterStr = "";
    if (this.startValue1 != undefined) {
      monthFilterStr = " AND MONTH IN (" + this.startValue1 + ")";
    }

    var yearFilterStr = "";
    if (this.endValue != undefined) {
      yearFilterStr = " AND YEAR IN (" + this.datePipe.transform(this.endValue, "yyyy") + ")";
    }

    var branchFilterStr = "";
    if (this.BRANCH_ID.length > 0) {
      branchFilterStr = " AND BRANCH_ID IN (" + this.BRANCH_ID + ")";
    }

    var departmentFilterStr = "";
    if (this.DEPARTMENT_ID.length > 0) {
      departmentFilterStr = " AND DEPARTMENT_ID IN (" + this.DEPARTMENT_ID + ")";
    }

    this.api.getEmployeeAttendanceRegister(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + monthFilterStr + yearFilterStr + branchFilterStr + departmentFilterStr).subscribe((data) => {
      if (data["code"] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data["count"];
        this.dataList = data["data"];

        if (this.initFilter) {
          this.initFilter = false;
        }
      }

    }, (err) => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });

    // Getting Data for Excel
    this.api.getEmployeeAttendanceRegister(0, 0, this.sortKey, sort, likeQuery + monthFilterStr + yearFilterStr + branchFilterStr + departmentFilterStr).subscribe(data => {
      if (data['code'] == 200) {
        this.excelDataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  onKeypressEvent() {
    document.getElementById("searchBtn").focus();
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.BRANCH_ID = [];
    this.DEPARTMENT_ID = [];
    this.startValue = null;
    this.startValue1 = undefined;
    this.endValue = null;
    this.month = this.today;
    this.startValue = new Date(this.current.getFullYear() + "-" + (this.current.getMonth() + 1) + "-01");
    this.endValue = this.current;
    this.search(true);
  }

  applyFilter() {
    if ((this.startValue1 != undefined) || (this.endValue != undefined) || (this.BRANCH_ID.length > 0) || (this.DEPARTMENT_ID.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else this.filterClass = "filter-visible";
  }

  edit(data: UpdateAttendanceRegister, empId) {
    data.EMPLOYEE_ID = empId;
    this.drawerData = Object.assign({}, data);
    this.drawerTitle = "Update Status";
    this.drawerVisible = true;
  }

  BRANCH_ID = [];
  DEPARTMENT_ID = [];
  branch = [];
  department = [];
  orgId = this.cookie.get('orgId');

  getBranch() {
    this.branch = [];

    this.api.getAllBranch(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.branch = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getDepartment() {
    this.department = [];

    this.api.getAllDepartments(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.department = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }
}