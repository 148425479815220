import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NzButtonType, NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { InchargeMeeting } from 'src/app/Models/InchargeMeeting';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-incharge-meeting-attendance',
  templateUrl: './incharge-meeting-attendance.component.html',
  styleUrls: ['./incharge-meeting-attendance.component.css']
})

export class InchargeMeetingAttendanceComponent implements OnInit {
  @Input() drawerClose!: Function;
  @Input() data: InchargeMeeting = new InchargeMeeting();
  @Input() trainattendiesData: any[] = [];
  @Input() MEETING_ID: any;
  @Input() drawerVisible: boolean = false;
  isSpinning = false;
  loadingRecords = false;
  isOk = true;
  filterQuery: string = "";
  filterValue = [];
  isFilterApplied: NzButtonType = "default";
  isloadSpinning = false;
  exportSpinning = false;
  filterClass: string = "filter-invisible";
  current = new Date();
  isStatusSpinning = false;
  pageIndex = 1;
  pageSize = 10;
  @Input() totalRecords = 1;
  dataList: any = [];
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  DESIGNSTION_ID = 0;
  DEPARTMENT_ID = 0;
  departmentnm: any = [];
  designationm: any = [];
  a: any = [];
  b: any = [];
  dataList2 = [];
  column = [['MEETING_ID', 'Meeting ID'], ['MEMBER_NAME', 'Member Name'], ['P_A', 'Is Attending'], ["CLIENT_ID", "Client ID"]];
  inchargeAreaIDs: string = ((this._cookie.get("INCHARGE_AREA_IDS") == "null") || (this._cookie.get("INCHARGE_AREA_IDS")) == "") ? "0" : this._cookie.get("INCHARGE_AREA_IDS");
  federationID = this._cookie.get("FEDERATION_ID");
  unitID = this._cookie.get("UNIT_ID");
  groupID = this._cookie.get("GROUP_ID");
  roleID: number = this.api.roleId;

  constructor(private api: ApiService, private router: Router, private datePipe: DatePipe, private message: NzNotificationService, private _cookie: CookieService) { }

  ngOnInit() { }

  close(): void {
    this.drawerClose();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.dataList = [];
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND";
      this.column.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    var filter = ""
    if (likeQuery)
      filter = this.filterQuery + likeQuery;

    else
      filter = this.filterQuery;

    this.a = this.departmentnm.toString();
    this.b = this.designationm.toString();

    this.api.getAllInchargeMeetingAttendanceDetails(this.MEETING_ID).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        this.trainattendiesData = this.dataList;
      }

    }, err => {
      console.log(err);
    });
  }

  CLIENT_ID = 1;

  save() {
    this.isSpinning = false;

    this.api.addBulkInchargeMeetingAttendance(Number(this.CLIENT_ID), Number(this.MEETING_ID), this.trainattendiesData).subscribe(data => {
      if (data['code'] == 200) {
        this.message.success("Incharge Meeting Attendance Created Successfully", "");
        this.drawerClose();
        this.isSpinning = false;
        this.drawerVisible = false;

      } else {
        this.message.error("Incharge Meeting Attendance Creation Failed", "");
        this.isSpinning = false;
      }

    }, err => {
      this.isSpinning = false;
    });
  }

  editAccess: boolean = false;

  giveEditAccessOrNot(inchargeAreaID: string) {
    let inchargeAreaIDs = this.inchargeAreaIDs.split(",");
    let a = inchargeAreaIDs.filter(value => {
      return value == inchargeAreaID;
    });

    if (a.length > 0)
      this.editAccess = true;

    else
      this.editAccess = false;
  }
}
