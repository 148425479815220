export class Membermaster {
    ID: number;
    CLIENT_ID: number;
    NAME: string;
    MOBILE_NUMBER: string;
    GENDER: string;
    DOB: string;
    MARITAL_STATUS: string;
    ADDRESS1: string;
    ADDRESS2: string;
    CITY: string;
    PINCODE: string;
    ANNIVERSARY_DATE: string;
    EMAIL_ID: string;
    FEDERATION_ID: number
    UNIT_ID: number;
    GROUP_ID: number
    MEMBERSHIP_DATE: string;
    EXPIRY_DATE: string;
    IS_FEDERATION_OFFICER: Boolean = false;
    INCHARGE_OF: any;
    STATUS: boolean = true;
    PASSWORD: string;
    ACTIVE_STATUS: string = "A";
    PROFILE_IMAGE: string;
}