import { Component, OnInit } from '@angular/core';
import { EmmService } from 'src/app/Service/emm.service';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-earlymarkreport',
  templateUrl: './earlymarkreport.component.html',
  styleUrls: ['./earlymarkreport.component.css'],
  providers: [DatePipe]
})

export class EarlymarkreportComponent implements OnInit {
  dateFormat = "dd/MMM/yyyy"
  formTitle = "Earlymark Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["DATE", "Date"], ["EXPECTED_TIME", "Expected Time"], ["REASON", "Reason"], ["STATUS", "Status"], ["REMARK", "Remark"], ["APPROVER_NAME", "Approver Name"],]
  isSpinning = false;
  loadingFilterEmployees = true;
  filterClass: string = "filter-visible";
  filterStatus = [];
  filterEmployees: EmployeeMaster[];
  employeeFilter
  filterStatusValue: string[] = ['AL'];
  date: Date[] = [];
  date1 = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + 1;
  excelDataList = [];

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() {
    this.date[0] = new Date(this.date1);
    this.date[1] = new Date();
    this.search(true);
    this.loadDataFilter();

    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    if (this.searchText != "") {
      this.filterQuery += " AND (";
      var likeQuery = " ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like ('%" + this.searchText + "%') OR";
      });

      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + " ) ";
    }

    this.api.getEarlymarkReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });

    // Getting Data for Excel
    this.api.getEarlymarkReport(0, 0, this.sortKey, sort, this.filterQuery).subscribe(data => {
      if (data['code'] == 200) {
        this.excelDataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
  }

  getName(STATUS) {
    if (STATUS == "P")
      return "Pending"
    else
      if (STATUS == "A")
        return "Approved"
      else if (STATUS == "R")
        return "Rejected"
  }

  loadDataFilter() {
    this.loadingFilterEmployees = true;

    this.api.getAllEmployees(0, 0, 'NAME', 'asc', '').subscribe(employees => {
      if (employees['code'] == 200) {
        this.filterEmployees = employees['data'];
        this.loadingFilterEmployees = false;
      }

    }, err => {
      console.log(err);
    });
  }


  clearFilter() {
    this.date = undefined;
    this.filterStatusValue = ['AL'];
    this.filterClass = "filter-invisible";
    this.employeeFilter = undefined;
    this.isFilterApplied = "default";
    this.filterQuery = "";
    this.search(true);
  }

  applyFilter() {
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    if (this.date == undefined || this.employeeFilter == undefined || this.filterStatusValue == undefined) {
      this.message.error("Please Select Fields", "");

    } else {
      this.filterQuery = " AND (DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd') + "') AND"
      this.filterQuery += " EMPLOYEE_ID = " + this.employeeFilter + " AND ";

      let statusString: string = "";

      if (this.filterStatusValue != undefined) {
        this.filterStatusValue.forEach(filterStatus => {
          statusString += "'" + filterStatus + "'" + ",";
        }); (this.filterStatusValue)

        if (statusString.toString() != "") {
          if (statusString.match("AL")) {
            console.log("ALL" + statusString.substring(0, statusString.length - 1));
            this.filterQuery += "STATUS IN ('P','A','R') AND ";

          } else {
            this.filterQuery += "STATUS IN (" + statusString.substring(0, statusString.length - 1) + ") AND ";
          }
        }
      }

      this.filterQuery = this.filterQuery.substring(0, this.filterQuery.length - 5);

      this.isSpinning = true;
      this.api.getEarlymarkReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isSpinning = false;
          this.isFilterApplied = "primary";
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.filterClass = "filter-invisible";
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

      // Getting Data for Excel
      this.api.getEarlymarkReport(0, 0, this.sortKey, sort, this.filterQuery).subscribe(data => {
        if (data['code'] == 200) {
          this.excelDataList = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  getTimeInAM_PM(time) {
    return this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + time;
  }
}
