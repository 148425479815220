import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
// import { appkeys } from 'src/app/app.constant';
import { AboutMaster } from 'src/app/Models/aboutmaster';
import { ApiService } from 'src/app/Service/api.service';
// import { ClientmasterService } from 'src/app/Service/clientmaster.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Addfinancial } from 'src/app/Models/Addfinancial';

@Component({
  selector: 'app-addfinancial',
  templateUrl: './addfinancial.component.html',
  styleUrls: ['./addfinancial.component.css']
})
export class AddfinancialComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  data: Addfinancial = new Addfinancial;
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // namepatt=/^[a-zA-Z \-\']+/
  namepatt = /[a-zA-Z][a-zA-Z ]+/
  mobpattern = /^[6-9]\d{9}$/
  fileURL: any;
  // albume:AlbumeMaster[]=[]
  // imgurl= appkeys.retriveimgUrl;


  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '350px',
    // minHeight: '12rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
    ],
    customClasses: [],
    uploadUrl: '',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fonts', 'uploadUrl'],
      ['video']
    ]
  };



  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    // this.loadalbume();
  }


  close(): void {
    this.drawerClose();
  }
  resetDrawer(websitebannerPage: NgForm) {
    websitebannerPage.form.reset();
  }

  //save
  save(addNew: boolean, websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;

    if (this.data.GUIDLINE_TITLE.trim() == "" && this.data.GUIDLINE_DESCRIPTION.trim() == ""
      //  && this.data.TEXT.trim()==""  &&this.data.TEXT_MR.trim()==""
      && this.data.SEQUENCE_NO != undefined) {
      this.isOk = false
      this.message.error("Please Fill all the Information", "");
    } else
      if (this.data.GUIDLINE_TITLE == null || this.data.GUIDLINE_TITLE.trim() == '') {
        this.isOk = false
        this.message.error('Please Fill  Guideline Title', '')

      }

      else
        if (this.data.GUIDLINE_DESCRIPTION == null || this.data.GUIDLINE_DESCRIPTION.trim() == '') {
          this.isOk = false
          this.message.error('Please Fill  Guideline  Description', '')

        }

        // else

        //   if (this.data.SEQUENCE_NO == undefined || this.data.SEQUENCE_NO <= 0) {
        //     this.isOk = false
        //     this.message.error('Please Enter Sequence No', '')
        //   }



          if (this.isOk) {


            this.isSpinning = true;
      
            {
              if (this.data.ID) {
                this.api.updateFinancial(this.data).subscribe((successCode) => {
                  if (successCode["code"] == '200') {
                    this.message.success('Information Updated successfully.....', '');
                    if (!addNew) this.drawerClose();
                    this.isSpinning = false;
                  } else {
                    this.message.error('Failed to Update information...', '');
                    this.isSpinning = false;
                  }
                });
              }
              else {
                this.api.createFinancial(this.data).subscribe((successCode) => {
                  if (successCode.code == '200') {
                    this.message.success('Information added successfully......', '');
                    if (!addNew) this.drawerClose();
                    else {
                      this.data = new Addfinancial();
                      this.resetDrawer(websitebannerPage);
        
        
                      this.api.getFinancial(1, 1, ' SEQUENCE_NO', 'desc', '').subscribe(data => {
                        if (data['count'] == 0) {
                          this.data.SEQUENCE_NO = 1;
                        } else {
                          this.data.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
                        }
                      },
                        (err) => {
                          console.log(err);
                        }
                      );
                    }
                    this.isSpinning = false;
                  } else {
                    this.message.error('Failed to store  information...', '');
                    this.isSpinning = false;
                  }
                });
              }
            }
          }
  }


  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}





