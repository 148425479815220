import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { differenceInCalendarDays } from 'date-fns';
import { ApiService } from 'src/app/Service/api.service';
import { GroupActivityMaster } from 'src/app/Models/GroupActivityMaster';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-group-activity-report',
  templateUrl: './group-activity-report.component.html',
  styleUrls: ['./group-activity-report.component.css']
})

export class GroupActivityReportComponent implements OnInit {
  formTitle = "Group Activities Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [["FEDERATION_NAME", "Federation"], ["UNIT_NAME", "Unit"], ["GROUP_NAME", "Group"], ["PROJECT_NAME", "Target Area"], ["DATE", "Date"], ["COUNT", "Count"], ["DETAILS", "Details"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: GroupActivityMaster = new GroupActivityMaster();
  isSpinning = false;
  federationID: number = Number(this._cookie.get("FEDERATION_ID"));
  unitID: number = Number(this._cookie.get("UNIT_ID"));
  groupID: number = Number(this._cookie.get("GROUP_ID"));
  roleID: number = this.api.roleId;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _cookie: CookieService) { }

  ngOnInit(): void {
    this.search(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var federationFilter = "";
    if (this.federationID != 0) {
      federationFilter = " AND FEDERATION_ID=" + this.federationID;
    }

    var unitFilter = "";
    if (this.unitID != 0) {
      unitFilter = " AND UNIT_ID=" + this.unitID;
    }

    var groupFilter = "";
    if (this.groupID != 0) {
      groupFilter = " AND GROUP_ID=" + this.groupID;
    }

    this.loadingRecords = true;

    this.api.getAllGroupActivities(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + federationFilter + unitFilter + groupFilter).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  today = new Date().setDate(new Date().getDate() + 4);

  add(): void {
    this.drawerTitle = "Add Group Activity";
    this.drawerData = new GroupActivityMaster();
    this.drawerVisible = true;
    this.drawerData.DATE = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  }

  edit(data: GroupActivityMaster): void {
    this.drawerTitle = "Update Group Activity Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.drawerData.COUNT = String(data.COUNT);
  }

  drawerClose(): void {
    this.search(true);
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }

  isVisible = false;
  isConfirmLoading = false;

  handleCancel(): void {
    this.isVisible = false;
    this.isConfirmLoading = false;
  }

  handleOk() {
    this.isConfirmLoading = true;
  }

  openExportModal() {
    this.isVisible = true;
  }

  MONTH: any;
  YEAR: any;

  photoModalTitle = "Activity Photo(es)";
  photoModalVisible: boolean = false;
  PHOTO1: string = "";
  DESCRIPTION1: string = "";
  PHOTO2: string = "";
  DESCRIPTION2: string = "";
  PHOTO3: string = "";
  DESCRIPTION3: string = "";
  PHOTO4: string = "";
  DESCRIPTION4: string = "";
  PHOTO5: string = "";
  DESCRIPTION5: string = "";

  viewPhotoes(data: GroupActivityMaster) {
    this.PHOTO1 = "";
    this.DESCRIPTION1 = "";
    this.PHOTO2 = "";
    this.DESCRIPTION2 = "";
    this.PHOTO3 = "";
    this.DESCRIPTION3 = "";
    this.PHOTO4 = "";
    this.DESCRIPTION4 = "";
    this.PHOTO5 = "";
    this.DESCRIPTION5 = "";
    this.photoModalVisible = true;
    if (data.PHOTO1 != " ") {
      this.PHOTO1 = this.api.retriveimgUrl + "groupActivity/" + data.PHOTO1;
      this.DESCRIPTION1 = data.DESCRIPTION1;
    }

    if (data.PHOTO2 != " ") {
      this.PHOTO2 = this.api.retriveimgUrl + "groupActivity/" + data.PHOTO2;
      this.DESCRIPTION2 = data.DESCRIPTION2;
    }

    if (data.PHOTO3 != " ") {
      this.PHOTO3 = this.api.retriveimgUrl + "groupActivity/" + data.PHOTO3;
      this.DESCRIPTION3 = data.DESCRIPTION3;
    }

    if (data.PHOTO4 != " ") {
      this.PHOTO4 = this.api.retriveimgUrl + "groupActivity/" + data.PHOTO4;
      this.DESCRIPTION4 = data.DESCRIPTION4;
    }

    if (data.PHOTO5 != " ") {
      this.PHOTO5 = this.api.retriveimgUrl + "groupActivity/" + data.PHOTO5;
      this.DESCRIPTION5 = data.DESCRIPTION5;
    }
  }

  photoModalCancel() {
    this.photoModalVisible = false;
  }

  viewPhoto(photoURL: string) {
    window.open(photoURL);
  }

  viewPDF(pdfURL: string) {
    window.open(this.api.retriveimgUrl + "groupActivity/" + pdfURL);
  }
}