export class InchargeMeeting {
    ID: number;
    CLIENT_ID: number;
    DATE: any;
    FROM_TIME: any;
    TO_TIME: any
    MEETING_NUMBER: string;
    MEETING_SUB: string;
    AGENDA: string;
    MINUTES: any;
    PHOTO1: any;
    PHOTO2: any;
    PHOTO3: any;
    PHOTO4: any;
    PHOTO5: any;
    DESCRIPTION1: string;
    DESCRIPTION2: string;
    DESCRIPTION3: string;
    DESCRIPTION4: string;
    DESCRIPTION5: string;
    INCHARGE_ID: number;
    PDF1: string;
    PDF2: string;
}