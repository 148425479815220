import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CentralCommittee } from 'src/app/Models/Central Committee';
import { joinus } from 'src/app/Models/Joinus';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-listjoinusform',
  templateUrl: './listjoinusform.component.html',
  styleUrls: ['./listjoinusform.component.css']
})
export class ListjoinusformComponent implements OnInit {


  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: joinus = new joinus();
  formTitle = "Join Us form Details Verification";
  dataList :any []= [];
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Name"],["FEDERATION_NAME", "Federation Name "],["UNIT_NAME", "Unit Name"],["GROUP_NAME", "Group Name"],]

  constructor( private api: ApiService,private message:NzNotificationService) { }

  ngOnInit(): void {
  this.loadingRecords = false;
  this.search();
  }

  keyup(event:any) {
    this.search();
  }

  search(reset: boolean = false) {
    if (reset) { this.pageIndex = 1; } var sort: string;
    try {
      this.sortValue = this.sortValue.startsWith("a") ? "asc" : "desc";
      console.log("an", this.sortValue);
    }
     catch (error) { sort = ""; }
      var likeQuery = ""; if (this.searchText != "")
       {
         likeQuery = " AND";

       this.columns.forEach(column =>
      { likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR"; });
     likeQuery = likeQuery.substring(0, likeQuery.length - 2); }

     this.loadingRecords = true;

    this.api.getjoinusvarification(this.pageIndex, this.pageSize, this.sortKey, this.sortValue,likeQuery).
    subscribe(data => { if (data['code'] == 200)
     {
      this.loadingRecords = false;
      this.totalRecords = data['count']; this.dataList = data['data'];
    } },
     err => { if (err['ok'] == false)
      this.message.error("Server Not Found", ""); });

  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  // add(): void {
  //   this.drawerTitle = "Add Central Committee";
  //   this.drawerData = new joinus();
  //   this.drawerData.COMMITTEE_TYPE_ID =null;
  //   this.api.getCommittee(1, 1, 'SEQ_NO', 'desc', '').subscribe(data => {
  //     if (data['count'] == 0) {
  //       this.drawerData.SEQ_NO = 1;
  //     } else {
  //       this.drawerData.SEQ_NO = data['data'][0]['SEQ_NO'] + 1;
  //     }
  //   }, err => {
  //     console.log(err);
  //   })
  //   this.drawerVisible = true;
  // }
  edit(data: joinus): void {
    this.drawerTitle = "Update Central Committee";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  sort(sort: { key: string; value: string }):
  void {
  this.sortKey = sort.key;
  this.sortValue = sort.value;
  console.log("SORT", this.sortValue);
  this.search(true);
  }
 
}
