import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzButtonType, NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-giantsreport',
  templateUrl: './giantsreport.component.html',
  styleUrls: ['./giantsreport.component.css']
})
export class GiantsreportComponent implements OnInit {

  formTitle = "Giants Report";
  pageIndex = 1;
  pageSize = 7;
  totalRecords = 1;
  dataList = [];
  d: any;
  dataList1 = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  filterValue = []
  isFilterApplied: NzButtonType = "default";
  columns: string[][] = []

  isloadSpinning = false;
  MONTH: any;
  YEAR: any;
  current = new Date();
  datePipe = new DatePipe("en-US");
  // filterClass: string = "filter-invisible";
  value1: any
  value2: any

  isVisible = false;
  i = -1;

  imgurl = this.api.retriveimgUrl;

  constructor(private message: NzNotificationService, private api: ApiService) { }

  ngOnInit(): void {
    // this.MONTH=new Date(this.current.getMonth() + 1 + "-01");
    // this.MONTH = this.datePipe.transform(this.MONTH, "MMMM");
    // console.log(this.MONTH)

    // this.YEAR=new Date(this.current.getFullYear() + "-01-01")
    // this.YEAR = this.datePipe.transform(this.YEAR, "yyyy");

    // this.changeDate(this.MONTH,this.YEAR)
    // this.search(true);
  }


  onChange(result: Date): void {
    console.log('onChange: ', result);
  }


  changeDate(month: any) {
    this.value1 = this.datePipe.transform(month, "MMMM")
    // this.value2 = this.datePipe.transform(year, "yyyy")
  }

  // sort(params: NzTableQueryParams): void {
  //   const { pageSize, pageIndex, sort } = params;
  //   const currentSort = sort.find(item => item.value !== null);
  //   const sortField = (currentSort && currentSort.key) || 'id';
  //   const sortOrder = (currentSort && currentSort.value) || 'desc';
  //   // console.log(currentSort)

  //   // console.log("sortOrder :" + sortOrder)
  //   this.pageIndex = pageIndex;
  //   this.pageSize = pageSize;

  //   if (this.pageSize != pageSize) {
  //     this.pageIndex = 1;
  //     this.pageSize = pageSize;
  //   }

  //   if (this.sortKey != sortField) {
  //     this.pageIndex = 1;
  //     this.pageSize = pageSize;
  //   }

  //   this.sortKey = sortField;
  //   this.sortValue = sortOrder;
  //   this.search();
  // }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }


  REPORTDATE
  REPORTYEAR
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "DATE";
      this.sortValue = "desc"
      this.dataList = []
    }


    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    // console.log("search text:" + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      //console.log("likeQuery" + likeQuery);
    }

    //   var filter = ""
    // if (likeQuery)
    //   filter = this.filterQuery + likeQuery
    // else
    //   filter = this.filterQuery


    this.api.getReportDetails(this.MONTH).subscribe(data => {
      this.loadingRecords = false;
      this.dataList = data['data'];
      this.dataList1 = data['getEvents'];
      //  this.d=this.dataList[0]['date']
      //  this.d = data['data'][0]['date'];
      // console.log(this.dataList)
      console.log(this.dataList1[0]['DATE'])
      this.REPORTDATE = this.dataList1[0]['DATE']
      this.REPORTDATE = this.datePipe.transform(this.REPORTDATE, "MMMM");
      this.REPORTYEAR = this.dataList1[0]['DATE']
      this.REPORTYEAR = this.datePipe.transform(this.REPORTYEAR, "yyyy");
      // console.log(this.d)  
      // console.log(this.dataList)
      // {date: '2022-08-01'}

      // this.disabledate=new Date(this.current.getFullYear() + "-" + (this.current.getMonth() + 1) + "-01");
      // this.disabledate = this.datePipe.transform(this.today, "yyyy-MM-dd");
      // console.log(this.disabledate)

      // this.filterClass = "filter-invisible";

    }, err => {
      console.log(err);
      // this.clearFilter();
    });



  }

  applyFilter() {


    // this.MONTH = this.datePipe.transform(this.MONTH, "MMMM");
    // this.YEAR = this.datePipe.transform(this.YEAR, "yyyy");
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    // this.filterQuery = ''
    // this.filterQuery += " AND MONTH='" + this.MONTH+"'"
    // this.filterQuery += " AND YEAR='" + this.YEAR+"'"

    this.filterQuery = ''
    if (this.MONTH != undefined) {
      // if (this.MONTH != null && this.YEAR == null) {
      //   this.message.error("Please Select Year", "");
      // }
      // else 
      if (this.MONTH == null) {
        this.message.error("Please Select Month", "");
      }
      else {
        // this.filterQuery += " AND ( DATE BETWEEN '" + this.FROM_DATE + ":00:00:00" + "' AND '" + this.TO_DATE + ":23:59:59" + "' ) "
        this.filterQuery += " AND MONTH='" + this.MONTH + "'";
        // this.filterQuery += " AND YEAR='" + this.YEAR+"'"
        // console.log(this.YEAR)
      }
    }

    this.dataList = []
    this.search(true)

  }


  // showFilter() {
  //   if (this.filterClass === "filter-visible")
  //     this.filterClass = "filter-invisible";
  //   else
  //     this.filterClass = "filter-visible";
  // }

  // clearFilter() {
  //   this.filterValue = []

  //   // this.MONTH = new Date(this.current.getMonth() + 1 + "-01");
  //   // this.MONTH = this.datePipe.transform(this.MONTH, "MMMM");
  //   // this.YEAR=new Date(this.current.getFullYear() + "-01-01");
  //   // this.YEAR = this.datePipe.transform(this.YEAR, "yyyy");
  //   this.dataList = []
  //   //this.pageIndex =1;
  //   this.isFilterApplied = "default";
  //   // this.filterClass = "filter-invisible";
  //   this.applyFilter()
  // }


  //modal data: CleaningMaster

  showreport(): void {
    this.isVisible = true;

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }


    this.filterQuery = ''
    if (this.MONTH != undefined) {

      if (this.MONTH == null) {
        this.message.error("Please Select Month", "");
      }
      else {

        this.filterQuery += " AND MONTH='" + this.MONTH + "'";

      }
    }

    this.dataList = []
    this.search(true)



    // this.SHIFTD='D';
    // let filterQueryD =" AND DATE='"+this.DATEREPORT  + "' AND SHIFT='"+this.SHIFTD + "'" 
    // this.api.getAllmagnetCleaningRecord(0, 0, 'ID', 'asc',filterQueryD).subscribe(data => {
    //   this.listOfDatareportD = data['data'];
    //   this.usernmd=this.listOfDatareportD[0]['USER_NAME']
    // }, err => {
    //   console.log(err);
    // });

    // this.SHIFTN='N';
    // let filterQueryN =" AND DATE='"+this.DATEREPORT  + "' AND SHIFT='"+this.SHIFTN + "'" 
    // this.api.getAllmagnetCleaningRecord(0, 0, 'ID', 'asc',filterQueryN).subscribe(data => {
    //   this.listOfDatareportN = data['data'];
    //   this.usernmn=this.listOfDatareportN[0]['USER_NAME']
    // }, err => {
    //   console.log(err);
    // });

  }

  // handleCancel(): void {
  //   this.i = -1;
  //   this.isVisible = false;
  // }



  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }
}
