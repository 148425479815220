
// import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { Cmsmeeting } from 'src/app/Models/Cmsmeeting';
// import { ProjectMaster } from 'src/app/Models/ProjectMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.css']
})
export class MeetingComponent implements OnInit {

  formTitle = "Meeting";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [["WEB_MEETING_STATUS", "Show In Web"],["DATE", "Date"], ["MEETING_SUB", "Meeting Subject"],["GROUP_NAME", "Group Name"],["VENUE", "Venue"],["DESCRIPTION1", "Discription"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Cmsmeeting = new Cmsmeeting();
  isSpinning = false;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset)
  {
     this.pageIndex = 1;
  }

  var sort: string;
    try {
      this.sortValue = this.sortValue.startsWith("a") ? "asc" : "desc";
      console.log("an", this.sortValue);
    }
    catch (error) { sort = ""; }

      var likeQuery = ""; if (this.searchText != "")
       {
         likeQuery = " AND";
         this.columns.forEach(column =>
      {
       likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR"; });
       likeQuery = likeQuery.substring(0, likeQuery.length - 2);
   }

     this.loadingRecords = true;

    this.api.getMeeting(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, likeQuery).
    subscribe(data => { if (data['code'] == 200)
     {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
  this.dataList = data['data'];
    } },
     err => { if (err['ok'] == false)
      this.message.error("Server Not Found", ""); });
  }

  keyup(event:any) {
    this.search();
  }



  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  today = new Date().setDate(new Date().getDate() + 4);

  // add(): void {
  //   this.drawerTitle = "Add Group Target Area";
  //   this.drawerData = new Cmsmeeting();
  //   this.drawerVisible = true;
  //   this.drawerData.STATUS = true;
  // }

  // edit(data: Cmsmeeting): void {
  //   this.drawerTitle = "Update Group Target Area";
  //   this.drawerData = Object.assign({}, data);
  //   this.drawerVisible = true;
  // }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }




   onStatusChange(data: Cmsmeeting, status) {
    data.WEB_MEETING_STATUS = status;

    this.api.updatemeeting(data).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Status", "");

      this.search();
    });
  }
}
