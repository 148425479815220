import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService, toArray } from 'ng-zorro-antd';
import { Membermaster } from 'src/app/Models/MemberMaster';
import { ApiService } from 'src/app/Service/api.service';
import { Input, ViewChild } from '@angular/core';
import { MemberDrawerComponent } from '../member-drawer/member-drawer.component';
import { CookieService } from 'ngx-cookie-service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { MemberUploadComponent } from '../../member-upload/member-upload.component';

@Component({
  selector: 'app-member-master',
  templateUrl: './member-master.component.html',
  styleUrls: ['./member-master.component.css']
})

export class MemberMasterComponent implements OnInit {
  passwordVisible = false;
  formTitle = "Manage Members";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [["NAME", "Name"], ["MOBILE_NUMBER", "Mobile No."], ["GENDER", "Gender"], ["MARITAL_STATUS", "Marital Status"], ["MEMBERSHIP_DATE", "Membership From"], ["EXPIRY_DATE", "Membership Upto"], ["FEDERATION_NAME", "Federation Name"], ["UNIT_NAME", "Unit Name"], ["GROUP_NAME", "Group Name"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Membermaster = new Membermaster();
  isSpinning = false;
  federationID = this._cookie.get("FEDERATION_ID");
  unitID = this._cookie.get("UNIT_ID");
  groupID = this._cookie.get("GROUP_ID");

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _cookie: CookieService) { }

  ngOnInit() {
    this.search(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var federationFilter = "";
    if (this.federationID != "0") {
      federationFilter = " AND FEDERATION_ID=" + this.federationID;
    }

    var unitFilter = "";
    if (this.unitID != "0") {
      unitFilter = " AND UNIT_ID=" + this.unitID;
    }

    var groupFilter = "";
    if (this.groupID != "0") {
      groupFilter = " AND GROUP_ID=" + this.groupID;
    }

    this.loadingRecords = true;

    this.api.getAllMembers(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + federationFilter + unitFilter + groupFilter).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  @ViewChild(MemberDrawerComponent, { static: false }) MemberDrawerComponentVar: MemberDrawerComponent;

  add(): void {
    this.drawerTitle = "Add Member";
    this.drawerData = new Membermaster();
    this.drawerVisible = true;
    this.drawerData.MEMBERSHIP_DATE = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    let expiryDate = new Date().setFullYear(new Date().getFullYear() + 1);
    this.drawerData.EXPIRY_DATE = this.datePipe.transform(expiryDate, "yyyy-MM-dd");
    this.MemberDrawerComponentVar.fileURL1 = null;
  }

  edit(data: Membermaster): void {
    console.log(data);

    this.drawerTitle = "Update Member Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

    if (this.drawerData.INCHARGE_OF == " ")
      this.drawerData.INCHARGE_OF = undefined;

    else {
      let inchargeStringArray = [];
      if ((data.INCHARGE_OF != undefined) && (data.INCHARGE_OF != null)) {
        inchargeStringArray = data.INCHARGE_OF.split(',');
      }

      let inchargeArray = [];
      for (var i = 0; i < inchargeStringArray.length; i++) {
        inchargeArray.push(Number(inchargeStringArray[i]));
      }

      this.drawerData.INCHARGE_OF = inchargeArray;
    }

    // Get Units and Groups
    this.MemberDrawerComponentVar.getUnits(data.FEDERATION_ID);
    this.MemberDrawerComponentVar.getGroups(data.UNIT_ID);

    // Anniversary Date
    if (this.datePipe.transform(this.drawerData.ANNIVERSARY_DATE, "yyyyMMdd") == "19000101")
      this.drawerData.ANNIVERSARY_DATE = undefined;

    this.MemberDrawerComponentVar.fileURL1 = null;
    if ((this.drawerData.PROFILE_IMAGE != " ") && (this.drawerData.PROFILE_IMAGE != null))
      this.drawerData.PROFILE_IMAGE = this.api.retriveimgUrl + "profileImage/" + this.drawerData.PROFILE_IMAGE;

    else
      this.drawerData.PROFILE_IMAGE = null;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }

  onStatusChange(data: Membermaster, status) {
    data.STATUS = status;

    // this.api.updateGroup(data).subscribe(successCode => {
    //   if (successCode['code'] == 200)
    //     this.message.success("Status Updated Successfully", "");

    //   else
    //     this.message.error("Failed to Update Status", "");

    //   this.search();
    // });
  }

  getGenderFullForm(gender) {
    if (gender == "M")
      return "Male";

    else if (gender == "F")
      return "Female";

    else if (gender == "O")
      return "Other";
  }

  getMaritalStatusFullForm(gender) {
    if (gender == "S")
      return "Single";

    else if (gender == "M")
      return "Married";

    else if (gender == "W")
      return "Widowed";

    else if (gender == "D")
      return "Divorced";

    else if (gender == "E")
      return "Separated";
  }

  memberPaymentDrawerVisible: boolean;
  memberPaymentDrawerTitle: string;
  memberPaymentDrawerData: Membermaster = new Membermaster();

  makePayment(data: Membermaster): void {
    this.memberPaymentDrawerTitle = "Payment Details";
    this.memberPaymentDrawerData = Object.assign({}, data);
    this.memberPaymentDrawerVisible = true;
  }

  memberPaymentDrawerClose(): void {
    this.search();
    this.memberPaymentDrawerVisible = false;
  }

  get memberPaymentCloseCallback() {
    return this.memberPaymentDrawerClose.bind(this);
  }

  getActiveStatus(status) {
    if (status == "P")
      return "Pending";

    else
      return "Active";
  }

  today = new Date().setDate(new Date().getDate());

  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) > 0;

  disabledExpiryDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  isVisible = false;
  isConfirmLoading = false;

  tempDataToActivate = new Membermaster();
  PASSWORD: any;
  MEMBERSHIP_DATE: any;
  EXPIRY_DATE: any;

  showModal(data): void {
    this.tempDataToActivate = data;
    this.isVisible = true;
    this.passwordVisible = false;
    this.MEMBERSHIP_DATE = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    let expiryDate = new Date().setFullYear(new Date().getFullYear() + 1);
    this.EXPIRY_DATE = this.datePipe.transform(expiryDate, "yyyy-MM-dd");
    this.PASSWORD = this.api.generate8DigitRandomNumber();
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  handleOk() {
    this.isConfirmLoading = true;
    this.tempDataToActivate.PASSWORD = this.PASSWORD;
    this.tempDataToActivate.MEMBERSHIP_DATE = this.datePipe.transform(this.MEMBERSHIP_DATE, "yyyy-MM-dd");
    this.tempDataToActivate.EXPIRY_DATE = this.datePipe.transform(this.EXPIRY_DATE, "yyyy-MM-dd");
    this.tempDataToActivate.ACTIVE_STATUS = "A";

    this.api.updateMember(this.tempDataToActivate).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Member Details Updated Successfully", "");
        this.isConfirmLoading = false;
        this.isVisible = false;
        this.search(false);

      } else {
        this.message.error("Member Details Updation Failed", "");
        this.isConfirmLoading = false;
        this.search(false);
      }
    });
  }

  importDrawerTitle: string = "";
  importDrawerVisible: boolean = false;
  @ViewChild(MemberUploadComponent, { static: false }) MemberUploadComponentVar: MemberUploadComponent;

  openImportDrawer(): void {
    this.MemberUploadComponentVar.newArray = [];
    this.importDrawerTitle = "Import Data";
    this.importDrawerVisible = true;
    this.MemberUploadComponentVar.uploadedMembersCount = 0;
    this.MemberUploadComponentVar.duplicateMembersCount = 0;
    this.MemberUploadComponentVar.failedToUploadMembersCount = 0;
  }

  importDrawerClose(): void {
    this.importDrawerVisible = false;
    this.search(true);
  }

  get importCloseCallback() {
    return this.importDrawerClose.bind(this);
  }

  discontinueMember(data: Membermaster) {
    data.ACTIVE_STATUS = "P";
    data.STATUS = false;

    this.api.updateMember(data).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Member Discontinued Successfully", "");
        this.search(false);

      } else {
        this.message.error("Failed to Update", "");
        this.search(false);
      }
    });
  }

  cancel() { }

  downloadCSVFile() {
    window.open(this.api.retriveimgUrl + "CSVFileFormat/MemberMaster.csv");
  }
}
