export class GroupBOD {

    ID:number=0;          
    UNIT_NAME:string='';          
    GROUP_NAME:string='';          
    PRESIDENT:string='';
    VPI:string='';
    VPE:string=''; 
    TYPE :string=''; 
    SECRETORY :string=''; 
    TREASURER :string=''; 
    DIRECTOR1 :string=''; 
    DIRECTOR2 :string=''; 
    DIRECTOR3 :string=''; 
    DIRECTOR4 :string=''; 
    DIRECTOR5 :string=''; 
}