import { Component, Input, OnInit } from '@angular/core';
import { CircularMaster } from 'src/app/Models/Circular';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/Service/api.service';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-listcircular',
  templateUrl: './listcircular.component.html',
  styleUrls: ['./listcircular.component.css']
})

export class ListcircularComponent implements OnInit {
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: CircularMaster = new CircularMaster();
  formTitle = "Manage Circulars";
  dataList = [];
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [['CREATOR_NAME', 'Created By'], ['CIRCULAR_TYPE_NAME', 'Circular Type'], ['CIRCULAR_NO', 'Circular No.'], ['SUBJECT', 'Subject'], ['DATE', 'Date'], ['STATUS', 'Status']];
  userID = this.api.userId;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _cookie: CookieService) { }

  ngOnInit(): void {
    this.loadingRecords = false;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';

    } catch (error) {
      sort = '';
    }

    var likeQuery = '';
    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    this.loadingRecords = true;
    this.api.getAllCircular(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + " AND CREATER_ID=" + this.userID).subscribe((data) => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, (err) => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search(false);
    this.drawerVisible = false;
  }

  add(): void {
    this.drawerTitle = 'Add Circular';
    this.drawerData = new CircularMaster();
    this.drawerVisible = true;
    this.drawerData.DATE = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  }

  edit(data: CircularMaster): void {
    this.drawerTitle = 'Update Circular';
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  markDone(data: any) {
    data.STATUS = 'F';

    this.api.updateCircular(data).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.message.success('Circular Updated Successfully', '');

      } else {
        this.message.error('Circular Updation Failed', '');
      }
    });
  }
}
