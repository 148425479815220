export class Messages{

ID:number;
MESSAGE_TITLE:string='';
MESSAGE_DESCRIPTION:string='';
STATUS:boolean=true;
SEQUENCE_NO:number=0 ;
NAME:string='';
GROUP_NAME:string='';



// ID:number=0;
// DESCRIPTION:string='';
// STATUS:boolean=true;
// SEQUENCE_NO:number=0 ;

}