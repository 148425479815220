import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
// import { appkeys } from 'src/app/app.constant';
import { cmsphoto } from 'src/app/Models/cmsphoto';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addgallery',
  templateUrl: './addgallery.component.html',
  styleUrls: ['./addgallery.component.css']
})
export class AddgalleryComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  data: cmsphoto = new cmsphoto;
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk=true;
  emailpattern=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // namepatt=/^[a-zA-Z \-\']+/
  namepatt=/[a-zA-Z][a-zA-Z ]+/
  mobpattern=/^[6-9]\d{9}$/
  fileURL:any;
  // imgurl= appkeys.retriveimgUrl;
  imgurl = this.api.retriveimgUrl + "/gallery/";

  constructor(private api:ApiService,
     private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
  }


  close(): void {
    this.drawerClose();
  }
  resetDrawer(websitebannerPage: NgForm) {
    this.data=new cmsphoto();
    websitebannerPage.form.markAsPristine();
    websitebannerPage.form.markAsUntouched();
  }

  //save
  save(addNew:boolean,websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk=true;

    if (this.data.TITLE.trim() == ""
    && this.data.SEQUENCE_NO!=undefined  && this.data.IMG_URL.trim() == "")  {
      this.isOk =false
     this.message.error("Please Fill All The Information", "");
   }else

   if(this.data.TITLE== null || this.data.TITLE.trim()==''){
    this.isOk =false
    this.message.error('Please Enter Album Title','')
  }
  // else

  // if(this.data.TITLE_MR== null || this.data.TITLE_MR.trim()==''){
  //  this.isOk =false
  //  this.message.error('कृपया अल्बमचे शीर्षक (मराठी) प्रविष्ट करा','')
  // }
  else

  if(this.data.SEQUENCE_NO== undefined || this.data.SEQUENCE_NO<=0){
    this.isOk =false
    this.message.error('Please Enter Sequence Number','')
  }else

   if(this.data.IMG_URL== null || this.data.IMG_URL.trim()==''){
    this.isOk =false
    this.message.error('Please Select Image','')
  }
  if(this.isOk){
    // this.isSpinning=false;

    this.isSpinning=true;
    if (this.fileURL != null) {
      var number = Math.floor(100000 + Math.random() * 900000);
      var fileExt = this.fileURL.name.split('.').pop();
      var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
      var url = '';
      url = d == null ? '' : d + number + '.' + fileExt;
      if (
        this.data.IMG_URL != undefined &&
        this.data.IMG_URL.trim() != ''
      ) {
        var arr = this.data.IMG_URL.split('/');
        if (arr.length > 1) {
          url = arr[5];
        }
      }
      this.api
        .onUpload2('gallery', this.fileURL, url)
        .subscribe((successCode) => {
          if (successCode ["code"] == 200) {
            this.data.IMG_URL =url
              // appkeys.retriveimgUrl + 'gallery/' + url;
              if (this.data.ID) {
                this.api.updatealbumMaster(this.data).subscribe((successCode) => {
                  if (successCode ["code"] == 200) {
                    this.message.success('Information Updated successfully', '');
                    this.fileURL=null;
                    if (!addNew) this.drawerClose();
                    this.isSpinning = false;
                  } else {
                    this.message.error('Failed To Update Information', '');
                    this.isSpinning = false;
                  }
                });
              } else {
                this.api.createalbumMaster(this.data).subscribe((successCode) => {
                  if (successCode.code == '200') {
                    this.message.success('Information Added Successfully', '');
                    this.fileURL=null;
                    if (!addNew) this.drawerClose();
                    else {
                      this.data = new cmsphoto();
                      this.resetDrawer(websitebannerPage);
                this.data.IMG_URL= '';

                this.api.getAllalbumMaster(1,1,'SEQUENCE_NO','desc','').subscribe (data =>{
                  if (data['count']==0){
                    this.data.SEQUENCE_NO=1;
                  }else
                  {
                    this.data.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
                  }
                },err=>{
                  console.log(err);
                })
                    }
                    this.isSpinning = false;
                  } else {
                    this.message.error('Failed To Store Information', '');
                    this.isSpinning = false;
                  }
                });
              }
          } else {
            this.message.error('Failed To Add Image', '');
            this.isSpinning = false;
          }
        });
    } else if(this.data.IMG_URL == null || this.data.IMG_URL == '') {
      this.message.error('Please Select Image', '');
            this.isSpinning = false;
    }else {
      if (this.data.ID) {
        this.api.updatealbumMaster(this.data).subscribe((successCode) => {
          if (successCode ["code"] == 200) {
            this.message.success('Information Updated Successfully', '');
            if (!addNew) this.drawerClose();
            this.isSpinning = false;
          } else {
            this.message.error('Failed To Update Information.', '');
            this.isSpinning = false;
          }
        });
      } else {
        this.api.createalbumMaster(this.data).subscribe((successCode) => {
          if (successCode.code == '200') {
            this.message.success('Information Added Successfully', '');
            if (!addNew) this.drawerClose();
            else {
              this.data = new cmsphoto();

            }
            this.isSpinning = false;
          } else {
            this.message.error('Failed To Store Information', '');
            this.isSpinning = false;
          }
        });
      }
    }
  }
  // if(this.data.ID)
  // {
  //   this.api.updatealbumMaster(this.data)
  //   .subscribe(successCode => {
  //     if(successCode.code=="200")
  //     {
  //         this.message.success("Record Updated Successfully...", "");
  //         if(!addNew)
  //         this.drawerClose();
  //         this.isSpinning = false;
  //     }
  //     else
  //     {
  //       this.message.error("Record Updation Failed...", "");
  //       this.isSpinning = false;
  //     }
  //   });
  // }
  // else{

  //     this.api.createalbumMaster(this.data)
  //     .subscribe(successCode => {
  //       if(successCode.code=="200")
  //       {
  //         this.message.success("Record Created Successfully...", "");
  //          if(!addNew)
  //          this.drawerClose();
  //           else
  //           {
  //             this.data=new cmsphoto();
  //           }
  //           this.isSpinning = false;
  //         }
  //          else
  //          {
  //           this.message.error("Record Creation Failed...", "");
  //           this.isSpinning = false;
  //          }
  //         });
  //       }
  }

// else
// {
//   this.message.error("Please Fill All Required Fields...","");
//   this.isSpinning = false;
// }


//Choose Image
  onFileSelected(event:any) {
    console.log(event);

    if (event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/png") {
      this.fileURL = <File>event.target.files[0];

    } else {
      this.message.error("Select Only JPEG/ JPG/ PNG File.", "");
      this.fileURL = null;
      this.data.IMG_URL= '';

    }
  }

  // if ( this.fileList1[0].type === 'image/jpg' || this.fileList1[0].type === 'image/png' || this.fileList1[0].type === 'image/jpeg') {
  //   this.profile1 = this.fileList1[0];
  //   this.getBase64(this.fileList1[0], (img: string) => {
  //     this.loading1 = false;
  //     this.avatarUrl1 = img;
  //   });
  // } else {
  //   this.message.error('Selected file is not Image', '');
  // }

  removeImage() {
    this.data.IMG_URL='';
    this.fileURL='';
  }


}
