import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
// import { appkeys } from 'src/app/app.constant';
import { cmsphoto } from 'src/app/Models/cmsphoto';
import { PhotoMaster } from 'src/app/Models/Photomaster';
import { ApiService } from 'src/app/Service/api.service';
@Component({
  selector: 'app-addphoto',
  templateUrl: './addphoto.component.html',
  styleUrls: ['./addphoto.component.css']
})
export class AddphotoComponent implements OnInit {

 
  @Input()
  drawerClose!: Function;
  @Input()
  data: PhotoMaster = new PhotoMaster;
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk=true;
  emailpattern=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // namepatt=/^[a-zA-Z \-\']+/
  namepatt=/[a-zA-Z][a-zA-Z ]+/
  mobpattern=/^[6-9]\d{9}$/
  fileURL:any;
  albume:cmsphoto[]=[]
  // imgurl= appkeys.retriveimgUrl;
  imgurl = this.api.retriveimgUrl + "/galleryAlbum/";


  constructor(private api:ApiService, private message: NzNotificationService , private datePipe:DatePipe) { }

  ngOnInit(): void {
    this.loadalbume();
  }

  //Album master
  loadalbume(){
    this.api.getAllalbumMaster(0, 0, 'SEQUENCE_NO', 'desc', '').subscribe(data =>{
      this.albume=data['data'];
    },err => {
      console.log(err);
      this.isSpinning=false;
    });
  }


  close(): void {
    this.drawerClose();
  }
  resetDrawer(websitebannerPage: NgForm) {
    this.data=new PhotoMaster();
    websitebannerPage.form.markAsPristine();
    websitebannerPage.form.markAsUntouched();
    this.loadalbume
  }
  //save
  save(addNew: boolean,websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk=true;

    if (this.data.ALBUM_ID <= 0 && this.data.TITLE.trim() == ""
    && this.data.SEQUENCE_NO!=undefined
    && this.data.IMG_URL.trim()== "" )  {
      this.isOk =false
     this.message.error("Please Fill All The Information", "");
    }
  //   else
  //   if(this.data.ALBUM_ID== undefined || this.data.ALBUM_ID<=0){
  //     this.isOk =false
  //     this.message.error('Please Select Album','')

  //  }
  else
  if (this.data.ALBUM_ID <= 0 || this.data.ALBUM_ID == null) {
    this.isOk = false
    this.message.error('Please Enter Album Name', '')

  }
   else
   if(this.data.TITLE== null || this.data.TITLE.trim()==''){
    this.isOk =false
    this.message.error('Please Select Photo Title','')

  }
  // else
  // if(this.data.TITLE_MR== null || this.data.TITLE_MR.trim()==''){
  //  this.isOk =false
  //  this.message.error('कृपया शीर्षक (मराठी) प्रविष्ट करा','')

  // }
  else

  if(this.data.SEQUENCE_NO== undefined || this.data.SEQUENCE_NO<=0){
    this.isOk =false
    this.message.error('Please Enter Sequence Number','')
  }else

   if(this.data.IMG_URL== null || this.data.IMG_URL.trim()==''){
    this.isOk =false
    this.message.error('Pleses Select Image','')
  }
  if(this.isOk)
  {
    // this.isSpinning=false;

    this.isSpinning=true;
    if (this.fileURL != null) {
      var number = Math.floor(100000 + Math.random() * 900000);
      var fileExt = this.fileURL.name.split('.').pop();
      var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
      var url = '';
      url = d == null ? '' : d + number + '.' + fileExt;
      if (
        this.data.IMG_URL != undefined &&
        this.data.IMG_URL.trim() != ''
      ) {
        var arr = this.data.IMG_URL.split('/');
        if (arr.length > 1) {
          url = arr[5];
        }
      }
      this.api
        .onUpload2('galleryAlbum', this.fileURL, url)
        .subscribe((successCode) => {
          if (successCode ["code"] == 200) {
            this.data.IMG_URL =url
              // appkeys.retriveimgUrl + 'PhotoImage/' + url;
              if (this.data.ID) {
                this.api.updatephotoMaster(this.data).subscribe((successCode) => {
                  if (successCode ["code"] == 200) {
                    this.message.success('Information Updated successfully', '');
                    this.fileURL=null;
                    if (!addNew) this.drawerClose();
                    this.isSpinning = false;
                  } else {
                    this.message.error('Failed To Update Information', '');
                    this.isSpinning = false;
                  }
                });
              } else {
                this.api.createphotoMaster(this.data).subscribe((successCode) => {
                  if (successCode.code == '200') {
                    this.message.success('Information Added Successfully', '');
                    this.fileURL=null;
                    if (!addNew) this.drawerClose();
                    else {
                      this.data = new PhotoMaster();
                            this.resetDrawer(websitebannerPage);
                      this.data.IMG_URL= '';


                      this.api.getAllphotoMaster(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(
                        (data) => {
                          if (data['count'] == 0) {
                            this.data.SEQUENCE_NO = 1;
                          } else {
                            this.data.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
                          }
                        },
                        (err) => {
                          console.log(err);
                        }
                      );
                      // this.api.getAllphotoMaster(1,1,'SEQUENCE_NO','desc','').subscribe (data =>{
                      //   if (data['count']==0){
                      //     this.data.SEQUENCE_NO=1;
                      //   }else
                      //   {
                      //     this.data.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
                      //   }
                      // },err=>{
                      //   console.log(err);
                      // })
                    }
                    this.isSpinning = false;
                  } else {
                    this.message.error('Failed To Store Information', '');
                    this.isSpinning = false;
                  }
                });
              }
          } else {
            this.message.error('Failed To Add Photo', '');
            this.isSpinning = false;
          }
        });
    } else if(this.data.IMG_URL == null || this.data.IMG_URL == ''){
      this.message.error('Please Select Photo', '');
            this.isSpinning = false;
    }else{
      if (this.data.ID) {
        this.api.updatephotoMaster(this.data).subscribe((successCode) => {
          if (successCode ["code"] == 200) {
            this.message.success('Information Updated successfully', '');
            if (!addNew) this.drawerClose();
            this.isSpinning = false;
          } else {
            this.message.error('Failed To Update Information', '');
            this.isSpinning = false;
          }
        });
      } else {
        this.api.createphotoMaster(this.data).subscribe((successCode) => {
          if (successCode.code == '200') {
            this.message.success('Information Added Successfully', '');
            if (!addNew) this.drawerClose();
            else {
              this.data = new PhotoMaster();
            }
            this.isSpinning = false;
          } else {
            this.message.error('Failed To Store Information', '');
            this.isSpinning = false;
          }
        });
      }
    }

  // if(this.data.ID)
  // {
  //   this.api.updatephotoMaster(this.data)
  //   .subscribe(successCode => {
  //     if(successCode.code=="200")
  //     {
  //         this.message.success("Record Updated Successfully...", "");
  //         if(!addNew)
  //         this.drawerClose();
  //         this.isSpinning = false;
  //     }
  //     else
  //     {
  //       this.message.error("Record Updation Failed...", "");
  //       this.isSpinning = false;
  //     }
  //   });
  // }
  // else{

  //     this.api.createphotoMaster(this.data)
  //     .subscribe(successCode => {
  //       if(successCode.code=="200")
  //       {
  //         this.message.success("Record Created Successfully...", "");
  //          if(!addNew)
  //          this.drawerClose();
  //           else
  //           {
  //             this.data=new PhotoMaster();
  //           }
  //           this.isSpinning = false;
  //         }
  //          else
  //          {
  //           this.message.error("Record Creation Failed...", "");
  //           this.isSpinning = false;
  //          }
  //         });
  //       }
  }

// else
// {
//   this.message.error("Please Fill All Required Fields...","");
//   this.isSpinning = false;
// }
  }
  //Choose Image
  onFileSelected(event:any) {
    console.log(event);

    if (event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/png") {
      this.fileURL = <File>event.target.files[0];

    } else {
      this.message.error("Select Only JPEG/ JPG/ PNG File.", "");
      this.fileURL = null;
      this.data.IMG_URL= '';

    }
  }

  removeImage() {
    this.data.IMG_URL='';
    this.fileURL='';
  }


}
