import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
// import { appkeys } from 'src/app/app.constant';
import { CmsPresident_Message } from 'src/app/Models/cmspresident-message';
import { Cmspre } from 'src/app/Models/Cmspre';
import { ApiService } from 'src/app/Service/api.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
// import { ClientmasterService } from 'src/app/Service/clientmaster.service';

@Component({
  selector: 'app-addpresident-message',
  templateUrl: './addpresident-message.component.html',
  styleUrls: ['./addpresident-message.component.css']
})
export class AddpresidentMessageComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  data: CmsPresident_Message = new CmsPresident_Message;
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  CmsPresident_Message: any
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // namepatt=/^[a-zA-Z \-\']+/
  namepatt = /[a-zA-Z][a-zA-Z ]+/
  mobpattern = /^[6-9]\d{9}$/
  fileURL: any;
  president: Cmspre[] = [];
  // albume:AlbumeMaster[]=[]
  // imgurl = this.api.imgUrl + "aboutFederation";
  imgurl = this.api.retriveimgUrl + "/presidentMessage/";


  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '300px',
    // minHeight: '12rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
    ],
    customClasses: [],
    uploadUrl: '',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fonts', 'uploadUrl'],
      ['video']
    ]
  };


  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    // this.loadalbume();
    this.getname();
    this.getname1();
  }


  close(): void {
    this.drawerClose();
  }
  resetDrawer(websitebannerPage: NgForm) {
    this.data = new CmsPresident_Message();
    websitebannerPage.form.markAsPristine();
    websitebannerPage.form.markAsUntouched();
    this.getname();
    this.getname1();

  }



  //save
  save(addNew: boolean, websitebannerPage: NgForm): void {
    this.isOk = true;
    this.isSpinning = false;

    if (((this.data.NAME == null || this.data.NAME.trim() == "")) &&
      ((this.data.PRESIDENT_MESSAGE == null || this.data.PRESIDENT_MESSAGE.trim() == ""))
      && this.data.SEQUENCE_NO != undefined && this.data.IMG_URL.trim() == "") {
      this.isOk = false
      this.message.error("Please Fill All The Information", "");
    }
    else
      if (this.data.NAME == null || this.data.NAME.trim() == '') {
        this.isOk = false
        this.message.error('Please Enter Name', '')
      }
      else
        if (this.data.PRESIDENT_MESSAGE == null || this.data.PRESIDENT_MESSAGE.trim() == '') {
          this.isOk = false
          this.message.error('Please Enter Description', '')

        } else

          if (this.data.SEQUENCE_NO == undefined || this.data.SEQUENCE_NO <= 0) {
            this.isOk = false
            this.message.error('Please Enter Sequence Number', '')
          } else

            if (this.data.IMG_URL == null || this.data.IMG_URL.trim() == '') {
              this.isOk = false
              this.message.error('Please Select Image', '')
            }

    if (this.isOk) {
      this.isSpinning = true;

      {
        if (this.fileURL != null) {
          var number = Math.floor(100000 + Math.random() * 900000);
          var fileExt = this.fileURL.name.split('.').pop();
          var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
          var url = '';
          url = d == null ? '' : d + number + '.' + fileExt;
          if (
            this.data.IMG_URL != undefined &&
            this.data.IMG_URL.trim() != ''
          ) {
            var arr = this.data.IMG_URL.split('/');
            if (arr.length > 1) {
              url = arr[5];
            }
          }


          // this.CmsPresident_Message = this.data.FEDERATION_ID;

          this.api
            .onUpload2('presidentMessage', this.fileURL, url)
            .subscribe((successCode) => {
              if (successCode["code"] == 200) {
                this.data.IMG_URL = url;
                // appkeys.retriveimgUrl + 'WebsiteBannerImage/' + url;
                if (this.data.ID) {
                  this.api
                    .updatePresident_Message(this.data)
                    .subscribe((successCode) => {
                      if (successCode["code"] == 200) {
                        this.message.success(
                          'Information Updated Successfully', ''
                        );
                        this.fileURL = null;
                        if (!addNew) this.drawerClose();
                        this.isSpinning = false;
                      } else {
                        this.message.error('Failed To Update Information', '');
                        this.isSpinning = false;
                      }
                    });
                } else {
                  this.api
                    .createPresident_Message(this.data)
                    .subscribe((successCode) => {
                      if (successCode.code == '200') {
                        this.message.success('Information Added Successfully', '');
                        this.fileURL = null;
                        if (!addNew) this.drawerClose();
                        else {
                          this.data = new CmsPresident_Message();
                          this.resetDrawer(websitebannerPage);

                          this.data.STATUS == true;

                          this.api.getPresident_Message(1, 1, ' SEQUENCE_NO', 'desc', '').subscribe(data => {
                            if (data['count'] == 0) {
                              this.data.SEQUENCE_NO = 1;
                            } else {
                              this.data.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
                            }
                          },
                            (err) => {
                              console.log(err);
                            }
                          );
                        }
                        this.isSpinning = false;
                      } else {
                        this.message.error('Failed To Store Information', '');
                        this.isSpinning = false;
                      }
                    });
                }
              } else {
                this.message.error('Failed To Add Image', '');
                this.isSpinning = false;
              }
            });
        } else if (this.data.IMG_URL == null || this.data.IMG_URL == '') {
          this.message.error('Please Select Image', '');
          this.isSpinning = false;
        } else {
          if (this.data.ID) {
            this.api.updatePresident_Message(this.data).subscribe((successCode) => {
              if (successCode["code"] == 200) {
                this.message.success('Information Updated Successfully', '');

                if (!addNew) this.drawerClose();
                this.isSpinning = false;
              } else {
                this.message.error('Failed To Update Information', '');
                this.isSpinning = false;
              }
            });
          } else {

            this.api.createPresident_Message(this.data).subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Added Successfully', '');

                if (!addNew) this.drawerClose();
                else {
                  this.data = new CmsPresident_Message();
                  this.resetDrawer(websitebannerPage);

                }
                this.isSpinning = false;
              } else {
                this.message.error('Failed To Store Information', '');
                this.isSpinning = false;
              }
            });
          }
        }
      }
    }
  }

  //Choose image
  onFileSelected(event: any) {
    console.log(event);
    let imgs = new Image()
    let isLtsize = false;
    imgs.src = window.URL.createObjectURL(event.target.files[0]);

    imgs.onload = () => {
      console.log(imgs.height);
      console.log(imgs.width);
      if (250 == imgs.height && imgs.width == 250) {
        isLtsize = true;

        if (
          event.target.files[0].type == 'image/jpeg' ||
          event.target.files[0].type == 'image/jpg' ||
          event.target.files[0].type == 'image/png'
        ) {
          this.fileURL = <File>event.target.files[0];
        } else {
          this.message.error('Please select only JPEG/ JPG/ PNG file.', '');
          this.fileURL = null;
          this.data.IMG_URL = '';
        }

      } else {
        this.fileURL = null;
        this.data.IMG_URL = ' ';
        this.message.error('The image will not fit between the dimensions of ' + 250 + ' ' + 'px Height  ' + ' And ' + ' ' + 250 + ' px Width ', '');
      }
    }
  }
  removeImage() {
    this.data.IMG_URL = '';
    this.fileURL = '';

  }


  getname() {
    this.api.getPresident_Name(0, 0, 'NAME', 'asc', " AND STATUS = 1").subscribe(data => {
      this.president = data['data'];
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  getname1() {
    this.api.getPresident_Name(0, 0, 'GROUP_NAME', 'asc', " AND STATUS = 1").subscribe(data => {
      this.president = data['data'];
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) { return false; } return true;
  }



}
