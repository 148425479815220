export class letestnews{
  ID:number=0;
  DATE:any;
  DESCRIPTION1:string='';
  MEETING_SUB:string='';
  PHOTO1:string='';
  // WEB_LATEST_NEWS_STATUS:boolean=true;
  WEB_STATUS:boolean=true;
  IS_ALL_PROJECT_UPDATE:boolean = true;


  }
