


export class membership{

    ID:number=0;
    GUIDLINE_TITLE:string='';
    GUIDLINE_DESCRIPTION:string='';
    STATUS:boolean=true;
    SEQUENCE_NO:number=0;


 }


