export class VarientMaster {
    ID: number;
    CLIENT_ID: number;
    MODEL_ID: number;
    NAME: string;
    CODE: string;
    STATUS: boolean = true;
    SEQUENCE_NO: number;
    FUEL_TYPE: string;
    TRANSMISSION: string;
    CC: string
    VARIENT_LITER: string;
}