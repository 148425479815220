export class cmsPress {
    ID:number=0;
    TITLE:string='';
    DESCRIPTION:string='';
    IMG_URL:string='';
    STATUS:boolean=true;
    SEQUENCE_NO:number=0;
    NEWS_CATEGORY:string='';

    NAME:string='';

    NEWS_CATEGORY_ID:number=0;
  }
  