import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TrainerMaster } from 'src/app/Models/trainermaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addtrainermaster',
  templateUrl: './addtrainermaster.component.html',
  styleUrls: ['./addtrainermaster.component.css']
})

export class AddtrainermasterComponent implements OnInit {
  @Input()
  drawerClose!: Function;
  @Input()
  data: TrainerMaster = new TrainerMaster();
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  namepatt = /^[a-zA-Z \-\']+/;
  mobpattern = /^[6-9]\d{9}$/;
  subjects: any[] = [];

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    this.getDataOfAllTrainerAccessor();
  }

  allTrainerAccessorData = [];
  allTrainerAccessorDataExceptCurrent = [];

  getDataOfAllTrainerAccessor() {
    this.api.getAlltrainer(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.allTrainerAccessorData = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getDataOfAllTrainerAccessorExceptCurrent(trainerAccessorID) {
    this.api.getAlltrainer(0, 0, 'ID', 'desc', ' AND ID!=' + trainerAccessorID).subscribe(data => {
      if (data['code'] == 200) {
        this.allTrainerAccessorDataExceptCurrent = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  loadOrganizations() {
    this.isSpinning = false;
  }

  close(myForm: NgForm): void {
    this.passwordVisible = false;
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    this.isOk = true;
    this.isSpinning = false;

    if (this.data.NAME != null && this.data.NAME.trim() != '') {
      if (!this.namepatt.test(this.data.NAME)) {
        this.isOk = false;
        this.message.error('Please Enter Valid Name', '');
      }

    } else {
      this.isOk = false;
      this.message.error('Please Enter Name', '');
    }

    if (this.data.MOBILE_NO != null && this.data.MOBILE_NO != 0) {
      if (!this.mobpattern.test(this.data.MOBILE_NO.toString())) {
        this.isOk = false;
        this.message.error('Please Enter Valid Mobile Number', '');
      }

    } else {
      this.isOk = false;
      this.message.error('Please Enter Mobile Number', '');
    }

    if (this.data.EMAIL_ID != null && this.data.EMAIL_ID.trim() != '') {
      if (!this.emailpattern.test(this.data.EMAIL_ID)) {
        this.isOk = false;
        this.message.error('Please Enter Valid Email', '');
      }

    } else {
      this.isOk = false;
      this.message.error('Please Enter Email', '');
    }

    if (this.data.PASSWORD == null || this.data.PASSWORD.trim() == '') {
      this.isOk = false;
      this.message.error('Please Enter Valid Password', '');
    }

    if (this.data.ROLE_ID == undefined || this.data.ROLE_ID == 0) {
      this.isOk = false;
      this.message.error('Please Select Valid Position', '');
    }

    if (this.isOk) {
      this.data.SUBJECT_ID = 0;
      this.isSpinning = true;

      if (this.data.ID) {
        var emailData1 = this.allTrainerAccessorDataExceptCurrent.filter(obj => {
          return (obj.EMAIL_ID.trim() == this.data.EMAIL_ID.trim());
        });

        if (emailData1.length == 0) {
          this.api.updatetrainer(this.data).subscribe(successCode => {
            if (successCode.code == 200) {
              this.message.success("Information Updated Successfully", "");
              this.isSpinning = false;

              if (!addNew)
                this.close(myForm);

            } else {
              this.message.error("Failed to Update Information", "");
              this.isSpinning = false;
            }
          });

        } else {
          this.message.error("Email Already Exist. Please Enter Other Email", "");
          this.isSpinning = false;
        }

      } else {
        var emailData2 = this.allTrainerAccessorData.filter(obj => {
          return (obj.EMAIL_ID.trim() == this.data.EMAIL_ID.trim());
        });

        if (emailData2.length == 0) {
          this.api.createtrainer(this.data).subscribe(successCode => {
            if (successCode.code == 200) {
              this.message.success("Information Created Successfully", "");
              this.isSpinning = false;

              if (!addNew)
                this.close(myForm);

              else
                this.data = new TrainerMaster();

            } else {
              this.message.error("Trainer Creation Failed", "");
              this.isSpinning = false;
            }
          });

        } else {
          this.message.error("Email Already Exist. Please Enter Other Email", "");
          this.isSpinning = false;
        }
      }
    }
  }

  numberOnly(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  passwordVisible = false;
}
