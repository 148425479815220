import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
// import { appkeys } from 'src/app/app.constant';
import { WebsiteBannerMaster } from 'src/app/Models/websitebannermaster copy';
import { ApiService } from 'src/app/Service/api.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-addbanner',
  templateUrl: './addbanner.component.html',
  styleUrls: ['./addbanner.component.css']
  // providers: [DatePipe],
})
export class AddbannerComponent implements OnInit {



  @Input()
  drawerClose!: Function;
  @Input()
  data: WebsiteBannerMaster = new WebsiteBannerMaster();
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  // imgurl = this.api.imgUrl + "banner";
  imgurl = this.api.retriveimgUrl + "/banner/";
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200px',
    // minHeight: '12rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    // placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
    ],
    customClasses: [],
    uploadUrl: '',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fonts', 'uploadUrl'],
      ['video']
    ]
  };


  // namepatt=/^[a-zA-Z \-\']+/
  fileURL: any;
  height: any;
  width: any;
  constructor(
    private api: ApiService,
    private message: NzNotificationService,
    public datePipe: DatePipe
  ) { }

  ngOnInit(): void { }

  close(): void {
    this.drawerClose();
  }

  resetDrawer(websitebannerPage: NgForm) {
    this.data = new WebsiteBannerMaster();
    websitebannerPage.form.markAsPristine();
    websitebannerPage.form.markAsUntouched();
  }
  //save
  save(addNew: boolean, websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;
    if (
      this.data.NAME.trim() == '' && this.data.SEQUENCE_NO != undefined &&
      this.data.IMG_URL.trim() == ''
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Information', '');
    }
    else if (!this.namepatt.test(this.data.NAME)) {
      this.isOk = false;
      this.message.error('Please Enter Banner Name', '');
    }
    // else
    // if (this.data.TITLE == null || this.data.TITLE.trim() == '') {
    //   this.isOk = false
    //   this.message.error('Please Fill Title', '')
    // }
    //  else
    // if (this.data.DESCRIPTION == null || this.data.DESCRIPTION.trim() == '') {
    //   this.isOk = false
    //   this.message.error('Please Fill Description', '')
    // }
    else if (this.data.SEQUENCE_NO == null || this.data.SEQUENCE_NO <= 0) {
      this.isOk = false;
      this.message.error('Please Enter Sequence No', '');
    } else if (
      this.data.IMG_URL == undefined ||
      this.data.IMG_URL.trim() == ''
    ) {
      this.isOk = false;
      this.message.error('Please Select Image', '');
    }

    if (this.isOk) {
      this.isSpinning = true;
      {
        if (this.fileURL != null) {
          var number = Math.floor(100000 + Math.random() * 900000);
          var fileExt = this.fileURL.name.split('.').pop();
          var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
          var url = '';
          url = d == null ? '' : d + number + '.' + fileExt;
          if (
            this.data.IMG_URL != undefined &&
            this.data.IMG_URL.trim() != ''
          ) {
            var arr = this.data.IMG_URL.split('/');
            if (arr.length > 1) {
              url = arr[5];
            }
          }

          this.api
            .onUpload2('banner', this.fileURL, url)
            .subscribe((successCode) => {
              if (successCode["code"] == 200) {
                this.data.IMG_URL = url;
                // appkeys.retriveimgUrl + 'WebsiteBannerImage/' + url;
                if (this.data.ID) {
                  this.api
                    .updatewebsiteBanner(this.data)
                    .subscribe((successCode) => {
                      if (successCode["code"] == 200) {
                        this.message.success(
                          'Information updated successfully', ''
                        );
                        this.fileURL = null;
                        if (!addNew) this.drawerClose();
                        this.isSpinning = false;
                      } else {
                        this.message.error('Failed to information', '');
                        this.isSpinning = false;
                      }
                    });
                } else {
                  this.api
                    .createwebsiteBanner(this.data)
                    .subscribe((successCode) => {
                      if (successCode.code == '200') {
                        this.message.success('Information added successfully', '');
                        this.fileURL = null;
                        if (!addNew) this.drawerClose();
                        else {
                          this.data = new WebsiteBannerMaster();
                          this.resetDrawer(websitebannerPage);

                          this.data.STATUS == true;
                          // this.data.IMG_URL==null;
                          this.fileURL = '';
                          this.api.getAllwebsiteBanner(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
                            if (data['count'] == 0) {
                              this.data.SEQUENCE_NO = 1;
                            } else {
                              this.data.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
                            }
                          }, err => {
                            console.log(err);
                          })
                        }
                        this.isSpinning = false;
                      } else {
                        this.message.error('Failed to store information', '');
                        this.isSpinning = false;
                      }
                    });
                }
              } else {
                this.message.error('Failed to add image', '');
                this.isSpinning = false;
              }
            });
        } else if (this.data.IMG_URL == null || this.data.IMG_URL == '') {
          this.message.error('Please select image for banner', '');
          this.isSpinning = false;
        } else {
          if (this.data.ID) {
            this.api.updatewebsiteBanner(this.data).subscribe((successCode) => {
              if (successCode["code"] == 200) {
                this.message.success('Information updated successfully', '');
                if (!addNew) this.drawerClose();
                this.isSpinning = false;
              } else {
                this.message.error('Failed to update information', '');
                this.isSpinning = false;
              }
            });
          } else {
            this.api.createwebsiteBanner(this.data).subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information added successfully', '');
                if (!addNew) this.drawerClose();
                else {
                  this.data = new WebsiteBannerMaster();
                }
                this.isSpinning = false;
              } else {
                this.message.error('Failed to store information', '');
                this.isSpinning = false;
              }
            });
          }
        }
      }
    }

    //  else
    //  {
    //    this.message.error("Please Fill All Required Fields...","");
    //    this.isSpinning = false;

    //  }
  }
  ////
  ////
  //Choose image
  // onFileSelected(event: any) {
  //   console.log(event);
  //   let imgs = new Image()
  //   let isLtsize = false;
  //   imgs.src = window.URL.createObjectURL(event.target.files[0]);

  //   imgs.onload = () => {
  //     console.log(imgs.height);
  //     console.log(imgs.width);
  //     if (576 == imgs.height && imgs.width == 1500) {
  //       isLtsize = true;

  //       if (
  //         event.target.files[0].type == 'image/jpeg' ||
  //         event.target.files[0].type == 'image/jpg' ||
  //         event.target.files[0].type == 'image/png'
  //       ) {
  //         this.fileURL = <File>event.target.files[0];
  //       } else {
  //         this.message.error('Please select only JPEG/ JPG/ PNG file.', '');
  //         this.fileURL = null;
  //         this.data.IMG_URL = '';
  //       }

  //     } else {
  //       this.fileURL = null;
  //       this.data.IMG_URL = ' ';
  //       this.message.error('The image will not fit between the dimensions of ' + 576 + ' ' + 'px Height  ' + ' And ' + ' ' + 1500 + ' px Width ', '');
  //     }
  //   }
  // }



  onFileSelected(event: any) {
    console.log(event);

    if (event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/png") {
      this.fileURL = <File>event.target.files[0];

    } else {
      this.message.error("Select Only JPEG/ JPG/ PNG File.", "");
      this.fileURL = null;
      this.data.IMG_URL = '';

    }
  }


  // image: any = '';

  // onFileSelected(event: any) {
  //   if (!event.target.files[0].type.includes('image/')) {
  //     this.message.error('Please select only JPEG/ JPG/ PNG file type. ', '');
  //     console.log(event.target.files[0].type.includes('image/'));
  //     this.data.IMG_URL = '';
  //   } else {
  //     const reader = new FileReader();
  //     let isLtsize = false;
  //     let imgs = new Image();
  //     imgs.src = window.URL.createObjectURL(event.target.files[0]);
  //     console.log(imgs.src, 'imgs.src');
  //     imgs.onload = () => {
  //       console.log(imgs.height);
  //       console.log(imgs.width);
  //       if (
  //         event.target.files[0].type == 'image/jpeg' ||
  //         event.target.files[0].type == 'image/jpg' ||
  //         event.target.files[0].type == 'image/png'
  //       ) {
  //         if (
  //           576 == imgs.height && imgs.width == 1500
  //         ) {
  //           isLtsize = true;
  //         }
  //         if (!isLtsize) {
  //           this.message.error(
  //             'The Selected Image size must between 576px height and 1500px width.',
  //             ''
  //           );
  //           this.data.IMG_URL = '';
  //         } else {
  //           if (
  //             event.target.files[0].type == 'image/jpeg' ||
  //             event.target.files[0].type == 'image/jpg' ||
  //             event.target.files[0].type == 'image/png'
  //           ) {
  //             this.fileURL = <File>event.target.files[0];
  //             const reader = new FileReader();
  //             if (event.target.files && event.target.files.length) {
  //               const [file] = event.target.files;
  //               reader.readAsDataURL(file);
  //               reader.onload = () => {
  //                 this.image = reader.result as string;
  //               };
  //               var number = Math.floor(100000 + Math.random() * 900000);
  //               console.log('number', number);
  //               var fileExt = this.fileURL.name.split('.').pop();
  //               console.log('fileExt', fileExt);
  //               var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
  //               console.log(d, 'd');
  //               var url = '';
  //               url = d == null ? '' : d + number + '.' + fileExt;
  //               console.log(url, 'url');
  //             }
  //           } else {
  //             this.message.error(
  //               'Please select only JPEG/ JPG/ PNG file type.',
  //                ''
  //             );
  //             this.fileURL = null;
  //             this.data.IMG_URL = '';
  //           }
  //         }
  //       } else {
  //         this.message.error(
  //           'Please select only JPEG/ JPG/ PNG file type.',
  //           ''
  //         );
  //         this.fileURL = null;
  //         this.data.IMG_URL = '';
  //       }
  //     };
  //   }
  // }

  removeImage() {
    this.data.IMG_URL = '';
    this.fileURL = '';
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) { return false; } return true;
  }

}
