import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Branchmaster } from 'src/app/Models/Branchmaster';
import { Department } from 'src/app/Models/department';
import { Designationmaster } from 'src/app/Models/designationmaster';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addemployeemaster',
  templateUrl: './addemployeemaster.component.html',
  styleUrls: ['./addemployeemaster.component.css']
})

export class AddemployeemasterComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input()
  data: EmployeeMaster = new EmployeeMaster();
  @ViewChild('accountMasterPage', { static: false }) public userFrm: NgForm;
  listdata1 = [];
  listdata2 = [];
  isSpinning = false
  @Input() listOfData: EmployeeMaster[] = [];
  OPEN_TIME;
  CLOSE_TIME;
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss')
  @Input() DAYS = false;
  isOk = true;
  @Input()
  dataList: any[] = [];
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  emailpattern =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  mobpattern = /^[6-9]\d{9}$/;
  passwordVisible = false;
  branch: Branchmaster[] = [];
  @Input() reportinghead: EmployeeMaster[] = [];
  department: Department[] = [];
  designation: Designationmaster[] = [];
  orgId = this.cookie.get('orgId');

  constructor(private api: ApiService, private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() {
    this.branchinfo();
    this.reporthead();
    this.dept();
    this.desig();
    this.getallorg1();
  }

  branchinfo() {
    this.api.getAllBranch(0, 0, '', '', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      this.branch = data['data'];

    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  reporthead() {
    var d = '';

    if (this.data.ID != undefined && this.data.ID > 0) {
      d = ' AND ID !=' + this.data.ID
    }

    this.api.getAllemployeeMaster(0, 0, '', '', ' AND  ID in (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID = ' + this.orgId + ' AND ROLE_ID<>2) AND STATUS=1' + d).subscribe(data => {
      this.reportinghead = data['data'];

    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  dept() {
    this.api.getAllDepartments(0, 0, '', '', '  AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      this.department = data['data'];

    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  desig() {
    this.api.getAllDesignation(0, 0, '', '', '  AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      this.designation = data['data'];

    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  alphaOnly(event) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }
    return true;
  }

  close(accountMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(accountMasterPage);
  }

  clsAlphaNoOnly(event) {
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
      return true;
    }

    event.preventDefault();
    return false;
  }

  getallorg1() {
    this.api.getAllemployeeMaster(0, 0, 'ID', 'desc', ' AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.listdata1 = data['data'];
      }
    }, err => {
      console.log(err);
    });
  }

  getallorg2(userID) {
    this.api.getAllemployeeMaster(0, 0, 'ID', 'desc', ' AND ORG_ID=' + this.orgId + " AND ID!=" + userID).subscribe(data => {
      if (data['code'] == 200) {
        this.listdata2 = data['data'];
      }
    }, err => {
      console.log(err);
    });
  }

  resetDrawer2() {
    this.userFrm.form.reset();
  }

  resetDrawer(accountMasterPage: NgForm) {
    accountMasterPage.form.reset();
    this.add1();
  }

  add1() {
    this.api.getAllemployeeMaster(1, 1, 'SEQUENCE_NO', 'desc', ' AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['count'] == 0) {
        this.data.SEQUENCE_NO = 1;

      } else {
        this.data.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
      }
      this.data.STATUS = true;

    }, err => {
      console.log(err);
    })
  }

  save(addNew: boolean, accountMasterPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;

    if (this.data.NAME == undefined || this.data.NAME.toString() == '') {
      this.isOk = false;
      this.message.error('Please Enter Name', '');
    }

    if (!this.namepatt.test(this.data.NAME)) {
      this.isOk = false;
      this.message.error('Please Enter Valid Name', '');
    }

    if (this.data.EMAIL_ID == null || this.data.EMAIL_ID.trim() == '') {
      this.isOk = false;
      this.message.error('Please Enter Email ID', '');
    }

    if (!this.emailpattern.test(this.data.EMAIL_ID)) {
      this.isOk = false;
      this.message.error('Please Enter Valid Email ID', '');
    }

    if (this.data.PASSWORD != undefined) {
      if (this.data.PASSWORD.trim() != "") {
        if (this.data.PASSWORD.length >= 8) {
          if (!this.api.passwordIsValid(this.data.PASSWORD)) {
            this.isOk = false;
            this.message.error("Please Enter Valid Password", "");
          }

        } else {
          this.isOk = false;
          this.message.error("Password Must be or Greater than 8 Characters", "");
        }

      } else {
        this.isOk = false;
        this.message.error("Please Enter Password", "");
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Password", "");
    }

    if (this.data.MOBILE_NUMBER == undefined || this.data.MOBILE_NUMBER <= 0) {
      this.isOk = false;
      this.message.error('Please Enter Mobile Number', '');
    }

    if (!this.mobpattern.test(this.data.MOBILE_NUMBER.toString())) {
      this.isOk = false;
      this.message.error('Please Enter Valid Mobile Number', '');
    }

    if (this.data.EMPLOYEE_CODE == null || this.data.EMPLOYEE_CODE.trim() == '') {
      this.isOk = false;
      this.message.error('Please Enter Employee Code', '');
    }

    if (this.data.SEQUENCE_NO == null || this.data.SEQUENCE_NO <= 0) {
      this.isOk = false;
      this.message.error('Please Enter Sequence Number', '');
    }

    if (this.data.DEPARTMENT_ID == undefined) {
      this.isOk = false
      this.message.error('Please Select Department', '')
    }

    if (this.data.BRANCH_ID == undefined) {
      this.isOk = false
      this.message.error('Please Select Branch', '')
    }

    if (this.data.DESIGNATION_ID == undefined) {
      this.isOk = false
      this.message.error('Please Select Designation', '')
    }

    if (this.data.REPORTING_HEAD_ID == undefined) {
      this.isOk = false
      this.message.error('Please Select Reporting Head', '')
    }

    if (this.data.TEMPORARY_HEAD_ID == undefined) {
      this.isOk = false
      this.message.error('Please Select Temporary Head', '')
    }

    if (this.isOk) {
      if (this.data.DEPARTMENT_ID == "0")
        this.data.DEPARTMENT_ID = "0";

      if (this.data.BRANCH_ID == "0")
        this.data.BRANCH_ID = "0";

      if (this.data.DESIGNATION_ID == "0")
        this.data.DESIGNATION_ID = "0";

      if (this.data.REPORTING_HEAD_ID == "0")
        this.data.REPORTING_HEAD_ID = "0";

      if (this.data.TEMPORARY_HEAD_ID == "0")
        this.data.TEMPORARY_HEAD_ID = "0";

      this.isSpinning = true;
      this.data.ORG_ID = Number(this.orgId);

      if (this.data.ID) {
        var emailData2 = this.listdata2.filter(obj => {
          return (obj.EMPLOYEE_CODE == this.data.EMPLOYEE_CODE) || (obj.EMAIL_ID == this.data.EMAIL_ID);
        });

        if (emailData2.length == 0) {
          this.api.updateemployeeMaster(this.data).subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Information Updated Successfully", "");

              if (!addNew)
                this.drawerClose();

              this.resetDrawer(accountMasterPage);
              this.isSpinning = false;

            } else {
              this.message.error("Information Updation Failed", "");
              this.isSpinning = false;
            }
          });

        } else {
          this.message.error("Employee Code Already Exist. Please Enter Other Employee Code", "");
          this.isSpinning = false;
        }

      } else {
        var emailData = this.listdata1.filter(obj => {
          return (obj.EMPLOYEE_CODE == this.data.EMPLOYEE_CODE) || (obj.EMAIL_ID == this.data.EMAIL_ID);
        });

        if (emailData.length == 0) {
          this.api.createemployeeMaster(this.data).subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.isSpinning = false;
              this.message.success("Information Saved Successfully", "");

              if (!addNew) {
                this.drawerClose();
                this.resetDrawer(accountMasterPage);

              } else {
                this.data = new EmployeeMaster();
                this.resetDrawer(accountMasterPage);

                this.api.getAllemployeeMaster(0, 0, 'SEQUENCE_NO', 'desc', ' AND  ID in(SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID = ' + this.orgId + ' AND ROLE_ID<>2)   AND STATUS=1  ').subscribe(data => {
                  if (data['count'] > 0) {
                    this.reportinghead = data['data'];
                  }

                }, err => {
                  console.log(err);
                })
              }

            } else {
              this.message.error("Information Saved Failed", "");
              this.isSpinning = false;
            }
          });

        } else {
          this.message.error("Employee Code Already Exist. Please Enter Other Employee Code", "");
          this.isSpinning = false;
        }
      }
    }
  }
}
