import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { UnitMaster } from 'src/app/Models/UnitMaster';
import { ApiService } from 'src/app/Service/api.service';
import { ManageUnitMembersComponent } from '../manage-unit-members/manage-unit-members.component';

@Component({
  selector: 'app-assign-unit-member',
  templateUrl: './assign-unit-member.component.html',
  styleUrls: ['./assign-unit-member.component.css']
})

export class AssignUnitMemberComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: UnitMaster;
  @Input() drawerVisible: boolean;
  isSpinning = false;
  leaveTypes = [];
  namePattern = "([A-Za-z0-9 \s]){1,}";

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getMembers();
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  numberOnly(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  isVisible = false;
  isConfirmLoading = false;
  roleID: number;

  showModal(rID: number): void {
    this.roleID = rID;
    this.isVisible = true;
  }

  NEW_MEMBER_ID: number;

  handleOk(data: UnitMaster): void {
    this.isConfirmLoading = true;

    this.api.assignUnit(this.roleID, data, this.NEW_MEMBER_ID).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Updated Successfully", "");
        this.isConfirmLoading = true;
        this.isVisible = false;

        if (this.roleID == 1)
          data.DIRECTOR = this.NEW_MEMBER_ID;
        else if (this.roleID == 2)
          data.OFFICER1 = this.NEW_MEMBER_ID;
        else if (this.roleID == 3)
          data.OFFICER2 = this.NEW_MEMBER_ID;
        else if (this.roleID == 4)
          data.VP = this.NEW_MEMBER_ID;

        this.getData1(data);
        this.NEW_MEMBER_ID = undefined;

      } else
        this.message.error("Failed to Update", "");
    });
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  members = [];
  memberLoading = false;

  getMembers() {
    this.memberLoading = true;

    this.api.getAllMembers(0, 0, "NAME", "asc", "").subscribe(data => {
      if (data['code'] == 200) {
        this.memberLoading = false;
        this.members = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  BOD: string = "";
  memberList = [];

  directorPhoto: string = "";
  directorName: string = "";
  directorMobile: string = "";
  directorFederation: string = "";
  directorUnit: string = "";
  directorGroup: string = "";

  officer1Photo: string = "";
  officer1Name: string = "";
  officer1Mobile: string = "";
  officer1Federation: string = "";
  officer1Unit: string = "";
  officer1Group: string = "";

  officer2Photo: string = "";
  officer2Name: string = "";
  officer2Mobile: string = "";
  officer2Federation: string = "";
  officer2Unit: string = "";
  officer2Group: string = "";

  vpPhoto: string = "";
  vpName: string = "";
  vpMobile: string = "";
  vpFederation: string = "";
  vpUnit: string = "";
  vpGroup: string = "";

  directorYesNo: boolean = false;
  officer1YesNo: boolean = false;
  officer2YesNo: boolean = false;
  vpYesNo: boolean = false;

  clearValues() {
    this.directorPhoto = "assets/anony.png";
    this.directorName = "";
    this.directorMobile = "";
    this.directorFederation = "";
    this.directorUnit = "";
    this.directorGroup = "";

    this.officer1Photo = "assets/anony.png";
    this.officer1Name = "";
    this.officer1Mobile = "";
    this.officer1Federation = "";
    this.officer1Unit = "";
    this.officer1Group = "";

    this.officer2Photo = "assets/anony.png";
    this.officer2Name = "";
    this.officer2Mobile = "";
    this.officer2Federation = "";
    this.officer2Unit = "";
    this.officer2Group = "";

    this.vpPhoto = "assets/anony.png";
    this.vpName = "";
    this.vpMobile = "";
    this.vpFederation = "";
    this.vpUnit = "";
    this.vpGroup = "";

    this.directorYesNo = false;
    this.officer1YesNo = false;
    this.officer2YesNo = false;
    this.vpYesNo = false;
  }

  getData1(dataParam: UnitMaster) {
    this.isSpinning = true;
    this.BOD = "";
    this.clearValues();

    this.BOD += dataParam.DIRECTOR ? dataParam.DIRECTOR + "," : "";
    this.BOD += dataParam.OFFICER1 ? dataParam.OFFICER1 + "," : "";
    this.BOD += dataParam.OFFICER2 ? dataParam.OFFICER2 + "," : "";
    this.BOD += dataParam.VP ? dataParam.VP + "," : "";

    if (this.BOD.length > 0) {
      this.BOD = this.BOD.substring(0, this.BOD.length - 1);

      this.api.getAllMembers(0, 0, "", "", " AND ID IN (" + this.BOD + ")").subscribe(data => {
        if (data['code'] == 200) {
          this.isSpinning = false;
          this.memberList = data['data'];

          if (dataParam.DIRECTOR) {
            this.directorYesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.DIRECTOR);
            });

            this.directorPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.directorPhoto != null) && (this.directorPhoto != ''))
              this.directorPhoto = this.api.retriveimgUrl + "profileImage/" + this.directorPhoto;

            else
              this.directorPhoto = "assets/anony.png";

            this.directorName = member.length > 0 ? member[0]["NAME"] : "";
            this.directorMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.directorFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.directorUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.directorGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.OFFICER1) {
            this.officer1YesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.OFFICER1);
            });

            this.officer1Photo = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.officer1Photo != null) && (this.officer1Photo != ''))
              this.officer1Photo = this.api.retriveimgUrl + "profileImage/" + this.officer1Photo;

            else
              this.officer1Photo = "assets/anony.png";

            this.officer1Name = member.length > 0 ? member[0]["NAME"] : "";
            this.officer1Mobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.officer1Federation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.officer1Unit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.officer1Group = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.OFFICER2) {
            this.officer2YesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.OFFICER2);
            });

            this.officer2Photo = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.officer2Photo != null) && (this.officer2Photo != ''))
              this.officer2Photo = this.api.retriveimgUrl + "profileImage/" + this.officer2Photo;

            else
              this.officer2Photo = "assets/anony.png";

            this.officer2Name = member.length > 0 ? member[0]["NAME"] : "";
            this.officer2Mobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.officer2Federation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.officer2Unit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.officer2Group = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.VP) {
            this.vpYesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.VP);
            });

            this.vpPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.vpPhoto != null) && (this.vpPhoto != ''))
              this.vpPhoto = this.api.retriveimgUrl + "profileImage/" + this.vpPhoto;

            else
              this.vpPhoto = "assets/anony.png";

            this.vpName = member.length > 0 ? member[0]["NAME"] : "";
            this.vpMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.vpFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.vpUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.vpGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else {
      this.isSpinning = false;
    }
  }

  memberDrawerTitle: string;
  memberDrawerData: any;
  memberDrawerVisible = false;
  BOD_Position: number;
  @ViewChild(ManageUnitMembersComponent, { static: false }) ManageUnitMembersComponentVar: ManageUnitMembersComponent;

  addMembers(BODPosition: any): void {
    this.BOD_Position = BODPosition;

    let role = "";
    if (this.BOD_Position == 1)
      role = "Director";
    else if (this.BOD_Position == 2)
      role = "Officer 1";
    else if (this.BOD_Position == 3)
      role = "Officer 2";
    else if (this.BOD_Position == 4)
      role = "Vice Precident";

    this.memberDrawerTitle = "Add Unit Members for " + role;
    this.memberDrawerVisible = true;
    this.ManageUnitMembersComponentVar.sortKey = "id";
    this.ManageUnitMembersComponentVar.sortValue = "desc";
    this.ManageUnitMembersComponentVar.search(true, BODPosition, this.data.ID);
  }

  memberDrawerClose(): void {
    this.memberDrawerVisible = false;

    this.api.getAllUnits(0, 0, "", "", " AND ID=" + this.data.ID).subscribe(data => {
      if (data['code'] == 200) {
        var updatedData = data['data'][0];
        this.getData1(updatedData);
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  get memberDrawerCloseCallback() {
    return this.memberDrawerClose.bind(this);
  }
}