import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { GroupMaster } from 'src/app/Models/GroupMaster';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-group-drawer',
  templateUrl: './group-drawer.component.html',
  styleUrls: ['./group-drawer.component.css']
})

export class GroupDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: GroupMaster;
  @Input() drawerVisible: boolean;
  isSpinning = false;
  leaveTypes = [];
  namePattern = "([A-Za-z0-9 \s]){1,}";
  federationID = this._cookie.get("FEDERATION_ID");
  unitID = this._cookie.get("UNIT_ID");
  groupID = this._cookie.get("GROUP_ID");

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _cookie: CookieService) { }

  ngOnInit() {
    this.getUnits();
  }

  units = [];

  getUnits() {
    var federationFilter = "";
    if (this.federationID != "0") {
      federationFilter = " AND FEDERATION_ID=" + this.federationID;
    }

    var unitFilter = "";
    if (this.unitID != "0") {
      unitFilter = " AND ID=" + this.unitID;
    }

    var groupFilter = "";
    if (this.groupID != "0") {
      groupFilter = " AND ID=(SELECT UNIT_ID FROM group_master WHERE ID=" + this.groupID + ")";
    }

    this.units = [];

    this.api.getAllUnits(0, 0, "NAME", "asc", " AND STATUS=1" + federationFilter + unitFilter + groupFilter).subscribe(data => {
      if (data['code'] == 200) {
        this.units = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.UNIT_ID == undefined || this.data.UNIT_ID == null) {
      isOk = false;
      this.message.error("Please Selct Valid Unit", "");
    }

    if (this.data.NAME != undefined && this.data.NAME != null) {
      if (this.data.NAME.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.NAME)) {
          isOk = false;
          this.message.error("Please Enter Valid Group Name", "");
        }

      } else {
        isOk = false;
        this.message.error("Please Enter Valid Group Name", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Enter Valid Group Name", "");
    }

    if (this.data.TYPE == undefined || this.data.TYPE == null) {
      isOk = false;
      this.message.error("Please Selct Valid Group Type", "");
    }

    if (this.data.BO_DATE == undefined || this.data.BO_DATE == null) {
      isOk = false;
      this.message.error("Please Selct Valid Date", "");
    }

    if (this.data._20_21_MEMBER_COUNT != undefined && this.data._20_21_MEMBER_COUNT != null) {
      if (this.data._20_21_MEMBER_COUNT.trim() == '') {
        isOk = false;
        this.message.error("Please Enter Valid 20 21 Member Count", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Enter Valid 20 21 Member Count", "");
    }

    if (this.data._21_22_MEMBER_COUNT != undefined && this.data._21_22_MEMBER_COUNT != null) {
      if (this.data._21_22_MEMBER_COUNT.trim() == '') {
        isOk = false;
        this.message.error("Please Enter Valid 21 22 Member Count", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Enter Valid 21 22 Member Count", "");
    }

    if (isOk) {
      this.isSpinning = true;
      this.data.BO_DATE = this.datePipe.transform(this.data.BO_DATE, "yyyy-MM-dd");

      if (this.data.ID) {
        this.api.updateGroup(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Group Details Updated Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

          } else {
            this.message.error("Group Details Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createGroup(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Group Created Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

            else {
              this.data = new GroupMaster();
            }

          } else {
            this.message.error("Group Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }

  numberOnly(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  today = new Date().setDate(new Date().getDate());

  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;
}
