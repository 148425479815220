import { Component, Input, OnInit } from '@angular/core';
// import { NzTableQueryParams } from 'ng-zorro-antd/table';
// import { appkeys } from 'src/app/app.constant';
import { PhotoMaster } from 'src/app/Models/photomaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-listphoto',
  templateUrl: './listphoto.component.html',
  styleUrls: ['./listphoto.component.css']
})
export class ListphotoComponent implements OnInit {

  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: PhotoMaster = new PhotoMaster();
  formTitle = 'Photo List';
  @Input()
  dataList: any[] = [];
  @Input()
  photoID: any = 0;
  //  @Input ()
  //  photoID:PhotoMaster[]=[];

  // dataList = [];
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = 'desc';
  sortKey: string = 'id';
  searchText: string = '';
  filterQuery: string = '';
  isFilterApplied: string = 'default';
  columns: string[][] = [
    ['SEQUENCE_NO', 'Sequence No'],
    ['ALBUM_ID', 'Album'],
    ['TITLE', 'Title'],
  ];
  @Input()
  drawerClose2!: Function;

  // imgurl = appkeys.retriveimgUrl;
  imgurl = this.api.retriveimgUrl + "/galleryAlbum/";

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.loadingRecords = false;
    this.search();
    // this.loadData();
    // this.search()
  }

  keyup(event:any) {
    this.search();
  }

  // search(reset: boolean = false) {
  //   if (reset) {
  //     this.pageIndex = 1;
  //     this.sortKey = 'id';
  //     this.sortValue = 'desc';
  //   }
  //   // this.loadingRecords = true;
  //   var sort: string;
  //   try {
  //     sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
  //   } catch (error) {
  //     sort = '';
  //   }
  //   var likeQuery = '';
  //   console.log('search text:' + this.searchText);
  //   if (this.searchText != '') {
  //     likeQuery = ' AND';
  //     this.columns.forEach((column) => {
  //       likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
  //     });
  //     likeQuery = likeQuery.substring(0, likeQuery.length - 2);
  //     console.log('likeQuery' + likeQuery);
  //   }
  //   var f = '';
  //   if (this.photoID == 0) {
  //     f = likeQuery;
  //   } else {
  //     f = likeQuery + ' AND ALBUM_ID=0';
  //   }

  //   this.api
  //     .getAllphotoMaster(this.pageIndex, this.pageSize, this.sortKey, sort, f)
  //     .subscribe(
  //       (data) => {
  //         this.loadingRecords = false;
  //         this.totalRecords = data['count'];
  //         this.dataList = data['data'];
  //         console.log(this.dataList);
  //         for (var i = 0; i < this.dataList.length; i++) {
  //           console.log(
  //             this.imgurl + 'PhotoImage/' + this.dataList[i]['ALBUM_IMG_URL']
  //           );
  //         }
  //         if (this.totalRecords == 0) {
  //           data.SEQUENCE_NO = 1;
  //         } else {
  //           data.SEQUENCE_NO =
  //             this.dataList[this.dataList.length - 1]['SEQUENCE_NO'] + 1;
  //         }
  //       },
  //       (err) => {
  //         console.log(err);
  //       }
  //     );
  // }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = 'id';
      this.sortValue = 'desc';
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    var likeQuery = '';
    console.log('search text:' + this.searchText);
    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
      console.log('likeQuery' + likeQuery);
    }
    var f = '';
    if (this.photoID == 0) {
      f = likeQuery;
    } else {
      f = likeQuery + ' AND ALBUM_ID=' + this.photoID;
    }

    this.api
      .getAllphotoMaster(this.pageIndex, this.pageSize, this.sortKey, sort, f)
      .subscribe(
        (data) => {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          console.log(this.dataList);
          for (var i = 0; i < this.dataList.length; i++) {
            console.log(
              this.imgurl + 'galleryAlbum/' + this.dataList[i]['ALBUM_IMG_URL']
            );
          }
          if (this.totalRecords == 0) {
            data.SEQUENCE_NO = 1;
          } else {
            data.SEQUENCE_NO =
              this.dataList[this.dataList.length - 1]['SEQUENCE_NO'] + 1;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = 'Add New Photo';
    this.drawerData = new PhotoMaster();
    this.api.getAllphotoMaster(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(
      (data) => {
        if (data['count'] == 0) {
          this.drawerData.SEQUENCE_NO = 1;
        } else {
          this.drawerData.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
        }
      },
      (err) => {
        console.log(err);
      }
    );
    // this.drawerData.IS_ACTIVE=true;
    this.drawerVisible = true;
  }
  edit(data: PhotoMaster): void {
    this.drawerTitle = 'Update Photo Details';
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
  // sort(params: NzTableQueryParams): void {
  //   const { pageSize, pageIndex, sort } = params;
  //   const currentSort = sort.find((item) => item.value !== null);
  //   const sortField = (currentSort && currentSort.key) || 'id';
  //   const sortOrder = (currentSort && currentSort.value) || 'asc';
  //   console.log(currentSort);

  //   console.log('sortOrder :' + sortOrder);
  //   this.pageIndex = pageIndex;
  //   this.pageSize = pageSize;

  //   if (this.pageSize != pageSize) {
  //     this.pageIndex = 1;
  //     this.pageSize = pageSize;
  //   }

  //   if (this.sortKey != sortField) {
  //     this.pageIndex = 1;
  //     this.pageSize = pageSize;
  //   }

  //   this.sortKey = sortField;
  //   this.sortValue = sortOrder;
  //   this.search();
  // }

  loadData() {
    this.api
      .getAllphotoMaster(
        this.pageIndex,
        this.pageSize,
        this.sortKey,
        '',
        'AND ALBUM_ID=' + this.photoID
      )
      .subscribe((data) => {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        console.log(this.dataList);
        // for (var i=0;i<this.dataList.length;i++){
        // console.log(this.imgurl+'galleryAlbum/'+this.dataList[i]['ALBUM_IMG_URL'])

        // }
        //   if(this.totalRecords==0){
        //     data.SEQUENCE_NO=1;
        //   }else{
        //     data.SEQUENCE_NO= this.dataList[this.dataList.length-1]['SEQUENCE_NO']+1
        //   }
        // }, err => {
        //   console.log(err);
      });
  }


}
