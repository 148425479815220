import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { InchargeActivity } from 'src/app/Models/InchargeActivity';
import { ApiService } from 'src/app/Service/api.service';
import { InchargeActivityDrawerComponent } from '../incharge-activity-drawer/incharge-activity-drawer.component';

@Component({
  selector: 'app-incharge-activity-master',
  templateUrl: './incharge-activity-master.component.html',
  styleUrls: ['./incharge-activity-master.component.css']
})

export class InchargeActivityMasterComponent implements OnInit {
  formTitle = "Manage Incharge Activities";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [["INCHARGE_AREA_NAME", "Incharge Area"], ["PROJECT_NAME", "Target Area"], ["DATE", "Date"], ["COUNT", "Count"], ["DETAILS", "Details"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: InchargeActivity = new InchargeActivity();
  isSpinning = false;
  federationID = this._cookie.get("FEDERATION_ID");
  unitID = this._cookie.get("UNIT_ID");
  groupID = this._cookie.get("GROUP_ID");
  roleID: number = this.api.roleId;
  inchargeAreaIDs: string = this._cookie.get("INCHARGE_AREA_IDS");

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _cookie: CookieService) { }

  ngOnInit() {
    this.search(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var federationFilter = "";
    if (this.federationID != "0") {
      federationFilter = " AND FEDERATION_ID=" + this.federationID;
    }

    var unitFilter = "";
    if (this.unitID != "0") {
      unitFilter = " AND UNIT_ID=" + this.unitID;
    }

    var groupFilter = "";
    if (this.groupID != "0") {
      groupFilter = " AND GROUP_ID=" + this.groupID;
    }

    this.loadingRecords = true;

    this.api.getAllInchargeActivities(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + federationFilter + unitFilter + groupFilter).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  today = new Date().setDate(new Date().getDate() + 4);
  @ViewChild(InchargeActivityDrawerComponent, { static: false }) InchargeActivityDrawerComponentVar: InchargeActivityDrawerComponent;

  add(): void {
    this.drawerTitle = "Add Incharge Activity";
    this.drawerData = new InchargeActivity();
    this.drawerVisible = true;
    this.drawerData.DATE = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.InchargeActivityDrawerComponentVar.fileURL1 = null;
    this.InchargeActivityDrawerComponentVar.fileURL2 = null;
    this.InchargeActivityDrawerComponentVar.fileURL3 = null;
    this.InchargeActivityDrawerComponentVar.fileURL4 = null;
    this.InchargeActivityDrawerComponentVar.fileURL5 = null;
    this.InchargeActivityDrawerComponentVar.pdfFileURL1 = null;
    this.InchargeActivityDrawerComponentVar.pdfFileURL2 = null;
    this.InchargeActivityDrawerComponentVar.editAccess = true;
  }

  edit(data: InchargeActivity): void {
    this.drawerTitle = "Update Incharge Activity Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.drawerData.COUNT = String(data.COUNT);
    this.InchargeActivityDrawerComponentVar.fileURL1 = null;
    this.InchargeActivityDrawerComponentVar.fileURL2 = null;
    this.InchargeActivityDrawerComponentVar.fileURL3 = null;
    this.InchargeActivityDrawerComponentVar.fileURL4 = null;
    this.InchargeActivityDrawerComponentVar.fileURL5 = null;
    this.InchargeActivityDrawerComponentVar.pdfFileURL1 = null;
    this.InchargeActivityDrawerComponentVar.pdfFileURL2 = null;

    if (this.drawerData.PHOTO1 != " ")
      this.drawerData.PHOTO1 = this.api.retriveimgUrl + "inchargeActivity/" + this.drawerData.PHOTO1;
    else
      this.drawerData.PHOTO1 = null;

    if (this.drawerData.PHOTO2 != " ")
      this.drawerData.PHOTO2 = this.api.retriveimgUrl + "inchargeActivity/" + this.drawerData.PHOTO2;
    else
      this.drawerData.PHOTO2 = null;

    if (this.drawerData.PHOTO3 != " ")
      this.drawerData.PHOTO3 = this.api.retriveimgUrl + "inchargeActivity/" + this.drawerData.PHOTO3;
    else
      this.drawerData.PHOTO3 = null;

    if (this.drawerData.PHOTO4 != " ")
      this.drawerData.PHOTO4 = this.api.retriveimgUrl + "inchargeActivity/" + this.drawerData.PHOTO4;
    else
      this.drawerData.PHOTO4 = null;

    if (this.drawerData.PHOTO5 != " ")
      this.drawerData.PHOTO5 = this.api.retriveimgUrl + "inchargeActivity/" + this.drawerData.PHOTO5;
    else
      this.drawerData.PHOTO5 = null;

    if (this.drawerData.PDF1 != " ")
      this.drawerData.PDF1 = this.drawerData.PDF1;
    else
      this.drawerData.PDF1 = null;

    if (this.drawerData.PDF2 != " ")
      this.drawerData.PDF2 = this.drawerData.PDF2;
    else
      this.drawerData.PDF2 = null;

    this.InchargeActivityDrawerComponentVar.editAccess = false;
    this.InchargeActivityDrawerComponentVar.giveEditAccessOrNot(this.drawerData.INCHARGE_ID.toString());
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }

  photoModalTitle = "Activity Photo(es)";
  photoModalVisible: boolean = false;
  PHOTO1: string = "";
  DESCRIPTION1: string = "";
  PHOTO2: string = "";
  DESCRIPTION2: string = "";
  PHOTO3: string = "";
  DESCRIPTION3: string = "";
  PHOTO4: string = "";
  DESCRIPTION4: string = "";
  PHOTO5: string = "";
  DESCRIPTION5: string = "";

  viewPhotoes(data: InchargeActivity) {
    this.PHOTO1 = "";
    this.DESCRIPTION1 = "";
    this.PHOTO2 = "";
    this.DESCRIPTION2 = "";
    this.PHOTO3 = "";
    this.DESCRIPTION3 = "";
    this.PHOTO4 = "";
    this.DESCRIPTION4 = "";
    this.PHOTO5 = "";
    this.DESCRIPTION5 = "";
    this.photoModalVisible = true;

    if (data.PHOTO1 != " ") {
      this.PHOTO1 = this.api.retriveimgUrl + "inchargeActivity/" + data.PHOTO1;
      this.DESCRIPTION1 = data.DESCRIPTION1;
    }

    if (data.PHOTO2 != " ") {
      this.PHOTO2 = this.api.retriveimgUrl + "inchargeActivity/" + data.PHOTO2;
      this.DESCRIPTION2 = data.DESCRIPTION2;
    }

    if (data.PHOTO3 != " ") {
      this.PHOTO3 = this.api.retriveimgUrl + "inchargeActivity/" + data.PHOTO3;
      this.DESCRIPTION3 = data.DESCRIPTION3;
    }

    if (data.PHOTO4 != " ") {
      this.PHOTO4 = this.api.retriveimgUrl + "inchargeActivity/" + data.PHOTO4;
      this.DESCRIPTION4 = data.DESCRIPTION4;
    }

    if (data.PHOTO5 != " ") {
      this.PHOTO5 = this.api.retriveimgUrl + "inchargeActivity/" + data.PHOTO5;
      this.DESCRIPTION5 = data.DESCRIPTION5;
    }
  }

  photoModalCancel() {
    this.photoModalVisible = false;
  }

  viewPhoto(photoURL: string) {
    window.open(photoURL);
  }

  viewPDF(pdfURL: string) {
    window.open(this.api.retriveimgUrl + "inchargeActivity/" + pdfURL);
  }

  checkInchargeID(inchargeAreaID) {
    let inchargeAreaIDs = this.inchargeAreaIDs.split(",");
    let a = inchargeAreaIDs.filter(value => {
      return value == inchargeAreaID;
    });

    if (a.length > 0)
      return true;

    else
      return false;
  }
}
