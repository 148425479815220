import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { Router } from '@angular/router';

@Component({
  selector: 'app-federation-president-dashboard',
  templateUrl: './federation-president-dashboard.component.html',
  styleUrls: ['./federation-president-dashboard.component.css']
})

export class FederationPresidentDashboardComponent implements OnInit {
  TOTAL_UNITS: number = 0;
  TOTAL_GROUPS: number = 0;
  TOTAL_MEMBERS: number = 0;
  TOTAL_ACTIVITIES: number = 0;
  TOTAL_MEETINGS: number = 0;
  TOTAL_CIRCULARS: number = 0;
  federationID: number = Number(this._cookie.get("FEDERATION_ID"));
  unitID: number = Number(this._cookie.get("UNIT_ID"));
  groupID: number = Number(this._cookie.get("GROUP_ID"));

  constructor(private router: Router, private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _cookie: CookieService) { }

  ngOnInit() {
    this.getCounts();
    this.getUnitWiseMemberCount();
    this.getGroupWiseMemberCount();
    this.getlatest10Members();
    this.getUpcomingTop5Activities();
    this.getUpcomingTop5Meetings();
  }

  getCounts() {
    this.api.getFederationPresidentCount(this.federationID, this.unitID, this.groupID).subscribe(data => {
      if (data['code'] == 200) {
        this.TOTAL_UNITS = data['data'][0]["UNIT_COUNT"];
        this.TOTAL_GROUPS = data['data1'][0]["GROUP_COUNT"];
        this.TOTAL_MEMBERS = data['data2'][0]["MEMBER_COUNT"];
        this.TOTAL_ACTIVITIES = data['data3'][0]["GROUP_ACTIVITY_COUNT"];
        this.TOTAL_MEETINGS = data['data4'][0]["GROUP_MEETING_COUNT"];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  // Unit Wise Member Count
  graphData = [];

  getUnitWiseMemberCount() {
    var federationFilter = "";
    if (this.federationID != 0) {
      federationFilter = " AND FEDERATION_ID=" + this.federationID;
    }

    var unitFilter = "";
    if (this.unitID != 0) {
      unitFilter = " AND UNIT_ID=" + this.unitID;
    }

    var groupFilter = "";
    if (this.groupID != 0) {
      groupFilter = " AND GROUP_ID=" + this.groupID;
    }

    this.api.getUnitWiseMemberCount(0, 0, "UNIT_ID", "asc", federationFilter + unitFilter + groupFilter + " AND ACTIVE_STATUS='A'").subscribe(data => {
      if (data['code'] == 200) {
        let memberList = data['data'];
        let graphLables = [];
        let graphData = [];
        let barChartDataArray = [];

        for (var i = 0; i < memberList.length; i++) {
          graphLables.push(memberList[i]["UNIT_NAME"]);
          graphData.push(memberList[i]["MEMBER_COUNT"]);
        }

        this.barChartLabels1 = graphLables;
        this.graphData = graphData;

        var barChartData = new Object();
        barChartData["data"] = this.graphData;
        barChartData["label"] = 'Member Count';
        barChartData["stack"] = "a";
        barChartDataArray.push(Object.assign({}, barChartData));
        this.barChartData1 = barChartDataArray;
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  // Horizontal Bar Chart
  public barChartPlugins1 = [
    DataLabelsPlugin
  ];

  public barChartOptions1: ChartOptions = {
    responsive: true
  };

  public barChartType1: ChartType = 'horizontalBar';
  public barChartLabels1: string[] = ['Unit 1', 'Unit 2', 'Unit 3'];
  public barChartLegend1 = true;

  public barChartData1: ChartDataSets[] = [
    { data: this.graphData, label: 'Member Count', stack: 'a' },
  ];

  // Group Wise Member Count
  columns: string[][] = [["FEDERATION_NAME", "Federation"], ["UNIT_NAME", "Unit"], ["GROUP_NAME", "Group"], ["MEMBER_COUNT", "Count"]];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.getGroupWiseMemberCount(true);
  }

  getGroupWiseMemberCount(reset: boolean = false) {
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var federationFilter = "";
    if (this.federationID != 0) {
      federationFilter = " AND FEDERATION_ID=" + this.federationID;
    }

    var unitFilter = "";
    if (this.unitID != 0) {
      unitFilter = " AND UNIT_ID=" + this.unitID;
    }

    var groupFilter = "";
    if (this.groupID != 0) {
      groupFilter = " AND GROUP_ID=" + this.groupID;
    }

    this.loadingRecords = true;

    this.api.getGroupWiseMemberCount(this.pageIndex, this.pageSize, this.sortKey, sort, federationFilter + unitFilter + groupFilter + " AND ACTIVE_STATUS='A'").subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  // Latest Top 10 Members
  latest10MemberColumns: string[][] = [["UNIT_NAME", "Unit"], ["GROUP_NAME", "Group"], ["NAME", "Name"], ["MOBILE_NUMBER", "Mobile No."], ["EMAIL_ID", "Email"]];
  latest10MemberLoadingRecords = true;
  latest10MemberDataList = [];
  latest10MemberPageIndex = 1;
  latest10MemberPageSize = 10;
  latest10MemberTotalRecords = 1;

  getlatest10Members() {
    this.latest10MemberLoadingRecords = true;

    this.api.getLatest10MembersFederationWise(this.federationID, this.unitID, this.groupID).subscribe(data => {
      if (data['code'] == 200) {
        this.latest10MemberLoadingRecords = false;
        this.latest10MemberDataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  // Upcoming Top 5 Activities
  upcomingTop5ActivitiesColumns: string[][] = [["FEDERATION_NAME", "Federation"], ["UNIT_NAME", "Unit"], ["GROUP_NAME", "Group"], ["DATE", "Date"], ["PROJECT_NAME", "Target Area"], ["DETAILS", "Activity Details"]];
  upcomingTop5ActivitiesLoadingRecords = true;
  upcomingTop5ActivitiesDataList = [];
  upcomingTop5ActivitiesPageIndex = 1;
  upcomingTop5ActivitiesPageSize = 10;
  upcomingTop5ActivitiesTotalRecords = 1;

  getUpcomingTop5Activities() {
    this.upcomingTop5ActivitiesLoadingRecords = true;

    this.api.getUpcoming5ActivitiesFederationWise(this.federationID, this.unitID, this.groupID).subscribe(data => {
      if (data['code'] == 200) {
        this.upcomingTop5ActivitiesLoadingRecords = false;
        this.upcomingTop5ActivitiesDataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  // Upcoming Top 5 Meetings
  upcomingTop5MeetingsColumns: string[][] = [["FEDERATION_NAME", "Federation"], ["UNIT_NAME", "Unit"], ["GROUP_NAME", "Group"], ["DATE", "Date"], ["MEETING_SUB", "Meeting Subject"], ["FROM_TIME", "Start Time"], ["TO_TIME", "End Time"]];
  upcomingTop5MeetingsLoadingRecords = true;
  upcomingTop5MeetingsDataList = [];
  upcomingTop5MeetingsPageIndex = 1;
  upcomingTop5MeetingsPageSize = 10;
  upcomingTop5MeetingsTotalRecords = 1;

  getUpcomingTop5Meetings() {
    this.upcomingTop5MeetingsLoadingRecords = true;

    this.api.getUpcoming5MeetingFederationWise(this.federationID, this.unitID, this.groupID).subscribe(data => {
      if (data['code'] == 200) {
        this.upcomingTop5MeetingsLoadingRecords = false;
        this.upcomingTop5MeetingsDataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  getTimeInAM_PM(time) {
    return this.datePipe.transform(new Date(), "yyyy-MM-dd" + " " + time);
  }

  goToUnitMaster() {
    this.router.navigateByUrl('/unitmaster');
  }

  goToGroupMaster() {
    this.router.navigateByUrl('/groupmaster');
  }

  goToMemberMaster() {
    this.router.navigateByUrl('/membermaster');
  }

  goToGroupActivityMaster() {
    this.router.navigateByUrl('/group-project-activity');
  }

  goToGroupMeetingMaster() {
    this.router.navigateByUrl('/group-meeting');
  }

  goToCircularMaster() {
    this.router.navigateByUrl('/circular');
  }
}
