import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CircularMaster } from 'src/app/Models/Circular';
// import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DatePipe } from '@angular/common';
import { CircularTypeMaster } from 'src/app/Models/Circulartype';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addcircular',
  templateUrl: './addcircular.component.html',
  styleUrls: ['./addcircular.component.css']
})

export class AddcircularComponent implements OnInit {
  @Input() data: CircularMaster = new CircularMaster();
  @Input() drawerClose!: Function;
  @Input() drawerVisible: boolean = false;
  isSpinning = false;
  circularType: CircularTypeMaster[] = [];
  viewId: any;

  // config: AngularEditorConfig = {
  //   editable: true,
  //   spellcheck: true,
  //   height: '10rem',
  //   minHeight: '5rem',
  //   placeholder: 'Enter text here...',
  //   translate: 'no',
  //   defaultParagraphSeparator: 'p',
  //   defaultFontName: 'Arial',
  //   toolbarHiddenButtons: [
  //     ['bold']
  //   ],

  //   customClasses: [
  //     {
  //       name: "quote",
  //       class: "quote",
  //     },
  //     {
  //       name: 'redText',
  //       class: 'redText'
  //     },
  //     {
  //       name: "titleText",
  //       class: "titleText",
  //       tag: "h1",
  //     },
  //   ]
  // };

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.viewId = Number(localStorage.getItem('viewData'));
    this.getCircular();
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  getCircular() {
    this.api.getAllCircularType(0, 0, "", "asc", " AND STATUS=1").subscribe(data => {
      this.circularType = data['data'];

    }, err => {
      this.isSpinning = false;
    });
  }

  alphaOnly(event: any) {
    event = event ? event : window.event;
    var charCode = event.which ? event.which : event.keyCode;

    if (charCode > 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }

    return true;
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  save(addNew: boolean, circularmaster: NgForm): void {
    var isOk = true;

    if (this.data.CIRCULAR_TYPE_ID == undefined || this.data.CIRCULAR_TYPE_ID == null) {
      isOk = false;
      this.message.error("Please Select Circular Type", "");
    }

    if (this.data.CIRCULAR_NO != undefined && this.data.CIRCULAR_NO != null) {
      if (this.data.CIRCULAR_NO.trim() == '') {
        isOk = false;
        this.message.error("Please Enter Valid Circular No.", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Enter Circular No.", "");
    }

    if (this.data.DATE == undefined || this.data.DATE == null) {
      isOk = false;
      this.message.error("Please Selct Valid Date", "");
    }

    if (this.data.SUBJECT != undefined && this.data.SUBJECT != null) {
      if (this.data.SUBJECT.trim() == '') {
        isOk = false;
        this.message.error("Please Enter Valid Subject", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Enter Subject", "");
    }

    if (this.data.DESCRIPTION != undefined && this.data.DESCRIPTION != null) {
      if (this.data.DESCRIPTION.trim() == '') {
        isOk = false;
        this.message.error("Please Enter Valid Description", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Enter Description", "");
    }

    if (isOk) {
      this.isSpinning = true;
      this.data.DATE = this.datePipe.transform(this.data.DATE, 'yyyy-MM-dd');
      this.data.STATUS = 'D';
      this.data.CREATER_ID = this.api.userId;

      if (this.data.ID) {
        this.api.updateCircular(this.data).subscribe((successCode) => {
          if (successCode.code == '200') {
            this.message.success('Circular Updated Successfully', '');
            this.isSpinning = false;

            if (!addNew)
              this.close(circularmaster);

          } else {
            this.message.error('Circular Updation Failed', '');
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createCircular(this.data).subscribe((successCode) => {
          if (successCode.code == '200') {
            this.message.success('Circular Created Successfully', '');
            this.isSpinning = false;

            if (!addNew)
              this.close(circularmaster);

            else {
              this.data = new CircularMaster();
            }

          } else {
            this.message.error('Circular Creation Failed', '');
            this.isSpinning = false;
          }
        });
      }
    }
  }
}
