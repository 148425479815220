import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Nanasquate } from 'src/app/Models/Nana\'sQuate';

import { ApiService } from 'src/app/Service/api.service';
// import { NzTableQueryParams } from 'ng-zorro-antd/table';
// import { appkeys } from 'src/app/app.constant';
// import { ClientmasterService } from 'src/app/clientmaster.service';


@Component({
  selector: 'app-listnanas-quate',
  templateUrl: './listnanas-quate.component.html',
  styleUrls: ['./listnanas-quate.component.css']
})
export class ListnanasQuateComponent implements OnInit {

  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: Nanasquate = new Nanasquate();
  formTitle = "About Nana's Chudasama ";
  @Input()
   dataList:any[] = [];
  // dataList = [];
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [ ["SEQ_NO","अनुक्रमांक"], ["TITLE","शीर्षक (इंग्रजी)"], ["TITLE_MR","शीर्षक (मराठी) "]];
  @Input()
  drawerClose2!: Function;  
  // imgurl= appkeys.retriveimgUrl;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
  this.loadingRecords = false; 
  this.search()

  }

  keyup(event:any) {
    this.search();
  }

  search(reset: boolean = false)
  
  {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    var likeQuery = "";
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }
    this.api.getnana(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      console.log(this.dataList)
      for (var i=0;i<this.dataList.length;i++){
      // console.log(this.imgurl+'AboutImage/'+this.dataList[i]['IMG_URL'])

      }
      if(this.totalRecords==0){
        data.SEQUENCE_NO=1;
      }else{
        data.SEQUENCE_NO= this.dataList[this.dataList.length-1]['SEQUENCE_NO']+1
      }
    }, err => {
      console.log(err);
    });
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Add About Nana's Chudasama"; 
    this.drawerData = new Nanasquate();
    this.api.getnana(1,1,'SEQUENCE_NO','desc','').subscribe (data =>{
      if (data['count']==0){
        this.drawerData.SEQUENCE_NO=1;
      }else
      {
        this.drawerData.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
      }
    },err=>{
      console.log(err);
    })
    this.drawerVisible = true;
  }
  edit(data: Nanasquate): void {
    this.drawerTitle = "Edit About Nana's Chudasama ";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
// sort(): void {
//     const { pageSize, pageIndex, sort} = params;
//     const currentSort = sort.find(item => item.value !== null);
//     const sortField = (currentSort && currentSort.key) || 'id';
//     const sortOrder = (currentSort && currentSort.value) || 'asc';
//     console.log(currentSort)

//     console.log("sortOrder :"+sortOrder)
//     this.pageIndex = pageIndex;
//     this.pageSize = pageSize;

//     if(this.pageSize != pageSize) {
//       this.pageIndex = 1;
//       this.pageSize = pageSize;
//     }    
    
//     if( this.sortKey != sortField) {
//       this.pageIndex = 1;
//       this.pageSize =pageSize;
//     }

//     this.sortKey = sortField;
//     this.sortValue = sortOrder;
//     this.search();
//   }
}

// params: NzTableQueryParams
