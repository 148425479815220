// import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ContactInfo } from 'src/app/Models/ContactInfo';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addcontact',
  templateUrl: './addcontact.component.html',
  styleUrls: ['./addcontact.component.css']
})
export class AddcontactComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  data: ContactInfo = new ContactInfo;
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // namepatt=/^[a-zA-Z \-\']+/
  namepatt = /[a-zA-Z][a-zA-Z ]+/
  mobpattern = /^[6-9]\d{9}$/


  //Google Map 

  constructor(private api: ApiService, private message: NzNotificationService) { }

  //Google map
  // latitude: number=0;
  // longitude: number=0;
  // zoom = 12;
  // @Input() center: google.maps.LatLngLiteral={ lat: 17.3430712475, lng: 74.7753577586 };
  // markerOptions: google.maps.MarkerOptions = { draggable: true };
  // @Input() markerPositions: google.maps.LatLngLiteral ={ lat: 17.3430712475, lng: 74.7753577586 };

  // addMarker2(event: any) {
  //   this.markerPositions = event.latLng.toJSON();
  //   this.latitude = this.markerPositions.lat;
  //   this.longitude = this.markerPositions.lng;

  //   this.data.LATI = this.latitude.toString();
  //   this.data.LONGI = this.longitude.toString();
  // }

  // googleMapPointer() {
  //   this.center = {
  //     lat: Number(this.data.LATI),
  //     lng: Number(this.data.LONGI),
  //   }
  //   this.markerPositions = { lat: Number(this.data.LATI), lng: Number(this.data.LONGI) };
  // }
  //////


  ngOnInit(): void {

  }

  close(): void {
    this.drawerClose();
  }

  //// Only number
  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ////

  resetDrawer(websitebannerPage: NgForm) {
    this.data = new ContactInfo();
    websitebannerPage.form.markAsPristine();
    websitebannerPage.form.markAsUntouched();
  }
  //save
  save(addNew: boolean, websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;

    if (this.data.EMAIL_ID.trim() == "" && this.data.MOBILE_NO == undefined
      && this.data.ADDRESS.trim() == "" && this.data.ADDRESS.trim() == "" ) {
      this.isOk = false
      this.message.error("Please Fill All The Information......", "");
    }
    else  if (this.data.EMAIL_ID == null || this.data.EMAIL_ID.trim() == '') {
        this.isOk = false
        this.message.error('Please Enter Email', '')
      } else

        if (!this.emailpattern.test(this.data.EMAIL_ID)) {
          this.isOk = false
          this.message.error('Please Enter a valid Email', '')
        } else

          if (this.data.MOBILE_NO == undefined || this.data.MOBILE_NO <= 0) {
            this.isOk = false
            this.message.error('Please Enter Mobile Number', '')
          } else
            if (!this.mobpattern.test(this.data.MOBILE_NO.toString())) {
              this.message.error('Please Enter a Valid Mobile Number ', '')
            }

            else

              if (this.data.ADDRESS == null || this.data.ADDRESS.trim() == '') {
                this.isOk = false
                this.message.error('Please Enter  Address', '')
              }

    if (this.isOk) {
      this.isSpinning = false;

      this.isSpinning = true;
      if (this.data.ID) {
        this.api.updatecontact(this.data)
          .subscribe(successCode => {
            if (successCode["code"] == "200") {
              this.message.success("Information Updated successfully......", "");
              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {
              this.message.error("Information has not changed......", "");
              this.isSpinning = false;
            }
          });
      }
      else {
        this.api
          .createcontact(this.data)
          .subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information added successfully', '');
              // this.fileURL = null;
              if (!addNew) this.drawerClose();
              else {
                this.data = new ContactInfo();
                this.resetDrawer(websitebannerPage);

                this.data.STATUS == true;

                this.api.getcontact(1, 1, ' SEQUENCE_NO', 'desc', '').subscribe(data => {
                  if (data['count'] == 0) {
                    this.data.SEQUENCE_NO = 1;
                  } else {
                    this.data.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
                  }
                },
                  (err) => {
                    console.log(err);
                  }
                );
              }
              this.isSpinning = false;
            } else {
              this.message.error('Failed to store  information...', '');
              this.isSpinning = false;
            }
          });
      }
    }
    // ...........................................
    // else
    // {
    //   this.message.error("Please Fill All Required Fields...","");
    //   this.isSpinning = false;
    // }

    // ....................................
  }
  //Open social media link method
  open(link: any) {
    if (link != null || link != undefined) {
      window.open(link);
    }
  }
}








 // && this.data.NEAREST_RAILWAY_STATION.trim() == ""
      // && this.data.NEAREST_BUS_STOP.trim() == "" && this.data.NEAREST_RAILWAY_STATION.trim() == ""
      // && this.data.NEAREST_ROOT1.trim() == "" && this.data.NEAREST_RAILWAY_STATION_MR.trim() == ""
      // && this.data.NEAREST_BUS_STOP_MR.trim() == "" && this.data.NEAREST_AIRWAY_MR.trim() == ""
      // && this.data.NEAREST_ROOT1_MR.trim() == ""