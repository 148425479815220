import { DatePipe } from '@angular/common';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Aboutfedration } from 'src/app/Models/Aboutfedration';
import { fed } from 'src/app/Models/fed';
// import { UtsavMaster } from 'src/app/Models/UtsavMaster';
import { ApiService } from 'src/app/Service/api.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
// import { appkeys } from 'src/app/app.constant';
// import { ClientmasterService } from 'src/app/clientmaster.service';
// import { UtsavMaster } from 'src/Models/Federationmaster';

@Component({
  selector: 'app-addaboutfederation',
  templateUrl: './addaboutfederation.component.html',
  styleUrls: ['./addaboutfederation.component.css']
})
export class AddaboutfederationComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  data: Aboutfedration = new Aboutfedration;
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  Aboutfedration: any
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // namepatt=/^[a-zA-Z \-\']+/
  namepatt = /[a-zA-Z][a-zA-Z ]+/
  mobpattern = /^[6-9]\d{9}$/
  fileURL: any;
  Fedration: fed[] = [];
  // albume:AlbumeMaster[]=[]
  // imgurl = this.api.imgUrl + "aboutFederation";
  imgurl = this.api.retriveimgUrl + "/aboutFederation/";


  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '300px',
    // minHeight: '12rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
    ],
    customClasses: [],
    uploadUrl: '',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fonts', 'uploadUrl'],
      ['video']
    ]
  };


  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    // this.loadalbume();
    this.getname();
  }


  close(): void {
    this.drawerClose();
  }
  resetDrawer(websitebannerPage: NgForm) {
    this.data = new Aboutfedration();
    // Fedration.selectedIndex = 0;
    
    // this.Fedration= null;
    websitebannerPage.form.markAsPristine();
    websitebannerPage.form.markAsUntouched();

    // this.Fedration.value = null;

  }



  //save
  save(addNew: boolean, websitebannerPage: NgForm): void {
    this.isOk = true;
    this.isSpinning = false;

    if (this.data.FEDERATION_ID == 0 && this.data.TITLE.trim() == ""
      && this.data.DESCRIPTION.trim() == ""
      && this.data.SEQUENCE_NO != undefined && this.data.IMG_URL.trim() == "") {
      this.isOk = false
      this.message.error("Please Fill All The Information......", "");
    }



    else
      if (this.data.FEDERATION_ID <= 0 || this.data.FEDERATION_ID == undefined) {
        this.isOk = false
        this.message.error('Please Enter Federation Name', '')

      }

      else
        if (this.data.TITLE == null || this.data.TITLE.trim() == '') {
          this.isOk = false
          this.message.error('Please Enter the Valid Title', '')

        }

        else
          if (this.data.DESCRIPTION == null || this.data.DESCRIPTION.trim() == '') {
            this.isOk = false
            this.message.error('Please enter the valid Description', '')

          } else

            if (this.data.SEQUENCE_NO == undefined || this.data.SEQUENCE_NO <= 0) {
              this.isOk = false
              this.message.error('Please Enter the Sequence Number', '')
            } else

              if (this.data.IMG_URL == null || this.data.IMG_URL.trim() == '') {
                this.isOk = false
                this.message.error('Select the image', '')
              }

    if (this.isOk) {
      //  this.isSpinning=false;

      this.isSpinning = true;

      {
        if (this.fileURL != null) {
          var number = Math.floor(100000 + Math.random() * 900000);
          var fileExt = this.fileURL.name.split('.').pop();
          var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
          var url = '';
          url = d == null ? '' : d + number + '.' + fileExt;
          if (
            this.data.IMG_URL != undefined &&
            this.data.IMG_URL.trim() != ''
          ) {
            var arr = this.data.IMG_URL.split('/');
            if (arr.length > 1) {
              url = arr[5];
            }
          }


      // this.Aboutfedration = this.data.FEDERATION_ID;

          this.api
            .onUpload2('aboutFederation', this.fileURL, url)
            .subscribe((successCode) => {
              if (successCode["code"] == 200) {
                this.data.IMG_URL = url;
                // appkeys.retriveimgUrl + 'WebsiteBannerImage/' + url;
                if (this.data.ID) {
                  this.api
                    .updateFedration(this.data)
                    .subscribe((successCode) => {
                      if (successCode["code"] == 200) {
                        this.message.success(
                          'Information updated successfully', ''
                        );
                        this.fileURL=null;
                        if (!addNew) this.drawerClose();
                        this.isSpinning = false;
                      } else {
                        this.message.error('Failed to Update information', '');
                        this.isSpinning = false;
                      }
                    });
                } else {
                  this.api
                    .createFedration(this.data)
                    .subscribe((successCode) => {
                      if (successCode.code == '200') {
                        this.message.success('Information added successfully', '');
                        this.fileURL=null;
                        if (!addNew) this.drawerClose();
                        else {
                          this.data = new Aboutfedration();
                          this.resetDrawer(websitebannerPage);

                          this.data.STATUS == true;





                          this.api.getFedration(1, 1, ' SEQUENCE_NO', 'desc', '').subscribe(data => {
                            if (data['count'] == 0) {
                              this.data.SEQUENCE_NO = 1;
                            } else {
                              this.data.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
                            }
                          },
                            (err) => {
                              console.log(err);
                            }
                          );
                        }
                        this.isSpinning = false;
                      } else {
                        this.message.error('Failed to store  information...', '');
                        this.isSpinning = false;
                      }
                    });
                }
              } else {
                this.message.error('Failed to add image', '');
                this.isSpinning = false;
              }
            });
        } else if (this.data.IMG_URL == null || this.data.IMG_URL == '') {
          this.message.error('Please select image', '');
          this.isSpinning = false;
        } else {
          if (this.data.ID) {
            this.api.updateFedration(this.data).subscribe((successCode) => {
              if (successCode["code"] == 200) {
                this.message.success('Information updated successfully', '');

                if (!addNew) this.drawerClose();
                this.isSpinning = false;
              } else {
                this.message.error('Failed to update information', '');
                this.isSpinning = false;
                //
               
                // this.data.FEDERATION_ID=null;
                //
              }
            });
          } else {

            this.api.createFedration(this.data).subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information added successfully', '');

                if (!addNew) this.drawerClose();
                else {
                  this.data = new Aboutfedration();
                  // this.data = new fed();
                  this.resetDrawer(websitebannerPage);
                  // this.data.FEDERATION_ID=null;

                }
                this.isSpinning = false;
              } else {
                this.message.error('Failed to store information', '');
                this.isSpinning = false;
              }
            });
          }
        }
      }
    }

    //  else
    //  {
    //    this.message.error("Please Fill All Required Fields...","");
    //    this.isSpinning = false;

    //  }
  }
  ////
  ////
  //Choose image
  // onFileSelected(event: any) {
  //   console.log(event);
  //   let imgs = new Image()
  //   let isLtsize = false;
  //   imgs.src = window.URL.createObjectURL(event.target.files[0]);

  //   imgs.onload = () => {
  //     console.log(imgs.height);
  //     console.log(imgs.width);
  //     if (500 == imgs.height && imgs.width == 457) {
  //       isLtsize = true;

  //       if (
  //         event.target.files[0].type == 'image/jpeg' ||
  //         event.target.files[0].type == 'image/jpg' ||
  //         event.target.files[0].type == 'image/png'
  //       ) {
  //         this.fileURL = <File>event.target.files[0];
  //       } else {
  //         this.message.error('Please select only JPEG/ JPG/ PNG file.', '');
  //         this.fileURL = null;
  //         this.data.IMG_URL = '';
  //       }

  //     } else {
  //       this.fileURL = null;
  //       this.data.IMG_URL = ' ';
  //       this.message.error('The image will not fit between the dimensions of ' + 500 + ' ' + 'px Height  ' + ' And ' + ' ' + 457 + ' px Width ', '');
  //     }
  //   }
  // }


  onFileSelected(event:any) {
    console.log(event);

    if (event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/png") {
      this.fileURL = <File>event.target.files[0];

    } else {
      this.message.error("Select Only JPEG/ JPG/ PNG File.", "");
      this.fileURL = null;
      this.data.IMG_URL= '';

    }
  }
  removeImage() {
    this.data.IMG_URL = '';
    this.fileURL = '';

  }


  getname() {

    this.api.getname(0, 0, 'NAME', 'asc', " AND STATUS = 1").subscribe(data => {
      this.Fedration = data['data'];
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }






  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) { return false; } return true;
  }

}




