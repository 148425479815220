export const environment = {
  production: true,

  firebase: {
    apiKey: "AIzaSyAOsHZ6EqSt-yJm83-PU2ZO_rGPpUnhReE",
    authDomain: "tickdesk-de93d.firebaseapp.com",
    projectId: "tickdesk-de93d",
    storageBucket: "tickdesk-de93d.appspot.com",
    messagingSenderId: "364226150066",
    appId: "1:364226150066:web:203a0cf2fd5225dba753ab",
    measurementId: "G-HWX8G137MJ",
  },

  appVersioning: {
    appVersion: require('../../package.json').version
  }
};
