import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { GroupMaster } from 'src/app/Models/GroupMaster';
import { ApiService } from 'src/app/Service/api.service';
import { NgForm } from '@angular/forms';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-manage-group-members',
  templateUrl: './manage-group-members.component.html',
  styleUrls: ['./manage-group-members.component.css']
})

export class ManageGroupMembersComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: GroupMaster;
  @Input() BOD_Position;
  @Input() dataList = [];
  @Input() drawerVisible: boolean;
  isSpinning = false;
  leaveTypes = [];
  namePattern = "([A-Za-z0-9 \s]){1,}";
  roleID = this.api.roleId;
  federationID = this._cookie.get("FEDERATION_ID");
  unitID = this._cookie.get("UNIT_ID");
  groupID = this._cookie.get("GROUP_ID");

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _cookie: CookieService) { }

  ngOnInit() {
    this.getMembers();
  }

  close(myForm: NgForm): void {
    this.drawerClose();
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  numberOnly(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  isVisible = false;
  isConfirmLoading = false;

  showModal(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isConfirmLoading = false;
    this.isVisible = false;
    this.NEW_MEMBER_ID = undefined;
    this.START_DATE = undefined;
    this.END_DATE = undefined;
    this.search(true, this.BOD_Position, this.data.ID);
  }

  members = [];
  memberLoading = false;

  getMembers() {
    this.memberLoading = true;

    this.api.getAllMembers(0, 0, "NAME", "asc", " AND ACTIVE_STATUS='A'").subscribe(data => {
      if (data['code'] == 200) {
        this.memberLoading = false;
        this.members = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  NEW_MEMBER_ID: number;
  START_DATE: string;
  END_DATE: string;

  handleOk() {
    var isOK = true;

    if (this.NEW_MEMBER_ID == undefined || this.NEW_MEMBER_ID == null) {
      isOK = false;
      this.message.error("Please Select Valid Member", "");
    }

    if (this.START_DATE == undefined || this.START_DATE == null) {
      isOK = false;
      this.message.error("Please Select Valid Start Date", "");
    }

    if (this.END_DATE == undefined || this.END_DATE == null) {
      isOK = false;
      this.message.error("Please Select Valid End Date", "");
    }

    if (isOK) {
      this.isConfirmLoading = true;

      var obj1 = new Object();
      obj1["GROUP_ID"] = this.data.ID;
      obj1["BOD_POSITION_ID"] = this.BOD_Position;
      obj1["MEMBER_ID"] = this.NEW_MEMBER_ID;
      obj1["START_DATE"] = this.datePipe.transform(this.START_DATE, "yyyy-MM-dd");
      obj1["END_DATE"] = this.datePipe.transform(this.END_DATE, "yyyy-MM-dd");
      obj1["APPLIED_BY_ID"] = this.api.userId;

      if (this.roleID == 3)
        obj1["KEY"] = "WORLD_COUNCIL";
      else
        obj1["KEY"] = "FEDERATION";

      this.api.assignGroupBOD(obj1).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.message.success("Memeber Added Successfully", "");
          this.search(true, this.BOD_Position, this.data.ID);
          this.isVisible = false;
          this.isConfirmLoading = false;
          this.NEW_MEMBER_ID = undefined;
          this.START_DATE = undefined;
          this.END_DATE = undefined;

        } else {
          this.isConfirmLoading = false;
          this.NEW_MEMBER_ID = undefined;
          this.START_DATE = undefined;
          this.END_DATE = undefined;
          this.message.error("Failed to Add Member", "");
        }
      });
    }
  }

  today = new Date().setDate(new Date().getDate());

  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) > 0;

  // disabledExpiryDate = (current: Date): boolean =>
  //   differenceInCalendarDays(current, this.today) < 0;

  disabledExpiryDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.START_DATE ? this.START_DATE : this.today) < 0;

  startDateChange() {
    this.END_DATE = undefined;
  }

  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [["START_DATE", "Start Date"], ["END_DATE", "End Date"], ["MEMBER_NAME", "Name"], ["MOBILE_NUMBER", "Mobile No."], ["GROUP_NAME", "Group"], ["BOD_POSITION_ID", "Position"]];
  drawerTitle: string;
  drawerData: GroupMaster = new GroupMaster();

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true, this.BOD_Position, this.data.ID);
  }

  search(reset: boolean = false, BOD_Position: any, groupID: any) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    this.api.getGroupBOD(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + " AND BOD_POSITION_ID=" + BOD_Position + " AND GROUP_ID=" + groupID).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  changeStatus(status, data) {
    data["STATUS"] = status;

    let role = "";
    if (data["BOD_POSITION_ID"] == 1)
      role = "PRESIDENT";
    else if (data["BOD_POSITION_ID"] == 2)
      role = "VPI";
    else if (data["BOD_POSITION_ID"] == 3)
      role = "VPE";
    else if (data["BOD_POSITION_ID"] == 4)
      role = "SECRETARY";
    else if (data["BOD_POSITION_ID"] == 5)
      role = "TREASURER";
    else if (data["BOD_POSITION_ID"] == 6)
      role = "DIRECTOR 1";
    else if (data["BOD_POSITION_ID"] == 7)
      role = "DIRECTOR 2";
    else if (data["BOD_POSITION_ID"] == 8)
      role = "DIRECTOR 3";
    else if (data["BOD_POSITION_ID"] == 9)
      role = "DIRECTOR 4";
    else if (data["BOD_POSITION_ID"] == 10)
      role = "DIRECTOR 5";

    data["ROLE_NAME"] = role + " " + data["GROUP_NAME"];

    this.api.updateGroupBOD(data).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Status Updated Successfully", "");
        this.search(false, this.BOD_Position, this.data.ID);

      } else {
        this.message.error("Failed to Update Status", "");
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  changeActiveStatus(activeStatus, data) {
    data["ACTIVE_STATUS"] = activeStatus;

    let role = "";
    if (data["BOD_POSITION_ID"] == 1)
      role = "PRESIDENT";
    else if (data["BOD_POSITION_ID"] == 2)
      role = "VPI";
    else if (data["BOD_POSITION_ID"] == 3)
      role = "VPE";
    else if (data["BOD_POSITION_ID"] == 4)
      role = "SECRETARY";
    else if (data["BOD_POSITION_ID"] == 5)
      role = "TREASURER";
    else if (data["BOD_POSITION_ID"] == 6)
      role = "DIRECTOR 1";
    else if (data["BOD_POSITION_ID"] == 7)
      role = "DIRECTOR 2";
    else if (data["BOD_POSITION_ID"] == 8)
      role = "DIRECTOR 3";
    else if (data["BOD_POSITION_ID"] == 9)
      role = "DIRECTOR 4";
    else if (data["BOD_POSITION_ID"] == 10)
      role = "DIRECTOR 5";

    data["ROLE_NAME"] = role + " " + data["GROUP_NAME"];

    this.api.updateGroupBOD(data).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Active Status Updated Successfully", "");
        this.search(false, this.BOD_Position, this.data.ID);

      } else {
        this.message.error("Failed to Update Active Status", "");
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  getBODPositionIDFullForm(BODPositionID: number) {
    if (BODPositionID == 1)
      return "President";
    else if (BODPositionID == 2)
      return "VPI";
    else if (BODPositionID == 3)
      return "VPE";
    else if (BODPositionID == 4)
      return "Secretary";
    else if (BODPositionID == 5)
      return "Treasurer";
    else if (BODPositionID == 6)
      return "Director 1";
    else if (BODPositionID == 7)
      return "Director 2";
    else if (BODPositionID == 8)
      return "Director 3";
    else if (BODPositionID == 9)
      return "Director 4";
    else if (BODPositionID == 10)
      return "Director 5";
  }

  enableOrDisableStatusWise(status) {
    if ((status == "P") && ((this.roleID == 3) || (this.unitID != '0')))
      return false;
    else
      return true;
  }

  enableOrDisableActiveStatusWise(status) {
    if ((status == "A") && ((this.roleID == 3) || (this.unitID != '0')))
      return false;
    else
      return true;
  }
}