export class PriceListDetails {
    ID: number;
    CLIENT_ID: number;
    VARIENT_ID: number;
    VARIENT_NAME: string;
    FUEL_TYPE: string;
    TRANSMISSION: string;
    INVOICE_AMOUNT: string;
    INSURANCE: string;
    RTO: string;
    FASTAG: string;
    ACCESSORIES: string;
    EX_WARRANTY: string;
    SOT: string;
    ON_ROAD_PRICE: string;
    TCS1: string;
    TCS5: string;
}