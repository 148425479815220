import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CentralCommittee } from 'src/app/Models/Central Committee';
import { committeeget } from 'src/app/Models/committeeget';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addcentralcommittee',
  templateUrl: './addcentralcommittee.component.html',
  styleUrls: ['./addcentralcommittee.component.css']
})
export class AddcentralcommitteeComponent implements OnInit {
  @Input()
  drawerClose!: Function;
  @Input()
  data: CentralCommittee = new CentralCommittee();
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  height: any;
  width: any;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  mobpattern = /^[6-9]\d{9}$/

  committee: committeeget[] = [];

  imgurl = this.api.retriveimgUrl + "Committee/";

  fileURL: any;
  constructor(
    private api: ApiService,
    private message: NzNotificationService,
    public datePipe: DatePipe
  ) { }

  ngOnInit(): void { 

    this.getname();
  }

  close(): void {
    this.drawerClose();
 
  }

  resetDrawer(websitebannerPage: NgForm) {
    this.data = new CentralCommittee();
    websitebannerPage.form.markAsPristine();
    websitebannerPage.form.markAsUntouched();
  }
  //save
  //save
  save(addNew: boolean, websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;
    if ( this.data.COMMITTEE_TYPE_ID == 0 &&this.data.NAME.trim() == '' &&  this.data.GROUP_NAME.trim() == '' &&  this.data.POSITION.trim() == '' &&
    this.data.EMAIL_ID.trim() == "" && this.data.MOBILE_NO == undefined &&                          
    this.data.SEQ_NO != undefined &&this.data.IMG_URL.trim() == ''
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Information', '');
    }

    else
    if (this.data.COMMITTEE_TYPE_ID <= 0 || this.data.COMMITTEE_TYPE_ID == undefined) {
      this.isOk = false
      this.message.error('Please select Committee Name', '')

    }

    else if (!this.namepatt.test(this.data.NAME)) {
      this.isOk = false;
      this.message.error('Please Enter Member Name', '');
    }

    else if (!this.namepatt.test(this.data.GROUP_NAME)) {
      this.isOk = false;
      this.message.error('Please Enter Group Name', '');
    }

    else if (!this.namepatt.test(this.data.POSITION)) {
      this.isOk = false;
      this.message.error('Please Enter position', '');
    }

    // else  if (this.data.EMAIL_ID == null || this.data.EMAIL_ID.trim() == '') {
    //   this.isOk = false
    //   this.message.error('Please Enter Email', '')
    // } 
    
    // else

    //   if (!this.emailpattern.test(this.data.EMAIL_ID)) {
    //     this.isOk = false
    //     this.message.error('Please Enter a valid Email', '')
    //   } 
      
    //   else

    //     if (this.data.MOBILE_NO == undefined || this.data.MOBILE_NO <= 0) {
    //       this.isOk = false
    //       this.message.error('Please Enter Mobile Number', '')
    //     } else
    //       if (!this.mobpattern.test(this.data.MOBILE_NO.toString())) {
    //         this.message.error('Please Enter a Valid Mobile Number ', '')
    //       }



    else if (this.data.SEQ_NO == null || this.data.SEQ_NO <= 0) {
      this.isOk = false;
      this.message.error('Please Enter Sequence No', '');
    } else if (
      this.data.IMG_URL == undefined ||
      this.data.IMG_URL.trim() == ''
    ) {
      this.isOk = false;
      this.message.error('Please Select Image', '');
    }

    if (this.isOk) {


      this.isSpinning = true;

      {
        if (this.fileURL != null) {
          var number = Math.floor(100000 + Math.random() * 900000);
          var fileExt = this.fileURL.name.split('.').pop();
          var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
          var url = '';
          url = d == null ? '' : d + number + '.' + fileExt;
          if (
            this.data.IMG_URL != undefined &&
            this.data.IMG_URL.trim() != ''
          ) {
            var arr = this.data.IMG_URL.split('/');
            if (arr.length > 1) {
              url = arr[5];
            }
          }

          this.api
            .onUpload2('Committee', this.fileURL, url)
            .subscribe((successCode) => {
              if (successCode["code"] == 200) {
                this.data.IMG_URL = url;
                // appkeys.retriveimgUrl + 'WebsiteBannerImage/' + url;
                if (this.data.ID) {
                  this.api
                    .updateCommittee(this.data)
                    .subscribe((successCode) => {
                      if (successCode["code"] == 200) {
                        this.message.success(
                          'Information Updated Successfully', '');
                        this.fileURL=null;
                        if (!addNew) this.drawerClose();
                        this.isSpinning = false;
                      } else {
                        this.message.error('Failed To Information', '');
                        this.isSpinning = false;
                      }
                    });
                } else {
                  this.api
                    .createCommittee(this.data)
                    .subscribe((successCode) => {
                      if (successCode.code == '200') {
                        this.message.success('Information Added Successfully', '');
                        this.fileURL=null;
                        if (!addNew) this.drawerClose();
                        else {
                          this.data = new CentralCommittee();
                          this.resetDrawer(websitebannerPage);

                          this.data.STATUS == true;
                          // this.data.ICON==null;
                          this.fileURL = '';
                          this.api.getCommittee(1, 1, 'SEQ_NO', 'desc', '').subscribe(data => {
                            if (data['count'] == 0) {
                              this.data.SEQ_NO = 1;
                            } else {
                              this.data.SEQ_NO = data['data'][0]['SEQ_NO'] + 1;
                            }
                          }, err => {
                            console.log(err);
                          })
                        }
                        this.isSpinning = false;
                      } else {
                        this.message.error('Failed To Store Information', '');
                        this.isSpinning = false;
                      }
                    });
                }
              } else {
                this.message.error('Failed To Add Image', '');
                this.isSpinning = false;
              }
            });
        } else if (this.data.IMG_URL == null || this.data.IMG_URL == '') {
          this.message.error('Please Select Logo', '');
          this.isSpinning = false;
        } else {
          if (this.data.ID) {
            this.api.updateCommittee(this.data).subscribe((successCode) => {
              if (successCode["code"] == 200) {
                this.message.success('Information Updated Successfully', '');
                if (!addNew) this.drawerClose();
                this.isSpinning = false;
              } else {
                this.message.error('Failed To Update Information', '');
                this.isSpinning = false;
              }
            });
          } else {
            this.api.createCommittee(this.data).subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Added Successfully', '');
                if (!addNew) this.drawerClose();
                else {
                  this.data = new CentralCommittee();
                }
                this.isSpinning = false;
              } else {
                this.message.error('Failed To Store Information', '');
                this.isSpinning = false;
              }
            });
          }
        }
      }
    }

    //  else
    //  {
    //    this.message.error("Please Fill All Required Fields...","");
    //    this.isSpinning = false;

    //  }
  }
  ////
  ////
  //Choose image
  onFileSelected(event: any) {
    console.log(event);
    let imgs = new Image()
    let isLtsize = false;
    imgs.src = window.URL.createObjectURL(event.target.files[0]);

    imgs.onload = () => {
      console.log(imgs.height);
      console.log(imgs.width);
      // if (376 == imgs.height && imgs.width == 1500)
      {
        isLtsize = true;

        if (
          event.target.files[0].type == 'image/jpeg' ||
          event.target.files[0].type == 'image/jpg' ||
          event.target.files[0].type == 'image/png'
        ) {
          this.fileURL = <File>event.target.files[0];
        } else {
          this.message.error('Please Select Only JPEG/ JPG/ PNG File.', '');
          this.fileURL = null;
          this.data.IMG_URL = '';
        }

      }
      // else {
      //   this.fileURL = null;
      //   this.data.ICON = ' ';
      //   this.message.error('The image will not fit between the dimensions of ' + 376 + ' ' + 'px Height  ' + ' And ' + ' ' + 1500 + ' px Width ', '');
      // }
    }
  }
  removeImage() {
    this.data.IMG_URL = '';
    this.fileURL = '';

  }


  
  getname() {

    this.api.getDropdownCommittee(0, 0, 'COMMITTEE_NAME', 'asc', "").subscribe(data => {
      this.committee = data['data'];
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) { return false; } return true;
  }


}
