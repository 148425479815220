export class FederationMaster {
    ID: number;
    CLIENT_ID: number;
    NAME: string;
    STATUS: boolean = true;
    PRESIDENT: number;
    IPP: number;
    VP1: number;
    VP2: number;
    VP3: number;
    SECRETORY: number;
    CO_SECRETORY: number;
    TREASURER: number;
    PRO1: number;
    PRO2: number;
    CO_ORDINATOR: number;
    SPECIAL_OFFICER1: number;
    SPECIAL_OFFICER2: number;
    SPECIAL_OFFICER3: number;
    SPECIAL_OFFICER4: number;
}