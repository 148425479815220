export class InchargeActivity {
    ID: number;
    CLIENT_ID: number;
    INCHARGE_ID: number;
    PROJECT_ID: number;
    DETAILS: string;
    PHOTO1: string;
    PHOTO2: string;
    PHOTO3: string;
    PHOTO4: string;
    PHOTO5: string;
    DESCRIPTION1: string;
    DESCRIPTION2: string;
    DESCRIPTION3: string;
    DESCRIPTION4: string;
    DESCRIPTION5: string;
    DATE: string;
    COUNT: string;
    PDF1: string;
    PDF2: string;
}