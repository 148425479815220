export class Aboutfedration{

    ID:number=0;
    TITLE:string='';
    DESCRIPTION:string='';
    FEDERATION_NAME:string='';
    IMG_URL:string='';
    STATUS:boolean=true;
    SEQUENCE_NO:number=0 ;
    NAME:string='';
    FEDERATION_ID:number=0;
}