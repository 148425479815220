import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/CommonModule/Dashboard/dashboard/dashboard.component';
import { ApplicationsComponent } from './pages/CommonModule/application/applications/applications.component';
import { ModulesComponent } from './pages/CommonModule/module/modules/modules.component';
import { UsersComponent } from './pages/CommonModule/user/users/users.component';
import { RolesComponent } from './pages/CommonModule/role/roles/roles.component';
import { SelectpageComponent } from './pages/CommonModule/selectpage/selectpage/selectpage.component';
import { LoginComponent } from './login/login.component';
import { FaqHeadsComponent } from './pages/SupportModule/FaqHead/faq-heads/faq-heads.component';
import { FaqsComponent } from './pages/SupportModule/FAQ/faqs/faqs.component';
import { FormsComponent } from './pages/CommonModule/form/forms/forms.component';
import { DepartmentsComponent } from './pages/SupportModule/Department/departments/departments.component';
import { EmailsComponent } from './pages/CommunicationModule/EmailServer/emails/emails.component';
import { SmssComponent } from './pages/CommunicationModule/SMSServer/smss/smss.component';
import { FoldersComponent } from './pages/DocumentModule/folder/folders/folders.component';
import { TicketsComponent } from './pages/SupportModule/Tickets/tickets/tickets.component';
import { UserapplicationmappingComponent } from './pages/CommonModule/user/userapplicationmapping/userapplicationmapping.component';
import { UsermodulemappingComponent } from './pages/CommonModule/user/usermodulemapping/usermodulemapping.component';
import { UseraccessmappingComponent } from './pages/CommonModule/user/useraccessmapping/useraccessmapping.component';
import { UserrolemappingComponent } from './pages/CommonModule/user/userrolemapping/userrolemapping.component';
import { TasksComponent } from './pages/SchedularModule/Task/tasks/tasks.component';
import { ViewhistoryComponent } from './pages/SchedularModule/History/viewhistory/viewhistory.component';
import { LogmoduleComponent } from './pages/LogModule/logmodule/logmodule.component';
import { UserdepartmentmappingComponent } from './pages/CommonModule/user/userdepartmentmapping/userdepartmentmapping.component';
import { TicketgroupComponent } from './pages/SupportModule/Tickets/ticketgroup/ticketgroup.component';
import { TestComponent } from './pages/CommonModule/test/test.component';
import { MyticketComponent } from './pages/UserModule/myticket/myticket.component';
import { TicketreportComponent } from './pages/Reports/ticketreport/ticketreport.component';
import { SummaryreportComponent } from './pages/Reports/summaryreport/summaryreport.component';
import { UserwisesummaryComponent } from './pages/Reports/userwisesummary/userwisesummary.component';
import { DashboardreportComponent } from './pages/Report/dashboardreport/dashboardreport.component';
import { OrganizationsComponent } from './pages/Organizations/organizations/organizations.component';
// import { AuthGuard } from './Service/auth.guard';
// import { BranchComponent } from './pages/BranchMaster/branch/branch.component';
import { BranchesComponent } from './pages/BranchMaster/branches/branches.component';
import { DesignationsComponent } from './pages/DesignationMaster/designations/designations.component';
import { TickRolesComponent } from './pages/RoleMaster/tick-roles/tick-roles.component';
import { FeaturesComponent } from './pages/FeatureMaster/features/features.component';
import { EmployeemasterlistComponent } from './pages/employeemaster/employeemasterlist/employeemasterlist.component';
// import { Clusterbranchmasterlistcomponent } from './pages/clusterbranchmaster/clusterbranchmasterlist/clusterbranchmasterlist.component';
// import { ClusterMaster } from './Models/clustermaster';
import { ClustermasterlistComponent } from './pages/clustermaster/clustermasterlist/clustermasterlist.component';
import { EmployeerolemaplistComponent } from './pages/employeerolemap/employeerolemaplist/employeerolemaplist.component';
import { EmploginComponent } from './emplogin/emplogin.component';
import { SearchfaqComponent } from './pages/UserModule/searchfaq/searchfaq.component';
import { FolderMasterComponent } from './pages/FolderAndFileModule/Folder/folder-master/folder-master.component';
import { SharedFolderMasterComponent } from './pages/FolderAndFileModule/SharedFolder/shared-folder-master/shared-folder-master.component';
import { FaqresponsereportComponent } from './pages/Reports/faqresponsereport/faqresponsereport.component';
import { OptionreportComponent } from './pages/Reports/optionreport/optionreport.component';
import { LeavetypesComponent } from './pages/EMM_Module/LeaveType/leavetypes/leavetypes.component';
import { ExpensesheadComponent } from './pages/EMM_Module/ExpenseHead/expenseshead/expenseshead.component';
import { EvaluationcriteriasComponent } from './pages/EMM_Module/EvaluationCriteria/evaluationcriterias/evaluationcriterias.component';
import { CustomersComponent } from './pages/EMM_Module/Customer/customers/customers.component';
import { HolidaysComponent } from './pages/EMM_Module/Holidays/holidays/holidays.component';
import { ThoughsComponent } from './pages/EMM_Module/Though/thoughs/thoughs.component';
import { BirthdaysComponent } from './pages/EMM_Module/birthday/birthdays/birthdays.component';
import { AttendancereportComponent } from './pages/EMM_Module/Reports/attendancereport/attendancereport.component';
import { LeavereportComponent } from './pages/EMM_Module/Reports/leavereport/leavereport.component';
import { LatemarkreportComponent } from './pages/EMM_Module/Reports/latemarkreport/latemarkreport.component';
import { EarlymarkreportComponent } from './pages/EMM_Module/Reports/earlymarkreport/earlymarkreport.component';
import { ExpensereportComponent } from './pages/EMM_Module/Reports/expensereport/expensereport.component';
import { EvaluationreportComponent } from './pages/EMM_Module/Reports/evaluationreport/evaluationreport.component';
import { AssetCategoryListComponent } from './pages/materialIssueModule/assetCategory/asset-category-list/asset-category-list.component';
import { AssetListComponent } from './pages/materialIssueModule/asset/asset-list/asset-list.component';
import { EarlymarkTilesComponent } from './pages/EMM_Module/Earlymark/earlymark-tiles/earlymark-tiles.component';
import { LatemarkTilesComponent } from './pages/EMM_Module/LateMark/latemark-tiles/latemark-tiles.component';
import { LeaveTilesComponent } from './pages/EMM_Module/leave/leave-tiles/leave-tiles.component';
import { ExpensesTilesComponent } from './pages/EMM_Module/expenses/expenses-tiles/expenses-tiles.component';
import { HeadApproveEmployeeLeavesTilesComponent } from './pages/EMM_Module/leave/head-approve-employee-leaves-tiles/head-approve-employee-leaves-tiles.component';
import { ApproveEmployeeLeavesTilesComponent } from './pages/EMM_Module/leave/approve-employee-leaves-tiles/approve-employee-leaves-tiles.component';
import { HeadApproveEmployeeLatemarkTilesComponent } from './pages/EMM_Module/LateMark/head-approve-employee-latemark-tiles/head-approve-employee-latemark-tiles.component';
import { HeadApproveEmployeeEarlymarkTilesComponent } from './pages/EMM_Module/Earlymark/head-approve-employee-earlymark-tiles/head-approve-employee-earlymark-tiles.component';
import { ExpensesApproveFromHeadTilesComponent } from './pages/EMM_Module/expenses/expenses-approve-from-head-tiles/expenses-approve-from-head-tiles.component';
import { ExpensesApproveFromAccountantTilesComponent } from './pages/EMM_Module/expenses/expenses-approve-from-accountant-tiles/expenses-approve-from-accountant-tiles.component';
import { AssetMappingListComponent } from './pages/materialIssueModule/assetMapping/asset-mapping-list/asset-mapping-list.component';
import { AssetRequestListComponent } from './pages/materialIssueModule/assetRequest/asset-request-list/asset-request-list.component';
import { AttendanceregisterComponent } from './pages/EMM_Module/Reports/attendanceregister/attendanceregister.component';
import { TrainingsublistComponent } from './pages/TrainingModule/trainingsubmaster/trainingsublist/trainingsublist.component';
import { TrainermasterlistComponent } from './pages/TrainingModule/trainermaster/trainermasterlist/trainermasterlist.component';
import { TrainingtypelistComponent } from './pages/TrainingModule/trainingtypemaster/trainingtypelist/trainingtypelist.component';
import { AnnualtrainpointlistComponent } from './pages/TrainingModule/annualtrainpoint/annualtrainpointlist/annualtrainpointlist.component';
import { TrainingpointmasterlistComponent } from './pages/TrainingModule/trainingpointmaster/trainingpointmasterlist/trainingpointmasterlist.component';
import { TrainingschedulelistComponent } from './pages/TrainingModule/trainingschedule/trainingschedulelist/trainingschedulelist.component';
import { InductionarealistComponent } from './pages/TrainingModule/inductionareamaster/inductionarealist/inductionarealist.component';
import { TrainfeedformmasterlistComponent } from './pages/TrainingModule/trainingfeedbackformmaster/trainfeedformmasterlist/trainfeedformmasterlist.component';
import { TrainingevalutionformlistComponent } from './pages/TrainingModule/trainingevalutionform/trainingevalutionformlist/trainingevalutionformlist.component';
import { TrainfeedmasterlistComponent } from './pages/TrainingModule/trainingfeedbackmaster/trainfeedmasterlist/trainfeedmasterlist.component';
import { TrainattendancecardlistComponent } from './pages/TrainingModule/trainattendancecard/trainattendancecardlist/trainattendancecardlist.component';
import { TrainerloginviewComponent } from './pages/TrainingModule/trainerlogin/trainerloginview/trainerloginview.component';
import { AccessorviewComponent } from './pages/TrainingModule/accessor/accessorview/accessorview.component';
import { AssetRegisterComponent } from './pages/materialIssueModule/Reports/asset-register/asset-register.component';
import { AssetAllocationRegisterComponent } from './pages/materialIssueModule/Reports/asset-allocation-register/asset-allocation-register.component';
import { AssetCategoryRegisterComponent } from './pages/materialIssueModule/Reports/asset-category-register/asset-category-register.component';
import { TrainerAccessorLoginComponent } from './trainer-accessor-login/trainer-accessor-login.component';
import { ModelMasterComponent } from './pages/SalesModule/model/model-master/model-master.component';
import { VarientMasterComponent } from './pages/SalesModule/varient/varient-master/varient-master.component';
import { PriceListMasterComponent } from './pages/SalesModule/priceList/price-list-master/price-list-master.component';
import { FederationMasterComponent } from './pages/FederationMaster/federation-master/federation-master.component';
import { UnitMasterComponent } from './pages/UnitMaster/unit-master/unit-master.component';
import { GroupMasterComponent } from './pages/GroupMaster/group-master/group-master.component';
import { InchargeAreaMasterComponent } from './pages/InchargeAreaMaster/incharge-area-master/incharge-area-master.component';
import { ProjectMasterComponent } from './pages/ProjectMaster/project-master/project-master.component';
import { MemberMasterComponent } from './pages/MemberMaster/member-master/member-master.component';
import { GroupmeetingattendanceComponent } from './pages/Meeting/groupmeetingattendance/groupmeetingattendance.component';
import { UnitdobComponent } from './pages/GiantReports/unitdob/unitdob.component';
import { GroupbodComponent } from './pages/GiantReports/groupbod/groupbod.component';
import { GroupActivityReportComponent } from './pages/GiantReports/group-activity-report/group-activity-report.component';
import { UnitmembershipsummaryComponent } from './pages/GiantReports/unitmembershipsummary/unitmembershipsummary.component';
import { GroupMeetingReportComponent } from './pages/GiantReports/group-meeting-report/group-meeting-report.component';
import { GroupMembershipMonthwiseComponent } from './pages/GiantReports/group-membership-monthwise/group-membership-monthwise.component';
import { GroupProjectActivityMasterComponent } from './pages/GroupProjectActivity/group-project-activity-master/group-project-activity-master.component';
import { ResigsterComponent } from './register-page/register-page.component';
import { MemberWiseSummaryComponent } from './pages/GiantReports/member-wise-summary/member-wise-summary.component';
import { FederationWiseMemberCountComponent } from './pages/GiantReports/federation-wise-member-count/federation-wise-member-count.component';
import { UnitWiseMemberCountComponent } from './pages/GiantReports/unit-wise-member-count/unit-wise-member-count.component';
import { GroupWiseMemberCountComponent } from './pages/GiantReports/group-wise-member-count/group-wise-member-count.component';
import { MemberUploadComponent } from './pages/member-upload/member-upload.component';
import { InchargeActivityMasterComponent } from './pages/InchargeActivity/incharge-activity-master/incharge-activity-master.component';
import { InchargeMeetingMasterComponent } from './pages/InchargeMeeting/incharge-meeting-master/incharge-meeting-master.component';
import { FederationPresidentDashboardComponent } from './pages/CommonModule/Dashboard/federation-president-dashboard/federation-president-dashboard.component';
import { UnitDirectorDashboardComponent } from './pages/CommonModule/Dashboard/unit-director-dashboard/unit-director-dashboard.component';
import { GroupPresidentDashboardComponent } from './pages/CommonModule/Dashboard/group-president-dashboard/group-president-dashboard.component';
import { GroupWiseActivitySummaryComponent } from './pages/GiantReports/group-wise-activity-summary/group-wise-activity-summary.component';
import { GroupWiseMeetingSummaryComponent } from './pages/GiantReports/group-wise-meeting-summary/group-wise-meeting-summary.component';
import { GiantsreportComponent } from './pages/GiantReports/giantsreport/giantsreport.component';
import { ListcircularComponent } from './pages/circular/listcircular/listcircular.component';
import { ListcirculartypeComponent } from './pages/circulartype/listcirculartype/listcirculartype.component';
import { ListgroupofficerComponent } from './pages/CMS/groupofficer/listgroupofficer/listgroupofficer.component';
import { AboutUsListComponent } from './pages/CMS/Home/about-us/about-us-list/about-us-list.component';
import { AdBannerlistComponent } from './pages/CMS/Home/ad-banner/ad-bannerlist/ad-bannerlist.component';
import { BannerListComponent } from './pages/CMS/Home/daynamic_banner/banner-list/banner-list.component';
import { NewsUpdatelistComponent } from './pages/CMS/Home/news-update/news-updatelist/news-updatelist.component';
import { ListaboutComponent } from './pages/CMS/About-Us/about_gaints_walfare/listabout/listabout.component';
import { ListgiantsprayerComponent } from './pages/CMS/About-Us/gaints_prayer/listgiantsprayer/listgiantsprayer.component';
import { ListnanasQuateComponent } from './pages/CMS/About-Us/nanas-quate/listnanas-quate/listnanas-quate.component';
import { ListmessagesComponent } from './pages/CMS/About-Us/messages/listmessages/listmessages.component';
import { ListaboutfederationComponent } from './pages/CMS/About-Us/aboutfederation/listaboutfederation/listaboutfederation.component';
import { ListunitComponent } from './pages/CMS/Resource/unit/listunit/listunit.component';
import { ListgroupComponent } from './pages/CMS/Resource/group_guidline/listgroup/listgroup.component';
import { ListannualtimetableComponent } from './pages/CMS/Resource/annualtimetable/listannualtimetable/listannualtimetable.component';
import { ListimportantdateComponent } from './pages/CMS/Resource/importantdate/listimportantdate/listimportantdate.component';
import { MemberShiplistComponent } from './pages/CMS/Join Us/membership/member-shiplist/member-shiplist.component';
import { GrouplistComponent } from './pages/CMS/Join Us/group/grouplist/grouplist.component';
import { FinancialListComponent } from './pages/CMS/Join Us/financial/financial-list/financial-list.component';
import { ListCguidelineComponent } from './pages/CMS/Resource/C Guideline/list-cguideline/list-cguideline.component';
import { PresidentMessagelistComponent } from './pages/CMS/Home/president-message/president-messagelist/president-messagelist.component';
// import { GallerylistComponent } from './pages/CMS/CMSGallery/gallerylist/gallerylist.component';
import { AddgalleryComponent } from './pages/CMS/CMSGallery/addgallery/addgallery.component';
import { GallerylistComponent } from './pages/CMS/CMSGallery/gallerylist/gallerylist.component';
import { ListcontactComponent } from './pages/CMS/Contact Us/listcontact/listcontact.component';
import { ListpressreleaseComponent } from './pages/CMS/News & Event/listpressrelease/listpressrelease.component';
import { LatestnewsComponent } from './pages/CMS/News & Event/latestnews/latestnews.component';
import { ListeventComponent } from './pages/CMS/News & Event/listevent/listevent.component';
import { MeetingComponent } from './pages/CMS/News & Event/meeting/meeting.component';
import { Faq1Component } from './pages/CMS/CMSFaq/faq_master/faq1/faq1.component';
import { Faqresponses1Component } from './pages/CMS/CMSFaq/faq_master/faqresponses1/faqresponses1.component';
import { FaqsHeadlistComponent } from './pages/CMS/CMSFaq/faqs_head/faqs-headlist/faqs-headlist.component';
import { Faqs1Component } from './pages/CMS/CMSFaq/faq_master/faqs1/faqs1.component';
import { ListpartnersComponent } from './pages/CMS/Home/Partners/listpartners/listpartners.component';
import { ListotherComponent } from './pages/CMS/Resource/Others/listother/listother.component';
import { ListshainaInfoComponent } from './pages/CMS/Home/shaina-Mam-Info/listshaina-info/listshaina-info.component';
import { ListChairpersonComponent } from './pages/CMS/CMS Committee/list-chairperson/list-chairperson.component';
import { ListcentralcommitteeComponent } from './pages/CMS/CMS Committee/Central Committee/listcentralcommittee/listcentralcommittee.component';
import { ListfederationcommitteeComponent } from './pages/CMS/CMS Committee/Federation wise committee/listfederationcommittee/listfederationcommittee.component';
import { ListfederationComponent } from './pages/CMS/CMS Committee/Total Federation/listfederation/listfederation.component';
import { ListunitesComponent } from './pages/CMS/CMS Committee/Total Unites/listunites/listunites.component';
import { GroupoflistComponent } from './pages/CMS/CMS Committee/Total Group/groupoflist/groupoflist.component';
import { ListmemberComponent } from './pages/CMS/CMS Committee/Total Member/listmember/listmember.component';
import { ListsocialfeedComponent } from './pages/CMS/Social Feeds/listsocialfeed/listsocialfeed.component';
import { ListcouncilComponent } from './pages/CMS/BOD/listcouncil/listcouncil.component';
import { ListjoinusformComponent } from './pages/CMS/Varification Tab Master/listjoinusform/listjoinusform.component';

export const routes: Routes = [
  { path: 'login', redirectTo: 'login' },
  { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
  { path: 'federationlogin', component: LoginComponent },
  { path: 'adminlogin', component: EmploginComponent },
  { path: 'login', component: EmploginComponent },
  { path: 'register', component: ResigsterComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'federation-dashboard', component: FederationPresidentDashboardComponent },
  { path: 'unit-dashboard', component: UnitDirectorDashboardComponent },
  { path: 'group-dashboard', component: GroupPresidentDashboardComponent },
  // { path: 'applications', component: ApplicationsComponent },
  // { path: 'modules', component: ModulesComponent },
  { path: 'users', component: UsersComponent },
  { path: 'roles', component: RolesComponent },
  { path: 'forms', component: FormsComponent },
  // { path: 'startpage', component: SelectpageComponent },
  { path: 'faqheads', component: FaqHeadsComponent },
  { path: 'faqs', component: FaqsComponent },
  // { path: 'supportmodule/departments', component: DepartmentsComponent },
  // { path: 'communicationmodule/emailserver', component: EmailsComponent },
  // { path: 'communicationmodule/smsserver', component: SmssComponent },
  { path: 'documentmodule/folders', component: FoldersComponent },
  { path: 'mytickets', component: MyticketComponent },
  { path: 'tickets', component: TicketsComponent },
  { path: 'reports/tickets', component: TicketreportComponent },
  { path: 'reports/departmentwisesummary', component: SummaryreportComponent },
  { path: 'reports/userwisesummary', component: UserwisesummaryComponent },
  // { path: 'userapplicationmapping', component: UserapplicationmappingComponent },
  // { path: 'usermodulemapping', component: UsermodulemappingComponent },
  // { path: 'useraccessmapping', component: UseraccessmappingComponent },
  // { path: 'userrolemapping', component: UserrolemappingComponent },
  // { path: 'userdepartmentmapping', component: UserdepartmentmappingComponent },
  // { path: 'Schedularmodule/tasks', component: TasksComponent },
  // { path: 'Schedularmodule/taskshistory', component: ViewhistoryComponent },
  // { path: 'loggermodule/loghistory', component: LogmoduleComponent },
  { path: 'ticketgroups', component: TicketgroupComponent },
  // { path: 'test', component: TestComponent },
  // {path :'organizations', component:OrganizationsComponent,canActivate:[AuthGuard],data:{roleId:['1']},  },
  { path: 'organizations', component: OrganizationsComponent },
  { path: 'departments', component: DepartmentsComponent },
  { path: 'branch', component: BranchesComponent },
  { path: 'designation', component: DesignationsComponent },
  { path: 'tickrole', component: TickRolesComponent },
  { path: 'features', component: FeaturesComponent },
  { path: 'employee', component: EmployeemasterlistComponent },
  { path: 'cluster', component: ClustermasterlistComponent },
  { path: 'employeeRoleMapping', component: EmployeerolemaplistComponent },
  { path: 'help', component: SearchfaqComponent },
  { path: 'folders', component: FolderMasterComponent },
  { path: 'sharedfolders', component: SharedFolderMasterComponent },
  { path: 'faqresponses', component: FaqresponsereportComponent },
  { path: 'reports/OptionWiseResport', component: OptionreportComponent },

  // EMM Module
  { path: 'leavetype', component: LeavetypesComponent },
  { path: 'evaluationcriteria', component: EvaluationcriteriasComponent },
  { path: 'location', component: CustomersComponent },
  { path: 'holiday', component: HolidaysComponent },
  { path: 'thought', component: ThoughsComponent },
  { path: 'birthday', component: BirthdaysComponent },
  { path: 'expensehead', component: ExpensesheadComponent },
  { path: 'leave', component: LeaveTilesComponent },
  { path: 'expenses', component: ExpensesTilesComponent },
  { path: 'earlymark', component: EarlymarkTilesComponent },
  { path: 'latemark', component: LatemarkTilesComponent },
  { path: 'approveleavesbyhr', component: ApproveEmployeeLeavesTilesComponent },
  { path: 'approveleavesbyhead', component: HeadApproveEmployeeLeavesTilesComponent },
  { path: 'approvelatemarkbyhead', component: HeadApproveEmployeeLatemarkTilesComponent },
  { path: 'approveearlymarkbyhead', component: HeadApproveEmployeeEarlymarkTilesComponent },
  { path: 'approveexpensesbyaccountant', component: ExpensesApproveFromAccountantTilesComponent },
  { path: 'approveexpensesbyhead', component: ExpensesApproveFromHeadTilesComponent },
  { path: 'attendancereport', component: AttendancereportComponent },
  { path: 'leavereport', component: LeavereportComponent },
  { path: 'latemarkreport', component: LatemarkreportComponent },
  { path: 'earlymarkreport', component: EarlymarkreportComponent },
  { path: 'expensereport', component: ExpensereportComponent },
  { path: 'attendanceregister', component: AttendanceregisterComponent },
  // { path: 'evaluationreport', component: EvaluationreportComponent },

  // Material Issue Module
  { path: 'assetcategory', component: AssetCategoryListComponent },
  { path: 'assets', component: AssetListComponent },
  { path: 'assetrequest', component: AssetRequestListComponent },
  { path: 'assetallocation', component: AssetMappingListComponent },
  { path: 'assetregister', component: AssetRegisterComponent },
  { path: 'assetallocationregister', component: AssetAllocationRegisterComponent },
  { path: 'assetcategoryregister', component: AssetCategoryRegisterComponent },

  // Training Module
  { path: 'trainingsubjectgroup', component: TrainingsublistComponent },
  { path: 'trainingsubject', component: TrainingpointmasterlistComponent },
  { path: 'traineraccessor', component: TrainermasterlistComponent },
  { path: 'trainingtype', component: TrainingtypelistComponent },
  { path: 'annualtrainingpoint', component: AnnualtrainpointlistComponent },
  { path: 'trainingschedule', component: TrainingschedulelistComponent },
  { path: 'venue', component: InductionarealistComponent },
  { path: 'trainingfeedbackform', component: TrainfeedformmasterlistComponent },
  { path: 'trainingevaluationform', component: TrainingevalutionformlistComponent },
  { path: 'trainingfeedback', component: TrainfeedmasterlistComponent },
  { path: 'trainattendcard', component: TrainattendancecardlistComponent },
  { path: 'trainingattendance', component: TrainerloginviewComponent },
  { path: 'trainingevaluation', component: AccessorviewComponent },

  // Sales Module
  { path: 'model', component: ModelMasterComponent },
  { path: 'varient', component: VarientMasterComponent },
  { path: 'pricelist', component: PriceListMasterComponent },

  // Giants Group
  { path: 'federationmaster', component: FederationMasterComponent },
  { path: 'unitmaster', component: UnitMasterComponent },
  { path: 'groupmaster', component: GroupMasterComponent },
  { path: 'inchargeareamaster', component: InchargeAreaMasterComponent },
  { path: 'grouptargetareas', component: ProjectMasterComponent },
  { path: 'membermaster', component: MemberMasterComponent },
  { path: 'group-project-activity', component: GroupProjectActivityMasterComponent },
  { path: 'group-meeting', component: GroupmeetingattendanceComponent },
  { path: 'incharge-activity', component: InchargeActivityMasterComponent },
  { path: 'incharge-meeting', component: InchargeMeetingMasterComponent },

  { path: 'unit-list', component: UnitdobComponent },
  { path: 'group-list', component: GroupbodComponent },
  { path: 'group-activity-report', component: GroupActivityReportComponent },
  { path: 'unit-membership-report', component: UnitmembershipsummaryComponent },
  { path: 'group-meeting-report', component: GroupMeetingReportComponent },
  { path: 'group-membership-report', component: GroupMembershipMonthwiseComponent },
  { path: 'member-details', component: MemberWiseSummaryComponent },
  { path: 'federation-wise-member-count', component: FederationWiseMemberCountComponent },
  { path: 'unit-wise-member-count', component: UnitWiseMemberCountComponent },
  { path: 'group-wise-member-count', component: GroupWiseMemberCountComponent },
  { path: 'member-upload', component: MemberUploadComponent },
  { path: 'group-wise-activity-summary', component: GroupWiseActivitySummaryComponent },
  { path: 'group-wise-meeting-summary', component: GroupWiseMeetingSummaryComponent },
  { path: 'giants-group-report', component: GiantsreportComponent },
  { path: "circular-type", component: ListcirculartypeComponent },
  { path: "circular", component: ListcircularComponent },
  // CMS
  { path: "group-officer-guideline", component:ListgroupofficerComponent},
  { path: "about-us-list", component:AboutUsListComponent},
  { path: "ad-banner", component:AdBannerlistComponent},
  { path: "dynamic-banner", component:BannerListComponent},
  { path: "news-and-update", component:NewsUpdatelistComponent},
  { path: "about-giants-welfare", component:ListaboutComponent},
  { path: "giants-prayer", component:ListgiantsprayerComponent},
  { path: "nana's-quate", component:ListnanasQuateComponent},
  { path: "messages", component:ListmessagesComponent},
  { path: "about-federation", component:ListaboutfederationComponent},
  { path: "unit-guideline", component:ListunitComponent},
  { path: "group-guideline", component:ListgroupComponent},
  { path: "annual-timeTable-guideline", component:ListannualtimetableComponent},
  { path: "important-dates-guideline", component:ListimportantdateComponent},
  { path: "memberShip-guideline", component:MemberShiplistComponent},
  { path: "join-us-group-guideline", component:GrouplistComponent},
  { path: "financial-guideline", component:FinancialListComponent},
  { path: "circulars-guideline", component:ListCguidelineComponent},
  { path: "president-message", component:PresidentMessagelistComponent},
  { path: "gallery", component:GallerylistComponent},
  { path: "contact-us", component:ListcontactComponent},
  { path: "press-release", component:ListpressreleaseComponent},
  { path: "project", component:LatestnewsComponent},
  { path: "events", component:ListeventComponent},
  { path: "meeting", component:MeetingComponent},

  { path: 'faqs-headlist', component: FaqsHeadlistComponent},

  { path: 'faqs1', component: Faqs1Component},
  { path: 'faqresponses1', component: Faqresponses1Component},
  { path: 'listpartners', component:ListpartnersComponent},
  { path: 'others-guideline', component:ListotherComponent},
  { path: 'listshaina-info', component:ListshainaInfoComponent},
  { path: 'list-chairperson', component:ListChairpersonComponent},
  { path: 'listcentralcommittee', component:ListcentralcommitteeComponent},
  { path: 'listfederationcommittee', component:ListfederationcommitteeComponent},
  { path: 'listfederation', component:ListfederationComponent},
  { path: 'listunites', component:ListunitesComponent},
  { path: 'groupoflist', component:GroupoflistComponent},
  { path: 'listmember', component:ListmemberComponent},
  { path: 'listsocialfeed', component:ListsocialfeedComponent},
  { path: 'listcouncil', component:ListcouncilComponent},
  { path: 'listjoinusform', component:ListjoinusformComponent},
 

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }



// APIKEY = 'pkrLPJOEb8jm1s8BugCa7B5DVaslvTUM'APPLICATION_KEY = 'EdodCfyQwy11XCDd'