import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { FederationCount } from 'src/app/Models/FederationCount';
import { federation } from 'src/app/Models/federstion';
import { GroupCount } from 'src/app/Models/GroupCount';
import { TotalUnites } from 'src/app/Models/TotalUnites';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-groupoflist',
  templateUrl: './groupoflist.component.html',
  styleUrls: ['./groupoflist.component.css']
})
export class GroupoflistComponent implements OnInit {

  formTitle = "Groups";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [["WEB_GROUP_STATUS", "Show In Web"], ["NAME", "Group Name"], ["FEDERATION_NAME", "Federation Name"], ["UNIT_NAME", "Unit Name"], ["VENUE", "Venue"],];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: GroupCount = new GroupCount();
  isSpinning = false;
  isFilterApplied: string = 'default';
  filterClass: string = 'filter-invisible';
  FEDERATION_ID: any;
  UNIT_ID: any
  ID: any
  federation: federation[] = []
  TotalUnites: TotalUnites[][]

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search(true);
    this.getname();
    // this.getunit();
  }
  keyup(event: any) {
    this.search();
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      this.sortValue = this.sortValue.startsWith("a") ? "asc" : "desc";
      console.log("an", this.sortValue);
    }
    catch (error) { sort = ""; }

    if (this.FEDERATION_ID != undefined && this.FEDERATION_ID > 0) {
      this.filterQuery =
        this.filterQuery + ' AND FEDERATION_ID=' + "'" + this.FEDERATION_ID + "'";

    }
    if (this.ID != undefined && this.ID > 0) {

      this.filterQuery =
        this.filterQuery + ' AND UNIT_ID=' + "'" + this.ID + "'";


    }


    var likeQuery = "";
    // if (this.searchText != "") {
    //   likeQuery = " AND";
    //   this.columns.forEach(column => {
    //     likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
    //   });
    //   likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    // }
    if (this.searchText != '') {
      likeQuery = ' AND (';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ' )';
    }
    this.loadingRecords = true;

    this.api.getgroup(this.pageIndex, this.pageSize, this.sortKey, 'asc', this.filterQuery + likeQuery).
      subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
  }



  sort(sort: { key: string; value: string }):
    void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    console.log("SORT", this.sortValue);
    this.search(true);
  }




  today = new Date().setDate(new Date().getDate() + 4);

  // add(): void {
  //   this.drawerTitle = "Add Group Target Area";
  //   this.drawerData = new letestnews();
  //   this.drawerVisible = true;
  //   this.drawerData.STATUS = true;
  // }

  // edit(data: letestnews): void {
  //   this.drawerTitle = "Update Group Target Area";
  //   this.drawerData = Object.assign({}, data);
  //   this.drawerVisible = true;
  // }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }


  // single status change
  onStatusChange(data: GroupCount, status) {
    data.WEB_GROUP_STATUS = status;
    
    if (data.WEB_GROUP_STATUS == true) {

      data.IS_ALL_GROUP_UPDATE = false;

    } else {
      data.IS_ALL_GROUP_UPDATE = true;
    }

    this.api.updategroup(data).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Status", "");

      this.search();
    });
  }

  // multipal status changes 

  allStatusChange(status) {
    this.drawerData.IS_ALL_GROUP_UPDATE = status;

    if (this.drawerData.IS_ALL_GROUP_UPDATE == true) {

      this.drawerData.WEB_GROUP_STATUS = true;
    } else {
      this.drawerData.WEB_GROUP_STATUS = false;
    }

    this.api.updategroup(this.drawerData).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Status", "");

      this.search();
    });
  }





  applyFilter() {

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    this.filterQuery = '';






    if (this.FEDERATION_ID != undefined && this.FEDERATION_ID > 0) {
      this.filterQuery =
        this.filterQuery + ' AND FEDERATION_ID=' + "'" + this.FEDERATION_ID + "'";


    }


    if (this.ID != undefined && this.ID > 0) {

      this.filterQuery =
        this.filterQuery + ' AND UNIT_ID=' + "'" + this.ID + "'";


    }





    var likeQuery = '';

    if (this.searchText != '') {
      likeQuery = ' AND (';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ' )';
    }
    this.api
      .getgroup(

        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        this.filterQuery

      )



      .subscribe(
        (data) => {
          console.log(data);
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        },
        (err) => {
          console.log(err);
        }
      );

  }



  clearFilter() {
    this.FEDERATION_ID = null;
    this.ID = null;
    this.filterQuery = '';
    this.applyFilter();
  }



  getname() {

    this.api.getDropdownfederation(0, 0, 'NAME', 'asc', "").subscribe(data => {
      this.federation = data['data'];
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }


  // getunit() {

  //   this.api.getunitdropdown(0, 0, 'NAME', 'asc', "").subscribe(data => {
  //     this.TotalUnites = data['data'];
  //   }, err => {
  //     console.log(err);
  //     this.isSpinning = false;
  //   });
  // }



  showFilter(): void {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';
  }

  demo(Event: any) {

    this.api.getunitdropdown(0, 0, 'NAME', 'asc', " AND FEDERATION_ID = " + Event).subscribe(data => {
      this.TotalUnites = data['data'];
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });



  }

  // demo1 (Event1:any){

  //   this.api.getgroup(0, 0, 'NAME', 'asc', " AND UNIT_ID = " + Event1).subscribe(data => {
  //     this.TotalUnites = data['data'];
  //   }, err => {
  //     console.log(err);
  //     this.isSpinning = false;
  //   });



  // }

}
