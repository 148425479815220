import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TrainingSchedule } from 'src/app/Models/trainingschedule';
import { ApiService } from 'src/app/Service/api.service';
import { TrainattendmapComponent } from '../trainattendmap/trainattendmap.component';

@Component({
  selector: 'app-trainingschedulelist',
  templateUrl: './trainingschedulelist.component.html',
  styleUrls: ['./trainingschedulelist.component.css']
})

export class TrainingschedulelistComponent implements OnInit {
  formTitle = "Manage Training Schedule";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  totalRecords1 = 1;
  totalRecords2 = 1;
  totalRecords3 = 1;
  totalRecords4 = 1;
  totalRecords5 = 1;
  totalRecords6 = 1;
  dataList = [];
  dataList1 = [];
  dataList2 = [];
  dataList3 = [];
  dataList4 = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  sortValue1: string = "desc";
  sortKey1: string = "id";
  sortValue2: string = "desc";
  sortKey2: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["PROGRAM_TITLE", "Program Title"], ["SUBJECT_TITLE", "Subject Name"], ["TRAINER_NAME", "Trainer Name"], ["ASSESSOR_NAME", "Accessor Name"], ["DATE", "Date"], ["START_TIME", "Start Time"], ["END_TIME", "End Time"], ["VENUE", "Venue"], ["ASSESSMENT_TYPE", "Assessment Type"]];
  attendmap: any = [{ SCHEDULE_ID: 1, EMP_ID: 1, ATTENDANCE: 0, FEEDBACK: "valid", RATINGS: 1, PERFORMANCE: 1, IS_ACTIVE: 0 }];
  traintrainermap: any = [{ SCHEDULE_ID: 1, TRAINER_ID: 1, IS_ACTIVE: 0 }];
  scheduleId = 0;
  empId = 0;
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: TrainingSchedule = new TrainingSchedule();
  drawerVisible1: boolean = false;
  drawerTitle1: string = '';
  drawerData1: TrainingSchedule = new TrainingSchedule();
  drawerData2: string[] = [];
  drawerVisible3: boolean = false;
  drawerTitle3: string = '';
  drawerData3 = [];
  data: TrainingSchedule = new TrainingSchedule();
  drawerVisible5!: boolean;
  drawerTitle5!: string;
  drawerData5 = [];
  drawerData6: any[] = [];
  drawerVisible4!: boolean;
  drawerTitle4!: string;
  drawerData4 = [];
  isVisible: boolean = false;
  i = -1;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.loadingRecords = true;
    this.api.getAlltrainingSchedule(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create New Training Schedule";
    this.drawerData = new TrainingSchedule();
    this.drawerVisible = true;
  }

  edit(data: TrainingSchedule): void {
    this.drawerTitle = "Update Training Schedule";
    this.drawerData = Object.assign({}, data);
    const s = '01-01-1970 ' + this.drawerData.START_TIME;
    const s2 = '01-01-1970 ' + this.drawerData.END_TIME;
    this.drawerData.START_TIME = new Date(s);
    this.drawerData.END_TIME = new Date(s2);
    this.drawerData.TRAINING_TYPE_ID = this.data.TRAINING_TYPE_ID;
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  drawerClose1(): void {
    this.search();
    this.drawerVisible1 = false;
  }

  ViewAttendies(data: TrainingSchedule): void {
    this.scheduleId = data.ID;
    this.drawerTitle4 = "View Mapped Attendies";

    this.api.getAlltrainingAttendiedMapping(0, 0, '', '', ' AND SCHEDULE_ID = ' + this.scheduleId + ' AND ATTENDANCE_STATUS=1').subscribe(data => {
      this.loadingRecords = false;
      this.drawerData4 = data['data'];
      this.drawerVisible4 = true;

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  get closeCallback4() {
    return this.drawerClose4.bind(this);
  }

  drawerClose4(): void {
    this.search();
    this.drawerVisible4 = false;
  }

  close4(): void {
    this.drawerClose4();
  }

  @ViewChild(TrainattendmapComponent, { static: false }) TrainattendmapComponentVar: TrainattendmapComponent;

  MapAttendies(data: TrainingSchedule): void {
    this.scheduleId = data.ID
    this.empId = data.ID;
    this.drawerData1 = data;
    this.TrainattendmapComponentVar.departmentnm = [];
    this.TrainattendmapComponentVar.designationm = [];

    this.api.trainingAttendiedMapping(Number(this.scheduleId), 0, 0).subscribe(data => {
      if (data['code'] == 200) {
        this.totalRecords2 = data['count'];
        this.drawerData2 = Object.assign([], data['data']);
        this.drawerVisible1 = true;
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Failed to Get Details", "");
    });

    this.drawerTitle1 = "Attendies for " + data.PROGRAM_TITLE + "";
  }

  Review(data1: any): void {
    this.scheduleId = data1.ID;
    var filter = " AND ATTENDANCE_STATUS = 1 " + " " + " AND SCHEDULE_ID =" + this.scheduleId;

    this.api.getAlltrainingAttendiedMapping(0, 0, '', '', filter).subscribe(data => {
      if (data['code'] = 200) {
        this.drawerData6 = Object.assign([], data['data']);
        this.drawerVisible5 = true;
      }

    }, err => {
      console.log(err);
      this.message.error("Failed to Get Details", "");
    });

    this.drawerTitle5 = "View Accessor Review";
  }

  get closeCallback5() {
    return this.drawerClose5.bind(this);
  }

  drawerClose5(): void {
    this.drawerVisible5 = false;
  }

  close(): void {
    this.drawerClose5();
  }

  showModal(data: any): void {
    this.isVisible = true;

    this.api.getAlltrainingSchedule(0, 0, 'ID', 'asc', ' AND ID=' + data.ID).subscribe(data => {
      this.loadingRecords = false;
      this.dataList2 = data['data'];

    }, err => {
      console.log(err);
    });

    this.scheduleId = data.ID;
    var filter = " AND ATTENDANCE_STATUS = 1 " + " " + " AND SCHEDULE_ID =" + this.scheduleId;

    this.api.getAlltrainingAttendiedMapping(0, 0, '', '', filter).subscribe(data => {
      this.loadingRecords = false;
      this.dataList3 = data['data'];

    }, err => {
      console.log(err);
    });
  }

  handleCancel(): void {
    this.i = -1;
    this.isVisible = false;
  }

  onPrint(divName: any) {
    const printContents = document.getElementById(divName).innerHTML;

    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  sort1(sort: { key: string; value: string }): void {
    this.sortKey1 = sort.key;
    this.sortValue1 = sort.value;
    this.search1(false);
  }

  search1(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue1.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    likeQuery = likeQuery + ' AND SCHEDULE_ID=' + this.scheduleId;

    this.api.getAlltrainingAttendiedMapping(0, 0, this.sortKey1, sort, likeQuery).subscribe(data => {
      this.totalRecords4 = data['count'];
      this.drawerData4 = data['data'];

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  sort2(sort: { key: string; value: string }): void {
    this.sortKey2 = sort.key;
    this.sortValue2 = sort.value;
    this.search2(false);
  }

  search2(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue2.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    likeQuery = likeQuery + ' AND ATTENDANCE_STATUS = 1' + ' AND SCHEDULE_ID=' + this.scheduleId

    this.api.getAlltrainingAttendiedMapping(0, 0, this.sortKey2, sort, likeQuery).subscribe(data => {
      this.totalRecords6 = data['count'];
      this.drawerData6 = data['data'];

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  screenwidth: any;

  getTimeInAM_PM(time) {
    return this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + time;
  }
}
