import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';
import { PriceList } from 'src/app/Models/PriceList';
import { ApiService } from 'src/app/Service/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { PriceListDetails } from 'src/app/Models/PriceListDetails';

@Component({
  selector: 'app-price-list-drawer',
  templateUrl: './price-list-drawer.component.html',
  styleUrls: ['./price-list-drawer.component.css']
})

export class PriceListDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: PriceList;
  @Input() drawerVisible: boolean;
  isSpinning = false;
  leaveTypes = [];
  namePattern = "([A-Za-z0-9 \s]){1,}";

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() { }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    for (var i = 0; i < this.priceListDetailsArry.length; i++) {
      if (this.priceListDetailsArry[i]["INVOICE_AMOUNT"] == "")
        this.priceListDetailsArry[i]["INVOICE_AMOUNT"] = "0";

      if (this.priceListDetailsArry[i]["INSURANCE"] == "")
        this.priceListDetailsArry[i]["INSURANCE"] = "0";

      if (this.priceListDetailsArry[i]["RTO"] == "")
        this.priceListDetailsArry[i]["RTO"] = "0";

      if (this.priceListDetailsArry[i]["FASTAG"] == "")
        this.priceListDetailsArry[i]["FASTAG"] = "0";

      if (this.priceListDetailsArry[i]["ACCESSORIES"] == "")
        this.priceListDetailsArry[i]["ACCESSORIES"] = "0";

      if (this.priceListDetailsArry[i]["EX_WARRANTY"] == "")
        this.priceListDetailsArry[i]["EX_WARRANTY"] = "0";

      if (this.priceListDetailsArry[i]["SOT"] == "")
        this.priceListDetailsArry[i]["SOT"] = "0";

      if (this.priceListDetailsArry[i]["ON_ROAD_PRICE"] == "")
        this.priceListDetailsArry[i]["ON_ROAD_PRICE"] = "0";

      if (this.priceListDetailsArry[i]["TCS1"] == "")
        this.priceListDetailsArry[i]["TCS1"] = "0";

      if (this.priceListDetailsArry[i]["TCS5"] == "")
        this.priceListDetailsArry[i]["TCS5"] = "0";
    }

    this.data.PRICE_LIST_DETAILS = this.priceListDetailsArry;

    if (this.data.NAME != undefined && this.data.NAME != null) {
      if (this.data.NAME.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.NAME)) {
          isOk = false;
          this.message.error("Please Enter Valid Price List Name", "");
        }

      } else {
        isOk = false;
        this.message.error("Please Enter Valid Price List Name", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Enter Valid Price List Name", "");
    }

    if (this.data.WEF_DATE == undefined || this.data.WEF_DATE == null) {
      isOk = false;
      this.message.error("Please Select Valid Date", "");
    }

    if (this.data.MODEL_ID == undefined || this.data.MODEL_ID == null) {
      isOk = false;
      this.message.error("Please Select Valid Model", "");
    }

    if (this.priceListDetailsArry.length == 0) {
      isOk = false;
      this.message.error("Please Fill Valid Price List Details", "");
    }

    if (isOk) {
      this.isSpinning = true;
      this.data.WEF_DATE = this.datePipe.transform(this.data.WEF_DATE, "yyyy-MM-dd");
      console.log(this.data);

      if (this.data.ID) {
        this.api.updatePriceList1(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Price List Details Updated Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

          } else {
            this.message.error("Price List Details Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createPriceList1(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Price List Created Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

            else {
              this.data = new PriceList();
            }

          } else {
            this.message.error("Price List Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }

  models = [];

  getAllModelsWithActiveStatus() {
    this.models = [];

    this.api.getAllModels(0, 0, 'NAME', 'asc', ' AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.models = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getAllModels() {
    this.models = [];

    this.api.getAllModels(0, 0, 'NAME', 'asc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.models = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  today = new Date().setDate(new Date().getDate());

  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  varientTotalRecords: number;
  varientDataPriceList = [];
  priceListDetails = new PriceListDetails();
  @Input() priceListDetailsArry = [];

  onModelChange(modelID) {
    this.api.getAllVarients(0, 0, 'NAME', 'asc', ' AND MODEL_ID=' + modelID + ' AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.varientDataPriceList = data['data'];

        this.priceListDetailsArry = [];
        for (var i = 0; i < this.varientDataPriceList.length; i++) {
          this.priceListDetails.VARIENT_ID = this.varientDataPriceList[i]["ID"];
          this.priceListDetails.VARIENT_NAME = this.varientDataPriceList[i]["NAME"];
          this.priceListDetails.FUEL_TYPE = this.varientDataPriceList[i]["FUEL_TYPE"];
          this.priceListDetails.TRANSMISSION = this.varientDataPriceList[i]["TRANSMISSION"];
          this.priceListDetails.INVOICE_AMOUNT = "";
          this.priceListDetails.INSURANCE = "";
          this.priceListDetails.RTO = "";
          this.priceListDetails.FASTAG = "";
          this.priceListDetails.ACCESSORIES = "";
          this.priceListDetails.EX_WARRANTY = "";
          this.priceListDetails.SOT = "";
          this.priceListDetails.ON_ROAD_PRICE = "";
          this.priceListDetails.TCS1 = "";
          this.priceListDetails.TCS5 = "";

          this.priceListDetailsArry.push(Object.assign({}, this.priceListDetails));
        }
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });

    // Create Price List Name
    let modelName = this.models.filter(obj => {
      return obj.ID == modelID;
    });

    if (modelName.length > 0)
      this.data.NAME = modelName[0]["NAME"] + " " + this.datePipe.transform(this.data.WEF_DATE, "ddMMMyyyy");
  }

  numberOnly(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  getFuelTypeFullForm(fuelType) {
    if (fuelType == "P")
      return "Petrol";

    else if (fuelType == "D")
      return "Diesel";

    else if (fuelType == "C")
      return "CNG";

    else if (fuelType == "L")
      return "LPG";

    else if (fuelType == "E")
      return "Electrical";

    else
      return "";
  }

  getTransmissionFullForm(transmissionType) {
    if (transmissionType == "M")
      return "Manual";

    else if (transmissionType == "A")
      return "Automatic";

    else
      return "";
  }

  loadingRecords = false;

  onInvoiceAmtChange(amt) {
    for (var i = 0; i < this.priceListDetailsArry.length; i++) {
      this.priceListDetailsArry[i]["INVOICE_AMOUNT"] = amt;
    }
  }

  onInsuranceAmtChange(amt) {
    for (var i = 0; i < this.priceListDetailsArry.length; i++) {
      this.priceListDetailsArry[i]["INSURANCE"] = amt;
    }
  }

  onRTOAmtChange(amt) {
    for (var i = 0; i < this.priceListDetailsArry.length; i++) {
      this.priceListDetailsArry[i]["RTO"] = amt;
    }
  }

  onFASTAGAmtChange(amt) {
    for (var i = 0; i < this.priceListDetailsArry.length; i++) {
      this.priceListDetailsArry[i]["FASTAG"] = amt;
    }
  }

  onAccessoriesAmtChange(amt) {
    for (var i = 0; i < this.priceListDetailsArry.length; i++) {
      this.priceListDetailsArry[i]["ACCESSORIES"] = amt;
    }
  }

  onWarrantyAmtChange(amt) {
    for (var i = 0; i < this.priceListDetailsArry.length; i++) {
      this.priceListDetailsArry[i]["EX_WARRANTY"] = amt;
    }
  }

  onSOTAmtChange(amt) {
    for (var i = 0; i < this.priceListDetailsArry.length; i++) {
      this.priceListDetailsArry[i]["SOT"] = amt;
    }
  }

  onOnRoadPriceAmtChange(amt) {
    for (var i = 0; i < this.priceListDetailsArry.length; i++) {
      this.priceListDetailsArry[i]["ON_ROAD_PRICE"] = amt;
    }
  }

  onTCS1AmtChange(amt) {
    for (var i = 0; i < this.priceListDetailsArry.length; i++) {
      this.priceListDetailsArry[i]["TCS1"] = amt;
    }
  }

  onTCS5AmtChange(amt) {
    for (var i = 0; i < this.priceListDetailsArry.length; i++) {
      this.priceListDetailsArry[i]["TCS5"] = amt;
    }
  }

  INVOICE_AMOUNT: string;
  INSURANCE: string;
  RTO: string;
  FASTAG: string;
  ACCESSORIES: string;
  EX_WARRANTY: string;
  SOT: string;
  ON_ROAD_PRICE: string;
  TCS1: string;
  TCS5: string;
}
