import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
// import { NzTableQueryParams } from 'ng-zorro-antd/table';
// import { appkeys } from 'src/app/app.constant';
// import { AboutMaster } from 'src/app/Models/aboutmaster';
import { CmsNews_Update } from 'src/app/Models/CmsNewsUpdate';
import { ApiService } from 'src/app/Service/api.service';
// import { ClientmasterService } from 'src/app/Service/clientmaster.service';

@Component({
  selector: 'app-news-updatelist',
  templateUrl: './news-updatelist.component.html',
  styleUrls: ['./news-updatelist.component.css']
})
export class NewsUpdatelistComponent implements OnInit {

  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: CmsNews_Update = new CmsNews_Update();
  formTitle = "News And Update";
  @Input()
  dataList: any[] = [];
  // dataList = [];
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["SEQUENCE_NO", "Sequence"], ["TITLE", "Title"], ["TITLE", "Loctaion"], ["DATE", "Date"]];
  @Input()
  drawerClose2!: Function;
  // imgurl= appkeys.retriveimgUrl;

  constructor(private api: ApiService, private message: NzNotificationService,private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.loadingRecords = false;
    this.search()

  }

  keyup(event: any) {
    this.search();
  }

  search(reset: boolean = false) {
    if (reset) { this.pageIndex = 1; } var sort: string;
    try {
      this.sortValue = this.sortValue.startsWith("a") ? "asc" : "desc";
      console.log("an", this.sortValue);
    }
    catch (error) { sort = ""; }
    var likeQuery = ""; if (this.searchText != "") {
      likeQuery = " AND";

      this.columns.forEach(column => { likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR"; });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    this.loadingRecords = true;

    this.api.getNews_Update(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, likeQuery).
      subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count']; this.dataList = data['data'];
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
  }


  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Add New News";
    this.drawerData = new CmsNews_Update();
    this.api.getNews_Update(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQUENCE_NO = 1;
      } else {
        this.drawerData.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
      }
    }, err => {
      console.log(err);
    })
    this.drawerVisible = true;
  }

  edit(data: CmsNews_Update): void {
    this.drawerTitle = "Update News";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  // sort(params: NzTableQueryParams): void {
  //     const { pageSize, pageIndex, sort} = params;
  //     const currentSort = sort.find(item => item.value !== null);
  //     const sortField = (currentSort && currentSort.key) || 'id';
  //     const sortOrder = (currentSort && currentSort.value) || 'asc';
  //     console.log(currentSort)

  //     console.log("sortOrder :"+sortOrder)
  //     this.pageIndex = pageIndex;
  //     this.pageSize = pageSize;

  //     if(this.pageSize != pageSize) {
  //       this.pageIndex = 1;
  //       this.pageSize = pageSize;
  //     }

  //     if( this.sortKey != sortField) {
  //       this.pageIndex = 1;
  //       this.pageSize =pageSize;
  //     }

  //     this.sortKey = sortField;
  //     this.sortValue = sortOrder;
  //     this.search();
  //   }

  sort(sort: { key: string; value: string }):
    void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    console.log("SORT", this.sortValue);
    this.search(true);
  }
  // getTimeIn12Hour(time: any) { return this.datePipe.transform(new Date(), 'dd-MM-yyyy' + ' ' + time); }



}
