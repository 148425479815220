export class Nanasquate{

  


 ID:number=0;
DESCRIPTION:string='';
STATUS:boolean=true;
SEQUENCE_NO:number=0 ;
TITLE:string='';
IMG_URL:string='';
}