import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Useraccessmapping } from '../Models/useraccessmapping';
import { ApiService } from '../Service/api.service';

@Component({
  selector: 'app-trainer-accessor-login',
  templateUrl: './trainer-accessor-login.component.html',
  styleUrls: ['./trainer-accessor-login.component.css']
})

export class TrainerAccessorLoginComponent implements OnInit {
  EMAIL_ID = "";
  PASSWORD = "";
  passwordVisible = false;
  supportKey = "";
  ORGANIZATION_ID: number;
  isLogedIn = false;
  userAccessData: Useraccessmapping;
  userId = Number(this.cookie.get('userId'));
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData = [];
  isSpinning = false;
  roleId = sessionStorage.getItem('roleId');

  constructor(private router: Router, private api: ApiService, private message: NzNotificationService, private cookie: CookieService) { }

  ngOnInit() {
    if (this.cookie.get('token') === '' || this.cookie.get('userId') === '' || this.cookie.get('token') === null) {
      this.isLogedIn = false;
      this.router.navigate(['/login']);

    } else {
      this.isLogedIn = true;
      this.router.navigate(['/dashboard']);
    }

    const userId = '1';
    this.api.requestPermission(userId);
  }

  login(): void {
    if (this.EMAIL_ID == "" && this.PASSWORD == "")
      this.message.error("Please enter email id and password", "");

    else {
      this.isSpinning = true;
      this.cookie.deleteAll();
      sessionStorage.clear();

      this.api.memberlogin(this.EMAIL_ID, this.PASSWORD).subscribe(data => {
        if (data['code'] == 200) {
          this.cookie.set('token', data["data"][0]["token"], 365, "", "", false, "Strict");
          this.cookie.set('userId', data["data"][0]['UserData'][0]['USER_ID'], 365, "", "", false, "Strict");
          this.cookie.set('userName', data["data"][0]['UserData'][0]['NAME'], 365, "", "", false, "Strict");
          this.cookie.set('roleId', data["data"][0]['UserData'][0]['ROLE_ID'], 365, "", "", false, "Strict");
          sessionStorage.setItem('userId', data["data"][0]['UserData'][0]['USER_ID']);
          sessionStorage.setItem('roleId', data["data"][0]['UserData'][0]['ROLE_ID']);
          sessionStorage.setItem('emailId', data["data"][0]['UserData'][0]['EMAIL_ID']);
          sessionStorage.setItem('orgId', data["data"][0]['UserData'][0]['ORG_ID']);
          this.cookie.set('orgId', data["data"][0]['UserData'][0]['ORG_ID'], 365, "", "", false, "Strict");
          this.cookie.set('deptId', data["data"][0]['UserData'][0]['DEPARTMENT_ID'], 365, "", "", false, "Strict");
          this.cookie.set('emailId', data["data"][0]['UserData'][0]['EMAIL_ID'], 365, "", "", false, "Strict")
          this.cookie.set('designationId', data["data"][0]['UserData'][0]['DESIGNATION_ID'], 365, "", "", false, "Strict")
          this.cookie.set('branchId', data["data"][0]['UserData'][0]['BRANCH_ID'], 365, "", "", false, "Strict");

          this.api.addLog('L', 'Login Successfully', this.cookie.get('emailId')).subscribe(data => {

          }, err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });

          this.api.getMemberChannels().subscribe(data => {
            if (data['code'] == 200) {
              this.cookie.set('channels', data["data"], 365, "", "", false, "Strict");
              // var channels = data["data"].split(',');
              // this.api.subscribeTokenToTopic(this.api.cloudID, channels);
              window.location.reload();
            }

          }, err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });

        } else if (data['code'] == 404) {
          this.isSpinning = false;
          this.message.error(data['message'], "");
        }

      }, err => {
        this.isSpinning = false;
        this.message.error(JSON.stringify(err), "");
      });
    }
  }

  mailformat = /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;
  phoneno = /^\d{10}$/;
}
