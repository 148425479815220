import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { FederationMaster } from 'src/app/Models/FederationMaster';
import { ApiService } from 'src/app/Service/api.service';
import { ManageUnitMembersComponent } from '../../UnitMaster/manage-unit-members/manage-unit-members.component';
import { ManageFederationMembersComponent } from '../manage-federation-members/manage-federation-members.component';

@Component({
  selector: 'app-assign-federation-members',
  templateUrl: './assign-federation-members.component.html',
  styleUrls: ['./assign-federation-members.component.css']
})

export class AssignFederationMembersComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: FederationMaster;
  roleID: number;
  @Input() drawerVisible: boolean;
  isSpinning = false;
  isUnitSpinning = false;
  leaveTypes = [];
  namePattern = "([A-Za-z0-9 \s]){1,}";
  imageRetriveURL = this.api.retriveimgUrl + "profileImage/";

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getMembers();
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  numberOnly(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  isVisible = false;
  isConfirmLoading = false;

  showModal(rID: number): void {
    this.roleID = rID;
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  members = [];
  memberLoading = false;

  getMembers() {
    this.memberLoading = true;

    this.api.getAllMembers(0, 0, "NAME", "asc", "").subscribe(data => {
      if (data['code'] == 200) {
        this.memberLoading = false;
        this.members = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  BOD: string = "";
  unitBOD: string = "";
  memberList = [];

  presidentPhoto: string = "";
  presidentName: string = "";
  presidentMobile: string = "";
  presidentFederation: string = "";
  presidentUnit: string = "";
  presidentGroup: string = "";

  ippPhoto: string = "";
  ippName: string = "";
  ippMobile: string = "";
  ippFederation: string = "";
  ippUnit: string = "";
  ippGroup: string = "";

  vp1Photo: string = "";
  vp1Name: string = "";
  vp1Mobile: string = "";
  vp1Federation: string = "";
  vp1Unit: string = "";
  vp1Group: string = "";

  vp2Photo: string = "";
  vp2Name: string = "";
  vp2Mobile: string = "";
  vp2Federation: string = "";
  vp2Unit: string = "";
  vp2Group: string = "";

  vp3Photo: string = "";
  vp3Name: string = "";
  vp3Mobile: string = "";
  vp3Federation: string = "";
  vp3Unit: string = "";
  vp3Group: string = "";

  secretaryPhoto: string = "";
  secretaryName: string = "";
  secretaryMobile: string = "";
  secretaryFederation: string = "";
  secretaryUnit: string = "";
  secretaryGroup: string = "";

  coSecretaryPhoto: string = "";
  coSecretaryName: string = "";
  coSecretaryMobile: string = "";
  coSecretaryFederation: string = "";
  coSecretaryUnit: string = "";
  coSecretaryGroup: string = "";

  treasurerPhoto: string = "";
  treasurerName: string = "";
  treasurerMobile: string = "";
  treasurerFederation: string = "";
  treasurerUnit: string = "";
  treasurerGroup: string = "";

  pro1Photo: string = "";
  pro1Name: string = "";
  pro1Mobile: string = "";
  pro1Federation: string = "";
  pro1Unit: string = "";
  pro1Group: string = "";

  pro2Photo: string = "";
  pro2Name: string = "";
  pro2Mobile: string = "";
  pro2Federation: string = "";
  pro2Unit: string = "";
  pro2Group: string = "";

  coOrdinatorPhoto: string = "";
  coOrdinatorName: string = "";
  coOrdinatorMobile: string = "";
  coOrdinatorFederation: string = "";
  coOrdinatorUnit: string = "";
  coOrdinatorGroup: string = "";

  specialOfficer1Photo: string = "";
  specialOfficer1Name: string = "";
  specialOfficer1Mobile: string = "";
  specialOfficer1Federation: string = "";
  specialOfficer1Unit: string = "";
  specialOfficer1Group: string = "";

  specialOfficer2Photo: string = "";
  specialOfficer2Name: string = "";
  specialOfficer2Mobile: string = "";
  specialOfficer2Federation: string = "";
  specialOfficer2Unit: string = "";
  specialOfficer2Group: string = "";

  specialOfficer3Photo: string = "";
  specialOfficer3Name: string = "";
  specialOfficer3Mobile: string = "";
  specialOfficer3Federation: string = "";
  specialOfficer3Unit: string = "";
  specialOfficer3Group: string = "";

  specialOfficer4Photo: string = "";
  specialOfficer4Name: string = "";
  specialOfficer4Mobile: string = "";
  specialOfficer4Federation: string = "";
  specialOfficer4Unit: string = "";
  specialOfficer4Group: string = "";

  unit1DirectorPhoto: string = "";
  unit1DirectorName: string = "";
  unit1DirectorMobile: string = "";
  unit1DirectorFederation: string = "";
  unit1DirectorUnit: string = "";
  unit1DirectorGroup: string = "";

  unit2DirectorPhoto: string = "";
  unit2DirectorName: string = "";
  unit2DirectorMobile: string = "";
  unit2DirectorFederation: string = "";
  unit2DirectorUnit: string = "";
  unit2DirectorGroup: string = "";

  unit3DirectorPhoto: string = "";
  unit3DirectorName: string = "";
  unit3DirectorMobile: string = "";
  unit3DirectorFederation: string = "";
  unit3DirectorUnit: string = "";
  unit3DirectorGroup: string = "";

  unit4DirectorPhoto: string = "";
  unit4DirectorName: string = "";
  unit4DirectorMobile: string = "";
  unit4DirectorFederation: string = "";
  unit4DirectorUnit: string = "";
  unit4DirectorGroup: string = "";

  unit5DirectorPhoto: string = "";
  unit5DirectorName: string = "";
  unit5DirectorMobile: string = "";
  unit5DirectorFederation: string = "";
  unit5DirectorUnit: string = "";
  unit5DirectorGroup: string = "";

  unit6DirectorPhoto: string = "";
  unit6DirectorName: string = "";
  unit6DirectorMobile: string = "";
  unit6DirectorFederation: string = "";
  unit6DirectorUnit: string = "";
  unit6DirectorGroup: string = "";

  unit7DirectorPhoto: string = "";
  unit7DirectorName: string = "";
  unit7DirectorMobile: string = "";
  unit7DirectorFederation: string = "";
  unit7DirectorUnit: string = "";
  unit7DirectorGroup: string = "";

  unit8DirectorPhoto: string = "";
  unit8DirectorName: string = "";
  unit8DirectorMobile: string = "";
  unit8DirectorFederation: string = "";
  unit8DirectorUnit: string = "";
  unit8DirectorGroup: string = "";

  unit9DirectorPhoto: string = "";
  unit9DirectorName: string = "";
  unit9DirectorMobile: string = "";
  unit9DirectorFederation: string = "";
  unit9DirectorUnit: string = "";
  unit9DirectorGroup: string = "";

  unit10DirectorPhoto: string = "";
  unit10DirectorName: string = "";
  unit10DirectorMobile: string = "";
  unit10DirectorFederation: string = "";
  unit10DirectorUnit: string = "";
  unit10DirectorGroup: string = "";

  unit11DirectorPhoto: string = "";
  unit11DirectorName: string = "";
  unit11DirectorMobile: string = "";
  unit11DirectorFederation: string = "";
  unit11DirectorUnit: string = "";
  unit11DirectorGroup: string = "";

  unit12DirectorPhoto: string = "";
  unit12DirectorName: string = "";
  unit12DirectorMobile: string = "";
  unit12DirectorFederation: string = "";
  unit12DirectorUnit: string = "";
  unit12DirectorGroup: string = "";

  unit13DirectorPhoto: string = "";
  unit13DirectorName: string = "";
  unit13DirectorMobile: string = "";
  unit13DirectorFederation: string = "";
  unit13DirectorUnit: string = "";
  unit13DirectorGroup: string = "";

  unit14DirectorPhoto: string = "";
  unit14DirectorName: string = "";
  unit14DirectorMobile: string = "";
  unit14DirectorFederation: string = "";
  unit14DirectorUnit: string = "";
  unit14DirectorGroup: string = "";

  presidentYesNo: boolean = false;
  ippYesNo: boolean = false;
  vp1YesNo: boolean = false;
  vp2YesNo: boolean = false;
  vp3YesNo: boolean = false;
  secretaryYesNo: boolean = false;
  coSecretaryYesNo: boolean = false;
  treasurerYesNo: boolean = false;
  pro1YesNo: boolean = false;
  pro2YesNo: boolean = false;
  coOrdinatorYesNo: boolean = false;
  specialOfficer1YesNo: boolean = false;
  specialOfficer2YesNo: boolean = false;
  specialOfficer3YesNo: boolean = false;
  specialOfficer4YesNo: boolean = false;

  clearValues() {
    this.presidentPhoto = "assets/anony.png";
    this.presidentName = "";
    this.presidentMobile = "";
    this.presidentFederation = "";
    this.presidentUnit = "";
    this.presidentGroup = "";

    this.ippPhoto = "assets/anony.png";
    this.ippName = "";
    this.ippMobile = "";
    this.ippFederation = "";
    this.ippUnit = "";
    this.ippGroup = "";

    this.vp1Photo = "assets/anony.png";
    this.vp1Name = "";
    this.vp1Mobile = "";
    this.vp1Federation = "";
    this.vp1Unit = "";
    this.vp1Group = "";

    this.vp2Photo = "assets/anony.png";
    this.vp2Name = "";
    this.vp2Mobile = "";
    this.vp2Federation = "";
    this.vp2Unit = "";
    this.vp2Group = "";

    this.vp3Photo = "assets/anony.png";
    this.vp3Name = "";
    this.vp3Mobile = "";
    this.vp3Federation = "";
    this.vp3Unit = "";
    this.vp3Group = "";

    this.secretaryPhoto = "assets/anony.png";
    this.secretaryName = "";
    this.secretaryMobile = "";
    this.secretaryFederation = "";
    this.secretaryUnit = "";
    this.secretaryGroup = "";

    this.coSecretaryPhoto = "assets/anony.png";
    this.coSecretaryName = "";
    this.coSecretaryMobile = "";
    this.coSecretaryFederation = "";
    this.coSecretaryUnit = "";
    this.coSecretaryGroup = "";

    this.treasurerPhoto = "assets/anony.png";
    this.treasurerName = "";
    this.treasurerMobile = "";
    this.treasurerFederation = "";
    this.treasurerUnit = "";
    this.treasurerGroup = "";

    this.pro1Photo = "assets/anony.png";
    this.pro1Name = "";
    this.pro1Mobile = "";
    this.pro1Federation = "";
    this.pro1Unit = "";
    this.pro1Group = "";

    this.pro2Photo = "assets/anony.png";
    this.pro2Name = "";
    this.pro2Mobile = "";
    this.pro2Federation = "";
    this.pro2Unit = "";
    this.pro2Group = "";

    this.coOrdinatorPhoto = "assets/anony.png";
    this.coOrdinatorName = "";
    this.coOrdinatorMobile = "";
    this.coOrdinatorFederation = "";
    this.coOrdinatorUnit = "";
    this.coOrdinatorGroup = "";

    this.specialOfficer1Photo = "assets/anony.png";
    this.specialOfficer1Name = "";
    this.specialOfficer1Mobile = "";
    this.specialOfficer1Federation = "";
    this.specialOfficer1Unit = "";
    this.specialOfficer1Group = "";

    this.specialOfficer2Photo = "assets/anony.png";
    this.specialOfficer2Name = "";
    this.specialOfficer2Mobile = "";
    this.specialOfficer2Federation = "";
    this.specialOfficer2Unit = "";
    this.specialOfficer2Group = "";

    this.specialOfficer3Photo = "assets/anony.png";
    this.specialOfficer3Name = "";
    this.specialOfficer3Mobile = "";
    this.specialOfficer3Federation = "";
    this.specialOfficer3Unit = "";
    this.specialOfficer3Group = "";

    this.specialOfficer4Photo = "assets/anony.png";
    this.specialOfficer4Name = "";
    this.specialOfficer4Mobile = "";
    this.specialOfficer4Federation = "";
    this.specialOfficer4Unit = "";
    this.specialOfficer4Group = "";

    this.unit1DirectorPhoto = "assets/anony.png";
    this.unit1DirectorName = "";
    this.unit1DirectorMobile = "";
    this.unit1DirectorFederation = "";
    this.unit1DirectorUnit = "";
    this.unit1DirectorGroup = "";

    this.unit2DirectorPhoto = "assets/anony.png";
    this.unit2DirectorName = "";
    this.unit2DirectorMobile = "";
    this.unit2DirectorFederation = "";
    this.unit2DirectorUnit = "";
    this.unit2DirectorGroup = "";

    this.unit3DirectorPhoto = "assets/anony.png";
    this.unit3DirectorName = "";
    this.unit3DirectorMobile = "";
    this.unit3DirectorFederation = "";
    this.unit3DirectorUnit = "";
    this.unit3DirectorGroup = "";

    this.unit4DirectorPhoto = "assets/anony.png";
    this.unit4DirectorName = "";
    this.unit4DirectorMobile = "";
    this.unit4DirectorFederation = "";
    this.unit4DirectorUnit = "";
    this.unit4DirectorGroup = "";

    this.unit5DirectorPhoto = "assets/anony.png";
    this.unit5DirectorName = "";
    this.unit5DirectorMobile = "";
    this.unit5DirectorFederation = "";
    this.unit5DirectorUnit = "";
    this.unit5DirectorGroup = "";

    this.unit6DirectorPhoto = "assets/anony.png";
    this.unit6DirectorName = "";
    this.unit6DirectorMobile = "";
    this.unit6DirectorFederation = "";
    this.unit6DirectorUnit = "";
    this.unit6DirectorGroup = "";

    this.unit7DirectorPhoto = "assets/anony.png";
    this.unit7DirectorName = "";
    this.unit7DirectorMobile = "";
    this.unit7DirectorFederation = "";
    this.unit7DirectorUnit = "";
    this.unit7DirectorGroup = "";

    this.unit8DirectorPhoto = "assets/anony.png";
    this.unit8DirectorName = "";
    this.unit8DirectorMobile = "";
    this.unit8DirectorFederation = "";
    this.unit8DirectorUnit = "";
    this.unit8DirectorGroup = "";

    this.unit9DirectorPhoto = "assets/anony.png";
    this.unit9DirectorName = "";
    this.unit9DirectorMobile = "";
    this.unit9DirectorFederation = "";
    this.unit9DirectorUnit = "";
    this.unit9DirectorGroup = "";

    this.unit10DirectorPhoto = "assets/anony.png";
    this.unit10DirectorName = "";
    this.unit10DirectorMobile = "";
    this.unit10DirectorFederation = "";
    this.unit10DirectorUnit = "";
    this.unit10DirectorGroup = "";

    this.unit11DirectorPhoto = "assets/anony.png";
    this.unit11DirectorName = "";
    this.unit11DirectorMobile = "";
    this.unit11DirectorFederation = "";
    this.unit11DirectorUnit = "";
    this.unit11DirectorGroup = "";

    this.unit12DirectorPhoto = "assets/anony.png";
    this.unit12DirectorName = "";
    this.unit12DirectorMobile = "";
    this.unit12DirectorFederation = "";
    this.unit12DirectorUnit = "";
    this.unit12DirectorGroup = "";

    this.unit13DirectorPhoto = "assets/anony.png";
    this.unit13DirectorName = "";
    this.unit13DirectorMobile = "";
    this.unit13DirectorFederation = "";
    this.unit13DirectorUnit = "";
    this.unit13DirectorGroup = "";

    this.unit14DirectorPhoto = "assets/anony.png";
    this.unit14DirectorName = "";
    this.unit14DirectorMobile = "";
    this.unit14DirectorFederation = "";
    this.unit14DirectorUnit = "";
    this.unit14DirectorGroup = "";

    this.presidentYesNo = false;
    this.ippYesNo = false;
    this.vp1YesNo = false;
    this.vp2YesNo = false;
    this.vp3YesNo = false;
    this.secretaryYesNo = false;
    this.coSecretaryYesNo = false;
    this.treasurerYesNo = false;
    this.pro1YesNo = false;
    this.pro2YesNo = false;
    this.coOrdinatorYesNo = false;
    this.specialOfficer1YesNo = false;
    this.specialOfficer2YesNo = false;
    this.specialOfficer3YesNo = false;
    this.specialOfficer4YesNo = false;
  }

  unitBODData = [];

  getData1(dataParam: FederationMaster) {
    this.isSpinning = true;
    this.BOD = "";
    this.clearValues();

    this.BOD += dataParam.PRESIDENT ? dataParam.PRESIDENT + "," : "";
    this.BOD += dataParam.IPP ? dataParam.IPP + "," : "";
    this.BOD += dataParam.VP1 ? dataParam.VP1 + "," : "";
    this.BOD += dataParam.VP2 ? dataParam.VP2 + "," : "";
    this.BOD += dataParam.VP3 ? dataParam.VP3 + "," : "";
    this.BOD += dataParam.SECRETORY ? dataParam.SECRETORY + "," : "";
    this.BOD += dataParam.CO_SECRETORY ? dataParam.CO_SECRETORY + "," : "";
    this.BOD += dataParam.TREASURER ? dataParam.TREASURER + "," : "";
    this.BOD += dataParam.PRO1 ? dataParam.PRO1 + "," : "";
    this.BOD += dataParam.PRO2 ? dataParam.PRO2 + "," : "";
    this.BOD += dataParam.CO_ORDINATOR ? dataParam.CO_ORDINATOR + "," : "";
    this.BOD += dataParam.SPECIAL_OFFICER1 ? dataParam.SPECIAL_OFFICER1 + "," : "";
    this.BOD += dataParam.SPECIAL_OFFICER2 ? dataParam.SPECIAL_OFFICER2 + "," : "";
    this.BOD += dataParam.SPECIAL_OFFICER3 ? dataParam.SPECIAL_OFFICER3 + "," : "";
    this.BOD += dataParam.SPECIAL_OFFICER4 ? dataParam.SPECIAL_OFFICER4 + "," : "";

    if (this.BOD.length > 0) {
      this.BOD = this.BOD.substring(0, this.BOD.length - 1);

      this.api.getAllMembers(0, 0, "", "", " AND ID IN (" + this.BOD + ")").subscribe(data => {
        if (data['code'] == 200) {
          this.isSpinning = false;
          this.memberList = data['data'];

          if (dataParam.PRESIDENT) {
            this.presidentYesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.PRESIDENT);
            });

            this.presidentPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.presidentPhoto != null) && (this.presidentPhoto != ''))
              this.presidentPhoto = this.api.retriveimgUrl + "profileImage/" + this.presidentPhoto;

            else
              this.presidentPhoto = "assets/anony.png";

            this.presidentName = member.length > 0 ? member[0]["NAME"] : "";
            this.presidentMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.presidentFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.presidentUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.presidentGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.IPP) {
            this.ippYesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.IPP);
            });

            this.ippPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.ippPhoto != null) && (this.ippPhoto != ''))
              this.ippPhoto = this.api.retriveimgUrl + "profileImage/" + this.ippPhoto;

            else
              this.ippPhoto = "assets/anony.png";

            this.ippName = member.length > 0 ? member[0]["NAME"] : "";
            this.ippMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.ippFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.ippUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.ippGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.VP1) {
            this.vp1YesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.VP1);
            });

            this.vp1Photo = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.vp1Photo != null) && (this.vp1Photo != ''))
              this.vp1Photo = this.api.retriveimgUrl + "profileImage/" + this.vp1Photo;

            else
              this.vp1Photo = "assets/anony.png";

            this.vp1Name = member.length > 0 ? member[0]["NAME"] : "";
            this.vp1Mobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.vp1Federation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.vp1Unit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.vp1Group = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.VP2) {
            this.vp2YesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.VP2);
            });

            this.vp2Photo = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.vp2Photo != null) && (this.vp2Photo != ''))
              this.vp2Photo = this.api.retriveimgUrl + "profileImage/" + this.vp2Photo;

            else
              this.vp2Photo = "assets/anony.png";

            this.vp2Name = member.length > 0 ? member[0]["NAME"] : "";
            this.vp2Mobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.vp2Federation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.vp2Unit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.vp2Group = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.VP3) {
            this.vp3YesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.VP3);
            });

            this.vp3Photo = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.vp3Photo != null) && (this.vp3Photo != ''))
              this.vp3Photo = this.api.retriveimgUrl + "profileImage/" + this.vp3Photo;

            else
              this.vp3Photo = "assets/anony.png";

            this.vp3Name = member.length > 0 ? member[0]["NAME"] : "";
            this.vp3Mobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.vp3Federation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.vp3Unit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.vp3Group = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.SECRETORY) {
            this.secretaryYesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.SECRETORY);
            });

            this.secretaryPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.secretaryPhoto != null) && (this.secretaryPhoto != ''))
              this.secretaryPhoto = this.api.retriveimgUrl + "profileImage/" + this.secretaryPhoto;

            else
              this.secretaryPhoto = "assets/anony.png";

            this.secretaryName = member.length > 0 ? member[0]["NAME"] : "";
            this.secretaryMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.secretaryFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.secretaryUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.secretaryGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.CO_SECRETORY) {
            this.coSecretaryYesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.CO_SECRETORY);
            });

            this.coSecretaryPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.coSecretaryPhoto != null) && (this.coSecretaryPhoto != ''))
              this.coSecretaryPhoto = this.api.retriveimgUrl + "profileImage/" + this.coSecretaryPhoto;

            else
              this.coSecretaryPhoto = "assets/anony.png";

            this.coSecretaryName = member.length > 0 ? member[0]["NAME"] : "";
            this.coSecretaryMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.coSecretaryFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.coSecretaryUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.coSecretaryGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.TREASURER) {
            this.treasurerYesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.TREASURER)
            });

            this.treasurerPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.treasurerPhoto != null) && (this.treasurerPhoto != ''))
              this.treasurerPhoto = this.api.retriveimgUrl + "profileImage/" + this.treasurerPhoto;

            else
              this.treasurerPhoto = "assets/anony.png";

            this.treasurerName = member.length > 0 ? member[0]["NAME"] : "";
            this.treasurerMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.treasurerFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.treasurerUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.treasurerGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.PRO1) {
            this.pro1YesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.PRO1);
            });

            this.pro1Photo = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.pro1Photo != null) && (this.pro1Photo != ''))
              this.pro1Photo = this.api.retriveimgUrl + "profileImage/" + this.pro1Photo;

            else
              this.pro1Photo = "assets/anony.png";

            this.pro1Name = member.length > 0 ? member[0]["NAME"] : "";
            this.pro1Mobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.pro1Federation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.pro1Unit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.pro1Group = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.PRO2) {
            this.pro2YesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.PRO2);
            });

            this.pro2Photo = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.pro2Photo != null) && (this.pro2Photo != ''))
              this.pro2Photo = this.api.retriveimgUrl + "profileImage/" + this.pro2Photo;

            else
              this.pro2Photo = "assets/anony.png";

            this.pro2Name = member.length > 0 ? member[0]["NAME"] : "";
            this.pro2Mobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.pro2Federation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.pro2Unit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.pro2Group = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.CO_ORDINATOR) {
            this.coOrdinatorYesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.CO_ORDINATOR);
            });

            this.coOrdinatorPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.coOrdinatorPhoto != null) && (this.coOrdinatorPhoto != ''))
              this.coOrdinatorPhoto = this.api.retriveimgUrl + "profileImage/" + this.coOrdinatorPhoto;

            else
              this.coOrdinatorPhoto = "assets/anony.png";

            this.coOrdinatorName = member.length > 0 ? member[0]["NAME"] : "";
            this.coOrdinatorMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.coOrdinatorFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.coOrdinatorUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.coOrdinatorGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.SPECIAL_OFFICER1) {
            this.specialOfficer1YesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.SPECIAL_OFFICER1);
            });

            this.specialOfficer1Photo = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.specialOfficer1Photo != null) && (this.specialOfficer1Photo != ''))
              this.specialOfficer1Photo = this.api.retriveimgUrl + "profileImage/" + this.specialOfficer1Photo;

            else
              this.specialOfficer1Photo = "assets/anony.png";

            this.specialOfficer1Name = member.length > 0 ? member[0]["NAME"] : "";
            this.specialOfficer1Mobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.specialOfficer1Federation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.specialOfficer1Unit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.specialOfficer1Group = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.SPECIAL_OFFICER2) {
            this.specialOfficer2YesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.SPECIAL_OFFICER2);
            });

            this.specialOfficer2Photo = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.specialOfficer2Photo != null) && (this.specialOfficer2Photo != ''))
              this.specialOfficer2Photo = this.api.retriveimgUrl + "profileImage/" + this.specialOfficer2Photo;

            else
              this.specialOfficer2Photo = "assets/anony.png";

            this.specialOfficer2Name = member.length > 0 ? member[0]["NAME"] : "";
            this.specialOfficer2Mobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.specialOfficer2Federation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.specialOfficer2Unit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.specialOfficer2Group = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.SPECIAL_OFFICER3) {
            this.specialOfficer3YesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.SPECIAL_OFFICER3);
            });

            this.specialOfficer3Photo = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.specialOfficer3Photo != null) && (this.specialOfficer3Photo != ''))
              this.specialOfficer3Photo = this.api.retriveimgUrl + "profileImage/" + this.specialOfficer3Photo;

            else
              this.specialOfficer3Photo = "assets/anony.png";

            this.specialOfficer3Name = member.length > 0 ? member[0]["NAME"] : "";
            this.specialOfficer3Mobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.specialOfficer3Federation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.specialOfficer3Unit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.specialOfficer3Group = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.SPECIAL_OFFICER4) {
            this.specialOfficer4YesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.SPECIAL_OFFICER4);
            });

            this.specialOfficer4Photo = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.specialOfficer4Photo != null) && (this.specialOfficer4Photo != ''))
              this.specialOfficer4Photo = this.api.retriveimgUrl + "profileImage/" + this.specialOfficer4Photo;

            else
              this.specialOfficer4Photo = "assets/anony.png";

            this.specialOfficer4Name = member.length > 0 ? member[0]["NAME"] : "";
            this.specialOfficer4Mobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.specialOfficer4Federation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.specialOfficer4Unit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.specialOfficer4Group = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else {
      this.isSpinning = false;
    }

    // Unit Directors
    this.isUnitSpinning = true;
    this.unitBOD = "";
    this.unitBODData = [];

    this.api.getAllUnits(0, 0, "ID", "asc", " AND FEDERATION_ID=" + dataParam.ID).subscribe(data => {
      if (data["code"] == 200) {
        this.unitBODData = data["data"];

        for (var i = 0; i < this.unitBODData.length; i++) {
          this.unitBOD += this.unitBODData[i]["DIRECTOR"] ? this.unitBODData[i]["DIRECTOR"] + "," : "";
        }

        if (this.unitBOD.length > 0) {
          this.unitBOD = this.unitBOD.substring(0, this.unitBOD.length - 1);

          this.api.getAllMembers(0, 0, "", "", " AND ID IN (" + this.unitBOD + ")").subscribe(data => {
            if (data['code'] == 200) {
              this.isUnitSpinning = false;
              let unitMemberList = data['data'];

              if (this.unitBODData[0]["DIRECTOR"]) {
                var member = unitMemberList.filter(obj => {
                  return (obj.ID == this.unitBODData[0]["DIRECTOR"]);
                });

                this.unit1DirectorPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
                if ((this.unit1DirectorPhoto != null) && (this.unit1DirectorPhoto != ''))
                  this.unit1DirectorPhoto = this.api.retriveimgUrl + "profileImage/" + this.unit1DirectorPhoto;

                else
                  this.unit1DirectorPhoto = "assets/anony.png";

                this.unit1DirectorName = member.length > 0 ? member[0]["NAME"] : "";
                this.unit1DirectorMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
                this.unit1DirectorFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
                this.unit1DirectorUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
                this.unit1DirectorGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
              }

              if (this.unitBODData[1]["DIRECTOR"]) {
                var member = unitMemberList.filter(obj => {
                  return (obj.ID == this.unitBODData[1]["DIRECTOR"]);
                });

                this.unit2DirectorPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
                if ((this.unit2DirectorPhoto != null) && (this.unit2DirectorPhoto != ''))
                  this.unit2DirectorPhoto = this.api.retriveimgUrl + "profileImage/" + this.unit2DirectorPhoto;

                else
                  this.unit2DirectorPhoto = "assets/anony.png";

                this.unit2DirectorName = member.length > 0 ? member[0]["NAME"] : "";
                this.unit2DirectorMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
                this.unit2DirectorFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
                this.unit2DirectorUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
                this.unit2DirectorGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
              }

              if (this.unitBODData[2]["DIRECTOR"]) {
                var member = unitMemberList.filter(obj => {
                  return (obj.ID == this.unitBODData[2]["DIRECTOR"]);
                });

                this.unit3DirectorPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
                if ((this.unit3DirectorPhoto != null) && (this.unit3DirectorPhoto != ''))
                  this.unit3DirectorPhoto = this.api.retriveimgUrl + "profileImage/" + this.unit3DirectorPhoto;

                else
                  this.unit3DirectorPhoto = "assets/anony.png";

                this.unit3DirectorName = member.length > 0 ? member[0]["NAME"] : "";
                this.unit3DirectorMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
                this.unit3DirectorFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
                this.unit3DirectorUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
                this.unit3DirectorGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
              }

              if (this.unitBODData[3]["DIRECTOR"]) {
                var member = unitMemberList.filter(obj => {
                  return (obj.ID == this.unitBODData[3]["DIRECTOR"]);
                });

                this.unit4DirectorPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
                if ((this.unit4DirectorPhoto != null) && (this.unit4DirectorPhoto != ''))
                  this.unit4DirectorPhoto = this.api.retriveimgUrl + "profileImage/" + this.unit4DirectorPhoto;

                else
                  this.unit4DirectorPhoto = "assets/anony.png";

                this.unit4DirectorName = member.length > 0 ? member[0]["NAME"] : "";
                this.unit4DirectorMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
                this.unit4DirectorFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
                this.unit4DirectorUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
                this.unit4DirectorGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
              }

              if (this.unitBODData[4]["DIRECTOR"]) {
                var member = unitMemberList.filter(obj => {
                  return (obj.ID == this.unitBODData[4]["DIRECTOR"]);
                });

                this.unit5DirectorPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
                if ((this.unit5DirectorPhoto != null) && (this.unit5DirectorPhoto != ''))
                  this.unit5DirectorPhoto = this.api.retriveimgUrl + "profileImage/" + this.unit5DirectorPhoto;

                else
                  this.unit5DirectorPhoto = "assets/anony.png";

                this.unit5DirectorName = member.length > 0 ? member[0]["NAME"] : "";
                this.unit5DirectorMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
                this.unit5DirectorFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
                this.unit5DirectorUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
                this.unit5DirectorGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
              }

              if (this.unitBODData[5]["DIRECTOR"]) {
                var member = unitMemberList.filter(obj => {
                  return (obj.ID == this.unitBODData[5]["DIRECTOR"]);
                });

                this.unit6DirectorPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
                if ((this.unit6DirectorPhoto != null) && (this.unit6DirectorPhoto != ''))
                  this.unit6DirectorPhoto = this.api.retriveimgUrl + "profileImage/" + this.unit6DirectorPhoto;

                else
                  this.unit6DirectorPhoto = "assets/anony.png";

                this.unit6DirectorName = member.length > 0 ? member[0]["NAME"] : "";
                this.unit6DirectorMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
                this.unit6DirectorFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
                this.unit6DirectorUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
                this.unit6DirectorGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
              }

              if (this.unitBODData[6]["DIRECTOR"]) {
                var member = unitMemberList.filter(obj => {
                  return (obj.ID == this.unitBODData[6]["DIRECTOR"]);
                });

                this.unit7DirectorPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
                if ((this.unit7DirectorPhoto != null) && (this.unit7DirectorPhoto != ''))
                  this.unit7DirectorPhoto = this.api.retriveimgUrl + "profileImage/" + this.unit7DirectorPhoto;

                else
                  this.unit7DirectorPhoto = "assets/anony.png";

                this.unit7DirectorName = member.length > 0 ? member[0]["NAME"] : "";
                this.unit7DirectorMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
                this.unit7DirectorFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
                this.unit7DirectorUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
                this.unit7DirectorGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
              }

              if (this.unitBODData[7]["DIRECTOR"]) {
                var member = unitMemberList.filter(obj => {
                  return (obj.ID == this.unitBODData[7]["DIRECTOR"]);
                });

                this.unit8DirectorPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
                if ((this.unit8DirectorPhoto != null) && (this.unit8DirectorPhoto != ''))
                  this.unit8DirectorPhoto = this.api.retriveimgUrl + "profileImage/" + this.unit8DirectorPhoto;

                else
                  this.unit8DirectorPhoto = "assets/anony.png";

                this.unit8DirectorName = member.length > 0 ? member[0]["NAME"] : "";
                this.unit8DirectorMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
                this.unit8DirectorFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
                this.unit8DirectorUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
                this.unit8DirectorGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
              }

              if (this.unitBODData[8]["DIRECTOR"]) {
                var member = unitMemberList.filter(obj => {
                  return (obj.ID == this.unitBODData[8]["DIRECTOR"]);
                });

                this.unit9DirectorPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
                if ((this.unit9DirectorPhoto != null) && (this.unit9DirectorPhoto != ''))
                  this.unit9DirectorPhoto = this.api.retriveimgUrl + "profileImage/" + this.unit9DirectorPhoto;

                else
                  this.unit9DirectorPhoto = "assets/anony.png";

                this.unit9DirectorName = member.length > 0 ? member[0]["NAME"] : "";
                this.unit9DirectorMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
                this.unit9DirectorFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
                this.unit9DirectorUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
                this.unit9DirectorGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
              }

              if (this.unitBODData[9]["DIRECTOR"]) {
                var member = unitMemberList.filter(obj => {
                  return (obj.ID == this.unitBODData[9]["DIRECTOR"]);
                });

                this.unit10DirectorPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
                if ((this.unit10DirectorPhoto != null) && (this.unit10DirectorPhoto != ''))
                  this.unit10DirectorPhoto = this.api.retriveimgUrl + "profileImage/" + this.unit10DirectorPhoto;

                else
                  this.unit10DirectorPhoto = "assets/anony.png";

                this.unit10DirectorName = member.length > 0 ? member[0]["NAME"] : "";
                this.unit10DirectorMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
                this.unit10DirectorFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
                this.unit10DirectorUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
                this.unit10DirectorGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
              }

              if (this.unitBODData[10]["DIRECTOR"]) {
                var member = unitMemberList.filter(obj => {
                  return (obj.ID == this.unitBODData[10]["DIRECTOR"]);
                });

                this.unit11DirectorPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
                if ((this.unit11DirectorPhoto != null) && (this.unit11DirectorPhoto != ''))
                  this.unit11DirectorPhoto = this.api.retriveimgUrl + "profileImage/" + this.unit11DirectorPhoto;

                else
                  this.unit11DirectorPhoto = "assets/anony.png";

                this.unit11DirectorName = member.length > 0 ? member[0]["NAME"] : "";
                this.unit11DirectorMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
                this.unit11DirectorFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
                this.unit11DirectorUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
                this.unit11DirectorGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
              }

              if (this.unitBODData[11]["DIRECTOR"]) {
                var member = unitMemberList.filter(obj => {
                  return (obj.ID == this.unitBODData[11]["DIRECTOR"]);
                });

                this.unit12DirectorPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
                if ((this.unit12DirectorPhoto != null) && (this.unit12DirectorPhoto != ''))
                  this.unit12DirectorPhoto = this.api.retriveimgUrl + "profileImage/" + this.unit12DirectorPhoto;

                else
                  this.unit12DirectorPhoto = "assets/anony.png";

                this.unit12DirectorName = member.length > 0 ? member[0]["NAME"] : "";
                this.unit12DirectorMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
                this.unit12DirectorFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
                this.unit12DirectorUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
                this.unit12DirectorGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
              }

              if (this.unitBODData[12]["DIRECTOR"]) {
                var member = unitMemberList.filter(obj => {
                  return (obj.ID == this.unitBODData[12]["DIRECTOR"]);
                });

                this.unit13DirectorPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
                if ((this.unit13DirectorPhoto != null) && (this.unit13DirectorPhoto != ''))
                  this.unit13DirectorPhoto = this.api.retriveimgUrl + "profileImage/" + this.unit13DirectorPhoto;

                else
                  this.unit13DirectorPhoto = "assets/anony.png";

                this.unit13DirectorName = member.length > 0 ? member[0]["NAME"] : "";
                this.unit13DirectorMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
                this.unit13DirectorFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
                this.unit13DirectorUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
                this.unit13DirectorGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
              }

              if (this.unitBODData[13]["DIRECTOR"]) {
                var member = unitMemberList.filter(obj => {
                  return (obj.ID == this.unitBODData[13]["DIRECTOR"]);
                });

                this.unit14DirectorPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
                if ((this.unit14DirectorPhoto != null) && (this.unit14DirectorPhoto != ''))
                  this.unit14DirectorPhoto = this.api.retriveimgUrl + "profileImage/" + this.unit14DirectorPhoto;

                else
                  this.unit14DirectorPhoto = "assets/anony.png";

                this.unit14DirectorName = member.length > 0 ? member[0]["NAME"] : "";
                this.unit14DirectorMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
                this.unit14DirectorFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
                this.unit14DirectorUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
                this.unit14DirectorGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
              }
            }

          }, err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });

        } else {
          this.isUnitSpinning = false;
        }
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  NEW_MEMBER_ID: number;

  handleOk(data: FederationMaster) {
    this.isConfirmLoading = true;

    this.api.assignFederation(this.roleID, data, this.NEW_MEMBER_ID).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Updated Successfully", "");
        this.isConfirmLoading = false;
        this.isVisible = false;

        if (this.roleID == 1)
          data.PRESIDENT = this.NEW_MEMBER_ID;
        else if (this.roleID == 2)
          data.IPP = this.NEW_MEMBER_ID;
        else if (this.roleID == 3)
          data.VP1 = this.NEW_MEMBER_ID;
        else if (this.roleID == 4)
          data.VP2 = this.NEW_MEMBER_ID;
        else if (this.roleID == 5)
          data.VP3 = this.NEW_MEMBER_ID;
        else if (this.roleID == 6)
          data.SECRETORY = this.NEW_MEMBER_ID;
        else if (this.roleID == 7)
          data.CO_SECRETORY = this.NEW_MEMBER_ID;
        else if (this.roleID == 8)
          data.TREASURER = this.NEW_MEMBER_ID;
        else if (this.roleID == 9)
          data.PRO1 = this.NEW_MEMBER_ID;
        else if (this.roleID == 10)
          data.PRO2 = this.NEW_MEMBER_ID;
        else if (this.roleID == 11)
          data.CO_ORDINATOR = this.NEW_MEMBER_ID;
        else if (this.roleID == 12)
          data.SPECIAL_OFFICER1 = this.NEW_MEMBER_ID;
        else if (this.roleID == 13)
          data.SPECIAL_OFFICER2 = this.NEW_MEMBER_ID;
        else if (this.roleID == 14)
          data.SPECIAL_OFFICER3 = this.NEW_MEMBER_ID;
        else if (this.roleID == 15)
          data.SPECIAL_OFFICER4 = this.NEW_MEMBER_ID;

        this.getData1(data);
        this.NEW_MEMBER_ID = undefined;

      } else
        this.message.error("Failed to Update", "");
    });
  }

  memberDrawerTitle: string;
  memberDrawerData: any;
  memberDrawerVisible = false;
  BOD_Position: number;
  @ViewChild(ManageFederationMembersComponent, { static: false }) ManageFederationMembersComponentVar: ManageFederationMembersComponent;

  addMembers(BODPosition: any): void {
    this.BOD_Position = BODPosition;

    let role = "";
    if (this.BOD_Position == 1)
      role = "President";
    else if (this.BOD_Position == 2)
      role = "IPP";
    else if (this.BOD_Position == 3)
      role = "Vice Precident 1";
    else if (this.BOD_Position == 4)
      role = "Vice Precident 2";
    else if (this.BOD_Position == 5)
      role = "Vice Precident 3";
    else if (this.BOD_Position == 6)
      role = "Secretary";
    else if (this.BOD_Position == 7)
      role = "Co Secretary";
    else if (this.BOD_Position == 8)
      role = "Treasurer";
    else if (this.BOD_Position == 9)
      role = "PRO 1";
    else if (this.BOD_Position == 10)
      role = "PRO 2";
    else if (this.BOD_Position == 11)
      role = "Co Ordinator";
    else if (this.BOD_Position == 12)
      role = "Special Officer 1";
    else if (this.BOD_Position == 13)
      role = "Special Officer 2";
    else if (this.BOD_Position == 14)
      role = "Special Officer 3";
    else if (this.BOD_Position == 15)
      role = "Special Officer 4";

    this.memberDrawerTitle = "Add Federation Members for " + role;
    this.memberDrawerVisible = true;
    this.ManageFederationMembersComponentVar.sortKey = "id";
    this.ManageFederationMembersComponentVar.sortValue = "desc";
    this.ManageFederationMembersComponentVar.search(true, BODPosition, this.data.ID);
    this.ManageFederationMembersComponentVar.getMembers(this.data.ID);
  }

  memberDrawerClose(): void {
    this.memberDrawerVisible = false;
    this.unitMemberDrawerVisible = false;

    this.api.getAllFederations(0, 0, "", "", " AND ID=" + this.data.ID).subscribe(data => {
      if (data['code'] == 200) {
        var updatedData = data['data'][0];
        this.getData1(updatedData);
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  get memberDrawerCloseCallback() {
    return this.memberDrawerClose.bind(this);
  }

  unitMemberDrawerTitle: string;
  unitMemberDrawerData: any;
  unitMemberDrawerVisible = false;
  unitBOD_Position: number;
  unitBODDataToInput: any;
  @ViewChild(ManageUnitMembersComponent, { static: false }) ManageUnitMembersComponentVar: ManageUnitMembersComponent;

  addUnit1Members(unitBOD_Position: any): void {
    this.unitBOD_Position = unitBOD_Position;
    this.unitBODDataToInput = this.unitBODData[0];
    console.log(this.unitBODDataToInput);

    let role = "";
    if (this.unitBOD_Position == 1)
      role = "Director";

    this.unitMemberDrawerTitle = "Add Unit Members for Unit 1 " + role;
    this.unitMemberDrawerVisible = true;
    this.ManageUnitMembersComponentVar.sortKey = "id";
    this.ManageUnitMembersComponentVar.sortValue = "desc";
    this.ManageUnitMembersComponentVar.search(true, unitBOD_Position, this.unitBODData[0]["ID"]);
  }

  addUnit2Members(unitBOD_Position: any): void {
    this.unitBOD_Position = unitBOD_Position;
    this.unitBODDataToInput = this.unitBODData[1];
    console.log(this.unitBODDataToInput);

    let role = "";
    if (this.unitBOD_Position == 1)
      role = "Director";

    this.unitMemberDrawerTitle = "Add Unit Members for Unit 2 " + role;
    this.unitMemberDrawerVisible = true;
    this.ManageUnitMembersComponentVar.sortKey = "id";
    this.ManageUnitMembersComponentVar.sortValue = "desc";
    this.ManageUnitMembersComponentVar.search(true, unitBOD_Position, this.unitBODData[1]["ID"]);
  }

  addUnit3Members(unitBOD_Position: any): void {
    this.unitBOD_Position = unitBOD_Position;
    this.unitBODDataToInput = this.unitBODData[2];
    console.log(this.unitBODDataToInput);

    let role = "";
    if (this.unitBOD_Position == 1)
      role = "Director";

    this.unitMemberDrawerTitle = "Add Unit Members for Unit 3 " + role;
    this.unitMemberDrawerVisible = true;
    this.ManageUnitMembersComponentVar.sortKey = "id";
    this.ManageUnitMembersComponentVar.sortValue = "desc";
    this.ManageUnitMembersComponentVar.search(true, unitBOD_Position, this.unitBODData[2]["ID"]);
  }

  addUnit4Members(unitBOD_Position: any): void {
    this.unitBOD_Position = unitBOD_Position;
    this.unitBODDataToInput = this.unitBODData[3];
    console.log(this.unitBODDataToInput);

    let role = "";
    if (this.unitBOD_Position == 1)
      role = "Director";

    this.unitMemberDrawerTitle = "Add Unit Members for Unit 4 " + role;
    this.unitMemberDrawerVisible = true;
    this.ManageUnitMembersComponentVar.sortKey = "id";
    this.ManageUnitMembersComponentVar.sortValue = "desc";
    this.ManageUnitMembersComponentVar.search(true, unitBOD_Position, this.unitBODData[3]["ID"]);
  }

  addUnit5Members(unitBOD_Position: any): void {
    this.unitBOD_Position = unitBOD_Position;
    this.unitBODDataToInput = this.unitBODData[4];
    console.log(this.unitBODDataToInput);

    let role = "";
    if (this.unitBOD_Position == 1)
      role = "Director";

    this.unitMemberDrawerTitle = "Add Unit Members for Unit 5 " + role;
    this.unitMemberDrawerVisible = true;
    this.ManageUnitMembersComponentVar.sortKey = "id";
    this.ManageUnitMembersComponentVar.sortValue = "desc";
    this.ManageUnitMembersComponentVar.search(true, unitBOD_Position, this.unitBODData[4]["ID"]);
  }

  addUnit6Members(unitBOD_Position: any): void {
    this.unitBOD_Position = unitBOD_Position;
    this.unitBODDataToInput = this.unitBODData[5];
    console.log(this.unitBODDataToInput);

    let role = "";
    if (this.unitBOD_Position == 1)
      role = "Director";

    this.unitMemberDrawerTitle = "Add Unit Members for Unit 6 " + role;
    this.unitMemberDrawerVisible = true;
    this.ManageUnitMembersComponentVar.sortKey = "id";
    this.ManageUnitMembersComponentVar.sortValue = "desc";
    this.ManageUnitMembersComponentVar.search(true, unitBOD_Position, this.unitBODData[5]["ID"]);
  }

  addUnit7Members(unitBOD_Position: any): void {
    this.unitBOD_Position = unitBOD_Position;
    this.unitBODDataToInput = this.unitBODData[6];
    console.log(this.unitBODDataToInput);

    let role = "";
    if (this.unitBOD_Position == 1)
      role = "Director";

    this.unitMemberDrawerTitle = "Add Unit Members for Unit 7 " + role;
    this.unitMemberDrawerVisible = true;
    this.ManageUnitMembersComponentVar.sortKey = "id";
    this.ManageUnitMembersComponentVar.sortValue = "desc";
    this.ManageUnitMembersComponentVar.search(true, unitBOD_Position, this.unitBODData[6]["ID"]);
  }

  addUnit8Members(unitBOD_Position: any): void {
    this.unitBOD_Position = unitBOD_Position;
    this.unitBODDataToInput = this.unitBODData[7];
    console.log(this.unitBODDataToInput);

    let role = "";
    if (this.unitBOD_Position == 1)
      role = "Director";

    this.unitMemberDrawerTitle = "Add Unit Members for Unit 8 " + role;
    this.unitMemberDrawerVisible = true;
    this.ManageUnitMembersComponentVar.sortKey = "id";
    this.ManageUnitMembersComponentVar.sortValue = "desc";
    this.ManageUnitMembersComponentVar.search(true, unitBOD_Position, this.unitBODData[7]["ID"]);
  }

  addUnit9Members(unitBOD_Position: any): void {
    this.unitBOD_Position = unitBOD_Position;
    this.unitBODDataToInput = this.unitBODData[8];
    console.log(this.unitBODDataToInput);

    let role = "";
    if (this.unitBOD_Position == 1)
      role = "Director";

    this.unitMemberDrawerTitle = "Add Unit Members for Unit 9 " + role;
    this.unitMemberDrawerVisible = true;
    this.ManageUnitMembersComponentVar.sortKey = "id";
    this.ManageUnitMembersComponentVar.sortValue = "desc";
    this.ManageUnitMembersComponentVar.search(true, unitBOD_Position, this.unitBODData[8]["ID"]);
  }

  addUnit10Members(unitBOD_Position: any): void {
    this.unitBOD_Position = unitBOD_Position;
    this.unitBODDataToInput = this.unitBODData[9];
    console.log(this.unitBODDataToInput);

    let role = "";
    if (this.unitBOD_Position == 1)
      role = "Director";

    this.unitMemberDrawerTitle = "Add Unit Members for Unit 10 " + role;
    this.unitMemberDrawerVisible = true;
    this.ManageUnitMembersComponentVar.sortKey = "id";
    this.ManageUnitMembersComponentVar.sortValue = "desc";
    this.ManageUnitMembersComponentVar.search(true, unitBOD_Position, this.unitBODData[9]["ID"]);
  }

  addUnit11Members(unitBOD_Position: any): void {
    this.unitBOD_Position = unitBOD_Position;
    this.unitBODDataToInput = this.unitBODData[10];
    console.log(this.unitBODDataToInput);

    let role = "";
    if (this.unitBOD_Position == 1)
      role = "Director";

    this.unitMemberDrawerTitle = "Add Unit Members for Unit 11 " + role;
    this.unitMemberDrawerVisible = true;
    this.ManageUnitMembersComponentVar.sortKey = "id";
    this.ManageUnitMembersComponentVar.sortValue = "desc";
    this.ManageUnitMembersComponentVar.search(true, unitBOD_Position, this.unitBODData[10]["ID"]);
  }

  addUnit12Members(unitBOD_Position: any): void {
    this.unitBOD_Position = unitBOD_Position;
    this.unitBODDataToInput = this.unitBODData[11];
    console.log(this.unitBODDataToInput);

    let role = "";
    if (this.unitBOD_Position == 1)
      role = "Director";

    this.unitMemberDrawerTitle = "Add Unit Members for Unit 12 " + role;
    this.unitMemberDrawerVisible = true;
    this.ManageUnitMembersComponentVar.sortKey = "id";
    this.ManageUnitMembersComponentVar.sortValue = "desc";
    this.ManageUnitMembersComponentVar.search(true, unitBOD_Position, this.unitBODData[11]["ID"]);
  }

  addUnit13Members(unitBOD_Position: any): void {
    this.unitBOD_Position = unitBOD_Position;
    this.unitBODDataToInput = this.unitBODData[12];
    console.log(this.unitBODDataToInput);

    let role = "";
    if (this.unitBOD_Position == 1)
      role = "Director";

    this.unitMemberDrawerTitle = "Add Unit Members for Unit 13 " + role;
    this.unitMemberDrawerVisible = true;
    this.ManageUnitMembersComponentVar.sortKey = "id";
    this.ManageUnitMembersComponentVar.sortValue = "desc";
    this.ManageUnitMembersComponentVar.search(true, unitBOD_Position, this.unitBODData[12]["ID"]);
  }

  addUnit14Members(unitBOD_Position: any): void {
    this.unitBOD_Position = unitBOD_Position;
    this.unitBODDataToInput = this.unitBODData[13];
    console.log(this.unitBODDataToInput);

    let role = "";
    if (this.unitBOD_Position == 1)
      role = "Director";

    this.unitMemberDrawerTitle = "Add Unit Members for Unit 14 " + role;
    this.unitMemberDrawerVisible = true;
    this.ManageUnitMembersComponentVar.sortKey = "id";
    this.ManageUnitMembersComponentVar.sortValue = "desc";
    this.ManageUnitMembersComponentVar.search(true, unitBOD_Position, this.unitBODData[13]["ID"]);
  }
}