import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { LeaveType } from 'src/app/Models/leave-type';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/Service/api.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-leavetype',
  templateUrl: './leavetype.component.html',
  styleUrls: ['./leavetype.component.css']
})

export class LeavetypeComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: LeaveType;
  @Input() drawerVisible: boolean;
  isSpinning = false;

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() { }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.NAME != undefined) {
      if (this.data.NAME.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.NAME)) {
          isOk = false;
          this.message.error("Please Enter Valid Name", "");
        }
      } else {
        isOk = false;
        this.message.error("Please Enter Valid Name", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Name", "");
    }

    if (this.data.TITLE != undefined) {
      if (this.data.TITLE.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.TITLE)) {
          isOk = false;
          this.message.error("Please Enter Valid Notification Title", "");
        }
      } else {
        isOk = false;
        this.message.error("Please Enter Valid Notification Title", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Notification Title", "");
    }

    if (this.data.LEAVE_APPROVAL_DAYS == undefined || this.data.LEAVE_APPROVAL_DAYS.trim() == "") {
      isOk = false;
      this.message.error("Please Enter Leave Approval Day(s)", '');
    }

    if (isOk) {
      this.isSpinning = true;

      if (this.data.ID) {
        this.api.updateLeaveType(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Leave Type Updated Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

          } else {
            this.message.error("Leave Type Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createLeaveType(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Leave Type Created Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

            else {
              this.data = new LeaveType();
              this.data.IS_ACTIVE = true;
            }

          } else {
            this.message.error("Leave Type Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
