export class AboutMaster {
ID:number=0;
HISTORY_TITLE:string='';
HISTORY_DESCRIPTION:string='';
GIANTS_STANDS_FOR_TITLE:string='';
GIANTS_STANDS_FOR_DESCRIPTION:string='';
AIMS_AND_OBJECT_TITLE:string='';
AIMS_AND_OBJECT_DESCRIPTION:string='';
STATUS:boolean=true;
SEQUENCE_NO:number=0 ;

}
