export class FederationCount {




    // ID: number = 0;
    // DATE: any;
    // DESCRIPTION1: string = '';
    // MEETING_SUB: string = '';
    // PHOTO1: string = '';
    // WEB_LATEST_NEWS_STATUS:boolean=true;
    // WEB_STATUS: boolean = true;


    ID: number = 0;
    PRESIDENT: string = '';
    NAME: string = '';
    // STATUS: boolean = true;

    WEB_FEDERATION_STATUS: boolean = true;

}