export class TotalUnites{


    BO_DATE:any;
    FEDERATION_NAME: string = '';
    NAME: string = '';
    WEB_UNIT_STATUS: boolean = true;
    UNIT_ID:number=0;
    UNIT_NAME: string='';
    FEDERATION_ID:number=0;
    IS_ALL_UPDATE:boolean = true;



    
    // ID: number = 0;
    // PRESIDENT: string = '';
    // NAME: string = '';
    // STATUS: boolean = true;


}