import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Earlymark } from 'src/app/Models/Earlymark';
import { ApiService } from 'src/app/Service/api.service';
import { HeadEarlymarkApproveDrawerComponent } from '../head-earlymark-approve-drawer/head-earlymark-approve-drawer.component';

@Component({
  selector: 'app-head-approve-employee-earlymark-tiles',
  templateUrl: './head-approve-employee-earlymark-tiles.component.html',
  styleUrls: ['./head-approve-employee-earlymark-tiles.component.css']
})

export class HeadApproveEmployeeEarlymarkTilesComponent implements OnInit {
  formTitle = "Manage Earlymark";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "DATE";
  searchText: string = "";
  filterQuery: string = "";
  filterClass: string = "filter-visible";
  dateFormat = "dd/MMM/yyyy";
  date: Date[] = [];
  date1 = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + 1;
  isSpinning = false;
  loadingFilterEmployees = true;
  filterEmployees: Earlymark[];
  employeeFilter
  filterStatusValue: string[] = ['P'];
  isFilterApplied: string = "default";
  columns: string[][] = [["STATUS", "Status"], ["NAME", "Name"], ["DATE", "Date"], ["EXPECTED_TIME", "Expected Time"], ["REASON", "Reason"], ["REMARK", "Remark"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Earlymark = new Earlymark();
  applicationId = Number(this.cookie.get('applicationId'))
  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: Earlymark = new Earlymark();
  listOfData: Earlymark[] = [];

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) { }

  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));

  ngOnInit() {
    this.search(true);
    this.date = [];
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var empFilter = " and (REPORTING_PERSON1 = " + this.userId + " or REPORTING_PERSON2 = " + this.userId + ")";

    var dateFilter = "";
    if (this.date != undefined && this.date.length != 0) {
      dateFilter = " AND (DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd') + "')";
    }

    var status = "";
    if (this.STATUS == "P")
      status = " and STATUS in ('P')";

    else if (this.STATUS == "A")
      status = " and STATUS in ('A')";

    else if (this.STATUS == "R")
      status = " and STATUS in ('R')";

    this.api.getAllEarlyMarks(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + empFilter + dateFilter + status).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  add(): void {
    this.drawerTitle = "Add Latemark";
    this.drawerData = new Earlymark();
    this.drawerVisible = true;
    this.drawerData.DATE = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  }

  @ViewChild(HeadEarlymarkApproveDrawerComponent, { static: false }) HeadEarlymarkApproveDrawerComponentVar: HeadEarlymarkApproveDrawerComponent;

  edit(data: Earlymark): void {
    console.log(data);

    this.drawerTitle = "Approve Earlymark";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

    if (this.drawerData.STATUS == 'A') {
      this.HeadEarlymarkApproveDrawerComponentVar.APPROVE = true;
      this.HeadEarlymarkApproveDrawerComponentVar.REMARK = this.drawerData.REMARK;

    } else if (this.drawerData.STATUS == 'R') {
      this.HeadEarlymarkApproveDrawerComponentVar.APPROVE = false;
      this.HeadEarlymarkApproveDrawerComponentVar.REMARK = this.drawerData.REMARK;

    } else {
      this.HeadEarlymarkApproveDrawerComponentVar.APPROVE = true;
      this.HeadEarlymarkApproveDrawerComponentVar.REMARK = undefined;
    }
  }

  applyFilter() {
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    if (this.date == undefined || this.date.length == 0) {
      this.message.error("Please Select Date", "");

    } else {
      this.filterQuery = " AND (DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd') + "' ) AND ";
      this.filterQuery = this.filterQuery.substring(0, this.filterQuery.length - 5);

      var empFilter = " and (REPORTING_PERSON1 = " + this.userId + " or REPORTING_PERSON2 = " + this.userId + ")";

      var status = "";
      if (this.STATUS == "P")
        status = " and STATUS in ('P')";

      else if (this.STATUS == "A")
        status = " and STATUS in ('A')";

      else if (this.STATUS == "R")
        status = " and STATUS in ('R')";

      this.isSpinning = true;
      this.api.getAllEarlyMarks(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + empFilter + status).subscribe(data => {
        if (data['code'] == 200) {
          this.isSpinning = false;
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isFilterApplied = "primary";
        }

      }, err => {
        console.log(err);
      });
    }

    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.date = [];
    this.filterStatusValue = ['P'];
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.employeeFilter = 1;
    this.filterQuery = "";
    this.search(true);
  }

  loadDataFilter() {
    this.loadingFilterEmployees = true;
  }

  drawerClose(): void {
    this.search(true);
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  STATUS = "P";

  changeRadioButton(earlymarkStatus) {
    this.STATUS = earlymarkStatus;
    this.search(true);
  }

  getTimeInAM_PM(time) {
    return this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + time;
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }
}
