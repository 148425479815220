import { Component, Input, OnInit } from '@angular/core';
import { Membermaster } from 'src/app/Models/MemberMaster';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-member-drawer',
  templateUrl: './member-drawer.component.html',
  styleUrls: ['./member-drawer.component.css']
})

export class MemberDrawerComponent implements OnInit {
  passwordVisible = false;
  @Input() drawerClose: Function;
  @Input() data: Membermaster;
  @Input() drawerVisible: boolean;
  isSpinning = false;
  leaveTypes = [];
  namePattern = "([A-Za-z0-9 \s]){1,}";
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  mobpattern = /^[6-9]\d{9}$/;
  federationID = this._cookie.get("FEDERATION_ID");
  unitID = this._cookie.get("UNIT_ID");
  groupID = this._cookie.get("GROUP_ID");

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _cookie: CookieService) { }

  ngOnInit() {
    this.getFederations();
    this.getInchargeAreas();
  }

  federations = [];

  getFederations() {
    var federationFilter = "";
    if (this.federationID != "0") {
      federationFilter = " AND ID=" + this.federationID;
    }

    var unitFilter = "";
    if (this.unitID != "0") {
      unitFilter = " AND ID=(SELECT FEDERATION_ID FROM unit_master WHERE ID=" + this.unitID + ")";
    }

    var groupFilter = "";
    if (this.groupID != "0") {
      groupFilter = " AND ID=(SELECT FEDERATION_ID FROM unit_master WHERE ID=(SELECT UNIT_ID FROM group_master WHERE ID=" + this.groupID + "))";
    }

    this.federations = [];

    this.api.getAllFederations(0, 0, "NAME", "asc", " AND STATUS=1" + federationFilter + unitFilter + groupFilter).subscribe(data => {
      if (data['code'] == 200) {
        this.federations = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  units = [];

  getUnits(federationID) {
    var unitFilter = "";
    if (this.unitID != "0") {
      unitFilter = " AND ID=" + this.unitID;
    }

    var groupFilter = "";
    if (this.groupID != "0") {
      groupFilter = " AND ID=(SELECT UNIT_ID FROM group_master WHERE ID=" + this.groupID + ")";
    }

    this.units = [];
    this.data.UNIT_ID = undefined;

    this.groups = [];
    this.data.GROUP_ID = undefined;

    this.api.getAllUnits(0, 0, "NAME", "asc", " AND FEDERATION_ID=" + federationID + " AND STATUS=1" + unitFilter + groupFilter).subscribe(data => {
      if (data['code'] == 200) {
        this.units = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  groups = [];

  getGroups(unitID) {
    var groupFilter = "";
    if (this.groupID != "0") {
      groupFilter = " AND ID=" + this.groupID;
    }

    this.groups = [];
    this.data.GROUP_ID = undefined;

    this.api.getAllGroups(0, 0, "NAME", "asc", " AND UNIT_ID=" + unitID + " AND STATUS=1" + groupFilter).subscribe(data => {
      if (data['code'] == 200) {
        this.groups = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  inchargeAreas = [];

  getInchargeAreas() {
    this.api.getAllInchargeAreas(0, 0, "NAME", "asc", " AND STATUS=1").subscribe(data => {
      if (data['code'] == 200) {
        this.inchargeAreas = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.NAME != undefined && this.data.NAME != null) {
      if (this.data.NAME.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.NAME)) {
          isOk = false;
          this.message.error("Please Enter Valid Member Name", "");
        }

      } else {
        isOk = false;
        this.message.error("Please Enter Valid Member Name", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Enter Valid Member Name", "");
    }

    if (this.data.MOBILE_NUMBER != undefined && this.data.MOBILE_NUMBER != null) {
      if (this.data.MOBILE_NUMBER.trim() != '') {
        if (!this.mobpattern.test(this.data.MOBILE_NUMBER.toString())) {
          isOk = false;
          this.message.error('Please Enter Valid Mobile Number', '');
        }

      } else {
        isOk = false;
        this.message.error("Please Enter Valid Mobile Number", "");
      }

    } else {
      isOk = false;
      this.message.error('Please Enter Mobile Number', '');
    }

    // if (this.data.GENDER == undefined || this.data.GENDER == null) {
    //   isOk = false;
    //   this.message.error('Please Select Gender', '');
    // }

    // if (this.data.MEMBERSHIP_DATE == undefined || this.data.MEMBERSHIP_DATE == null) {
    //   isOk = false;
    //   this.message.error('Please Select Membership Date', '');
    // }

    // if (this.data.EXPIRY_DATE == undefined || this.data.EXPIRY_DATE == null) {
    //   isOk = false;
    //   this.message.error('Please Select Expiry Date', '');
    // }

    if (this.data.FEDERATION_ID == undefined || this.data.FEDERATION_ID == null) {
      isOk = false;
      this.message.error('Please Select Federation', '');
    }

    if (this.data.UNIT_ID == undefined || this.data.UNIT_ID == null) {
      isOk = false;
      this.message.error('Please Select Unit', '');
    }

    if (this.data.GROUP_ID == undefined || this.data.GROUP_ID == null) {
      isOk = false;
      this.message.error('Please Select Group', '');
    }

    this.data.EMAIL_ID = this.data.EMAIL_ID == undefined ? "" : this.data.EMAIL_ID;

    if ((this.data.EMAIL_ID.trim() != "")) {
      if ((this.data.EMAIL_ID != undefined) && (this.data.EMAIL_ID != null)) {
        if (!this.emailpattern.test(this.data.EMAIL_ID)) {
          isOk = false;
          this.message.error('Please Enter Valid Email', '');
        }

      } else {
        isOk = false;
        this.message.error('Please Enter Email', '');
      }
    }

    this.data.PASSWORD = this.data.PASSWORD == undefined ? "" : this.data.PASSWORD;

    if ((this.data.PASSWORD.trim() != "")) {
      if ((this.data.PASSWORD != undefined) && (this.data.PASSWORD != null)) {
        if (this.data.PASSWORD.trim() != "") {
          if (this.data.PASSWORD.length >= 8) {
            if (!this.api.passwordIsValid(this.data.PASSWORD)) {
              isOk = false;
              this.message.error("Please Enter Valid Password", "");
            }

          } else {
            isOk = false;
            this.message.error("Password Must be or Greater than 8 Characters", "");
          }

        } else {
          isOk = false;
          this.message.error("Please Enter Password", "");
        }

      } else {
        isOk = false;
        this.message.error("Please Enter Password", "");
      }
    }

    this.data.INCHARGE_OF = this.data.INCHARGE_OF == undefined ? "" : this.data.INCHARGE_OF;

    if (isOk) {
      this.isSpinning = true;
      this.data.ACTIVE_STATUS = "A";
      this.data.DOB = this.datePipe.transform(this.data.DOB, "yyyy-MM-dd");

      if (this.data.ANNIVERSARY_DATE)
        this.data.ANNIVERSARY_DATE = this.datePipe.transform(this.data.ANNIVERSARY_DATE, "yyyy-MM-dd");

      else {
        let defaultDate = new Date(1900, 0, 1);
        this.data.ANNIVERSARY_DATE = this.datePipe.transform(defaultDate, "yyyy-MM-dd");
      }

      this.data.MEMBERSHIP_DATE = this.datePipe.transform(this.data.MEMBERSHIP_DATE, "yyyy-MM-dd");
      this.data.EXPIRY_DATE = this.datePipe.transform(this.data.EXPIRY_DATE, "yyyy-MM-dd");

      if ((this.data.INCHARGE_OF != ""))
        this.data.INCHARGE_OF = this.data.INCHARGE_OF.toString();
      else
        this.data.INCHARGE_OF = " ";

      if ((this.data.PASSWORD == undefined) || (this.data.PASSWORD == null) || (this.data.PASSWORD.trim() == ""))
        this.data.PASSWORD = this.api.generate8DigitRandomNumber();

      this.imageUpload1();
      this.data.PROFILE_IMAGE = (this.photo1Str == "") ? " " : this.photo1Str;

      if (this.data.ID) {
        this.api.updateMember(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Member Details Updated Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

          } else {
            this.message.error("Member Details Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createMember(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Member Created Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

            else {
              this.data = new Membermaster();
            }

          } else {
            this.message.error("Member Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }

      this.fileURL1 = null;
    }
  }

  numberOnly(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  today = new Date().setDate(new Date().getDate());

  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) > 0;

  disabledExpiryDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  todayForBirth = new Date().setFullYear(new Date().getFullYear() - 18);

  disabledBirthDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.todayForBirth) > 0;

  dobOnOpen(status) {
    if (status)
      this.data.DOB = this.datePipe.transform(this.todayForBirth, "yyyy-MM-dd");
  }

  fileURL1: any = null;

  clear1() {
    this.fileURL1 = null;
    this.data.PROFILE_IMAGE = null;
  }

  viewImage(imageName) {
    window.open(imageName);
  }

  onFileSelected1(event: any) {
    if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/jpg' || event.target.files[0].type == 'image/png') {
      this.fileURL1 = <File>event.target.files[0];

      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.data.PROFILE_IMAGE = reader.result as string;
        };
      }

    } else {
      this.message.error('Please Choose Only JPEG/ JPG/ PNG File', '');
      this.fileURL1 = null;
    }
  }

  folderName = "profileImage";
  photo1Str: string;

  imageUpload1() {
    this.photo1Str = "";

    if (!this.data.ID) {
      if (this.fileURL1) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL1.name.split('.').pop();
        var url = "PI" + number + "." + fileExt;

        this.api.onUpload2(this.folderName, this.fileURL1, url).subscribe(res => {
          if (res["code"] == 200) {
            console.log("Uploaded");

          } else {
            console.log("Not Uploaded");
          }
        });

        this.photo1Str = url;

      } else {
        this.photo1Str = "";
      }

    } else {
      if (this.fileURL1) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL1.name.split('.').pop();
        var url = "PI" + number + "." + fileExt;

        this.api.onUpload2(this.folderName, this.fileURL1, url).subscribe(res => {
          if (res["code"] == 200) {
            console.log("Uploaded");

          } else {
            console.log("Not Uploaded");
          }
        });

        this.photo1Str = url;

      } else {
        if (this.data.PROFILE_IMAGE) {
          let photoURL = this.data.PROFILE_IMAGE.split("/");
          this.photo1Str = photoURL[photoURL.length - 1];

        } else
          this.photo1Str = "";
      }
    }
  }

  cancel() { }
}
