export class Cmsevent {



  ID:number=0;
  DATE:any;
  DESCRIPTION1:string='';
  DETAILS:string='';
  GROUP_NAME:string='';
  PHOTO1:string='';
  STATUS:boolean=true;
  VENUE:string='';

  WEB_STATUS:boolean=true;
  IS_ALL_EVENT_UPDATE:boolean = true;


  }
