export class CentralCommittee {
    ID:number = 0;
    NAME:string='';
    GROUP_NAME:string='';
    MOBILE_NO:number= null;
    IMG_URL:string='';
    EMAIL_ID:string='';
    STATUS:boolean=true;
    SEQ_NO:number=0;
    COMMITTEE_NAME:string='';
    COMMITTEE_TYPE_ID:number=0;
    POSITION:string='';


    // FEDERATION_ID:number=0;

  }
  