export class Cmsmeeting{

  DATE:any
  DESCRIPTION1:string='';
  GROUP_NAME:string='';
  ID:number=0;
  MEETING_SUB:string='';
  PHOTO1:string='';
  VENUE:string='';
  WEB_MEETING_STATUS: boolean=true;






  // ID: number;
  // CLIENT_ID: number;
  // NAME: string;
  // STATUS: boolean = true;

  }
