import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-group-membership-monthwise',
  templateUrl: './group-membership-monthwise.component.html',
  styleUrls: ['./group-membership-monthwise.component.css']
})

export class GroupMembershipMonthwiseComponent implements OnInit {
  drawerVisible!: boolean;
  drawerTitle!: string;
  formTitle = "Group Membership Monthwise Report";
  dataList = [];
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  groupname: string = '';
  filterQuery: string = "";
  isFilterApplied: any = "default";
  filterClass: string = 'filter-invisible';
  isSpinning = false;
  GROUP = 0;
  groups: any[] = [];
  units: any[] = [];
  UNIT = 0;
  selectedDate: Date[] = []
  value1: any;
  value2: any;
  current = new Date();
  columns: string[][] = [["UNIT_NAME", " Unit Name "], ["GROUP_NAME", " Group Name "]];

  constructor(private api: ApiService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.loadingRecords = false;
    // this.loadGroups();
    this.changeDate(this.selectedDate)
  }

  //   loadGroups(){
  //   this.api.getAllGroups(0,0,'','',' AND STATUS = 1').subscribe(data =>{
  //     this.groups=data['data'];
  //   },err => {
  //     console.log(err); 
  //   });

  // this.api.getAllUnits(0,0,'','',' AND STATUS = 1').subscribe(data =>{
  //     this.units=data['data'];
  //   },err => {
  //     console.log(err); 
  //   });
  // }

  changeDate(value: any) {
    this.value1 = this.datePipe.transform(value[0], "yyyy-MM-dd")
    this.value2 = this.datePipe.transform(value[1], "yyyy-MM-dd")
  }

  //Key enter event
  onKeypressEvent(event: any) {
    document.getElementById('button').focus()
    // document.getElementById('button').focus()
    this.search()
  }

  keyup(event: any) {
    this.search();
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    var likeQuery = "";
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }
    // this.api.getAllNewSubscriberReport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery+
    //   this.filterQuery).subscribe(data => {
    this.loadingRecords = false;
    //   this.totalRecords = data['count'];
    //   this.dataList = data['data'];

    // }, err => {
    //   console.log(err);
    // });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  showFilter(): void {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';
  }

  applyFilter() {
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    this.filterQuery = ''
    // if (this.value1 != undefined && this.value2 != undefined) {
    //   if (this.value1 != null && this.value2 == null) {
    //     this.message.error("Please Select End Date", "");
    //   }
    //   else if (this.value1 == null && this.value2 != null) {
    //     this.message.error("Please Select Start Date", "");
    //   }
    //   else {
    //     this.filterQuery = " AND DATE between '" + this.value1 + ":00:00:00" + "' AND '" + this.value2 + ":23:59:59" + "' "

    //   }
    // } else {
    //   this.filterQuery = ''
    // }

    if (this.UNIT == 0) {
      this.filterQuery += this.filterQuery;
    } else {
      this.filterQuery = this.filterQuery + ' AND UNIT_ID = ' + this.UNIT;
    }
    if (this.GROUP == 0) {
      this.filterQuery += this.filterQuery;
    } else {
      this.filterQuery = this.filterQuery + ' AND GROUP_ID = ' + this.GROUP;
    }
    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    // this.api
    //   .getAllNewSubscriberReport(
    //     this.pageIndex,
    //     this.pageSize,
    //     this.sortKey,
    //     sort, likeQuery +
    //   this.filterQuery
    //   )
    //   .subscribe(
    //     (data) => {
    //       console.log(data);
    this.loadingRecords = false;
    //       this.isFilterApplied = 'primary';
    //       this.totalRecords = data['count'];
    //       this.dataList = data['data'];
    //       this.isSpinning = false;
    //       this.filterClass = 'filter-invisible';
    //       // this.search();
    //     },
    //     (err) => {
    //       console.log(err);
    //     }
    //   );


    // this.dataList=[];
  }
  clearFilter() {
    this.value1 = '';
    this.value2 = '';
    this.selectedDate = [];
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.dataList = [];
    this.GROUP = 0
    this.UNIT = 0
    this.search();

  }

  disabledDate = (selected: Date): boolean =>
    differenceInCalendarDays(selected, this.current) > 0
}


