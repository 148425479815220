export class GroupMaster {
    ID: number;
    CLIENT_ID: number;
    UNIT_ID: number;
    NAME: string;
    STATUS: boolean = true;
    TYPE: string;
    BO_DATE: string;
    _20_21_MEMBER_COUNT: string;
    _21_22_MEMBER_COUNT: string;
    PRESIDENT: number;
    VPI: number;
    VPE: number;
    SECRETORY: number;
    TREASURER: number;
    DIRECTOR1: number;
    DIRECTOR2: number;
    DIRECTOR3: number;
    DIRECTOR4: number;
    DIRECTOR5: number;
}