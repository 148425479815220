import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CmsGroupOfficer } from 'src/app/Models/CmsGroupOfficer';
import { ApiService } from 'src/app/Service/api.service';



@Component({
  selector: 'app-listgroupofficer',
  templateUrl: './listgroupofficer.component.html',
  styleUrls: ['./listgroupofficer.component.css']
})
export class ListgroupofficerComponent implements OnInit {
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: CmsGroupOfficer = new CmsGroupOfficer();
  formTitle = "Group Officer Guideline";
  @Input()
   dataList:any[] = [];
  // dataList = [];
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [ ["SEQUENCE_NO","Seq No"], ["GUIDLINE_TITLE","Title"],];
  @Input()
  drawerClose2!: Function;
  // imgurl= appkeys.retriveimgUrl;

  constructor(private api: ApiService,private message:NzNotificationService) { }

  ngOnInit(): void {
  this.loadingRecords = false;
  this.search()

  }

  keyup(event:any) {
    this.search();
  }

  search(reset: boolean = false) {
    if (reset)
  {
     this.pageIndex = 1;
  }

  var sort: string;
    try {
      this.sortValue = this.sortValue.startsWith("a") ? "asc" : "desc";
      console.log("an", this.sortValue);
    }
    catch (error) { sort = ""; }

      var likeQuery = ""; if (this.searchText != "")
       {
         likeQuery = " AND";
         this.columns.forEach(column =>
      {
       likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR"; });
       likeQuery = likeQuery.substring(0, likeQuery.length - 2);
   }

     this.loadingRecords = true;

    this.api.getGroupOfficer(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, likeQuery).
    subscribe(data => { if (data['code'] == 200)
     {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
  this.dataList = data['data'];
    } },
     err => { if (err['ok'] == false)
      this.message.error("Server Not Found", ""); });
  }


  sort(sort: { key: string; value: string }):
  void {
  this.sortKey = sort.key;
  this.sortValue = sort.value;
  console.log("SORT", this.sortValue);
  this.search(true);
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Add New Guideline";
    this.drawerData = new CmsGroupOfficer();
    this.api.getGroupOfficer(1,1,'SEQUENCE_NO','desc','').subscribe (data =>{
      if (data['count']==0){
        this.drawerData.SEQUENCE_NO=1;
      }else
      {
        this.drawerData.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
      }
    },err=>{
      console.log(err);
    })
    this.drawerVisible = true;
  }
  edit(data: CmsGroupOfficer): void {
    this.drawerTitle = "Update Guideline ";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

 


}

// params: NzTableQueryParams
