import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { ModelMaster } from 'src/app/Models/ModelMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-model-drawer',
  templateUrl: './model-drawer.component.html',
  styleUrls: ['./model-drawer.component.css']
})

export class ModelDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: ModelMaster;
  @Input() drawerVisible: boolean;
  isSpinning = false;
  leaveTypes = [];
  namePattern = "([A-Za-z0-9 \s]){1,}";

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() { }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.NAME != undefined && this.data.NAME != null) {
      if (this.data.NAME.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.NAME)) {
          isOk = false;
          this.message.error("Please Enter Valid Model Name", "");
        }

      } else {
        isOk = false;
        this.message.error("Please Enter Valid Model Name", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Enter Valid Model Name", "");
    }

    if (this.data.CODE != undefined && this.data.CODE != null) {
      if (this.data.CODE.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.CODE)) {
          isOk = false;
          this.message.error("Please Enter Valid Model Code", "");
        }

      } else {
        isOk = false;
        this.message.error("Please Enter Valid Model Code", "");
      }

    } else {
      isOk = false;
      this.message.error("Please Enter Valid Model Code", "");
    }

    if (isOk) {
      this.isSpinning = true;

      if (this.data.ID) {
        this.api.updateModel(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Model Details Updated Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

          } else {
            this.message.error("Model Details Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createModel(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Model Created Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

            else {
              this.data = new ModelMaster();
              this.getSequenceNo();
            }

          } else {
            this.message.error("Model Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }

  getSequenceNo() {
    this.api.getAllModels(1, 1, 'ID', 'desc', '').subscribe(data => {
      console.log(data);

      if (data['code'] == 200 && data['count'] > 0) {
        this.data.SEQUENCE_NO = Number(data['data'][0]['SEQUENCE_NO']) + 1;

      } else {
        this.data.SEQUENCE_NO = 1;
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  numberOnly(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

}
