import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';
import { ExpensesHeadMaster } from 'src/app/Models/expenses-head-master';
import { CustomerMaster } from 'src/app/Models/customer-master';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-expensereport',
  templateUrl: './expensereport.component.html',
  styleUrls: ['./expensereport.component.css'],
  providers: [DatePipe]
})

export class ExpensereportComponent implements OnInit {
  dateFormat = "dd/MMM/yyyy"
  formTitle = "Expense Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "VOUCHER_NO";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["VOUCHER_NO", "Vocher No."], ["DATE", "Expense Date"], ["CLAIM_DATE", "Claim Date"], ["EXPENSE_HEAD_NAME", "Head"], ["CUSTOMER_NAME", "Customer"], ["PARTY_NAME", "Party"], ["AMOUNT", "Amount"], ["DESCRIPTION", "Description"], ["PEOPLES_NAMES", "People With"], ["ATTACHMENT", "View Bill"], ["HEAD_STATUS", "Head Status"], ["HEAD_TIME", "Head Time"], ["HEAD_REMARK", "Head Remark"], ["AC_STATUS", "Accountant Status"], ["AC_REMARK", "Accountant Remark"], ["AC_TIME", "Accountant Time"], ["STATUS", "Main Status"]];
  loadingFilterEmployees = true;
  loadingFilterExpenseHeads = true;
  loadingFilterCustomers = true;
  filterClass: string = "filter-visible";
  initFilter = true;
  filterStatus
  filterEmployees: EmployeeMaster[];
  filterExpenseHeads: ExpensesHeadMaster[];
  filterCustomers: CustomerMaster[];
  employeeFilter
  expanseHeadFilter
  customerFilter
  filterExpenseHeadValue: string[] = ['AL'];
  filterCustomerValue: string[] = ['AL'];
  filterStatusValue: string[] = ['AL'];
  date: Date[] = [];
  date1 = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + 1;
  isSpinning = false;
  excelDataList = [];

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() {
    this.date[0] = new Date(this.date1);
    this.date[1] = new Date();
    this.search(true);
    this.loadDataFilter();
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    if (this.searchText != "") {
      this.filterQuery += " AND (";
      var likeQuery = " ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like ('%" + this.searchText + "%') OR";
      });
      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + ")";
    }

    this.api.getExpenseReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });

    // Getting Data for Excel
    this.api.getExpenseReport(0, 0, this.sortKey, sort, this.filterQuery).subscribe(data => {
      if (data['code'] == 200) {
        this.excelDataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  onKeypressEvent(reset: boolean = false) {
    document.getElementById('button').focus();
    this.search(true);
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
  }

  loadDataFilter() {
    this.loadingFilterEmployees = true;

    this.api.getAllEmployees(0, 0, 'NAME', 'asc', '').subscribe(employees => {
      if (employees['code'] == 200) {
        this.filterEmployees = employees['data'];
        this.loadingFilterEmployees = false;
      }

    }, err => {
      console.log(err);
    });

    this.api.getAllExpenseHead(0, 0, 'NAME', 'asc', '').subscribe(expenseHeads => {
      if (expenseHeads['code'] == 200) {
        this.filterExpenseHeads = expenseHeads['data'];
        this.loadingFilterExpenseHeads = false;
      }

    }, err => {
      console.log(err);
    });

    this.api.getAllCustomers(0, 0, 'NAME', 'asc', '').subscribe(customers => {
      if (customers['code'] == 200) {
        this.filterCustomers = customers['data'];
        this.loadingFilterCustomers = false;
      }

    }, err => {
      console.log(err);
    });
  }

  getAttachment(value) {
    window.open(this.api.retriveimgUrl + "expenses/" + value);
  }

  getName(STATUS) {
    if (STATUS == "P")
      return "Pending"
    else
      if (STATUS == "A")
        return "Approved"
      else
        if (STATUS == "O")
          return "On Hold"
        else
          if (STATUS == "HA")
            return "Head Approved"
          else if (STATUS == "HR")
            return "Head Rejected"
          else if (STATUS == "R")
            return "Rejected"
          else
            return ""
  }

  clearFilter() {
    this.date = undefined;
    this.filterQuery = "";
    this.filterClass = "filter-invisible";
    this.employeeFilter = undefined;
    this.filterStatusValue = ['AL'];
    this.filterCustomerValue = ['AL'];
    this.filterExpenseHeadValue = ['AL'];
    this.isFilterApplied = "default";
    this.search(true);
  }

  applyFilter() {
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("" + this.date);

    if (this.date == undefined || this.employeeFilter == undefined || this.filterStatusValue == undefined || this.filterExpenseHeadValue == undefined || this.filterCustomerValue == undefined) {
      this.message.error("Please Select All Fields", "");

    } else {
      this.filterQuery = " AND (CLAIM_DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd') + "') AND";
      this.filterQuery += " EMPLOYEE_ID = " + this.employeeFilter + " AND ";

      let statusString: string = "";
      if (this.filterStatusValue != undefined) {
        this.filterStatusValue.forEach(filterStatus => {
          statusString += "'" + filterStatus + "'" + ",";
        }); (this.filterStatusValue)

        if (statusString.toString() != "") {
          if (statusString.match("AL")) {
            this.filterQuery += "STATUS IN ('P','A','R','O','HA','HR') AND ";

          } else {
            this.filterQuery += "STATUS IN (" + statusString.substring(0, statusString.length - 1) + ") AND ";
          }
        }
      }

      let expenseHeadString: string = "";
      if (this.filterExpenseHeadValue != undefined) {
        this.filterExpenseHeadValue.forEach(filterExpenseHead => {
          expenseHeadString += "'" + filterExpenseHead + "'" + ",";
        }); (this.filterExpenseHeadValue)

        if (expenseHeadString.toString() != "") {
          if (expenseHeadString.match("AL")) {
            this.filterQuery = this.filterQuery;

          } else {
            this.filterQuery += "HEAD_ID IN (" + expenseHeadString.substring(0, expenseHeadString.length - 1) + ") AND ";
          }
        }
      }

      let customerString: string = "";
      if (this.filterCustomerValue != undefined) {
        this.filterCustomerValue.forEach(filterCustomer => {
          customerString += "'" + filterCustomer + "'" + ",";
        }); (this.filterCustomerValue)

        if (customerString.toString() != "") {
          if (customerString.match("AL")) {
            this.filterQuery = this.filterQuery;

          } else {
            this.filterQuery += "CUSTOMER_ID IN (" + customerString.substring(0, customerString.length - 1) + ") AND ";
          }
        }
      }

      this.filterQuery = this.filterQuery.substring(0, this.filterQuery.length - 5);
      console.log("Final " + this.filterQuery);

      this.isSpinning = true;
      this.api.getExpenseReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isSpinning = false;
          this.isFilterApplied = "primary";
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.filterClass = "filter-invisible";
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

      // Getting Data for Excel
      this.api.getExpenseReport(0, 0, this.sortKey, sort, this.filterQuery).subscribe(data => {
        if (data['code'] == 200) {
          this.excelDataList = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }
}
