export class joinus{




    FEDERATION_ID:number=0;
    UNIT_ID:number=0;
    GROUP_ID:number=0;
    // FEDERATION_ID: string = ''
    // UNIT_ID: string = ''
    // GROUP_ID: string = ''
    NAME: string = ''
    GENDER: string = ''
    DOB:any;
    MEMBERSHIP_DATE:any;
    NICK_NAME: string = ''
    EDUCATIONAL_QUALIFICATION: string = ''
    OCCUPATIONAL_PARTICULARS: string = ''
    MEMBERSHIP_OTHER_ORGANISATION: string = ''
    NAME_SPOUSE: string = ''
    ADDRESS1: string = ''
    MOBILE_NUMBER: number;
    ADDRESS2: string = ''
    COMMUNICATION_MOBILE_NUMBER: number;
    SPONSORS_NAME: string = ''
    SPONSORS_ADDRESS: string = ''
    APPLICATION_DATE: number;
    PROFILE_IMAGE: string = ''
    
}