import { DatePipe } from '@angular/common';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CmsGroup_Guidline } from 'src/app/Models/CmsGroup_Guidline';
import { ApiService } from 'src/app/Service/api.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-addgroup-guidline',
  templateUrl: './addgroup-guidline.component.html',
  styleUrls: ['./addgroup-guidline.component.css']
})
export class AddgroupGuidlineComponent implements OnInit {
  @Input()
  drawerClose!: Function;
  @Input()
  data: CmsGroup_Guidline = new CmsGroup_Guidline;
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // namepatt=/^[a-zA-Z \-\']+/
  namepatt = /[a-zA-Z][a-zA-Z ]+/
  mobpattern = /^[6-9]\d{9}$/
  fileURL: any;
  // albume:AlbumeMaster[]=[]
  // imgurl= appkeys.retriveimgUrl;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '350px',
    // minHeight: '12rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
    ],
    customClasses: [],
    uploadUrl: '',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fonts', 'uploadUrl'],
      ['video']
    ]
  };

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    // this.loadalbume();
  }


  close(): void {
    this.drawerClose();
  }
  resetDrawer(websitebannerPage: NgForm) {
    this.data = new CmsGroup_Guidline();
    // websitebannerPage.form.reset();
    websitebannerPage.form.markAsPristine();
    websitebannerPage.form.markAsUntouched();
  }

  //save
  save(addNew: boolean,websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk=true;

    if (this.data.GUIDLINE_TITLE.trim() == ""
    && this.data.GUIDLINE_DESCRIPTION.trim() == "" && this.data.SEQUENCE_NO != undefined  )  {
      this.isOk =false
     this.message.error("Please Enter All Information", "");
    }

   else

   if(this.data.GUIDLINE_TITLE==null || this.data.GUIDLINE_TITLE.trim()==''){
    this.isOk =false
    this.message.error('Please Enter Guideline Title','')
  }else

  if(this.data.GUIDLINE_DESCRIPTION==null || this.data.GUIDLINE_DESCRIPTION.trim()==''){
   this.isOk =false
   this.message.error('Please Enter Guideline Description','')
  }
  // else

  // if(this.data.SEQUENCE_NO== undefined || this.data.SEQUENCE_NO<=0){
  //   this.isOk =false
  //   this.message.error('Please Enter Sequence No','')
  // }

  if(this.isOk)
  {
    // this.isSpinning=false;

    this.isSpinning=true;
  if(this.data.ID)
  {
    this.api.updateGroupGuidline(this.data)
    .subscribe(successCode => {
      if(successCode["code"]==200)
      {
          this.message.success("Information Updated Succesfully", "");
          if(!addNew)
          this.drawerClose();
          this.isSpinning = false;
      }
      else
      {
        this.message.error("Failed To Update Information", "");
        this.isSpinning = false;
      }
    });
  }
  else{
      this.api.createGroupGuidline(this.data)
      .subscribe(successCode => {
        if(successCode.code=="200")
        {
          this.message.success("Information Added Succesfully", "");

           if(!addNew)
           this.drawerClose();
            else
            {
              this.resetDrawer(websitebannerPage);

              this.api.getGroupGuidline(1,1,'','desc','').subscribe (data =>{
                if (data['count']==0){
                  this.data.SEQUENCE_NO=1;
                }else
                {
                  this.data.SEQUENCE_NO=data['data'][0]['SEQUENCE_NO']+1;
                }
              },err=>{
                console.log(err);
              })
            }
            this.isSpinning = false;
          }
           else
           {
            this.message.error("Failed To Store Information", "");
            this.isSpinning = false;
           }
          });
        }
  }

  }



  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }







}
