import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Leave } from 'src/app/Models/Leave';
import { ApiService } from 'src/app/Service/api.service';
import { HeadLeaveApproveDrawerComponent } from '../head-leave-approve-drawer/head-leave-approve-drawer.component';
import { LeaveApproveDrawerComponent } from '../leave-approve-drawer/leave-approve-drawer.component';

@Component({
  selector: 'app-head-approve-employee-leaves-tiles',
  templateUrl: './head-approve-employee-leaves-tiles.component.html',
  styleUrls: ['./head-approve-employee-leaves-tiles.component.css']
})

export class HeadApproveEmployeeLeavesTilesComponent implements OnInit {
  formTitle = "Manage Leave";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "FROM_DATE";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["STATUS", "Status"], ["NAME", "Name"], ["LEAVE_TYPE_NAME", "Leave Type"], ["FROM_DATE", "From Date"], ["TO_DATE", "To Date"], ["REASON", "Reason"], ["HEAD_APPROVED_DATES", "Head Approved Date"], ["HEAD_REMARK", "Head Remark"], ["HR_APPROVED_DATES", "HR Approved Date"], ["HR_REMARK", "HR Remark"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Leave = new Leave();
  headDrawerVisible: boolean;
  headDrawerTitle: string;
  headDrawerData: Leave = new Leave();
  isSpinning = false;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));

  employeeList = [];

  ngOnInit() {
    this.search(true);
    this.date = [];
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var dateFilter = "";
    if (this.date != undefined && this.date.length != 0) {
      dateFilter = " AND (FROM_DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd') + "')";
    }

    var empFilter = " and (REPORTING_PERSON1 = " + this.userId + " or REPORTING_PERSON2 = " + this.userId + ")";

    var status = "";
    if (this.STATUS == "P")
      status = " and STATUS in ('P')";

    else if (this.STATUS == "A")
      status = " and STATUS in ('A','HA')";

    else if (this.STATUS == "R")
      status = " and STATUS in ('R','HR')";

    this.api.getAllEmployeeLeave(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + empFilter + dateFilter + status).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  today = new Date().setDate(new Date().getDate() + 4);

  @ViewChild(LeaveApproveDrawerComponent, { static: false }) LeaveApproveDrawerComponentVar: LeaveApproveDrawerComponent;

  add(): void {
    this.drawerTitle = "Add Leave";
    this.drawerData = new Leave();
    this.drawerVisible = true;
    this.drawerData.FROM_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");
    this.drawerData.TO_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");
  }

  edit(data: Leave): void {
    this.drawerTitle = "Approve Leave";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

    if (this.drawerData.STATUS == 'A' || this.drawerData.STATUS == 'HA') {
      this.LeaveApproveDrawerComponentVar.APPROVE = true;
      this.LeaveApproveDrawerComponentVar.REMARK = this.drawerData.HR_REMARK;

    } else if (this.drawerData.STATUS == 'R' || this.drawerData.STATUS == 'HR') {
      this.LeaveApproveDrawerComponentVar.APPROVE = false;
      this.LeaveApproveDrawerComponentVar.REMARK = this.drawerData.REJECT_REMARK;

    } else {
      this.LeaveApproveDrawerComponentVar.APPROVE = true;
      this.LeaveApproveDrawerComponentVar.REMARK = undefined;
    }
  }

  @ViewChild(HeadLeaveApproveDrawerComponent, { static: false }) HeadLeaveApproveDrawerComponentVar: HeadLeaveApproveDrawerComponent;

  editFromHead(data: Leave): void {
    this.headDrawerTitle = "Approve Leave";
    this.headDrawerData = Object.assign({}, data);
    this.headDrawerVisible = true;

    if (this.headDrawerData.STATUS == 'A' || this.headDrawerData.STATUS == 'HA') {
      this.HeadLeaveApproveDrawerComponentVar.APPROVE = true;
      this.HeadLeaveApproveDrawerComponentVar.REMARK = this.headDrawerData.HEAD_REMARK;

    } else if (this.headDrawerData.STATUS == 'R' || this.headDrawerData.STATUS == 'HR') {
      this.HeadLeaveApproveDrawerComponentVar.APPROVE = false;
      this.HeadLeaveApproveDrawerComponentVar.REMARK = this.headDrawerData.HEAD_REMARK;

    } else {
      this.HeadLeaveApproveDrawerComponentVar.APPROVE = true;
      this.HeadLeaveApproveDrawerComponentVar.REMARK = undefined;
    }

    var d = this.datePipe.transform(this.headDrawerData.FROM_DATE, "yyyy-MM-dd");
    var d1 = this.datePipe.transform(this.headDrawerData.TO_DATE, "yyyy-MM-dd");

    this.HeadLeaveApproveDrawerComponentVar.createDate(d, d1);
  }

  drawerClose(): void {
    this.search(true);
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  headDrawerClose(): void {
    this.search(true);
    this.headDrawerVisible = false;
  }

  get headDrawerCloseCallback() {
    return this.headDrawerClose.bind(this);
  }

  filterClass: string = "filter-visible";

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  date: Date[] = [];
  filterStatusValue: string[] = ['P'];
  date1 = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + 1;
  employeeFilter
  dateFormat = "dd/MMM/yyyy";

  clearFilter() {
    this.date = [];
    this.filterStatusValue = ['P'];
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.employeeFilter = 1;
    this.filterQuery = "";
    this.search(true);
  }

  applyFilter() {
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    if (this.date == undefined || this.date.length == 0) {
      this.message.error("Please Select Date", "");

    } else {
      this.filterQuery = " AND (FROM_DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd') + "') AND ";
      this.filterQuery = this.filterQuery.substring(0, this.filterQuery.length - 5);

      var empFilter = " and (REPORTING_PERSON1 = " + this.userId + " or REPORTING_PERSON2 = " + this.userId + ")";

      var status = "";
      if (this.STATUS == "P")
        status = " and STATUS in ('P')";

      else if (this.STATUS == "A")
        status = " and STATUS in ('A','HA')";

      else if (this.STATUS == "R")
        status = " and STATUS in ('R','HR')";

      this.isSpinning = true;
      this.api.getAllEmployeeLeave(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + empFilter + status).subscribe(data => {
        if (data['code'] == 200) {
          this.isSpinning = false;
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isFilterApplied = "primary";
        }

      }, err => {
        console.log(err);
      });
    }

    this.filterClass = "filter-invisible";
  }

  STATUS = "P";

  changeRadioButton(earlymarkStatus) {
    this.STATUS = earlymarkStatus;
    this.search(true);
  }

  getTimeInAM_PM(time) {
    return this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + time;
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }
}
