export class WebsiteBannerMaster {

    ID:number=0;
    NAME:string='';
    // NAME_MR:string='';
    DESCRIPTION:string='';
    TITLE:string='';
    IMG_URL:string='';
    STATUS:boolean=true;
    SEQUENCE_NO:number=0;

}
