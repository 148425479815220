import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
// import { appkeys } from 'src/app/app.constant';
// import { AboutMaster } from 'src/app/Models/aboutmaster';
import { ApiService } from 'src/app/Service/api.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CmsAboutShort } from 'src/app/Models/CmsAboutShort';
// import { ClientmasterService } from 'src/app/Service/clientmaster.service';

@Component({
  selector: 'app-addabout',
  templateUrl: './addabout.component.html',
  styleUrls: ['./addabout.component.css']
})
export class AddaboutComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  data: CmsAboutShort = new CmsAboutShort;
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // namepatt=/^[a-zA-Z \-\']+/
  namepatt = /[a-zA-Z][a-zA-Z ]+/
  mobpattern = /^[6-9]\d{9}$/
  fileURL: any;
  height: any;
  width: any;

  imgurl = this.api.retriveimgUrl + "/aboutUs/";

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '300px',
    // minHeight: '12rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
    ],
    customClasses: [],
    uploadUrl: '',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fonts', 'uploadUrl'],
      ['video']
    ]
  };



  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    // this.loadalbume();
  }


  close(): void {
    this.drawerClose();
  }
  resetDrawer(websitebannerPage: NgForm) {
    websitebannerPage.form.reset();
  }

  //save
  save(addNew: boolean, websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;

    if (this.data.TITLE.trim() == ""
      && this.data.DESCRIPTION.trim() == ""
      && this.data.SEQUENCE_NO != undefined && this.data.IMG_URL.trim() == "") {
      this.isOk = false
      this.message.error("Please Fill All The Information", "");
    } else
      if (this.data.TITLE == null || this.data.TITLE.trim() == '') {
        this.isOk = false
        this.message.error('Please Fill Title', '')

      }
      else
        if (this.data.DESCRIPTION == null || this.data.DESCRIPTION.trim() == '') {
          this.isOk = false
          this.message.error('Please Fill Description', '')
        }
        else
          if (this.data.SEQUENCE_NO == undefined || this.data.SEQUENCE_NO <= 0) {
            this.isOk = false
            this.message.error('Please Enter Sequence No', '')
          } else
            if (this.data.IMG_URL == null || this.data.IMG_URL.trim() == '') {
              this.isOk = false
              this.message.error('Please Select Image', '')
            }

    if (this.isOk) {
      // this.isSpinning=false;

      this.isSpinning = true;
      if (this.fileURL != null) {
        var number = Math.floor(100000 + Math.random() * 900000);
        var fileExt = this.fileURL.name.split('.').pop();
        var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
        var url = '';
        url = d == null ? '' : d + number + '.' + fileExt;
        if (
          this.data.IMG_URL != undefined &&
          this.data.IMG_URL.trim() != ''
        ) {
          var arr = this.data.IMG_URL.split('/');
          if (arr.length > 1) {
            url = arr[5];
          }
        }

        this.api
          .onUpload2('aboutUs', this.fileURL, url)
          .subscribe((successCode) => {
            if (successCode["code"] == 200) {
              this.data.IMG_URL = url
              if (this.data.ID) {
                this.api.updateAboutMaster(this.data).subscribe((successCode) => {
                  if (successCode["code"] == 200) {
                    this.message.success('Information Updated successfully', '');
                    this.fileURL=null;
                    if (!addNew) this.drawerClose();
                    this.isSpinning = false;
                  } else {
                    this.message.error('Failed to update information', '');
                    this.isSpinning = false;
                  }
                });
              } else {
                this.api.createAboutMaster(this.data).subscribe((successCode) => {
                  if (successCode.code == '200') {
                    this.message.success('Information added successfully', '');
                    this.fileURL=null;
                    if (!addNew) this.drawerClose();
                    else {
                      this.data = new CmsAboutShort();
                      this.resetDrawer(websitebannerPage);
                      this.data.IMG_URL = '';
                      this.fileURL = ''


                      this.api.getAboutMaster(1, 1, ' SEQUENCE_NO', 'desc', '').subscribe(data => {
                        if (data['count'] == 0) {
                          this.data.SEQUENCE_NO = 1;
                        } else {
                          this.data.SEQUENCE_NO = data['data'][0][' SEQUENCE_NO'] + 1;
                        }
                      }, err => {
                        console.log(err);
                      })
                    }
                    this.isSpinning = false;
                  } else {
                    this.message.error('Failed to store information', '');
                    this.isSpinning = false;
                  }
                });
              }
            } else {
              this.message.error('Failed to add image', '');
              this.isSpinning = false;
            }
          });
      } else if (this.data.IMG_URL == null || this.data.IMG_URL == '') {
        this.message.error('Please select image', '');
        this.isSpinning = false;
      } else {
        if (this.data.ID) {
          this.api.updateAboutMaster(this.data).subscribe((successCode) => {
            if (successCode["code"] == 200) {
              this.message.success('Information Updated successfully', '');
              if (!addNew) this.drawerClose();
              this.isSpinning = false;
            } else {
              this.message.error('Failed to update information', '');
              this.isSpinning = false;
            }
          });
        } else {
          this.api.createAboutMaster(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information added successfully', '');
              if (!addNew) this.drawerClose();
              else {
                this.data = new CmsAboutShort();
              }
              this.isSpinning = false;
            } else {
              this.message.error('Failed to store information.', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }
  //Choose Image
  onFileSelected(event: any) {
    console.log(event);
    let imgs = new Image()
    let isLtsize = false;
    imgs.src = window.URL.createObjectURL(event.target.files[0]);

    imgs.onload = () => {
      console.log(imgs.height);
      console.log(imgs.width);
      if (170 == imgs.height && imgs.width == 355) {
        isLtsize = true;

        if (
          event.target.files[0].type == 'image/jpeg' ||
          event.target.files[0].type == 'image/jpg' ||
          event.target.files[0].type == 'image/png'
        ) {
          this.fileURL = <File>event.target.files[0];
        } else {
          this.message.error('Please select only JPEG/ JPG/ PNG file.', '');
          this.fileURL = null;
          this.data.IMG_URL = '';
        }

      } else {
        this.fileURL = null;
        this.data.IMG_URL = ' ';
        this.message.error('The image will not fit between the dimensions of ' + 170 + ' ' + 'px Height  ' + ' And ' + ' ' + 355 + ' px Width ', '');
      }
    }
  }


  removeImage() {
    this.data.IMG_URL = '';
    this.fileURL = '';
  }


  // img 2



  onFileSelected1(event: any) {
    console.log(event);
    let imgs = new Image()
    let isLtsize = false;
    imgs.src = window.URL.createObjectURL(event.target.files[0]);

    imgs.onload = () => {
      console.log(imgs.height);
      console.log(imgs.width);
      if (500 == imgs.height && imgs.width == 457) {
        isLtsize = true;

        if (
          event.target.files[0].type == 'image/jpeg' ||
          event.target.files[0].type == 'image/jpg' ||
          event.target.files[0].type == 'image/png'
        ) {
          this.fileURL = <File>event.target.files[0];
        } else {
          this.message.error('Please select only JPEG/ JPG/ PNG file.', '');
          this.fileURL = null;
          this.data.IMG_URL2 = '';
        }

      } else {
        this.fileURL = null;
        this.data.IMG_URL2 = ' ';
        this.message.error('The image will not fit between the dimensions of ' + 500 + ' ' + 'px Height  ' + ' And ' + ' ' + 457 + ' px Width ', '');
      }
    }
  }



  removeImage1() {
    this.data.IMG_URL2 = '';
    this.fileURL = '';
  }






















  omit(event: any) { const charCode = (event.which) ? event.which : event.keyCode; if (charCode > 31 && (charCode < 48 || charCode > 57)) { return false; } return true; }
}
