export class CmsAnnualTimeTable_Guidline {

    ID:number=0;
    GUIDLINE_TITLE:string='';
    GUIDLINE_DESCRIPTION:string='';
    DATE:any;
    STATUS:boolean=true;
    SEQUENCE_NO:number=0 ;
  
  }
  