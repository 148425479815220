import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
// import { appkeys } from 'src/app/app.constant';
// import { AboutMaster } from 'src/app/Models/aboutmaster';
import { CmsNews_Update } from 'src/app/Models/CmsNewsUpdate';
import { ApiService } from 'src/app/Service/api.service';
// import { ClientmasterService } from 'src/app/Service/clientmaster.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-addnews-update',
  templateUrl: './addnews-update.component.html',
  styleUrls: ['./addnews-update.component.css']
})
export class AddnewsUpdateComponent implements OnInit {



  @Input()
  drawerClose!: Function;
  @Input()
  data: CmsNews_Update = new CmsNews_Update();
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  height: any;
  width: any;
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  fileURL: any;
  // height: any;
  // width: any;
  // albume:AlbumeMaster[]=[]
  // imgurl= appkeys.retriveimgUrl;
  // imgurl = this.api.imgUrl + "newsAndUpdate";
  imgurl = this.api.retriveimgUrl + "/newsAndUpdate/";

  dateFormat = 'dd-MM-yyyy';
  current = new Date();
  today = new Date();

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200px',
    // minHeight: '12rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    // placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
    ],
    customClasses: [],
    uploadUrl: '',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fonts', 'uploadUrl'],
      ['video']
    ]
  };

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    // this.loadalbume();
  }
  close(): void {
    this.drawerClose();
  }

  resetDrawer(websitebannerPage: NgForm) {
    this.data = new CmsNews_Update();
    websitebannerPage.form.markAsPristine();
    websitebannerPage.form.markAsUntouched();
  }
  //save
  //save
  save(addNew: boolean, websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;
    if (
      this.data.TITLE.trim() == ''
      && this.data.DESCRIPTION.trim() == ""
      && this.data.DATE == undefined&&
      this.data.SEQUENCE_NO != undefined &&
      this.data.IMG_URL.trim() == ''
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Information', '');
    }

    else
      if (this.data.TITLE == null || this.data.TITLE.trim() == '') {
        this.isOk = false
        this.message.error('Please Fill Title', '')

      }

      else
        if (this.data.DESCRIPTION == null || this.data.DESCRIPTION.trim() == '') {
          this.isOk = false
          this.message.error('Please Fill Description', '')
        }
        else
          if (this.data.DATE == undefined || this.data.DATE == null) {
            this.isOk = false
            this.message.error('Please Select Date', '')
          }
          else
          if (this.data.SEQUENCE_NO == undefined || this.data.SEQUENCE_NO <= 0) {
             this.isOk = false
             this.message.error('Please Enter Sequence No', '')
          }
          else
          if (this.data.IMG_URL == null || this.data.IMG_URL.trim() == '') {
             this.isOk = false
             this.message.error('Please Select Image', '')
          }
      if (this.isOk) {
        if (this.data.DATE == undefined) {
            this.data.DATE = null;
          } else {
            this.data.DATE = this.datePipe.transform(this.data.DATE, "yyyy-MM-dd");
          }
      this.isSpinning = true;
      {
        if (this.fileURL != null) {
          var number = Math.floor(100000 + Math.random() * 900000);
          var fileExt = this.fileURL.name.split('.').pop();
          var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
          var url = '';
          url = d == null ? '' : d + number + '.' + fileExt;
          if (
            this.data.IMG_URL != undefined &&
            this.data.IMG_URL.trim() != ''
          ) {
            var arr = this.data.IMG_URL.split('/');
            if (arr.length > 1) {
              url = arr[5];
            }
          }
    this.api
            .onUpload2('newsAndUpdate', this.fileURL, url)
            .subscribe((successCode) => {
              if (successCode["code"] == 200) {
                this.data.IMG_URL = url;
                if (this.data.ID) {
                  this.api
                    .updateNews_Update(this.data)
                    .subscribe((successCode) => {
                      if (successCode["code"] == 200) {
                        this.message.success(
                          'Information Updated Successfully', ''
                        );
                        this.fileURL=null;
                        if (!addNew) this.drawerClose();
                        this.isSpinning = false;
                      } else {
                        this.message.error('Failed To Add Information', '');
                        this.isSpinning = false;
                      }
                    });
                } else {
                  this.api
                    .createNews_Update(this.data)
                    .subscribe((successCode) => {
                      if (successCode.code == '200') {
                        this.message.success('Information Added Successfully', '');
                        this.fileURL=null;
                        if (!addNew) this.drawerClose();
                        else {
                          this.data = new  CmsNews_Update();
                          this.resetDrawer(websitebannerPage);

                          this.data.STATUS == true;
                          this.fileURL = '';
                          this.api.getNews_Update(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
                            if (data['count'] == 0) {
                              this.data.SEQUENCE_NO = 1;
                            } else {
                              this.data.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
                            }
                          }, err => {
                            console.log(err);
                          })
                        }
                        this.isSpinning = false;
                      } else {
                        this.message.error('Failed To Store Information', '');
                        this.isSpinning = false;
                      }
                    });
                }
              } else {
                this.message.error('Failed To Add Image', '');
                this.isSpinning = false;
              }
            });
        } else if (this.data.IMG_URL == null || this.data.IMG_URL == '') {
          this.message.error('Please Select Image ', '');
          this.isSpinning = false;
        } else {
          if (this.data.ID) {
            this.api.updateNews_Update(this.data).subscribe((successCode) => {
              if (successCode["code"] == 200) {
                this.message.success('Information Updated Successfully', '');
                if (!addNew) this.drawerClose();
                this.isSpinning = false;
              } else {
                this.message.error('Failed To Update Information', '');
                this.isSpinning = false;
              }
            });
          } else {
            this.api.createNews_Update(this.data).subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information Added Successfully', '');
                if (!addNew) this.drawerClose();
                else {
                  this.data = new  CmsNews_Update();
                }
                this.isSpinning = false;
              } else {
                this.message.error('Failed To Store Information', '');
                this.isSpinning = false;
              }
            });
          }
        }
      }
    }
  }
  //Choose Image
  // onFileSelected(event: any) {
  //   console.log(event);
  //   let imgs = new Image()
  //   let isLtsize = false;
  //   imgs.src = window.URL.createObjectURL(event.target.files[0]);

  //   imgs.onload = () => {
  //     console.log(imgs.height);
  //     console.log(imgs.width);
  //     if (147 == imgs.height && imgs.width == 150) {
  //       isLtsize = true;

  //       if (
  //         event.target.files[0].type == 'image/jpeg' ||
  //         event.target.files[0].type == 'image/jpg' ||
  //         event.target.files[0].type == 'image/png'
  //       ) {
  //         this.fileURL = <File>event.target.files[0];
  //       } else {
  //         this.message.error('Please select only JPEG/ JPG/ PNG file.', '');
  //         this.fileURL = null;
  //         this.data.IMG_URL = '';
  //       }

  //     } else {
  //       this.fileURL = null;
  //       this.data.IMG_URL = ' ';
  //       this.message.error('The image will not fit between the dimensions of ' + 147 + ' ' + 'px Height  ' + ' And ' + ' ' + 150 + ' px Width ', '');
  //     }
  //   }
  // }


  onFileSelected(event:any) {
    console.log(event);

    if (event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/png") {
      this.fileURL = <File>event.target.files[0];

    } else {
      this.message.error("Select Only JPEG/ JPG/ PNG File.", "");
      this.fileURL = null;
      this.data.IMG_URL= '';

    }
  }

  removeImage() {
    this.data.IMG_URL = '';
    this.fileURL = '';
  }
  omit(event: any) { const charCode = (event.which) ? event.which : event.keyCode; if (charCode > 31 && (charCode < 48 || charCode > 57)) { return false; } return true; }

  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) > 0;

}
