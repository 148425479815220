import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TrainingSchedule } from 'src/app/Models/trainingschedule';
import { TrainigPointMap, TrainingSubMaster } from 'src/app/Models/trainingsubmaster';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { InductionAreaMaster } from 'src/app/Models/inductionareamaster';
import { DatePipe } from '@angular/common';
import { TrainerMaster } from 'src/app/Models/trainermaster';
import { ApiService } from 'src/app/Service/api.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-addtrainingschedule',
  templateUrl: './addtrainingschedule.component.html',
  styleUrls: ['./addtrainingschedule.component.css']
})

export class AddtrainingscheduleComponent implements OnInit {
  @Input() drawerClose!: Function;
  @Input() data: TrainingSchedule = new TrainingSchedule();
  @Input() drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  posnopatt = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  namepatt = /^[a-zA-Z \-\']+/
  mobpattern = /^[6-9]\d{9}$/
  today = new Date();
  START_TIME: any = null;
  END_TIME: any = null;
  subjects: TrainingSubMaster[] = [];
  employees: EmployeeMaster[] = [];
  indareas: InductionAreaMaster[] = [];
  trainers: TrainerMaster[] = [];
  accessor: TrainerMaster[] = [];
  subpoints: TrainigPointMap[] = [];
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);

  constructor(private api5: ApiService, private api4: ApiService, private api3: ApiService, private api2: ApiService, private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.loadSubjects();
    this.loadindarea();
    this.loadTrainer();
    this.loadAccessor();
  }

  loadSubjects() {
    this.api2.getAlltrainingSubject(0, 0, '', '', 'AND IS_ACTIVE!=false').subscribe(data => {
      this.subjects = data['data'];

    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  loadindarea() {
    this.api4.getAllinductionArea(0, 0, '', '', 'AND IS_ACTIVE!=false').subscribe(data => {
      this.indareas = data['data'];

    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  loadTrainer() {
    this.api5.getAlltrainer(0, 0, 'NAME', 'asc', " AND IS_ACTIVE=1 AND ROLE_ID=18").subscribe(data => {
      if (data['code'] == 200) {
        this.trainers = data['data'];
      }

    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  loadAccessor() {
    this.api5.getAlltrainer(0, 0, 'NAME', 'asc', " AND IS_ACTIVE=1 AND ROLE_ID=19").subscribe(data => {
      if (data['code'] == 200) {
        this.accessor = data['data'];
      }

    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  changeFrom(event) { }

  loadOrganizations() {
    this.isSpinning = false;
  }

  loadRoles() {
    this.isSpinning = false;
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    this.isOk = true;
    this.isSpinning = false;

    if (this.data.PROGRAM_TITLE != undefined) {
      if (this.data.PROGRAM_TITLE.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.PROGRAM_TITLE)) {
          this.isOk = false;
          this.message.error("Please Enter Valid Program Title", "");
        }

      } else {
        this.isOk = false;
        this.message.error("Please Enter Valid Program Title", "");
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Valid Program Title", "");
    }

    if (this.data.SUBJECT_ID == undefined || this.data.SUBJECT_ID == 0) {
      this.isOk = false;
      this.message.error('Please Select Valid Subject', '');
    }

    if (this.data.TRAINER_ID == undefined || this.data.TRAINER_ID == 0) {
      this.isOk = false;
      this.message.error('Please Select Valid Trainer', '');
    }

    if (this.data.ASSESSOR_ID == undefined || this.data.ASSESSOR_ID == null) {
      this.isOk = false;
      this.message.error('Please Select Valid Assessor', '');
    }

    if (this.data.DATE == undefined || this.data.DATE == null) {
      this.isOk = false;
      this.message.error('Please Select Valid Date', '');
    }

    if (this.data.START_TIME == undefined || this.data.START_TIME == null) {
      this.isOk = false;
      this.message.error('Please Select Valid Start Time', '');
    }

    if (this.data.END_TIME == undefined || this.data.END_TIME == null) {
      this.isOk = false;
      this.message.error('Please Select Valid End Time', '');
    }

    if (this.data.ASSESSMENT_TYPE == undefined || this.data.ASSESSMENT_TYPE == "") {
      this.isOk = false;
      this.message.error('Please Select Valid Assessment Type', '');
    }

    if (this.data.VENUE == undefined || this.data.VENUE == null) {
      this.isOk = false;
      this.message.error('Please Enter Valid Venue', '');
    }

    if (this.data.STATUS == undefined || this.data.STATUS == null) {
      this.isOk = false;
      this.message.error('Please Select Valid Status', '');
    }

    if (this.isOk) {
      this.isSpinning = true;

      if (this.data.DATE == undefined)
        this.data.DATE = null;
      else
        this.data.DATE = this.datePipe.transform(this.data.DATE, "yyyy-MM-dd");

      if (this.data.START_TIME == undefined)
        this.data.START_TIME = null;
      else
        this.data.START_TIME = this.datePipe.transform(this.data.START_TIME, "HH:mm");

      if (this.data.END_TIME == undefined)
        this.data.END_TIME = null;
      else
        this.data.END_TIME = this.datePipe.transform(this.data.END_TIME, "HH:mm");

      this.data.DATE = this.datePipe.transform(this.data.DATE, "yyyy-MM-dd");

      if (this.data.ID) {
        this.api.updatetrainingSchedule(this.data).subscribe(successCode => {
          if (successCode.code == 200) {
            this.message.success("Training Schedule Updated Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

          } else {
            this.message.error("Training Schedule Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createtrainingSchedule(this.data).subscribe(successCode => {
          if (successCode.code == 200) {
            this.message.success("Training Schedule Created Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(myForm);

            else
              this.data = new TrainingSchedule();

          } else {
            this.message.error("Training Schedule Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }
}