import { Component, Input, OnInit } from '@angular/core';
import { WebsiteBannerMaster } from 'src/app/Models/websitebannermaster copy';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { Federationwisecommittee } from 'src/app/Models/Federation wise committee';

@Component({
  selector: 'app-addfederationcommittee',
  templateUrl: './addfederationcommittee.component.html',
  styleUrls: ['./addfederationcommittee.component.css']
})
export class AddfederationcommitteeComponent implements OnInit {

  
  @Input()
  drawerClose!: Function;
  @Input()
  data: Federationwisecommittee = new Federationwisecommittee();
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  // imgurl = this.api.imgUrl + "banner";
  imgurl = this.api.retriveimgUrl + "/banner/";
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200px',
    // minHeight: '12rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    // placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
    ],
    customClasses: [],
    uploadUrl: '',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fonts', 'uploadUrl'],
      ['video']
    ]
  };


  // namepatt=/^[a-zA-Z \-\']+/
  fileURL: any;
  height: any;
  width: any;
  constructor(
    private api: ApiService,
    private message: NzNotificationService,
    public datePipe: DatePipe
  ) { }

  ngOnInit(): void { }

  close(): void {
    this.drawerClose();
  }

  resetDrawer(websitebannerPage: NgForm) {
    this.data = new Federationwisecommittee();
    websitebannerPage.form.markAsPristine();
    websitebannerPage.form.markAsUntouched();
  }
  //save
  save(addNew: boolean, websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;
    if (
      this.data.FEDERATION_NAME.trim() == '' && this.data.GROUP_NAME.trim() == '' &&
      this.data.MEMBER_NAME.trim() == '' && this.data.POSITION.trim() == '' && this.data.SEQ_NO != undefined &&
      this.data.IMG_URL.trim() == ''
    ) {
      this.isOk = false;
      this.message.error('Please Fill All The Information', '');
    }

    else if (!this.namepatt.test(this.data.FEDERATION_NAME)) {
      this.isOk = false;
      this.message.error('Please Select Fedration Name', '');
    }

    else
    if (this.data.GROUP_NAME == null || this.data.GROUP_NAME.trim() == '') {
      this.isOk = false
      this.message.error('Please Fill Group Name', '')
    }

     else
    if (this.data.MEMBER_NAME == null || this.data.MEMBER_NAME.trim() == '') {
      this.isOk = false
      this.message.error('Please Fill  Member Name', '')
    }

    else
    if (this.data.POSITION == null || this.data.POSITION.trim() == '') {
      this.isOk = false
      this.message.error('Please Fill Position', '')
    }


    else if (this.data.SEQ_NO == null || this.data.SEQ_NO <= 0) {
      this.isOk = false;
      this.message.error('Please Enter Sequence No', '');
    } else if (
      this.data.IMG_URL == undefined ||
      this.data.IMG_URL.trim() == ''
    ) {
      this.isOk = false;
      this.message.error('Please Select Image', '');
    }

    if (this.isOk) {
      this.isSpinning = true;
      {
        if (this.fileURL != null) {
          var number = Math.floor(100000 + Math.random() * 900000);
          var fileExt = this.fileURL.name.split('.').pop();
          var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
          var url = '';
          url = d == null ? '' : d + number + '.' + fileExt;
          if (
            this.data.IMG_URL != undefined &&
            this.data.IMG_URL.trim() != ''
          ) {
            var arr = this.data.IMG_URL.split('/');
            if (arr.length > 1) {
              url = arr[5];
            }
          }

          this.api
            .onUpload2('banner', this.fileURL, url)
            .subscribe((successCode) => {
              if (successCode["code"] == 200) {
                this.data.IMG_URL = url;
                // appkeys.retriveimgUrl + 'WebsiteBannerImage/' + url;
                if (this.data.ID) {
                  this.api
                    .updatewebsiteBanner(this.data)
                    .subscribe((successCode) => {
                      if (successCode["code"] == 200) {
                        this.message.success(
                          'Information updated successfully', ''
                        );
                        this.fileURL=null;
                        if (!addNew) this.drawerClose();
                        this.isSpinning = false;
                      } else {
                        this.message.error('Failed to information', '');
                        this.isSpinning = false;
                      }
                    });
                } else {
                  this.api
                    .createwebsiteBanner(this.data)
                    .subscribe((successCode) => {
                      if (successCode.code == '200') {
                        this.message.success('Information added successfully', '');
                        this.fileURL=null;
                        if (!addNew) this.drawerClose();
                        else {
                          this.data = new Federationwisecommittee();
                          this.resetDrawer(websitebannerPage);

                          this.data.STATUS == true;
                          // this.data.IMG_URL==null;
                          this.fileURL = '';
                          this.api.getAllwebsiteBanner(1, 1, 'SEQ_NO', 'desc', '').subscribe(data => {
                            if (data['count'] == 0) {
                              this.data.SEQ_NO = 1;
                            } else {
                              this.data.SEQ_NO = data['data'][0]['SEQ_NO'] + 1;
                            }
                          }, err => {
                            console.log(err);
                          })
                        }
                        this.isSpinning = false;
                      } else {
                        this.message.error('Failed to store information', '');
                        this.isSpinning = false;
                      }
                    });
                }
              } else {
                this.message.error('Failed to add image', '');
                this.isSpinning = false;
              }
            });
        } else if (this.data.IMG_URL == null || this.data.IMG_URL == '') {
          this.message.error('Please select image for banner', '');
          this.isSpinning = false;
        } else {
          if (this.data.ID) {
            this.api.updatewebsiteBanner(this.data).subscribe((successCode) => {
              if (successCode["code"] == 200) {
                this.message.success('Information updated successfully', '');
                if (!addNew) this.drawerClose();
                this.isSpinning = false;
              } else {
                this.message.error('Failed to update information', '');
                this.isSpinning = false;
              }
            });
          } else {
            this.api.createwebsiteBanner(this.data).subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information added successfully', '');
                if (!addNew) this.drawerClose();
                else {
                  this.data = new Federationwisecommittee();
                }
                this.isSpinning = false;
              } else {
                this.message.error('Failed to store information', '');
                this.isSpinning = false;
              }
            });
          }
        }
      }
    }

    //  else
    //  {
    //    this.message.error("Please Fill All Required Fields...","");
    //    this.isSpinning = false;

    //  }
  }
  ////
  ////
  //Choose image
  onFileSelected(event: any) {
    console.log(event);
    let imgs = new Image()
    let isLtsize = false;
    imgs.src = window.URL.createObjectURL(event.target.files[0]);

    imgs.onload = () => {
      console.log(imgs.height);
      console.log(imgs.width);
      if (576 == imgs.height && imgs.width == 1500) {
        isLtsize = true;

        if (
          event.target.files[0].type == 'image/jpeg' ||
          event.target.files[0].type == 'image/jpg' ||
          event.target.files[0].type == 'image/png'
        ) {
          this.fileURL = <File>event.target.files[0];
        } else {
          this.message.error('Please select only JPEG/ JPG/ PNG file.', '');
          this.fileURL = null;
          this.data.IMG_URL = '';
        }

      } else {
        this.fileURL = null;
        this.data.IMG_URL = ' ';
        this.message.error('The image will not fit between the dimensions of ' + 700 + ' ' + 'px Height  ' + ' And ' + ' ' + 1520 + ' px Width ', '');
      }
    }
  }

  removeImage() {
    this.data.IMG_URL = '';
    this.fileURL = '';

  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) { return false; } return true;
  }

}
