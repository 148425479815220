import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-attendancereport',
  templateUrl: './attendancereport.component.html',
  styleUrls: ['./attendancereport.component.css'],
  providers: [DatePipe]
})

export class AttendancereportComponent implements OnInit {
  dateFormat = "dd/MMM/yyyy";
  formTitle = "Attendance Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  excelDataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  isSpinning = false
  columns: string[][] = [["DATE", "Date"], ["IN_TIME", "In Time"], ["IN_LOCATION", "In Location"], ["IN_DISTANCE", "In Distance"], ["OUT_TIME", "Out Time"], ["OUT_LOCATION", "Out Location"], ["OUT_DISTANCE", "Out Distance"], ["WORKING_HOURS", "Working Hours"], ["STATUS", "Status"], ["DAYIN_REASON", "Day In Reason"], ["DAYOUT_REASON", "Day Out Reason"]];
  loadingFilterEmployees = true;
  filterClass: string = "filter-visible";
  initFilter = true;
  filterStatus = []
  filterEmployees: EmployeeMaster[];
  employeeFilter = [];
  filterStatusValue: string[] = [];
  date: Date[] = [];
  date1 = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + 1;

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService, private cookie: CookieService) { }

  ngOnInit() {
    this.date[0] = new Date(this.date1);
    this.date[1] = new Date();
    this.search(true);
    this.loadDataFilter();
    this.getBranch();
    this.getDepartment();

    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    if (this.searchText != "") {
      this.filterQuery += " AND (";
      var likeQuery = " ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like ('%" + this.searchText + "%') OR";
      });

      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + " ) ";
    }

    var empFilterStr = "";
    if (this.employeeFilter.length > 0) {
      empFilterStr = " AND EMPLOYEE_ID IN (" + this.employeeFilter + ")";
    }

    var statusFilterStr = "";
    if (this.filterStatusValue.length > 0) {
      statusFilterStr = " AND STATUS IN (" + this.filterStatusValue + ")";
    }

    var dateFilter = "";
    if ((this.date != undefined) && (this.date.length != 0)) {
      dateFilter = " AND (DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    var branchFilterStr = "";
    if (this.BRANCH_ID.length > 0) {
      branchFilterStr = " AND BRANCH_ID IN (" + this.BRANCH_ID + ")";
    }

    var departmentFilterStr = "";
    if (this.DEPARTMENT_ID.length > 0) {
      departmentFilterStr = " AND DEPARTMENT_ID IN (" + this.DEPARTMENT_ID + ")";
    }

    this.api.getAttendanceReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + empFilterStr + statusFilterStr + dateFilter + branchFilterStr + departmentFilterStr).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });

    // Getting Data for Excel
    this.api.getAttendanceReport(0, 0, this.sortKey, sort, this.filterQuery + empFilterStr + statusFilterStr + dateFilter + branchFilterStr + departmentFilterStr).subscribe(data => {
      if (data['code'] == 200) {
        this.excelDataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  onKeypressEvent(reset: boolean = false) {
    document.getElementById('button').focus();
    this.search(true);
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
  }

  getLocation(value: string) {
    value = value.replace('_', ",")
    window.open("http://maps.google.com/maps?q=" + value, "_blank");
  }

  getName(STATUS) {
    if (STATUS == "P")
      return "Present";

    else if (STATUS == "L")
      return "Leave";

    else if (STATUS == "A")
      return "Absent";

    else if (STATUS == "HF")
      return "Halfday";

    else if (STATUS == "H")
      return "Holiday";

    else if (STATUS == "LM")
      return "Latemark";

    else
      return "";
  }

  loadDataFilter() {
    this.loadingFilterEmployees = true;

    this.api.getAllEmployees(0, 0, 'NAME', 'asc', '').subscribe(employees => {
      if (employees['code'] == 200) {
        this.filterEmployees = employees['data'];
        this.loadingFilterEmployees = false;
      }

    }, err => {
      console.log(err);
    });
  }

  clearFilter() {
    this.date = [];
    this.filterStatusValue = [];
    this.employeeFilter = [];
    this.BRANCH_ID = [];
    this.DEPARTMENT_ID = [];
    this.filterClass = "filter-invisible";
    this.isFilterApplied = "default";
    this.filterQuery = "";
    this.search(true);
  }

  applyFilter() {
    if ((this.date.length > 0) || (this.filterStatusValue.length > 0) || (this.employeeFilter.length > 0) || (this.BRANCH_ID.length > 0) || (this.DEPARTMENT_ID.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  getTimeInAM_PM(time) {
    return this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + time;
  }

  BRANCH_ID = [];
  DEPARTMENT_ID = [];
  branch = [];
  department = [];
  orgId = this.cookie.get('orgId');

  getBranch() {
    this.branch = [];

    this.api.getAllBranch(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.branch = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getDepartment() {
    this.department = [];

    this.api.getAllDepartments(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.department = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }
}
