export class Socialfeeds  {

  UNIT_NAME :string='';
  HOME_GROUP_NAME: string='';
  HASHTAGS: string='';
  FEDERATION_NAME: string='';
  DESCRIPTION :string='';
  CREATER_MEMBER_NAME: string='';
  WEB_POST_STATUS: boolean=true;



}
