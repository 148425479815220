import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { FederationCount } from 'src/app/Models/FederationCount';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-listfederation',
  templateUrl: './listfederation.component.html',
  styleUrls: ['./listfederation.component.css']
})
export class ListfederationComponent implements OnInit {

  formTitle = "Federation";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [["WEB_FEDERATION_STATUS", "Show In Web"],["NAME", "Name"],["FEDERATION_PRESIDENT", "President Name"],];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: FederationCount = new FederationCount();
  isSpinning = false;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search(true);
  }
  keyup(event: any) {
    this.search();
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      this.sortValue = this.sortValue.startsWith("a") ? "asc" : "desc";
      console.log("an", this.sortValue);
    }
    catch (error) { sort = ""; }

    var likeQuery = ""; if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    this.loadingRecords = true;

    this.api.getfedration(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, likeQuery).
      subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
  }



  sort(sort: { key: string; value: string }):
    void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    console.log("SORT", this.sortValue);
    this.search(true);
  }




  today = new Date().setDate(new Date().getDate() + 4);

  // add(): void {
  //   this.drawerTitle = "Add Group Target Area";
  //   this.drawerData = new letestnews();
  //   this.drawerVisible = true;
  //   this.drawerData.STATUS = true;
  // }

  // edit(data: letestnews): void {
  //   this.drawerTitle = "Update Group Target Area";
  //   this.drawerData = Object.assign({}, data);
  //   this.drawerVisible = true;
  // }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }

  onStatusChange(data: FederationCount, status) {
    data.WEB_FEDERATION_STATUS = status;

    this.api.updatefedration(data).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Status", "");

      this.search();
    });
  }
}
