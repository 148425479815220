export class WorldChairperson {
    // ID:number = 0;
    // NAME:string = '';
   
    // ICON:string = '';
    // LINK:string = '';
    // STATUS:boolean=true;
    // SEQUENCE_NO:number = 0;






    ID:number=0;
    NAME:string='';
    POSOTION:string=''
    IMG_URL:string=''
    STATUS:boolean=true;
    SEQ_NO:number=0;








  }
  