export class PhotoMaster {

    ID:number=0;
    ALBUM_ID:number=0;
    TITLE:string='';
    TITLE_MR:string='';
    IMG_URL:string='';
    STATUS:boolean=true;
    SEQUENCE_NO:number=0 ;
}
