import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
// import { appkeys } from 'src/app/constant';
import { AboutMaster } from 'src/app/Models/AboutMaster';
import { ApiService } from 'src/app/Service/api.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-about-walfare',
  templateUrl: './about-walfare.component.html',
  styleUrls: ['./about-walfare.component.css']
})
export class AboutWalfareComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  data: AboutMaster = new AboutMaster;
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // namepatt=/^[a-zA-Z \-\']+/
  namepatt = /[a-zA-Z][a-zA-Z ]+/
  mobpattern = /^[6-9]\d{9}$/
  fileURL: any;



  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '300px',
    // minHeight: '12rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
    ],
    customClasses: [],
    uploadUrl: '',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fonts', 'uploadUrl'],
      ['video']
    ]
  };




  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    // this.loadalbume();
  }


  close(): void {
    this.drawerClose();
  }
  resetDrawer(websitebannerPage: NgForm) {
    websitebannerPage.form.reset();
  }

  //save
  save(addNew: boolean, websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;

    if (this.data.HISTORY_TITLE.trim() == "" && this.data.GIANTS_STANDS_FOR_TITLE.trim() == "" && this.data.AIMS_AND_OBJECT_TITLE.trim() == ""
      && this.data.HISTORY_DESCRIPTION.trim() == "" && this.data.GIANTS_STANDS_FOR_DESCRIPTION.trim() == "" && this.data.AIMS_AND_OBJECT_DESCRIPTION.trim() == ""
      && this.data.SEQUENCE_NO != undefined) {
      this.isOk = false
      this.message.error("Please Fill All The Information......", "");
    }
    else
      if (this.data.HISTORY_TITLE == null || this.data.HISTORY_TITLE.trim() == '') {
        this.isOk = false
        this.message.error('Please Enter the History Title', '')

      }

      else
        if (this.data.HISTORY_DESCRIPTION == null || this.data.HISTORY_DESCRIPTION.trim() == '') {
          this.isOk = false
          this.message.error('Please Enter the History Description', '')

        }


        else
          if (this.data.GIANTS_STANDS_FOR_TITLE == null || this.data.GIANTS_STANDS_FOR_TITLE.trim() == '') {
            this.isOk = false
            this.message.error('Please Enter the Giants Stands For Title', '')

          }

          else
            if (this.data.GIANTS_STANDS_FOR_DESCRIPTION == null || this.data.GIANTS_STANDS_FOR_DESCRIPTION.trim() == '') {
              this.isOk = false
              this.message.error('Please Enter the giants Stands For Description', '')

            }

            else
              if (this.data.AIMS_AND_OBJECT_TITLE == null || this.data.AIMS_AND_OBJECT_TITLE.trim() == '') {
                this.isOk = false
                this.message.error('Please Enter the Aims & Objective Title', '')

              }


              else
                if (this.data.AIMS_AND_OBJECT_DESCRIPTION == null || this.data.AIMS_AND_OBJECT_DESCRIPTION.trim() == '') {
                  this.isOk = false
                  this.message.error('Please Enter Aims And objective Description', '')

                }



    if (this.data.SEQUENCE_NO == undefined || this.data.SEQUENCE_NO <= 0) {
      this.isOk = false
      this.message.error(' please Enter Sequence Number ', '')
    }


    if (this.isOk) {


      this.isSpinning = true;

      {
        if (this.data.ID) {
          this.api.updataboutus(this.data).subscribe((successCode) => {
            if (successCode["code"] == '200') {
              this.message.success('Information Updated successfully...', '');
              if (!addNew) this.drawerClose();
              this.isSpinning = false;
            } else {
              this.message.error('Failed to Update information...', '');
              this.isSpinning = false;
            }
          });
        }
        else {
          this.api.createaboutus(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information added successfully......', '');
              if (!addNew) this.drawerClose();
              else {
                this.data = new AboutMaster();
                this.resetDrawer(websitebannerPage);

                // this.data.STATUS == true;
                // this.data.STATUS == true;


                this.api.getaboutus(1, 1, ' SEQUENCE_NO', 'desc', '').subscribe(data => {
                  if (data['count'] == 0) {
                    this.data.SEQUENCE_NO = 1;
                  } else {
                    this.data.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
                  }
                },
                  (err) => {
                    console.log(err);
                  }
                );
              }
              this.isSpinning = false;
            } else {
              this.message.error('Failed to store  information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


}


