import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { Messages } from 'src/app/Models/Messages';
// import { AboutMaster } from 'src/app/Models/AboutMaster';
import { ApiService } from 'src/app/Service/api.service';
// import { NzTableQueryParams } from 'ng-zorro-antd/table';
// import { appkeys } from 'src/app/app.constant';
// import { ClientmasterService } from 'src/app/clientmaster.service';
// import { AboutMaster } from 'src/Models/AboutMaster';

@Component({
  selector: 'app-listmessages',
  templateUrl: './listmessages.component.html',
  styleUrls: ['./listmessages.component.css']
})
export class ListmessagesComponent implements OnInit {


  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: Messages = new Messages();
  formTitle = " Message  ";
  @Input()
  dataList: any[] = [];
  // dataList = [];
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["SEQUENCE_NO", " Seq No "],["NAME", "Name"],["GROUP_NAME", "Group Name"], ["MESSAGE_TITLE", "Message Title"],];
  @Input()
  drawerClose2!: Function;
  // imgurl= appkeys.retriveimgUrl;

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit(): void {
    this.loadingRecords = false;
    this.search()

  }

  keyup(event: any) {
    this.search();
  }

  // search(reset: boolean = false) 
  // {
  //   if (reset) {
  //     this.pageIndex = 1;
  //     this.sortKey = "id";
  //     this.sortValue = "desc"
  //   }
  //   // this.loadingRecords = true;
  //   var sort: string;
  //   try {
  //     sort = this.sortValue.startsWith("a") ? "asc" : "desc";
  //   } catch (error) {
  //     sort = "";
  //   }
  //   var likeQuery = "";
  //   console.log("search text:" + this.searchText);
  //   if (this.searchText != "") {
  //     likeQuery = " AND";
  //     this.columns.forEach(column => {
  //       likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
  //     });
  //     likeQuery = likeQuery.substring(0, likeQuery.length - 2)
  //     console.log("likeQuery" + likeQuery);
  //   }
  //   this.api.getMessage(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
  //     this.loadingRecords = false;
  //     this.totalRecords = data['count'];
  //     this.dataList = data['data'];
  //     console.log(this.dataList)
  //     for (var i=0;i<this.dataList.length;i++){
  //     // console.log(this.imgurl+'AboutImage/'+this.dataList[i]['IMG_URL'])

  //     }
  //     if(this.totalRecords==0){
  //       data.SEQUENCE_NO=1;
  //     }else{
  //       data.SEQUENCE_NO= this.dataList[this.dataList.length-1]['SEQUENCE_NO']+1
  //     }
  //   }, err => {
  //     console.log(err);
  //   });
  // }


  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      this.sortValue = this.sortValue.startsWith("a") ? "asc" : "desc";
      console.log("an", this.sortValue);
    }
    catch (error) { sort = ""; }

    var likeQuery = ""; if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    this.loadingRecords = true;

    this.api.getMessage(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, likeQuery).
      subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
  }



  sort(sort: { key: string; value: string }):
    void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    console.log("SORT", this.sortValue);
    this.search(true);
  }







  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Add Message";
    this.drawerData = new Messages();
    this.api.getMessage(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQUENCE_NO = 1;
      } else {
        this.drawerData.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
      }
    }, err => {
      console.log(err);
    })
    this.drawerVisible = true;
  }
  edit(data: Messages): void {
    this.drawerTitle = "Update Message ";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }


}

// params: NzTableQueryParams
