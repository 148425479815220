import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ExportDirective } from './directives/export.directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component'
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData, AsyncPipe, DatePipe } from '@angular/common';
import en from '@angular/common/locales/en';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ApplicationComponent } from './pages/CommonModule/application/application/application.component';
import { ApplicationsComponent } from './pages/CommonModule/application/applications/applications.component';
import { ModuleComponent } from './pages/CommonModule/module/module/module.component';
import { ModulesComponent } from './pages/CommonModule/module/modules/modules.component';
import { UserComponent } from './pages/CommonModule/user/user/user.component';
import { UsersComponent } from './pages/CommonModule/user/users/users.component';
import { ApplicationModuleMappingComponent } from './pages/CommonModule/application/application-module-mapping/application-module-mapping.component';
import { UserModuleMappingComponent } from './pages/CommonModule/user/user-module-mapping/user-module-mapping.component';
import { DashboardComponent } from './pages/CommonModule/Dashboard/dashboard/dashboard.component';
import { RolesComponent } from './pages/CommonModule/role/roles/roles.component';
import { RoleComponent } from './pages/CommonModule/role/role/role.component';
import { RolemodulemappingComponent } from './pages/CommonModule/role/rolemodulemapping/rolemodulemapping.component';
import { FaqHeadsComponent } from './pages/SupportModule/FaqHead/faq-heads/faq-heads.component'
import { FaqHeadComponent } from './pages/SupportModule/FaqHead/faq-head/faq-head.component'
import { FaqsComponent } from './pages/SupportModule/FAQ/faqs/faqs.component';
import { FaqComponent } from './pages/SupportModule/FAQ/faq/faq.component';
import { FormsComponent } from './pages/CommonModule/form/forms/forms.component'
import { FormComponent } from './pages/CommonModule/form/form/form.component'
import { SelectpageComponent } from './pages/CommonModule/selectpage/selectpage/selectpage.component';
import { RoledetailsComponent } from './pages/CommonModule/role/roledetails/roledetails.component';
import { FaqresponsesComponent } from './pages/SupportModule/FAQ/faqresponses/faqresponses.component';
import { DepartmentComponent } from './pages/SupportModule/Department/department/department.component';
import { DepartmentsComponent } from './pages/SupportModule/Department/departments/departments.component';
// import { DepartmentcalenderComponent } from './pages/SupportModule/Department/departmentcalender/departmentcalender/departmentcalender.component';
import { EmailComponent } from './pages/CommunicationModule/EmailServer/email/email.component';
import { EmailsComponent } from './pages/CommunicationModule/EmailServer/emails/emails.component';
import { HistoryComponent } from './pages/CommunicationModule/EmailServer/history/history.component';
import { SMSHistoryComponent } from './pages/CommunicationModule/SMSServer/history/SMShistory.component';
import { SmsComponent } from './pages/CommunicationModule/SMSServer/sms/sms.component';
import { SmssComponent } from './pages/CommunicationModule/SMSServer/smss/smss.component';
import { FolderComponent } from './pages/DocumentModule/folder/folder/folder.component';
import { FoldersComponent } from './pages/DocumentModule/folder/folders/folders.component';
import { TicketsComponent } from './pages/SupportModule/Tickets/tickets/tickets.component';
import { TicketdetailsComponent } from './pages/SupportModule/Tickets/ticketdetails/ticketdetails.component';
import { UserapplicationmappingComponent } from './pages/CommonModule/user/userapplicationmapping/userapplicationmapping.component';
import { UsermodulemappingComponent } from './pages/CommonModule/user/usermodulemapping/usermodulemapping.component';
import { UseraccessmappingComponent } from './pages/CommonModule/user/useraccessmapping/useraccessmapping.component';
import { UserdepartmentmappingComponent } from './pages/CommonModule/user/userdepartmentmapping/userdepartmentmapping.component';
import { UserrolemappingComponent } from './pages/CommonModule/user/userrolemapping/userrolemapping.component';
import { TaskComponent } from './pages/SchedularModule/Task/task/task.component';
import { TasksComponent } from './pages/SchedularModule/Task/tasks/tasks.component';
import { ViewhistoryComponent } from './pages/SchedularModule/History/viewhistory/viewhistory.component';
import { LogmoduleComponent } from './pages/LogModule/logmodule/logmodule.component';
import { TicketgroupComponent } from './pages/SupportModule/Tickets/ticketgroup/ticketgroup.component';
import { TicketgroupsComponent } from './pages/SupportModule/Tickets/ticketgroups/ticketgroups.component';
import { TestsComponent } from './pages/tests/tests.component';
import { ViewbodyComponent } from './pages/CommunicationModule/EmailServer/viewbody/viewbody.component';
import { TestComponent } from './pages/CommonModule/test/test.component';
import { MyticketComponent } from './pages/UserModule/myticket/myticket.component';
import { CreateticketComponent } from './pages/UserModule/createticket/createticket.component';
import { ViewchatticketComponent } from './pages/UserModule/viewchatticket/viewchatticket.component';
import { TicketreportComponent } from './pages/Reports/ticketreport/ticketreport.component';
import { SummaryreportComponent } from './pages/Reports/summaryreport/summaryreport.component';
import { UserwisesummaryComponent } from './pages/Reports/userwisesummary/userwisesummary.component';
import { DashboardreportComponent } from './pages/Report/dashboardreport/dashboardreport.component';
import { AwsbulksenderComponent } from './pages/CommunicationModule/aws/awsbulksender/awsbulksender.component';
import { AwstemplateComponent } from './pages/CommunicationModule/aws/awstemplate/awstemplate.component';
import { AwsemailsComponent } from './pages/CommunicationModule/aws/awsemails/awsemails.component';
import { AwstemplatesComponent } from './pages/CommunicationModule/aws/awstemplates/awstemplates.component';
import { ChattdetailsicketComponent } from './pages/Reports/chattdetailsicket/chattdetailsicket.component';
import { OrganizationComponent } from './pages/Organizations/organization/organization.component';
import { OrganizationsComponent } from './pages/Organizations/organizations/organizations.component';
import { BranchesComponent } from './pages/BranchMaster/branches/branches.component';
import { BranchComponent } from './pages/BranchMaster/branch/branch.component';
import { DesignationComponent } from './pages/DesignationMaster/designation/designation.component';
import { DesignationsComponent } from './pages/DesignationMaster/designations/designations.component';
// import { AwsbulksenderComponent } from './pages/CommunicationModule/aws/awsbulksender/awsbulksender.component';
// import { AwsemailsComponent } from './pages/CommunicationModule/aws/awsemails/awsemails.component';
// import { AwstemplateComponent } from './pages/CommunicationModule/aws/awstemplate/awstemplate.component';
// import { AwstemplatesComponent } from './pages/CommunicationModule/aws/awstemplates/awstemplates.component';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { TickRoleComponent } from './pages/RoleMaster/tick-role/tick-role.component';
import { TickRolesComponent } from './pages/RoleMaster/tick-roles/tick-roles.component';
import { FeatureComponent } from './pages/FeatureMaster/feature/feature.component';
import { FeaturesComponent } from './pages/FeatureMaster/features/features.component';
import { HelploginComponent } from './helplogin/helplogin.component';
// import { Clusterbranchmasterlistcomponent } from './pages/clusterbranchmaster/clusterbranchmasterlist/clusterbranchmasterlist.component';
// import { AddclusterbranchmasterComponent } from './pages/clusterbranchmaster/addclusterbranchmaster/addclusterbranchmaster.component';
import { EmployeeMaster } from './Models/employeemaster';
import { AddemployeemasterComponent } from './pages/employeemaster/addemployeemaster/addemployeemaster.component';
import { EmployeemasterlistComponent } from './pages/employeemaster/employeemasterlist/employeemasterlist.component';
import { ClustermasterlistComponent } from './pages/clustermaster/clustermasterlist/clustermasterlist.component';
import { AddclustermasterComponent } from './pages/clustermaster/addclustermaster/addclustermaster.component';
import { AddemployeerolemapComponent } from './pages/employeerolemap/addemployeerolemap/addemployeerolemap.component';
import { EmployeerolemaplistComponent } from './pages/employeerolemap/employeerolemaplist/employeerolemaplist.component';
import { EmploginComponent } from './emplogin/emplogin.component';
import { SearchfaqComponent } from './pages/UserModule/searchfaq/searchfaq.component';
import { FolderMasterComponent } from './pages/FolderAndFileModule/Folder/folder-master/folder-master.component';
import { FolderDrawerComponent } from './pages/FolderAndFileModule/Folder/folder-drawer/folder-drawer.component';
import { FileDrawerComponent } from './pages/FolderAndFileModule/Folder/file-drawer/file-drawer.component';

import { SharedFolderMasterComponent } from './pages/FolderAndFileModule/SharedFolder/shared-folder-master/shared-folder-master.component';
import { TransferTicketDrawerComponent } from './pages/SupportModule/Tickets/transfer-ticket-drawer/transfer-ticket-drawer.component';
import { FaqresponsereportComponent } from './pages/Reports/faqresponsereport/faqresponsereport.component';
import { MynotificationsComponent } from './pages/Notify/mynotifications/mynotifications.component';
import { SendnotificationsComponent } from './pages/Notify/sendnotifications/sendnotifications.component';
import { SharingExistingFolderComponent } from './pages/FolderAndFileModule/Folder/sharing-existing-folder/sharing-existing-folder.component';
import { ViewNotificationDrawerComponent } from './pages/NotificationModule/view-notification-drawer/view-notification-drawer.component';
import { AddNewNotificationDrawerComponent } from './pages/NotificationModule/add-new-notification-drawer/add-new-notification-drawer.component';
import { OptionreportComponent } from './pages/Reports/optionreport/optionreport.component';
import { SendEmailDrawerComponent } from './pages/NotificationModule/send-email-drawer/send-email-drawer.component';
import { ChangePasswordDrawerComponent } from './pages/CommonModule/change-password-drawer/change-password-drawer.component';
import { LeavetypesComponent } from './pages/EMM_Module/LeaveType/leavetypes/leavetypes.component';
import { LeavetypeComponent } from './pages/EMM_Module/LeaveType/leavetype/leavetype.component';
import { AddEmployeeLeaveComponent } from './pages/EMM_Module/leave/add-employee-leave/add-employee-leave.component';
import { ExpenseheadComponent } from './pages/EMM_Module/ExpenseHead/expensehead/expensehead.component';
import { ExpensesheadComponent } from './pages/EMM_Module/ExpenseHead/expenseshead/expenseshead.component';
import { AddEmployeeExpensesComponent } from './pages/EMM_Module/expenses/add-employee-expenses/add-employee-expenses.component';
import { EvaluationcriteriaComponent } from './pages/EMM_Module/EvaluationCriteria/evaluationcriteria/evaluationcriteria.component';
import { EvaluationcriteriasComponent } from './pages/EMM_Module/EvaluationCriteria/evaluationcriterias/evaluationcriterias.component';
import { CustomersComponent } from './pages/EMM_Module/Customer/customers/customers.component';
import { CustomerComponent } from './pages/EMM_Module/Customer/customer/customer.component';
import { HolidaysComponent } from './pages/EMM_Module/Holidays/holidays/holidays.component';
import { ThoughsComponent } from './pages/EMM_Module/Though/thoughs/thoughs.component';
import { ThoughComponent } from './pages/EMM_Module/Though/though/though.component';
import { BirthdaysComponent } from './pages/EMM_Module/birthday/birthdays/birthdays.component';
import { BirthdayComponent } from './pages/EMM_Module/birthday/birthday/birthday.component';
import { AddearlymarkComponent } from './pages/EMM_Module/Earlymark/addearlymark/addearlymark.component';
import { AddlatemarkComponent } from './pages/EMM_Module/LateMark/addlatemark/addlatemark.component';
import { EmployeeexpensetypemappingComponent } from './pages/employeemaster/employeeexpensetypemapping/employeeexpensetypemapping.component';
import { LeaveApproveDrawerComponent } from './pages/EMM_Module/leave/leave-approve-drawer/leave-approve-drawer.component';
import { HeadLeaveApproveDrawerComponent } from './pages/EMM_Module/leave/head-leave-approve-drawer/head-leave-approve-drawer.component';
import { HeadLatemarkApproveDrawerComponent } from './pages/EMM_Module/LateMark/head-latemark-approve-drawer/head-latemark-approve-drawer.component';
import { HeadEarlymarkApproveDrawerComponent } from './pages/EMM_Module/Earlymark/head-earlymark-approve-drawer/head-earlymark-approve-drawer.component';
import { EmployeecollegemappingComponent } from './pages/employeemaster/employeecollegemapping/employeecollegemapping.component';
import { AccountantExpensesApproveDrawerComponent } from './pages/EMM_Module/expenses/accountant-expenses-approve-drawer/accountant-expenses-approve-drawer.component';
import { HeadExpensesApproveDrawerComponent } from './pages/EMM_Module/expenses/head-expenses-approve-drawer/head-expenses-approve-drawer.component';
import { AttendancereportComponent } from './pages/EMM_Module/Reports/attendancereport/attendancereport.component';
import { LeavereportComponent } from './pages/EMM_Module/Reports/leavereport/leavereport.component';
import { LatemarkreportComponent } from './pages/EMM_Module/Reports/latemarkreport/latemarkreport.component';
import { EarlymarkreportComponent } from './pages/EMM_Module/Reports/earlymarkreport/earlymarkreport.component';
import { ExpensereportComponent } from './pages/EMM_Module/Reports/expensereport/expensereport.component';
import { EvaluationreportComponent } from './pages/EMM_Module/Reports/evaluationreport/evaluationreport.component';
import { AssetCategoryListComponent } from './pages/materialIssueModule/assetCategory/asset-category-list/asset-category-list.component';
import { AssetCategoryDrawerComponent } from './pages/materialIssueModule/assetCategory/asset-category-drawer/asset-category-drawer.component';
import { AssetListComponent } from './pages/materialIssueModule/asset/asset-list/asset-list.component';
import { AssetDrawerComponent } from './pages/materialIssueModule/asset/asset-drawer/asset-drawer.component';
import { EarlymarkTilesComponent } from './pages/EMM_Module/Earlymark/earlymark-tiles/earlymark-tiles.component';
import { LatemarkTilesComponent } from './pages/EMM_Module/LateMark/latemark-tiles/latemark-tiles.component';
import { LeaveTilesComponent } from './pages/EMM_Module/leave/leave-tiles/leave-tiles.component';
import { ExpensesTilesComponent } from './pages/EMM_Module/expenses/expenses-tiles/expenses-tiles.component';
import { HeadApproveEmployeeLeavesTilesComponent } from './pages/EMM_Module/leave/head-approve-employee-leaves-tiles/head-approve-employee-leaves-tiles.component';
import { ApproveEmployeeLeavesTilesComponent } from './pages/EMM_Module/leave/approve-employee-leaves-tiles/approve-employee-leaves-tiles.component';
import { HeadApproveEmployeeLatemarkTilesComponent } from './pages/EMM_Module/LateMark/head-approve-employee-latemark-tiles/head-approve-employee-latemark-tiles.component';
import { HeadApproveEmployeeEarlymarkTilesComponent } from './pages/EMM_Module/Earlymark/head-approve-employee-earlymark-tiles/head-approve-employee-earlymark-tiles.component';
import { ExpensesApproveFromHeadTilesComponent } from './pages/EMM_Module/expenses/expenses-approve-from-head-tiles/expenses-approve-from-head-tiles.component';
import { ExpensesApproveFromAccountantTilesComponent } from './pages/EMM_Module/expenses/expenses-approve-from-accountant-tiles/expenses-approve-from-accountant-tiles.component';
import { AssetMappingListComponent } from './pages/materialIssueModule/assetMapping/asset-mapping-list/asset-mapping-list.component';
import { AssetMappingDrawerComponent } from './pages/materialIssueModule/assetMapping/asset-mapping-drawer/asset-mapping-drawer.component';
import { AssetRequestListComponent } from './pages/materialIssueModule/assetRequest/asset-request-list/asset-request-list.component';
import { AssetRequestDrawerComponent } from './pages/materialIssueModule/assetRequest/asset-request-drawer/asset-request-drawer.component';
import { AttendanceregisterComponent } from './pages/EMM_Module/Reports/attendanceregister/attendanceregister.component';
import { UpdateAttendanceRegisterComponent } from './pages/EMM_Module/Reports/update-attendance-register/update-attendance-register.component';
import { TrainingsublistComponent } from './pages/TrainingModule/trainingsubmaster/trainingsublist/trainingsublist.component';
import { AddtrainingsubComponent } from './pages/TrainingModule/trainingsubmaster/addtrainingsub/addtrainingsub.component';
import { TrainermasterlistComponent } from './pages/TrainingModule/trainermaster/trainermasterlist/trainermasterlist.component';
import { AddtrainermasterComponent } from './pages/TrainingModule/trainermaster/addtrainermaster/addtrainermaster.component';
import { TrainingtypelistComponent } from './pages/TrainingModule/trainingtypemaster/trainingtypelist/trainingtypelist.component';
import { AddtrainingtypeComponent } from './pages/TrainingModule/trainingtypemaster/addtrainingtype/addtrainingtype.component';
import { AnnualtrainpointlistComponent } from './pages/TrainingModule/annualtrainpoint/annualtrainpointlist/annualtrainpointlist.component';
import { AddannualtrainpointComponent } from './pages/TrainingModule/annualtrainpoint/addannualtrainpoint/addannualtrainpoint.component';
import { TrainingtrainerMapComponent } from './pages/TrainingModule/annualtrainpoint/trainingtrainermap/trainingtrainermap.component';
import { TrainingpointmasterlistComponent } from './pages/TrainingModule/trainingpointmaster/trainingpointmasterlist/trainingpointmasterlist.component';
import { AddtrainingpointmasterComponent } from './pages/TrainingModule/trainingpointmaster/addtrainingpointmaster/addtrainingpointmaster.component';
import { TrainingschedulelistComponent } from './pages/TrainingModule/trainingschedule/trainingschedulelist/trainingschedulelist.component';
import { AddtrainingscheduleComponent } from './pages/TrainingModule/trainingschedule/addtrainingschedule/addtrainingschedule.component';
import { TrainattendmapComponent } from './pages/TrainingModule/trainingschedule/trainattendmap/trainattendmap.component';
import { InductionarealistComponent } from './pages/TrainingModule/inductionareamaster/inductionarealist/inductionarealist.component';
import { AddinductionareaComponent } from './pages/TrainingModule/inductionareamaster/addinductionarea/addinductionarea.component';
import { TrainfeedformmasterlistComponent } from './pages/TrainingModule/trainingfeedbackformmaster/trainfeedformmasterlist/trainfeedformmasterlist.component';
import { AddtrainfeedformmasterComponent } from './pages/TrainingModule/trainingfeedbackformmaster/addtrainfeedformmaster/addtrainfeedformmaster.component';
import { TrainingevalutionformlistComponent } from './pages/TrainingModule/trainingevalutionform/trainingevalutionformlist/trainingevalutionformlist.component';
import { AddtrainingevalutionformComponent } from './pages/TrainingModule/trainingevalutionform/addtrainingevalutionform/addtrainingevalutionform.component';
import { TrainfeedmasterlistComponent } from './pages/TrainingModule/trainingfeedbackmaster/trainfeedmasterlist/trainfeedmasterlist.component';
import { AddtrainfeedmasterComponent } from './pages/TrainingModule/trainingfeedbackmaster/addtrainfeedmaster/addtrainfeedmaster.component';
import { TrainattendancecardlistComponent } from './pages/TrainingModule/trainattendancecard/trainattendancecardlist/trainattendancecardlist.component';
import { AddtrainattendancecardComponent } from './pages/TrainingModule/trainattendancecard/addtrainattendancecard/addtrainattendancecard.component';
import { AttendcardComponent } from './pages/TrainingModule/trainattendancecard/attendcard/attendcard.component';
import { TrainerloginviewComponent } from './pages/TrainingModule/trainerlogin/trainerloginview/trainerloginview.component';
import { TrainerloginviewdrawerComponent } from './pages/TrainingModule/trainerlogin/trainerloginviewdrawer/trainerloginviewdrawer.component';
import { AccessorviewComponent } from './pages/TrainingModule/accessor/accessorview/accessorview.component';
import { AccessorviewdrawerComponent } from './pages/TrainingModule/accessor/accessorviewdrawer/accessorviewdrawer.component';
import { AssetRegisterComponent } from './pages/materialIssueModule/Reports/asset-register/asset-register.component';
import { AssetAllocationRegisterComponent } from './pages/materialIssueModule/Reports/asset-allocation-register/asset-allocation-register.component';
import { AssetMaintenanceRegisterComponent } from './pages/materialIssueModule/Reports/asset-maintenance-register/asset-maintenance-register.component';
import { AssetCategoryRegisterComponent } from './pages/materialIssueModule/Reports/asset-category-register/asset-category-register.component';
import { AssetRegisterForAssetCategoryRegisterComponent } from './pages/materialIssueModule/Reports/asset-register-for-asset-category-register/asset-register-for-asset-category-register.component';
import { NgxPrintModule } from 'ngx-print';
import { TrainerAccessorLoginComponent } from '../app/trainer-accessor-login/trainer-accessor-login.component';
import { ModelMasterComponent } from './pages/SalesModule/model/model-master/model-master.component';
import { ModelDrawerComponent } from './pages/SalesModule/model/model-drawer/model-drawer.component';
import { VarientMasterComponent } from './pages/SalesModule/varient/varient-master/varient-master.component';
import { VarientDrawerComponent } from './pages/SalesModule/varient/varient-drawer/varient-drawer.component';
import { PriceListMasterComponent } from './pages/SalesModule/priceList/price-list-master/price-list-master.component';
import { PriceListDrawerComponent } from './pages/SalesModule/priceList/price-list-drawer/price-list-drawer.component';
import { PriceListMappingComponent } from './pages/SalesModule/priceList/price-list-mapping/price-list-mapping.component';
import { FederationMasterComponent } from './pages/FederationMaster/federation-master/federation-master.component';
import { FederationDrawerComponent } from './pages/FederationMaster/federation-drawer/federation-drawer.component';
import { AssignFederationMembersComponent } from './pages/FederationMaster/assign-federation-members/assign-federation-members.component';
import { UnitMasterComponent } from './pages/UnitMaster/unit-master/unit-master.component';
import { UnitDrawerComponent } from './pages/UnitMaster/unit-drawer/unit-drawer.component';
import { GroupMasterComponent } from './pages/GroupMaster/group-master/group-master.component';
import { GroupDrawerComponent } from './pages/GroupMaster/group-drawer/group-drawer.component';
import { InchargeAreaMasterComponent } from './pages/InchargeAreaMaster/incharge-area-master/incharge-area-master.component';
import { InchargeAreaDrawerComponent } from './pages/InchargeAreaMaster/incharge-area-drawer/incharge-area-drawer.component';
import { ProjectMasterComponent } from './pages/ProjectMaster/project-master/project-master.component';
import { ProjectDrawerComponent } from './pages/ProjectMaster/project-drawer/project-drawer.component';
import { MemberMasterComponent } from './pages/MemberMaster/member-master/member-master.component';
import { MemberDrawerComponent } from './pages/MemberMaster/member-drawer/member-drawer.component';
import { AssignUnitMemberComponent } from './pages/UnitMaster/assign-unit-member/assign-unit-member.component';
import { AssignGroupMemberComponent } from './pages/GroupMaster/assign-group-member/assign-group-member.component';
import { GroupmeetingattendanceComponent } from './pages/Meeting/groupmeetingattendance/groupmeetingattendance.component';
import { AddgroupmeetingattendanceComponent } from './pages/Meeting/addgroupmeetingattendance/addgroupmeetingattendance.component';
import { GroupmeetattendiesmapComponent } from './pages/Meeting/groupmeetattendiesmap/groupmeetattendiesmap.component';
import { UnitdobComponent } from './pages/GiantReports/unitdob/unitdob.component';
import { GroupbodComponent } from './pages/GiantReports/groupbod/groupbod.component';
import { GroupActivityReportComponent } from './pages/GiantReports/group-activity-report/group-activity-report.component';
import { UnitmembershipsummaryComponent } from './pages/GiantReports/unitmembershipsummary/unitmembershipsummary.component';
import { GroupMeetingReportComponent } from './pages/GiantReports/group-meeting-report/group-meeting-report.component';
import { GroupMembershipMonthwiseComponent } from './pages/GiantReports/group-membership-monthwise/group-membership-monthwise.component';
import { ViewGroupMeetingAttendiesComponent } from './pages/GiantReports/view-group-meeting-attendies/view-group-meeting-attendies.component';
import { ViewUnitBodComponent } from './pages/GiantReports/view-unit-bod/view-unit-bod.component';
import { ViewGroupBodComponent } from './pages/GiantReports/view-group-bod/view-group-bod.component';
import { GroupProjectActivityMasterComponent } from './pages/GroupProjectActivity/group-project-activity-master/group-project-activity-master.component';
import { GroupProjectActivityDrawerComponent } from './pages/GroupProjectActivity/group-project-activity-drawer/group-project-activity-drawer.component';
import { MemberPaymentDrawerComponent } from './pages/MemberMaster/member-payment-drawer/member-payment-drawer.component';
import { ManageFederationMembersComponent } from './pages/FederationMaster/manage-federation-members/manage-federation-members.component';
import { ManageUnitMembersComponent } from './pages/UnitMaster/manage-unit-members/manage-unit-members.component';
import { ManageGroupMembersComponent } from './pages/GroupMaster/manage-group-members/manage-group-members.component';
import { ResigsterComponent } from './register-page/register-page.component';
import { MemberWiseSummaryComponent } from './pages/GiantReports/member-wise-summary/member-wise-summary.component';
import { FederationWiseMemberCountComponent } from './pages/GiantReports/federation-wise-member-count/federation-wise-member-count.component';
import { UnitWiseMemberCountComponent } from './pages/GiantReports/unit-wise-member-count/unit-wise-member-count.component';
import { GroupWiseMemberCountComponent } from './pages/GiantReports/group-wise-member-count/group-wise-member-count.component';
import { MemberUploadComponent } from './pages/member-upload/member-upload.component';
import { InchargeActivityMasterComponent } from './pages/InchargeActivity/incharge-activity-master/incharge-activity-master.component';
import { InchargeActivityDrawerComponent } from './pages/InchargeActivity/incharge-activity-drawer/incharge-activity-drawer.component';
import { InchargeMeetingDrawerComponent } from './pages/InchargeMeeting/incharge-meeting-drawer/incharge-meeting-drawer.component';
import { InchargeMeetingMasterComponent } from './pages/InchargeMeeting/incharge-meeting-master/incharge-meeting-master.component';
import { InchargeMeetingAttendanceComponent } from './pages/InchargeMeeting/incharge-meeting-attendance/incharge-meeting-attendance.component';
import { FederationPresidentDashboardComponent } from './pages/CommonModule/Dashboard/federation-president-dashboard/federation-president-dashboard.component';
import { ChartsModule } from 'ng2-charts';
import { UnitDirectorDashboardComponent } from './pages/CommonModule/Dashboard/unit-director-dashboard/unit-director-dashboard.component';
import { GroupPresidentDashboardComponent } from './pages/CommonModule/Dashboard/group-president-dashboard/group-president-dashboard.component';
import { GroupWiseActivitySummaryComponent } from './pages/GiantReports/group-wise-activity-summary/group-wise-activity-summary.component';
import { GroupWiseMeetingSummaryComponent } from './pages/GiantReports/group-wise-meeting-summary/group-wise-meeting-summary.component';
import { GiantsreportComponent } from './pages/GiantReports/giantsreport/giantsreport.component';
import { AddcirculartypeComponent } from './pages/circulartype/addcirculartype/addcirculartype.component';
import { ListcirculartypeComponent } from './pages/circulartype/listcirculartype/listcirculartype.component';
import { ListcircularComponent } from './pages/circular/listcircular/listcircular.component';
import { AddcircularComponent } from './pages/circular/addcircular/addcircular.component';
import { AddgroupofficerComponent } from './pages/CMS/groupofficer/addgroupofficer/addgroupofficer.component';
import { ListgroupofficerComponent } from './pages/CMS/groupofficer/listgroupofficer/listgroupofficer.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AboutUsListComponent } from './pages/CMS/Home/about-us/about-us-list/about-us-list.component';
import { AddaboutComponent } from './pages/CMS/Home/about-us/addabout/addabout.component';
import { AddadBannerComponent } from './pages/CMS/Home/ad-banner/addad-banner/addad-banner.component';
import { AdBannerlistComponent } from './pages/CMS/Home/ad-banner/ad-bannerlist/ad-bannerlist.component';
import { AddbannerComponent } from './pages/CMS/Home/daynamic_banner/addbanner/addbanner.component';
import { BannerListComponent } from './pages/CMS/Home/daynamic_banner/banner-list/banner-list.component';
import { AddnewsUpdateComponent } from './pages/CMS/Home/news-update/addnews-update/addnews-update.component';
import { NewsUpdatelistComponent } from './pages/CMS/Home/news-update/news-updatelist/news-updatelist.component';
import { AboutWalfareComponent } from './pages/CMS/About-Us/about_gaints_walfare/about-walfare/about-walfare.component';
import { ListaboutComponent } from './pages/CMS/About-Us/about_gaints_walfare/listabout/listabout.component';
import { AddgiantsprayerComponent } from './pages/CMS/About-Us/gaints_prayer/addgiantsprayer/addgiantsprayer.component';
import { ListgiantsprayerComponent } from './pages/CMS/About-Us/gaints_prayer/listgiantsprayer/listgiantsprayer.component';
import { AddnanasQuateComponent } from './pages/CMS/About-Us/nanas-quate/addnanas-quate/addnanas-quate.component';
import { ListnanasQuateComponent } from './pages/CMS/About-Us/nanas-quate/listnanas-quate/listnanas-quate.component';
import { AddmessagesComponent } from './pages/CMS/About-Us/messages/addmessages/addmessages.component';
import { ListmessagesComponent } from './pages/CMS/About-Us/messages/listmessages/listmessages.component';
import { AddaboutfederationComponent } from './pages/CMS/About-Us/aboutfederation/addaboutfederation/addaboutfederation.component';
import { ListaboutfederationComponent } from './pages/CMS/About-Us/aboutfederation/listaboutfederation/listaboutfederation.component';
import { AddunitComponent } from './pages/CMS/Resource/unit/addunit/addunit.component';
import { ListunitComponent } from './pages/CMS/Resource/unit/listunit/listunit.component';
import { AddgroupGuidlineComponent } from './pages/CMS/Resource/group_guidline/addgroup-guidline/addgroup-guidline.component';
import { ListgroupComponent } from './pages/CMS/Resource/group_guidline/listgroup/listgroup.component';
import { AddannualtimetableComponent } from './pages/CMS/Resource/annualtimetable/addannualtimetable/addannualtimetable.component';
import { ListannualtimetableComponent } from './pages/CMS/Resource/annualtimetable/listannualtimetable/listannualtimetable.component';
import { AddimportantdateComponent } from './pages/CMS/Resource/importantdate/addimportantdate/addimportantdate.component';
import { ListimportantdateComponent } from './pages/CMS/Resource/importantdate/listimportantdate/listimportantdate.component';
import { AddmemberShipComponent } from './pages/CMS/Join Us/membership/addmember-ship/addmember-ship.component';
import { MemberShiplistComponent } from './pages/CMS/Join Us/membership/member-shiplist/member-shiplist.component';
import { AddgroupComponent } from './pages/CMS/Join Us/group/addgroup/addgroup.component';
import { GrouplistComponent } from './pages/CMS/Join Us/group/grouplist/grouplist.component';
import { AddfinancialComponent } from './pages/CMS/Join Us/financial/addfinancial/addfinancial.component';
import { FinancialListComponent } from './pages/CMS/Join Us/financial/financial-list/financial-list.component';
import { AddCguidelineComponent } from './pages/CMS/Resource/C Guideline/add-cguideline/add-cguideline.component';
import { ListCguidelineComponent } from './pages/CMS/Resource/C Guideline/list-cguideline/list-cguideline.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { PresidentMessagelistComponent } from './pages/CMS/Home/president-message/president-messagelist/president-messagelist.component';
import { AddpresidentMessageComponent } from './pages/CMS/Home/president-message/addpresident-message/addpresident-message.component';
import { AddgalleryComponent } from './pages/CMS/CMSGallery/addgallery/addgallery.component';
import { GallerylistComponent } from './pages/CMS/CMSGallery/gallerylist/gallerylist.component';
import { AddcontactComponent } from './pages/CMS/Contact Us/addcontact/addcontact.component';
import { ListcontactComponent } from './pages/CMS/Contact Us/listcontact/listcontact.component';
import { AddpressreleaseComponent } from './pages/CMS/News & Event/addpressrelease/addpressrelease.component';
import { ListpressreleaseComponent } from './pages/CMS/News & Event/listpressrelease/listpressrelease.component';
import { LatestnewsComponent } from './pages/CMS/News & Event/latestnews/latestnews.component';
import { ListeventComponent } from './pages/CMS/News & Event/listevent/listevent.component';
import { MeetingComponent } from './pages/CMS/News & Event/meeting/meeting.component';
import { Faq1Component } from './pages/CMS/CMSFaq/faq_master/faq1/faq1.component';
import { Faqs1Component } from './pages/CMS/CMSFaq/faq_master/faqs1/faqs1.component';
import { Faqresponses1Component } from './pages/CMS/CMSFaq/faq_master/faqresponses1/faqresponses1.component';
import { AddfaqsHeadComponent } from './pages/CMS/CMSFaq/faqs_head/addfaqs-head/addfaqs-head.component';
import { FaqsHeadlistComponent } from './pages/CMS/CMSFaq/faqs_head/faqs-headlist/faqs-headlist.component';
import { AddphotoComponent } from './pages/CMS/CMSGallery/photo1/addphoto/addphoto.component';
import { ListphotoComponent } from './pages/CMS/CMSGallery/photo1/listphoto/listphoto.component';
import { AddpartnersComponent } from './pages/CMS/Home/Partners/addpartners/addpartners.component';
import { ListpartnersComponent } from './pages/CMS/Home/Partners/listpartners/listpartners.component';
import { AddotherComponent } from './pages/CMS/Resource/Others/addother/addother.component';
import { ListotherComponent } from './pages/CMS/Resource/Others/listother/listother.component';
// import { ShainaMamInfoComponent } from './pages/CMS/Home/shaina-mam-info/shaina-mam-info.component';
import { AddshainaInfoComponent } from './pages/CMS/Home/shaina-Mam-Info/addshaina-info/addshaina-info.component';
import { ListshainaInfoComponent } from './pages/CMS/Home/shaina-Mam-Info/listshaina-info/listshaina-info.component';
import { AddChairpersonComponent } from './pages/CMS/CMS Committee/add-chairperson/add-chairperson.component';
import { ListChairpersonComponent } from './pages/CMS/CMS Committee/list-chairperson/list-chairperson.component';
import { AddcentralcommitteeComponent } from './pages/CMS/CMS Committee/Central Committee/addcentralcommittee/addcentralcommittee.component';
import { ListcentralcommitteeComponent } from './pages/CMS/CMS Committee/Central Committee/listcentralcommittee/listcentralcommittee.component';
import { AddfederationcommitteeComponent } from './pages/CMS/CMS Committee/Federation wise committee/addfederationcommittee/addfederationcommittee.component';
import { ListfederationcommitteeComponent } from './pages/CMS/CMS Committee/Federation wise committee/listfederationcommittee/listfederationcommittee.component';
import { ListfederationComponent } from './pages/CMS/CMS Committee/Total Federation/listfederation/listfederation.component';
import { ListunitesComponent } from './pages/CMS/CMS Committee/Total Unites/listunites/listunites.component';
import { GroupoflistComponent } from './pages/CMS/CMS Committee/Total Group/groupoflist/groupoflist.component';
import { ListmemberComponent } from './pages/CMS/CMS Committee/Total Member/listmember/listmember.component';
import { ListsocialfeedComponent } from './pages/CMS/Social Feeds/listsocialfeed/listsocialfeed.component';
import { AddcouncilComponent } from './pages/CMS/BOD/addcouncil/addcouncil.component';
import { ListcouncilComponent } from './pages/CMS/BOD/listcouncil/listcouncil.component';
import { AddjoinusformComponent } from './pages/CMS/Varification Tab Master/addjoinusform/addjoinusform.component';
import { ListjoinusformComponent } from './pages/CMS/Varification Tab Master/listjoinusform/listjoinusform.component';
// import { AddcentralcommitteeComponent } from './pages/CMS/CMS Committee/addcentralcommittee/addcentralcommittee.component';
// import { ListcentralcommitteeComponent } from './pages/CMS/CMS Committee/listcentralcommittee/listcentralcommittee.component';


registerLocaleData(en);

@NgModule({
  declarations: [
    AddgroupofficerComponent,
    ListgroupofficerComponent,
    AppComponent,
    TestComponent,
    // AwsbulksenderComponent,
    // AwsemailsComponent,
    // AwstemplateComponent,
    // AwstemplatesComponent,
    TransferTicketDrawerComponent,
    LoginComponent,
    ApplicationComponent,
    ApplicationsComponent,
    ModuleComponent,
    ModulesComponent,
    UserComponent,
    UsersComponent,
    ApplicationModuleMappingComponent,
    UserModuleMappingComponent,
    DashboardComponent,
    RolesComponent,
    RoleComponent,
    RolemodulemappingComponent,
    FaqHeadsComponent,
    FaqHeadComponent,
    FaqsComponent,
    FaqComponent,
    FormsComponent,
    FormComponent,
    RoledetailsComponent,
    SelectpageComponent,
    FaqresponsesComponent,
    DepartmentComponent,
    DepartmentsComponent,
    // DepartmentcalenderComponent,
    EmailComponent,
    EmailsComponent,
    HistoryComponent,
    SmsComponent,
    SmssComponent,
    FolderComponent,
    FoldersComponent,
    TicketsComponent,
    TicketdetailsComponent,
    UserapplicationmappingComponent,
    UsermodulemappingComponent,
    UseraccessmappingComponent,
    UserdepartmentmappingComponent,
    UserrolemappingComponent,
    TaskComponent,
    TasksComponent,
    ViewhistoryComponent,
    LogmoduleComponent,
    TicketgroupComponent,
    TicketgroupsComponent,
    SMSHistoryComponent,
    ExportDirective,
    TestsComponent,
    ViewbodyComponent,
    MyticketComponent,
    CreateticketComponent,
    ViewchatticketComponent,
    TicketreportComponent,
    SummaryreportComponent,
    UserwisesummaryComponent,
    DashboardreportComponent,
    AwsbulksenderComponent,
    AwstemplateComponent,
    AwsemailsComponent,
    AwstemplatesComponent,
    ChattdetailsicketComponent,
    OrganizationComponent,
    OrganizationsComponent,
    BranchesComponent,
    BranchComponent,
    DesignationComponent,
    DesignationsComponent,
    TickRoleComponent,
    TickRolesComponent,
    FeatureComponent,
    FeaturesComponent,
    HelploginComponent,
    ClustermasterlistComponent,
    AddclustermasterComponent,
    EmployeemasterlistComponent,
    AddemployeemasterComponent,
    AddemployeerolemapComponent,
    EmployeerolemaplistComponent,
    EmploginComponent,
    SearchfaqComponent,
    FolderMasterComponent,
    FolderDrawerComponent,
    FileDrawerComponent,
    SharedFolderMasterComponent,
    FaqresponsereportComponent,
    FolderMasterComponent,
    FolderDrawerComponent,
    FileDrawerComponent,
    SharedFolderMasterComponent,
    TransferTicketDrawerComponent,
    MynotificationsComponent,
    SendnotificationsComponent,
    FolderMasterComponent,
    FolderDrawerComponent,
    FileDrawerComponent,
    SharedFolderMasterComponent,
    TransferTicketDrawerComponent,
    SharingExistingFolderComponent,
    ViewNotificationDrawerComponent,
    AddNewNotificationDrawerComponent,
    OptionreportComponent,
    SendEmailDrawerComponent,
    ChangePasswordDrawerComponent,
    LeavetypesComponent,
    LeavetypeComponent,
    AddEmployeeLeaveComponent,
    ExpenseheadComponent,
    ExpensesheadComponent,
    AddEmployeeExpensesComponent,
    EvaluationcriteriaComponent,
    EvaluationcriteriasComponent,
    CustomersComponent,
    CustomerComponent,
    HolidaysComponent,
    ThoughsComponent,
    ThoughComponent,
    BirthdaysComponent,
    BirthdayComponent,
    AddearlymarkComponent,
    AddlatemarkComponent,
    EmployeeexpensetypemappingComponent,
    LeaveApproveDrawerComponent,
    HeadLeaveApproveDrawerComponent,
    HeadLatemarkApproveDrawerComponent,
    HeadEarlymarkApproveDrawerComponent,
    EmployeecollegemappingComponent,
    AccountantExpensesApproveDrawerComponent,
    HeadExpensesApproveDrawerComponent,
    AttendancereportComponent,
    LeavereportComponent,
    LatemarkreportComponent,
    EarlymarkreportComponent,
    ExpensereportComponent,
    EvaluationreportComponent,
    EarlymarkTilesComponent,
    LatemarkTilesComponent,
    LeaveTilesComponent,
    ExpensesTilesComponent,
    HeadApproveEmployeeLeavesTilesComponent,
    ApproveEmployeeLeavesTilesComponent,
    HeadApproveEmployeeLatemarkTilesComponent,
    HeadApproveEmployeeEarlymarkTilesComponent,
    ExpensesApproveFromHeadTilesComponent,
    ExpensesApproveFromAccountantTilesComponent,
    AttendanceregisterComponent,
    UpdateAttendanceRegisterComponent,
    AssetCategoryListComponent,
    AssetCategoryDrawerComponent,
    AssetListComponent,
    AssetDrawerComponent,
    AssetMappingListComponent,
    AssetMappingDrawerComponent,
    AssetRequestListComponent,
    AssetRequestDrawerComponent,
    TrainingsublistComponent,
    AddtrainingsubComponent,
    TrainermasterlistComponent,
    AddtrainermasterComponent,
    TrainingtypelistComponent,
    AddtrainingtypeComponent,
    AnnualtrainpointlistComponent,
    AddannualtrainpointComponent,
    TrainingtrainerMapComponent,
    TrainingpointmasterlistComponent,
    AddtrainingpointmasterComponent,
    TrainingschedulelistComponent,
    AddtrainingscheduleComponent,
    TrainattendmapComponent,
    InductionarealistComponent,
    AddinductionareaComponent,
    TrainfeedformmasterlistComponent,
    AddtrainfeedformmasterComponent,
    TrainingevalutionformlistComponent,
    AddtrainingevalutionformComponent,
    TrainfeedmasterlistComponent,
    AddtrainfeedmasterComponent,
    TrainattendancecardlistComponent,
    AddtrainattendancecardComponent,
    AttendcardComponent,
    TrainerloginviewComponent,
    TrainerloginviewdrawerComponent,
    AccessorviewComponent,
    AccessorviewdrawerComponent,
    AssetRegisterComponent,
    AssetAllocationRegisterComponent,
    AssetMaintenanceRegisterComponent,
    AssetCategoryRegisterComponent,
    AssetRegisterForAssetCategoryRegisterComponent,
    TrainerAccessorLoginComponent,
    ModelMasterComponent,
    ModelDrawerComponent,
    VarientMasterComponent,
    VarientDrawerComponent,
    PriceListMasterComponent,
    PriceListDrawerComponent,
    PriceListMappingComponent,
    FederationMasterComponent,
    FederationDrawerComponent,
    UnitMasterComponent,
    UnitDrawerComponent,
    GroupMasterComponent,
    GroupDrawerComponent,
    InchargeAreaMasterComponent,
    InchargeAreaDrawerComponent,
    ProjectMasterComponent,
    ProjectDrawerComponent,
    AssignFederationMembersComponent,
    MemberMasterComponent,
    MemberDrawerComponent,
    AssignUnitMemberComponent,
    AssignGroupMemberComponent,
    GroupmeetingattendanceComponent,
    AddgroupmeetingattendanceComponent,
    GroupmeetattendiesmapComponent,
    UnitdobComponent,
    GroupbodComponent,
    GroupActivityReportComponent,
    UnitmembershipsummaryComponent,
    GroupMeetingReportComponent,
    GroupMembershipMonthwiseComponent,
    ViewGroupMeetingAttendiesComponent,
    ViewUnitBodComponent,
    ViewGroupBodComponent,
    GroupProjectActivityMasterComponent,
    GroupProjectActivityDrawerComponent,
    MemberPaymentDrawerComponent,
    ManageFederationMembersComponent,
    ManageUnitMembersComponent,
    ManageGroupMembersComponent,
    ResigsterComponent,
    MemberWiseSummaryComponent,
    FederationWiseMemberCountComponent,
    UnitWiseMemberCountComponent,
    GroupWiseMemberCountComponent,
    MemberUploadComponent,
    InchargeActivityMasterComponent,
    InchargeActivityDrawerComponent,
    InchargeMeetingDrawerComponent,
    InchargeMeetingMasterComponent,
    InchargeMeetingAttendanceComponent,
    FederationPresidentDashboardComponent,
    UnitDirectorDashboardComponent,
    GroupPresidentDashboardComponent,
    GroupWiseActivitySummaryComponent,
    GroupWiseMeetingSummaryComponent,
    GiantsreportComponent,
    AddcirculartypeComponent,
    ListcirculartypeComponent,
    ListcircularComponent,
    AddcircularComponent,

    // CMS

    AboutUsListComponent,
    AddaboutComponent,
    AddadBannerComponent,
    AdBannerlistComponent,
    AddbannerComponent,
    BannerListComponent,
    AddnewsUpdateComponent,
    NewsUpdatelistComponent,
    AboutWalfareComponent,
    ListaboutComponent,
    AddgiantsprayerComponent,
    ListgiantsprayerComponent,
    AddnanasQuateComponent,
    ListnanasQuateComponent,
    AddmessagesComponent,
    ListmessagesComponent,
    AddaboutfederationComponent,
    ListaboutfederationComponent,
    AddunitComponent,
    ListunitComponent,
    AddgroupGuidlineComponent,
    ListgroupComponent,
    AddannualtimetableComponent,
    ListannualtimetableComponent,
    AddimportantdateComponent,
    ListimportantdateComponent,
    AddmemberShipComponent,
    MemberShiplistComponent,
    AddgroupComponent,
    GrouplistComponent,
    AddfinancialComponent,
    FinancialListComponent,
    AddCguidelineComponent,
    ListCguidelineComponent,
    PresidentMessagelistComponent,
    AddpresidentMessageComponent,
    AddgalleryComponent,
    GallerylistComponent,
    AddcontactComponent,
    ListcontactComponent,
    AddpressreleaseComponent,
    ListpressreleaseComponent,
    LatestnewsComponent,
    ListeventComponent,
    MeetingComponent,
    Faq1Component,
    Faqs1Component,
    Faqresponses1Component,
    AddfaqsHeadComponent,
    FaqsHeadlistComponent,
    AddphotoComponent,
    ListphotoComponent,
    AddpartnersComponent,
    ListpartnersComponent,
    AddotherComponent,
    ListotherComponent,
    // ShainaMamInfoComponent,
    AddshainaInfoComponent,
    ListshainaInfoComponent,
    AddChairpersonComponent,
    ListChairpersonComponent,
    AddcentralcommitteeComponent,
    ListcentralcommitteeComponent,
    AddfederationcommitteeComponent,
    ListfederationcommitteeComponent,
    ListfederationComponent,
    ListunitesComponent,
    GroupoflistComponent,
    ListmemberComponent,
    ListsocialfeedComponent,
    AddcouncilComponent,
    ListcouncilComponent,
    AddjoinusformComponent,
    ListjoinusformComponent,
    // AddcentralcommitteeComponent,
    // ListcentralcommitteeComponent,



  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    IconsProviderModule,
    NgZorroAntdModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    NzSwitchModule,
    NgxPrintModule,
    AngularFireModule.initializeApp(environment.firebase),
    ChartsModule,
    AngularEditorModule,
    NzSelectModule,



  ],

  providers: [{ provide: NZ_I18N, useValue: en_US }, CookieService, AsyncPipe, DatePipe],
  bootstrap: [AppComponent]
})

export class AppModule { }
