import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { GroupBOD } from 'src/app/Models/groupbod';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { UnitBOD } from 'src/app/Models/unitbod';
import { ApiService } from 'src/app/Service/api.service';
import { GroupMaster } from 'src/app/Models/GroupMaster';
import { ViewGroupBodComponent } from '../view-group-bod/view-group-bod.component';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-groupbod',
  templateUrl: './groupbod.component.html',
  styleUrls: ['./groupbod.component.css']
})

export class GroupbodComponent implements OnInit {
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: GroupBOD = new GroupBOD();
  formTitle = " Group List";
  dataList = [];
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  groupname: string = '';
  filterQuery: string = "";
  isFilterApplied: any = "default";
  filterClass: string = 'filter-invisible';
  isSpinning = false;
  assessment: any;
  selectedDate: Date[] = []
  value1: any;
  value2: any;
  selectedUnitName: any = '';
  units: UnitBOD[] = [];
  current = new Date();
  // , ["_20_21_MEMBER_COUNT", "20 21 Member Count"], ["_21_22_MEMBER_COUNT", "21 22 Member Count"]
  columns: string[][] = [["FEDERATION_NAME", "Federation"], ["UNIT_NAME", "Unit"], ["NAME", "Group"], ["STATUS", "Active"]];
  federationID = this._cookie.get("FEDERATION_ID");
  unitID = this._cookie.get("UNIT_ID");
  groupID = this._cookie.get("GROUP_ID");

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _cookie: CookieService) { }

  ngOnInit(): void {
    this.loadingRecords = false;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    var federationFilter = "";
    if (this.federationID != "0") {
      federationFilter = " AND FEDERATION_ID=" + this.federationID;
    }

    var unitFilter = "";
    if (this.unitID != "0") {
      unitFilter = " AND UNIT_ID=" + this.unitID;
    }

    var groupFilter = "";
    if (this.groupID != "0") {
      groupFilter = " AND GROUP_ID=" + this.groupID;
    }

    this.loadingRecords = true;

    this.api.getAllGroups(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + federationFilter + unitFilter + groupFilter).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  memberDrawerTitle: string;
  memberDrawerData: any;
  memberDrawerVisible = false;

  @ViewChild(ViewGroupBodComponent, { static: false }) ViewGroupBodComponentVar: ViewGroupBodComponent;

  viewBOD(data: GroupMaster): void {
    this.memberDrawerTitle = "Group Members";
    this.memberDrawerData = Object.assign({}, data);
    this.memberDrawerVisible = true;
    this.ViewGroupBodComponentVar.getData1(data);
  }

  memberDrawerClose(): void {
    this.memberDrawerVisible = false;
    this.search(false);
  }

  get memberDrawerCloseCallback() {
    return this.memberDrawerClose.bind(this);
  }
}