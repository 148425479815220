import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CookieService } from 'ngx-cookie-service';
import { GroupMeetAttendance } from 'src/app/Models/GroupMeetAttendance';
import { GroupMeetingAttendance } from 'src/app/Models/GroupMeetingAttendance';
import { ApiService } from 'src/app/Service/api.service';
import { AddgroupmeetingattendanceComponent } from '../addgroupmeetingattendance/addgroupmeetingattendance.component';

@Component({
  selector: 'app-groupmeetingattendance',
  templateUrl: './groupmeetingattendance.component.html',
  styleUrls: ['./groupmeetingattendance.component.css']
})

export class GroupmeetingattendanceComponent implements OnInit {
  formTitle = "Manage Group Meetings";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  totalRecords2 = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["DATE", "Date"], ["FEDERATION_NAME", "Federation"], ["UNIT_NAME", "Unit"], ["GROUP_NAME", "Group"], ["FROM_TIME", "From Time"], ["TO_TIME", "To Time"], ["MEETING_NUMBER", "Meeting Number"], ["MEETING_SUB", "Meeting Subject"], ["AGENDA", "Agenda"], ["MINUTES", "Minutes"]];
  scheduleId = 0;
  empId = 0;
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: GroupMeetAttendance = new GroupMeetAttendance();
  drawerVisible1: boolean = false;
  drawerTitle1: string = '';
  drawerData1: GroupMeetingAttendance = new GroupMeetingAttendance();
  drawerData2: string[] = [];
  federationID: number = Number(this._cookie.get("FEDERATION_ID"));
  unitID: number = Number(this._cookie.get("UNIT_ID"));
  groupID: number = Number(this._cookie.get("GROUP_ID"));
  roleID: number = this.api.roleId;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _cookie: CookieService) { }

  ngOnInit() {
    this.search(true);
  }

  keyup(event: any) {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    var federationFilter = "";
    if (this.federationID != 0) {
      federationFilter = " AND FEDERATION_ID=" + this.federationID;
    }

    var unitFilter = "";
    if (this.unitID != 0) {
      unitFilter = " AND UNIT_ID=" + this.unitID;
    }

    var groupFilter = "";
    if (this.groupID != 0) {
      groupFilter = " AND GROUP_ID=" + this.groupID;
    }

    this.loadingRecords = true;

    this.api.getAllgroupMeeting(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + federationFilter + unitFilter + groupFilter).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  @ViewChild(AddgroupmeetingattendanceComponent, { static: false }) AddgroupmeetingattendanceComponentVar: AddgroupmeetingattendanceComponent;

  add(): void {
    this.drawerTitle = "Create New Group Meeting";
    this.drawerData = new GroupMeetAttendance();
    this.drawerVisible = true;
    this.drawerData.DATE = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.AddgroupmeetingattendanceComponentVar.fileURL1 = null;
    this.AddgroupmeetingattendanceComponentVar.fileURL2 = null;
    this.AddgroupmeetingattendanceComponentVar.fileURL3 = null;
    this.AddgroupmeetingattendanceComponentVar.fileURL4 = null;
    this.AddgroupmeetingattendanceComponentVar.fileURL5 = null;
    this.AddgroupmeetingattendanceComponentVar.pdfFileURL1 = null;
    this.AddgroupmeetingattendanceComponentVar.pdfFileURL2 = null;
  }

  edit(data: GroupMeetAttendance): void {
    this.drawerTitle = "Update Group Meeting Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.drawerData.FROM_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData.FROM_TIME;
    this.drawerData.TO_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.drawerData.TO_TIME;
    this.AddgroupmeetingattendanceComponentVar.fileURL1 = null;
    this.AddgroupmeetingattendanceComponentVar.fileURL2 = null;
    this.AddgroupmeetingattendanceComponentVar.fileURL3 = null;
    this.AddgroupmeetingattendanceComponentVar.fileURL4 = null;
    this.AddgroupmeetingattendanceComponentVar.fileURL5 = null;
    this.AddgroupmeetingattendanceComponentVar.pdfFileURL1 = null;
    this.AddgroupmeetingattendanceComponentVar.pdfFileURL2 = null;

    if (this.drawerData.PHOTO1 != " ")
      this.drawerData.PHOTO1 = this.api.retriveimgUrl + "groupMeeting/" + this.drawerData.PHOTO1;
    else
      this.drawerData.PHOTO1 = null;

    if (this.drawerData.PHOTO2 != " ")
      this.drawerData.PHOTO2 = this.api.retriveimgUrl + "groupMeeting/" + this.drawerData.PHOTO2;
    else
      this.drawerData.PHOTO2 = null;

    if (this.drawerData.PHOTO3 != " ")
      this.drawerData.PHOTO3 = this.api.retriveimgUrl + "groupMeeting/" + this.drawerData.PHOTO3;
    else
      this.drawerData.PHOTO3 = null;

    if (this.drawerData.PHOTO4 != " ")
      this.drawerData.PHOTO4 = this.api.retriveimgUrl + "groupMeeting/" + this.drawerData.PHOTO4;
    else
      this.drawerData.PHOTO4 = null;

    if (this.drawerData.PHOTO5 != " ")
      this.drawerData.PHOTO5 = this.api.retriveimgUrl + "groupMeeting/" + this.drawerData.PHOTO5;
    else
      this.drawerData.PHOTO5 = null;

    if (this.drawerData.PDF1 != " ")
      this.drawerData.PDF1 = this.drawerData.PDF1;
    else
      this.drawerData.PDF1 = null;

    if (this.drawerData.PDF2 != " ")
      this.drawerData.PDF2 = this.drawerData.PDF2;
    else
      this.drawerData.PDF2 = null;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  MEETING_ID;

  MapAttendies(data: GroupMeetAttendance): void {
    this.drawerTitle1 = "Attendies for " + data.MEETING_NUMBER + "";
    this.drawerVisible1 = true;

    this.MEETING_ID = data.ID;
    this.api.getAllgroupMeetingAttendanceDetails(this.MEETING_ID).subscribe(data => {
      if (data['code'] == '200') {
        this.totalRecords2 = data['count'];
        this.drawerData2 = Object.assign([], data['data']);
        this.drawerVisible1 = true;
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  getTimeInAM_PM(time) {
    return this.datePipe.transform(new Date(), "yyyy-MM-dd" + " " + time);
  }

  photoModalTitle = "Meeting Photo(es)";
  photoModalVisible: boolean = false;
  PHOTO1: string = "";
  DESCRIPTION1: string = "";
  PHOTO2: string = "";
  DESCRIPTION2: string = "";
  PHOTO3: string = "";
  DESCRIPTION3: string = "";
  PHOTO4: string = "";
  DESCRIPTION4: string = "";
  PHOTO5: string = "";
  DESCRIPTION5: string = "";

  viewPhotoes(data: GroupMeetAttendance) {
    this.PHOTO1 = "";
    this.DESCRIPTION1 = "";
    this.PHOTO2 = "";
    this.DESCRIPTION2 = "";
    this.PHOTO3 = "";
    this.DESCRIPTION3 = "";
    this.PHOTO4 = "";
    this.DESCRIPTION4 = "";
    this.PHOTO5 = "";
    this.DESCRIPTION5 = "";
    this.photoModalVisible = true;

    if (data.PHOTO1 != " ") {
      this.PHOTO1 = this.api.retriveimgUrl + "groupMeeting/" + data.PHOTO1;
      this.DESCRIPTION1 = data.DESCRIPTION1;
    }

    if (data.PHOTO2 != " ") {
      this.PHOTO2 = this.api.retriveimgUrl + "groupMeeting/" + data.PHOTO2;
      this.DESCRIPTION2 = data.DESCRIPTION2;
    }

    if (data.PHOTO3 != " ") {
      this.PHOTO3 = this.api.retriveimgUrl + "groupMeeting/" + data.PHOTO3;
      this.DESCRIPTION3 = data.DESCRIPTION3;
    }

    if (data.PHOTO4 != " ") {
      this.PHOTO4 = this.api.retriveimgUrl + "groupMeeting/" + data.PHOTO4;
      this.DESCRIPTION4 = data.DESCRIPTION4;
    }

    if (data.PHOTO5 != " ") {
      this.PHOTO5 = this.api.retriveimgUrl + "groupMeeting/" + data.PHOTO5;
      this.DESCRIPTION5 = data.DESCRIPTION5;
    }
  }

  photoModalCancel() {
    this.photoModalVisible = false;
  }

  viewPhoto(photoURL: string) {
    window.open(photoURL);
  }

  viewPDF(pdfURL: string) {
    window.open(this.api.retriveimgUrl + "groupMeeting/" + pdfURL);
  }
}
