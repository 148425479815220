export class shainaMamInfo{

  


//     ID:number=0;
//    DESCRIPTION:string='';
//    STATUS:boolean=true;
//    SEQUENCE_NO:number=0 ;
//    TITLE:string='';
//    IMG_URL:string='';
//   NAME: any;
//   ICON: any;


  ID:number=0;
  TITLE:string='';
  DESCRIPTION:string='';
  STATUS:boolean=true;
  SEQ_NO:number=0 ;
  IMG_URL:string='';



   }