import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { cmsFaq1 } from 'src/app/Models/cmsFaq1';

import { ApiService } from 'src/app/Service/api.service';
import { cmsFaqresponses1 } from 'src/app/Models/cmsFaqresponses1';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Faqresponses1Component } from '../faqresponses1/faqresponses1.component';


@Component({
  selector: 'app-faqs1',
  templateUrl: './faqs1.component.html',
  styleUrls: ['./faqs1.component.css']
})
export class Faqs1Component implements OnInit {

  formTitle = "Manage FAQs";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "FAQ Head Name"], ["QUESTION", "Question"], ["ANSWER", "Answer"], ["TAGS", "Tags"], ["POSITIVE_COUNT", "Positive Count"], ["NEGATIVE_COUNT", "Negative Count"], ["SEQ_NO", "Sequence No"]]
  applicationId = Number(this.cookie.get('applicationId'))
  @ViewChild(Faqresponses1Component, { static: false }) faqResponse1: Faqresponses1Component;
  // @ViewChild(FaqComponent,{static:false}) myInputVariable: ElementRef;
  // @ViewChild('inputFile') myInputVariable: ElementRef;

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: cmsFaq1 = new cmsFaq1();

  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: cmsFaq1 = new cmsFaq1();
  constructor(private api: ApiService, private cookie: CookieService,private message:NzNotificationService) { }
  ngOnInit() {
    this.search();
  }
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset)
  {
     this.pageIndex = 1;
  }

  var sort: string;
    try {
      this.sortValue = this.sortValue.startsWith("a") ? "asc" : "desc";
      console.log("an", this.sortValue);
    }
    catch (error) { sort = ""; }

      var likeQuery = ""; if (this.searchText != "")
       {
         likeQuery = " AND";
         this.columns.forEach(column =>
      {
       likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR"; });
       likeQuery = likeQuery.substring(0, likeQuery.length - 2);
   }

     this.loadingRecords = true;

    this.api.getAllFaqs1(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, likeQuery).
    subscribe(data => { if (data['code'] == 200)
     {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
  this.dataList = data['data'];
    } },
     err => { if (err['ok'] == false)
      this.message.error("Server Not Found", ""); });
  }

  keyup(event:any) {
    this.search();
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New FAQ";
    this.drawerData = new cmsFaq1();
    this.drawerData.STATUS = true;
    this.drawerData.FAQ_HEAD_ID = null;
    this.drawerData.QUESTION = '';
    this.drawerData.ANSWER = '';
    this.drawerData.TAGS = '';
    this.drawerData.URL = '';
    this.drawerData.TAGS_STRING = [];
    // this.form.nativeElement.reset()
    // this.myInputVariable.nativeElement.value = '';


    // this.drawerVisible = true;
    this.api.getAllFaqs1(1, 1, 'SEQ_NO', 'desc','' ).subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQ_NO = 1;
      } else {
        this.drawerData.SEQ_NO = data['data'][0]['SEQ_NO'] + 1;
      }
    }, err => {
      console.log(err);
    })
    this.drawerVisible = true;
  }
  edit(data: cmsFaq1): void {
    this.drawerTitle = "Update FAQ";
    try {
      data.TAGS_STRING = data.TAGS.split(",");
    } catch (error) {
      data.TAGS_STRING = [];
    }
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  ViewResponses(data: cmsFaq1) {
    this.drawerTitle1 = "FAQ Responses";
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisible1 = true;
    this.faqResponse1.applyFilter(data.ID);
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.search();
    this.drawerVisible1 = false;
  }


}
