import { DatePipe } from '@angular/common';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { giantsprayer } from 'src/app/Models/giantsprayer';
// import { giantsprayer } from 'src/app/Models/giantsprayer';
import { ApiService } from 'src/app/Service/api.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
// import { appkeys } from 'src/app/app.constant';
// import { ClientmasterService } from 'src/app/clientmaster.service';
// import { giantsprayer } from 'src/Models/giantsprayer';

@Component({
  selector: 'app-addgiantsprayer',
  templateUrl: './addgiantsprayer.component.html',
  styleUrls: ['./addgiantsprayer.component.css']
})
export class AddgiantsprayerComponent implements OnInit {
  @Input()
  drawerClose!: Function;
  @Input()
  data: giantsprayer = new giantsprayer;
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // namepatt=/^[a-zA-Z \-\']+/
  namepatt = /[a-zA-Z][a-zA-Z ]+/
  mobpattern = /^[6-9]\d{9}$/
  fileURL: any;
  // albume:AlbumeMaster[]=[]
  // imgurl= appkeys.retriveimgUrl;


  // video

  isOkImageSize = true;
  fileURL5: any;

  // end video


  imgurl = this.api.retriveimgUrl + "/giantsPrayer/";



  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '350px',
    // minHeight: '12rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
    ],
    customClasses: [],
    uploadUrl: '',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fonts', 'uploadUrl'],
      ['video']
    ]
  };

  loadingRecords=true;


  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    // this.loadalbume();

    this.loadingRecords = false;
    this.search();
  }


  close(): void {
    this.drawerClose();
  }
  resetDrawer(websitebannerPage: NgForm) {
    websitebannerPage.form.reset();
  }
  search() {
    this.api
   .getGiantsPrayer(1, 1, ' SEQUENCE_NO', 'desc', '')
   .subscribe(
     (data) => {
       this.loadingRecords = false;
       if (data.code == '200' && data['count']>0) {
       this.data = data['data'][0];

       this.ishow5 =false;

       if(this.data.VIDEO_URL !=''){
         this.ishow5 =true
       }
       }
     },
     (err) => {
       console.log(err);
     }
   );
}

  //save





  save(addNew: boolean, websitebannerPage: NgForm): void {
    this.isOk = true;
    this.isSpinning = false;

    if (this.data.TITLE.trim() == ""
      && this.data.DESCRIPTION.trim() == ""
      && this.data.SEQUENCE_NO != undefined) {
      this.isOk = false
      this.message.error("Please Fill All The Information......", "");
    }

    else
      if (this.data.TITLE == null || this.data.TITLE.trim() == '') {
        this.isOk = false
        this.message.error('Please Fill  Title', '')

      }

      else
        if (this.data.DESCRIPTION == null || this.data.DESCRIPTION.trim() == '') {
          this.isOk = false
          this.message.error('Please Enter the Valid Discription', '')

        }


    if (this.isOk) {


      this.isSpinning = true;

      {
        if (this.data.ID) {
          this.api.updatGiantsPrayer(this.data).subscribe((successCode) => {
            if (successCode["code"] == '200') {
              this.message.success('Information updated successfully...', '');
              if (!addNew) this.drawerClose();
              this.isSpinning = false;
            } else {
              this.message.error('Failed to Update information..', '');
              this.isSpinning = false;
            }
          });
        }
        else {
          this.api.createGiantsPrayer(this.data).subscribe((successCode) => {
            if (successCode.code == '200') {
              this.message.success('Information added successfully......', '');
              if (!addNew) this.drawerClose();
              else {
                this.data = new giantsprayer();
                this.resetDrawer(websitebannerPage);


                this.api.getGiantsPrayer(1, 1, ' SEQUENCE_NO', 'desc', '').subscribe(data => {
                  if (data['count'] == 0) {
                    this.data.SEQUENCE_NO = 1;
                  } else {
                    this.data.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;

                  }
                },
                  (err) => {
                    console.log(err);
                  }
                );
              }
              this.isSpinning = false;
            } else {
              this.message.error('Failed to store  information...', '');
              this.isSpinning = false;
            }
          });
        }
      }
    }
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }




  // video upload




  ishow5 = true;
  checkImage5(event: any) {
    this.isOkImageSize = true;
    const isLimit1M = event.target.files[0].size > 5000000;

    if (event.target.files.length == 0) {
      this.message.error('Please Upload  video', '');
      this.fileURL5 = null;


    }
    else if (isLimit1M) {
      this.message.error('Please Upload Video Max 5mb in size', '');

    }
    else if (event.target.files[0].type == "video/mp4" || event.target.files[0].type == "video/x-m4v" || event.target.files[0].type == "video/*" || event.target.files[0].type == "video/WEBM" || event.target.files[0].type == "video/webm") {
      this.fileURL5 = <File>event.target.files[0];



    } else {
      this.message.error("Please Upload only mp4/ x-m4v/ WEBM/webm files.", "");
      this.fileURL5 = null;


    }

    if (this.fileURL5 != null) {
      var number = Math.floor(100000 + Math.random() * 900000);
      var fileExt = this.fileURL5.name.split('.').pop();
      var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
      var url = '';
      url = d == null ? '' : d + number + '.' + fileExt;
      console.log("URL = " + url);
      if (
        this.data.VIDEO_URL != undefined &&
        this.data.VIDEO_URL.trim() != ''
      ) {
        var arr = this.data.VIDEO_URL.split('/');
        if (arr.length > 1) {
          url = arr[5];
        }
      }
      this.data.VIDEO_URL = url;
      this.ishow5 = false;
      this.uploadImage(this.fileURL5, url)

    }
  }


  uploadImage(fileURL: any, url: any) {
    this.isSpinning = true
    this.api
      .onUpload2('giantsPrayer', fileURL, url)
      .subscribe((successCode) => {
        if (successCode["code"] == '200') {
          // this.saveInfo()

          // this.saveInfo()

          //  
          this.isSpinning = false
          // this.ngOnInit();
        } else {
          this.message.error('Upload Fails...', '');
          this.isSpinning = false;
        }
      });
  }



  
  // saveInfo(){
  //   this.data.TAB_ID = 8; // For inserting Tab Info table. This is hardcoded.
  //   this.data.IS_FILLED_BY_ADMIN = 1; // For inserting Tab Info table. This is hardcoded.
  //   this.data.MEMBER_ID = this.viewId ;
  //   this.isSpinning = false;
  //   this.isOk=true;
    
  //  if(this.isOk){
  //   // this.isSpinning=false; 

  //   this.isSpinning=true;  
  //             appkeys.retriveimgUrl + 'PhotoImage/' + url;
  //             if (this.data.ID) {
  //               this.api.updatGiantsPrayer(this.data).subscribe((successCode) => {
  //                 if (successCode["code"] == '200') {
  //                   this.message.success('Photo Update Successfully...', '');
  //                   this.isSpinning = false;
  //                   this.ngOnInit();
  //                   this.search();
  //                 } else {
  //                   this.message.error('Update Error...', '');
  //                   this.isSpinning = false;
  //                 }
  //               });
  //             } else {
  //               this.api.createGiantsPrayer (this.data).subscribe((successCode) => {
  //                 if (successCode.code == '200') {
  //                   this.message.success('Upload Successfully...', '');
  //                   this.isSpinning = false;
  //                   this.ngOnInit();
  //                   this.search();
  //                 } else {
  //                   this.message.error('Upload Fails...', '');
  //                   this.isSpinning = false;
  //                 }
  //               });
  //             }
  //           }
  // }





}