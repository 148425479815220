import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { GroupMaster } from 'src/app/Models/GroupMaster';
import { ApiService } from 'src/app/Service/api.service';
import { AssignGroupMemberComponent } from '../assign-group-member/assign-group-member.component';

@Component({
  selector: 'app-group-master',
  templateUrl: './group-master.component.html',
  styleUrls: ['./group-master.component.css']
})

export class GroupMasterComponent implements OnInit {
  formTitle = "Manage Groups";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [["FEDERATION_NAME", "Federation"], ["UNIT_NAME", "Unit"], ["NAME", "Group"], ["_20_21_MEMBER_COUNT", "20 21 Member Count"], ["_21_22_MEMBER_COUNT", "21 22 Member Count"], ["STATUS", "Active"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: GroupMaster = new GroupMaster();
  isSpinning = false;
  federationID = this._cookie.get("FEDERATION_ID");
  unitID = this._cookie.get("UNIT_ID");
  groupID = this._cookie.get("GROUP_ID");

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _cookie: CookieService) { }

  ngOnInit() {
    this.search(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var federationFilter = "";
    if (this.federationID != "0") {
      federationFilter = " AND FEDERATION_ID=" + this.federationID;
    }

    var unitFilter = "";
    if (this.unitID != "0") {
      unitFilter = " AND UNIT_ID=" + this.unitID;
    }

    var groupFilter = "";
    if (this.groupID != "0") {
      groupFilter = " AND ID=" + this.groupID;
    }

    this.loadingRecords = true;

    this.api.getAllGroups(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + federationFilter + unitFilter + groupFilter).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  today = new Date().setDate(new Date().getDate() + 4);

  add(): void {
    this.drawerTitle = "Add Group";
    this.drawerData = new GroupMaster();
    this.drawerVisible = true;
    this.drawerData.STATUS = true;
    this.drawerData.BO_DATE = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  }

  edit(data: GroupMaster): void {
    this.drawerTitle = "Update Group Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.drawerData._20_21_MEMBER_COUNT = String(data._20_21_MEMBER_COUNT);
    this.drawerData._21_22_MEMBER_COUNT = String(data._21_22_MEMBER_COUNT);
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }

  onStatusChange(data: GroupMaster, status) {
    data.STATUS = status;

    this.api.updateGroup(data).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Status", "");

      this.search();
    });
  }

  memberDrawerTitle: string;
  memberDrawerData: any;
  memberDrawerVisible = false;

  @ViewChild(AssignGroupMemberComponent, { static: false }) AssignGroupMemberComponentVar: AssignGroupMemberComponent;

  addMembers(data: GroupMaster): void {
    this.memberDrawerTitle = "Add Group Members";
    console.log("data", data);
    this.memberDrawerData = Object.assign({}, data);
    this.memberDrawerVisible = true;
    this.AssignGroupMemberComponentVar.getData1(data);
  }

  memberDrawerClose(): void {
    this.memberDrawerVisible = false;
    this.search();
  }

  get memberDrawerCloseCallback() {
    return this.memberDrawerClose.bind(this);
  }
}
