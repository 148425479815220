export class CmsPresident_Message{


  ID:number=0;
  // TITLE:string='';
  PRESIDENT_MESSAGE:string='';
  PRESIDENT_NAME:string='';
  NAME:string='';
  GROUP_NAME:string='';
  IMG_URL:string='';
  STATUS:boolean=true;
  SEQUENCE_NO:number=0 ;
  POSOTION:string='';
  PRESIDENT_ID:number=0;
}
