export class Group{




ID: number;
GUIDLINE_TITLE:string='';
GUIDLINE_DESCRIPTION:string='';
STATUS:boolean=true;
SEQUENCE_NO:number=0 ;
}