import { Component, Input, OnInit } from '@angular/core';
// import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { cmsphoto } from 'src/app/Models/cmsphoto';
import { NzNotificationService } from 'ng-zorro-antd';
import { PhotoMaster } from 'src/app/Models/Photomaster';
import { ApiService } from 'src/app/Service/api.service';
// import { appkeys } from 'src/app/app.constant';
@Component({
  selector: 'app-gallerylist',
  templateUrl: './gallerylist.component.html',
  styleUrls: ['./gallerylist.component.css']
})
export class GallerylistComponent implements OnInit {

  drawerTitle2 = '';
  drawerVisible!: boolean;
  drawer2Visible!: boolean;
  drawer2Data: any[] = [];
  drawerTitle!: string;
  drawerData: cmsphoto = new cmsphoto();
  drawerData2: PhotoMaster = new PhotoMaster();
  formTitle = 'Album List';
  dataList: any[] = [];
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = 'desc';
  sortKey: string = 'id';
  searchText: string = '';
  filterQuery: string = '';
  isFilterApplied: string = 'default';
  columns: string[][] = [ ['SEQUENCE_NO', 'Sequence No'],['TITLE', 'Title']];
  // imgurl= appkeys.retriveimgUrl;

  imgurl = this.api.retriveimgUrl + "/gallery/";

  constructor(private api: ApiService, private message: NzNotificationService) {}

  ngOnInit(): void {
    this.loadingRecords = false;
    this.search();
  }

  keyup(event:any) {
    this.search();
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = 'id';
      this.sortValue = 'desc';
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    var likeQuery = '';
    console.log('search text:' + this.searchText);
    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
      console.log('likeQuery' + likeQuery);
    }
    this.api
      .getAllalbumMaster(
        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        likeQuery
      )
      .subscribe(
        (data) => {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          console.log(this.dataList);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = 'Add New Album';
    this.drawerData = new cmsphoto();
    this.api.getAllalbumMaster(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(
      (data) => {
        if (data['count'] == 0) {
          this.drawerData.SEQUENCE_NO = 1;
        } else {
          this.drawerData.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
        }
      },
      (err) => {
        console.log(err);
      }
    );
    // this.drawerData.IS_ACTIVE=true;
    this.drawerVisible = true;
  }
  edit(data: cmsphoto): void {
    this.drawerTitle = 'Update Album';
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }
  drawerClose(): void {
    // this.pageIndex
    this.dataList = [];

    this.search();
    this.drawerVisible = false;
  }
  // sort(params: NzTableQueryParams): void {
  //   const { pageSize, pageIndex, sort } = params;
  //   const currentSort = sort.find((item) => item.value !== null);
  //   const sortField = (currentSort && currentSort.key) || 'id';
  //   const sortOrder = (currentSort && currentSort.value) || 'asc';
  //   console.log(currentSort);

  //   console.log('sortOrder :' + sortOrder);
  //   this.pageIndex = pageIndex;
  //   this.pageSize = pageSize;

  //   if (this.pageSize != pageSize) {
  //     this.pageIndex = 1;
  //     this.pageSize = pageSize;
  //   }

  //   if (this.sortKey != sortField) {
  //     this.pageIndex = 1;
  //     this.pageSize = pageSize;
  //   }

  //   this.sortKey = sortField;
  //   this.sortValue = sortOrder;
  //   this.search();
  // }

  ////Another component
photoID:any;
  openComp(data:cmsphoto) {
    this.drawer2Data = [];
    this.drawerTitle2 = 'Photo List';
    this.photoID=data.ID;
    this.drawer2Visible = true;
    this.api.getAllphotoMaster(0, 0, '', 'desc', 'AND ALBUM_ID=' +data.ID).subscribe(
      (data) => {
        this.drawer2Data = data['data']
        console.log(this.drawer2Data);

        // if (data['count'] == 0) {
        //   this.drawerData.SEQUENCE_NO = 1;
        // } else {
        //   this.drawerData.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
        // }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  drawerClose2(): void {
    this.drawer2Visible = false;
  }

  get closeCallback2() {
    return this.drawerClose2.bind(this);
  }


}
