export class Federationwisecommittee {

    // ID:number=0;
    // NAME:string='';
    // DESCRIPTION:string='';
    // TITLE:string='';
    // IMG_URL:string='';
    // STATUS:boolean=true;
    // SEQUENCE_NO:number=0;



    // new fields


    ID:number=0;
    FEDERATION_NAME:string='';
    GROUP_NAME:string='';
    MEMBER_NAME:string='';
    POSITION:string='';
    IMG_URL:string='';
    STATUS:boolean=true;
    SEQ_NO:number=0;

}
