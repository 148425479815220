import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { VarientMaster } from 'src/app/Models/VarientMaster';
import { ApiService } from 'src/app/Service/api.service';
import { VarientDrawerComponent } from '../varient-drawer/varient-drawer.component';

@Component({
  selector: 'app-varient-master',
  templateUrl: './varient-master.component.html',
  styleUrls: ['./varient-master.component.css']
})

export class VarientMasterComponent implements OnInit {
  formTitle = "Manage Varients";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [["MODEL_NAME", "Model"], ["NAME", "Name"], ["CODE", "Code"], ["FUEL_TYPE", "Fuel Type"], ["TRANSMISSION", "Transmission"], ["CC", "CC"], ["VARIENT_LITER", "Fuel Capacity"], ["SEQUENCE_NO", "Sequence No."], ["STATUS", "Active"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: VarientMaster = new VarientMaster();
  isSpinning = false;
  isFilterApplied: string = "default";
  filterClass: string = "filter-visible";

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search(true);
    this.getSequenceNo();
    this.getAllModels();
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var modelFilterStr = "";
    if (this.MODEL_ID.length > 0) {
      modelFilterStr = " AND MODEL_ID IN (" + this.MODEL_ID + ")";
    }

    this.api.getAllVarients(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + modelFilterStr).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  today = new Date().setDate(new Date().getDate() + 4);
  @ViewChild(VarientDrawerComponent, { static: false }) VarientDrawerComponentVar: VarientDrawerComponent;

  add(): void {
    this.drawerTitle = "Add Varient";
    this.drawerData = new VarientMaster();
    this.drawerVisible = true;
    this.drawerData.STATUS = true;
    this.drawerData.SEQUENCE_NO = this.seqNo;
    this.VarientDrawerComponentVar.getAllModelsWithActiveStatus();
  }

  seqNo: number;

  getSequenceNo() {
    this.api.getAllVarients(1, 1, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200 && data['count'] > 0) {
        this.seqNo = Number(data['data'][0]['SEQUENCE_NO']) + 1;

      } else {
        this.seqNo = 1;
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  edit(data: VarientMaster): void {
    this.drawerTitle = "Update Varient Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    this.VarientDrawerComponentVar.getAllModels();
  }

  drawerClose(): void {
    this.search(true);
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }

  getFuelTypeFullForm(fuelType) {
    if (fuelType == "P")
      return "Petrol";

    else if (fuelType == "D")
      return "Diesel";

    else if (fuelType == "C")
      return "CNG";

    else if (fuelType == "L")
      return "LPG";

    else if (fuelType == "E")
      return "Electrical";

    else
      return "";
  }

  getTransmissionFullForm(transmissionType) {
    if (transmissionType == "M")
      return "Manual";

    else if (transmissionType == "A")
      return "Automatic";

    else
      return "";
  }

  models = [];

  getAllModels() {
    this.models = [];

    this.api.getAllModels(0, 0, 'NAME', 'asc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.models = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  MODEL_ID = [];

  applyFilter() {
    if ((this.MODEL_ID.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.MODEL_ID = [];
    this.search(true);
  }

  onStatusChange(data: VarientMaster, status) {
    data.STATUS = status;

    this.api.updateVarient(data).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Status", "");

      this.search();
    });
  }
}
