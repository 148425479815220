import { Component, OnInit } from '@angular/core';
import { cmsFaqHead1 } from 'src/app/Models/cmsFaqHead1';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-faqs-headlist',
  templateUrl: './faqs-headlist.component.html',
  styleUrls: ['./faqs-headlist.component.css']
})
export class FaqsHeadlistComponent implements OnInit {

  formTitle = "Manage FAQ Heads";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["PARENT_NAME", "Parent Name"], ["NAME", "FAQ Head Name"]]
  applicationId = Number(this.cookie.get('applicationId'))
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: cmsFaqHead1 = new cmsFaqHead1();
  constructor(private api: ApiService, private cookie: CookieService,private message:NzNotificationService) { }
  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset)
  {
     this.pageIndex = 1;
  }

  var sort: string;
    try {
      this.sortValue = this.sortValue.startsWith("a") ? "asc" : "desc";
      console.log("an", this.sortValue);
    }
    catch (error) { sort = ""; }

      var likeQuery = ""; if (this.searchText != "")
       {
         likeQuery = " AND";
         this.columns.forEach(column =>
      {
       likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR"; });
       likeQuery = likeQuery.substring(0, likeQuery.length - 2);
   }

     this.loadingRecords = true;

    this.api.getAllFaqHeads1(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, likeQuery).
    subscribe(data => { if (data['code'] == 200)
     {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
  this.dataList = data['data'];
    } },
     err => { if (err['ok'] == false)
      this.message.error("Server Not Found", ""); });
  }

  keyup(event:any) {
    this.search();
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    // this.drawerTitle = "Create New Faq Head";
    // this.drawerData = new  Faqhead();
    // this.drawerData.STATUS=true
    // this.drawerData.IS_PARENT=true
    // this.drawerVisible = true;

    this.drawerTitle = "Create New FAQ Head";
    this.drawerData = new cmsFaqHead1();
    this.api.getAllFaqHeads1(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQUENCE_NO = 1;
      } else {
        this.drawerData.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
      }
    }, err => {
      console.log(err);
    })
    this.drawerData.ORG_ID = null;
    this.drawerData.PARENT_ID = null;
    this.drawerData.NAME = null;
    this.drawerData.DESCRIPTION = null;
    // this.drawerData.ORG_ID=null;

    this.drawerVisible = true;


  }
  edit(data: cmsFaqHead1): void {
    console.log(data)
    this.drawerTitle = "Update FAQ Head";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

}
