import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Membermaster } from 'src/app/Models/MemberMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-member-upload',
  templateUrl: './member-upload.component.html',
  styleUrls: ['./member-upload.component.css']
})

export class MemberUploadComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() drawerVisible: boolean;
  stringJson: any;
  stringObject: any;
  str = '';
  newArray = [];
  f: any;
  isSpinning: boolean = false;
  federationID = this._cookie.get("FEDERATION_ID");
  unitID = this._cookie.get("UNIT_ID");
  groupID = this._cookie.get("GROUP_ID");

  constructor(private datePipe: DatePipe, private api: ApiService, private message: NzNotificationService, private _cookie: CookieService) { }

  getIDs() {
    let unitFilter = "";
    unitFilter = " AND ID=(SELECT UNIT_ID FROM group_master WHERE ID=" + this.groupID + ")";

    this.api.getAllUnits(0, 0, "NAME", "asc", unitFilter).subscribe(data => {
      if ((data['code'] == 200) && (data['count'] > 0)) {
        this.unitIDForImport = data['data'][0]["ID"];
        this.federationIDForImport = data['data'][0]["FEDERATION_ID"];
        this.groupIDForImport = Number(this.groupID);
      }

    }, err => {
      console.log(err);
    });
  }

  ngOnInit() {
    this.getIDs();
  }

  fileReaded: any;
  nm;

  csv2Array(fileInput: any) {
    this.fileReaded = fileInput.target.files[0];
    this.nm = this.fileReaded.name
    let reader: FileReader = new FileReader();

    reader.readAsText(this.fileReaded);
    reader.onload = (e) => {
      let csv: any = reader.result;
      let allTextLines = csv.split(/\r|\n|\r/);
      let headers = allTextLines[0].split(',');
      let lines = [];

      for (let i = 0; i < allTextLines.length; i++) {
        let data = allTextLines[i].split(',');
        if (data.length === headers.length) {
          let tarr = [];

          for (let j = 0; j < headers.length; j++) {
            tarr.push(data[j]);
          }

          lines.push(tarr);
        }
      }

      lines.splice(0, 1);

      this.newArray = lines.map(function (item) {
        return {
          "TEMP_ID": Number(Math.floor(Math.random() * 1E8)),
          "TEMP_STATUS": "Pending",
          "NAME": item[0],
          "MOBILE_NUMBER": item[1],
          "EMAIL_ID": item[2],
          "GENDER": item[3],
          "DOB": item[4],
          "MARITAL_STATUS": item[5],
          "ANNIVERSARY_DATE": item[6],
          "ADDRESS1": item[7],
          "ADDRESS2": item[8],
          "CITY": item[9],
          "PINCODE": item[10],
          "MEMBERSHIP_DATE": item[11],
          "EXPIRY_DATE": item[12]
        }
      })

      console.log(this.newArray);
    }
  }

  deletefile() {
    this.nm = '';
    this.newArray = [];
  }

  clear() {
    this.nm = '';
    this.newArray = [];
  }

  columns: string[][] = [["TEMP_STATUS", "Status"], ["NAME", "Name"], ["MOBILE_NUMBER", "Mobile No."], ["EMAIL_ID", "Email"], ["GENDER", "Gender"], ["DOB", "DOB"], ["MARITAL_STATUS", "Marital Status"], ["ANNIVERSARY_DATE", "Anniversary Date"], ["ADDRESS1", "Address 1"], ["ADDRESS2", "Address 2"], ["CITY", "City"], ["PINCODE", "Pincode"], ["MEMBERSHIP_DATE", "Membership From"], ["EXPIRY_DATE", "Membership Upto"]];

  close(): void {
    this.drawerClose();
  }

  federationIDForImport: number = 0;
  unitIDForImport: number = 0;
  groupIDForImport: number = 0;
  uploadedMembersCount: number = 0;
  duplicateMembersCount: number = 0;
  failedToUploadMembersCount: number = 0;
  dataListAfterImport = [];

  importData() {
    this.dataListAfterImport = [];

    if ((this.newArray.length == 0)) {
      this.message.error("Please Select CSV File", "");
    }

    if ((this.newArray.length > 0) && (this.federationIDForImport != 0) && (this.unitIDForImport != 0) && (this.groupIDForImport != 0)) {
      for (var i = 0; i < this.newArray.length; i++) {
        if (this.newArray[i]["MOBILE_NUMBER"].trim() != "") {
          let gender = null;
          if ((this.newArray[i]["GENDER"].trim()).toLowerCase() == "male")
            gender = "M";

          else if ((this.newArray[i]["GENDER"].trim()).toLowerCase() == "female")
            gender = "F";

          else if ((this.newArray[i]["GENDER"].trim()).toLowerCase() == "other")
            gender = "F";

          else
            gender = null;

          let maritalStatus = null;
          if ((this.newArray[i]["MARITAL_STATUS"].trim()).toLowerCase() == "single")
            maritalStatus = "S";

          else if ((this.newArray[i]["MARITAL_STATUS"].trim()).toLowerCase() == "married")
            maritalStatus = "M";

          else if ((this.newArray[i]["MARITAL_STATUS"].trim()).toLowerCase() == "widowed")
            maritalStatus = "W";

          else if ((this.newArray[i]["MARITAL_STATUS"].trim()).toLowerCase() == "divorced")
            maritalStatus = "D";

          else if ((this.newArray[i]["MARITAL_STATUS"].trim()).toLowerCase() == "separated")
            maritalStatus = "E";

          else
            maritalStatus = null;

          var memberData = new Membermaster();
          memberData.NAME = this.newArray[i]["NAME"];
          memberData.MOBILE_NUMBER = this.newArray[i]["MOBILE_NUMBER"];
          memberData.EMAIL_ID = this.newArray[i]["EMAIL_ID"];
          memberData.PASSWORD = this.generate8DigitRandomNumber();
          memberData.GENDER = gender;
          memberData.DOB = ((this.newArray[i]["DOB"]).trim() != "") ? this.datePipe.transform(this.newArray[i]["DOB"], "yyyy-MM-dd") : null;
          memberData.MARITAL_STATUS = maritalStatus;
          memberData.ANNIVERSARY_DATE = ((this.newArray[i]["ANNIVERSARY_DATE"]).trim() != "") ? this.datePipe.transform(this.newArray[i]["ANNIVERSARY_DATE"], "yyyy-MM-dd") : null;
          memberData.ADDRESS1 = this.newArray[i]["ADDRESS1"];
          memberData.ADDRESS2 = this.newArray[i]["ADDRESS2"];
          memberData.CITY = this.newArray[i]["CITY"];
          memberData.PINCODE = this.newArray[i]["PINCODE"];
          memberData.MEMBERSHIP_DATE = ((this.newArray[i]["MEMBERSHIP_DATE"]).trim() != "") ? this.datePipe.transform(this.newArray[i]["MEMBERSHIP_DATE"], "yyyy-MM-dd") : null;
          memberData.EXPIRY_DATE = ((this.newArray[i]["EXPIRY_DATE"]).trim() != "") ? this.datePipe.transform(this.newArray[i]["EXPIRY_DATE"], "yyyy-MM-dd") : null;
          memberData.ACTIVE_STATUS = "A";
          memberData.FEDERATION_ID = this.federationIDForImport;
          memberData.UNIT_ID = this.unitIDForImport;
          memberData.GROUP_ID = this.groupIDForImport;
          memberData["TEMP_ID"] = this.newArray[i]["TEMP_ID"];
          memberData["TEMP_STATUS"] = this.newArray[i]["TEMP_STATUS"];
          let tempData = memberData;
          this.isSpinning = true;

          this.api.createMember(memberData).subscribe(successCode => {
            if (successCode['code'] == 200) {
              // this.message.success("Member Created Successfully", "");
              this.isSpinning = false;
              this.uploadedMembersCount = (this.uploadedMembersCount) + 1;
              tempData["TEMP_STATUS"] = "Upload";
              this.dataListAfterImport.push(tempData);
              this.updateImportedArray();

            } else if (successCode['code'] == 300) {
              // this.message.error("Duplicate Member", "");
              this.isSpinning = false;
              this.duplicateMembersCount = (this.duplicateMembersCount) + 1;
              tempData["TEMP_STATUS"] = "Duplicate";
              this.dataListAfterImport.push(tempData);
              this.updateImportedArray();

            } else {
              // this.message.error("Member Creation Failed", "");
              this.isSpinning = false;
              this.failedToUploadMembersCount = (this.failedToUploadMembersCount) + 1;
              tempData["TEMP_STATUS"] = "Failed";
              this.dataListAfterImport.push(tempData);
              this.updateImportedArray();
            }
          });

        } else {
          this.failedToUploadMembersCount = (this.failedToUploadMembersCount) + 1;
        }
      }
    }
  }

  updateImportedArray() {
    if (this.newArray.length == this.dataListAfterImport.length) {
      this.newArray = this.dataListAfterImport;
    }
  }

  generate8DigitRandomNumber() {
    return String(Math.floor(Math.random() * 1E8));
  }

  cancel(): void { }
}
