import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { letestnews } from 'src/app/Models/letestnews';
// import { ProjectMaster } from 'src/app/Models/ProjectMaster';
import { ApiService } from 'src/app/Service/api.service';


@Component({
  selector: 'app-latestnews',
  templateUrl: './latestnews.component.html',
  styleUrls: ['./latestnews.component.css']
})
export class LatestnewsComponent implements OnInit {

  formTitle = "Projects";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [["WEB_STATUS", "Show In Web"],["DATE_OF_PROJECT", "Date"], ["PROJECT_NAME", "Project Name"], ["GROUP_NAME", "Group Name"], ["PROJECT_TYPE_NAME", "Project Type"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: letestnews = new letestnews();
  isSpinning = false;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search(true);
  }
  keyup(event: any) {
    this.search();
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      this.sortValue = this.sortValue.startsWith("a") ? "asc" : "desc";
      console.log("an", this.sortValue);
    }
    catch (error) { sort = ""; }

    var likeQuery = ""; if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    this.loadingRecords = true;

    this.api.getproject(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, likeQuery).
      subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
  }



  sort(sort: { key: string; value: string }):
    void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    console.log("SORT", this.sortValue);
    this.search(true);
  }




  today = new Date().setDate(new Date().getDate() + 4);

  // add(): void {
  //   this.drawerTitle = "Add Group Target Area";
  //   this.drawerData = new letestnews();
  //   this.drawerVisible = true;
  //   this.drawerData.STATUS = true;
  // }

  // edit(data: letestnews): void {
  //   this.drawerTitle = "Update Group Target Area";
  //   this.drawerData = Object.assign({}, data);
  //   this.drawerVisible = true;
  // }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }

  onStatusChange(data: letestnews, status) {
    data.WEB_STATUS = status;

    if(data.WEB_STATUS == true){

      data.IS_ALL_PROJECT_UPDATE = false;
    }else{
      data.IS_ALL_PROJECT_UPDATE = true;
    }

    this.api.updateproject(data).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Status", "");

      this.search();
    });
  }

  // 

  allStatusChange(status) {
    this.drawerData.IS_ALL_PROJECT_UPDATE = status;

    if(this.drawerData.IS_ALL_PROJECT_UPDATE == true){
      this.drawerData.WEB_STATUS = true
    }else{
      this.drawerData.WEB_STATUS = false
    }

    this.api.updateproject(this.drawerData).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Status", "");

      this.search();
    });
  }
}
