import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzDatePickerComponent, NzNotificationService } from 'ng-zorro-antd';
import { Leave } from 'src/app/Models/Leave';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-leave-approve-drawer',
  templateUrl: './leave-approve-drawer.component.html',
  styleUrls: ['./leave-approve-drawer.component.css']
})

export class LeaveApproveDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: Leave;
  @Input() drawerVisible: boolean;
  isSpinning = false;

  leaveTypes = [];

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getLeaveTypes();
  }

  getLeaveTypes() {
    this.leaveTypes = [];

    this.api.getAllLeaveType(0, 0, 'NAME', 'asc', ' and IS_ACTIVE=1').subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.leaveTypes = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    console.log(this.data);
    var isOk = true;

    if (this.data.FROM_DATE == undefined) {
      isOk = false;
      this.message.error("Please Select Valid From Date", "");
    }

    if (this.data.TO_DATE != undefined) {
      let givenTime = new Date(this.datePipe.transform(this.data.FROM_DATE, "yyyy-MM-dd"));
      let lateTime = new Date(this.datePipe.transform(this.data.TO_DATE, "yyyy-MM-dd"));

      if ((givenTime.getTime()) > (lateTime.getTime())) {
        isOk = false;
        this.message.error("Please Select Valid To Date", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Select Valid To Date", "");
    }

    if (!this.APPROVE) {
      if (this.REMARK == undefined || this.REMARK.trim() == "") {
        isOk = false;
        this.message.error("Please Enter Valid Remark", "");
      }
    }

    if (isOk) {
      this.isSpinning = true;

      if (this.REMARK == undefined || this.REMARK.trim() == "")
        this.REMARK = "No Remark";

      this.data.APPROVER_ID = this.api.userId;
      this.data.FROM_DATE = this.datePipe.transform(this.data.FROM_DATE, "yyyy-MM-dd");
      this.data.TO_DATE = this.datePipe.transform(this.data.TO_DATE, "yyyy-MM-dd");

      if (this.APPROVE) {
        this.data.STATUS = "A";
        this.data.HEAD_REMARK = this.REMARK;
        this.data.REJECT_REMARK = this.REMARK;

      } else {
        this.data.STATUS = "R";
        this.data.HEAD_REMARK = this.REMARK;
        this.data.REJECT_REMARK = this.REMARK;
      }

      console.log(this.data);

      if (this.data.ID) {
        this.api.updateLeave(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Successfully Leave Approved by HR", "");

            if (!addNew)
              this.close(myForm);
            this.isSpinning = false;

          } else {
            this.message.error("Failed to Leave Approve", "");
            this.isSpinning = false;
          }
        });

      } else {
        console.log(this.data);

        // this.api.createLeave(this.data).subscribe(successCode => {
        //   if (successCode['code'] == "200") {
        //     this.message.success("Leave Created Successfully", "");

        //     if (!addNew)
        //       this.close(myForm);

        //     else {
        //       this.data = new Leave();
        //     }

        //     this.isSpinning = false;

        //   } else {
        //     this.message.error("Leave Creation Failed", "");
        //     this.isSpinning = false;
        //   }
        // });
      }
    }
  }

  createDate(date, date1) {
    this.today = new Date(date);
    this.today1 = new Date(date1);
  }

  today: any;
  today1: any;
  // today = new Date().setDate(new Date().getDate());

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  disabledStartDate1 = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today1) > 0;

  @ViewChild('moduleEndDatePicker', { static: false }) moduleEndDatePicker: NzDatePickerComponent;

  moduleStartDateHandle(open: boolean) {
    if (!open) {
      this.data.TO_DATE = null;
      this.data.TO_DATE = this.data.FROM_DATE;

      // this.moduleEndDatePicker.open();
    }
  }

  disabledEndDate = (endValue: Date): boolean => {
    if (this.data.FROM_DATE != null) {
      if (!endValue) {
        return false;
      }

      var modulePreviousDate = new Date(this.data.FROM_DATE);
      modulePreviousDate.setDate(modulePreviousDate.getDate() + (-1));

      return endValue <= new Date(modulePreviousDate);
    };
  }

  APPROVE: boolean = true;
  REMARK: string;

}
