import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { CircularTypeMaster } from 'src/app/Models/Circulartype';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-listcirculartype',
  templateUrl: './listcirculartype.component.html',
  styleUrls: ['./listcirculartype.component.css']
})

export class ListcirculartypeComponent implements OnInit {
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: CircularTypeMaster = new CircularTypeMaster();
  formTitle = "Manage Circular Type";
  dataList = [];
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [['NAME', 'Name'], ['SEQ_NO', 'Sequence No.'], ['STATUS', 'Status']];

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _cookie: CookieService) { }

  ngOnInit(): void {
    this.loadingRecords = false;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';

    } catch (error) {
      sort = '';
    }

    var likeQuery = '';
    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    this.loadingRecords = true;
    this.api.getAllCircularType(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe((data) => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, (err) => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search(false);
    this.drawerVisible = false;
  }

  add(): void {
    this.drawerTitle = 'Add Circular Type';
    this.drawerData = new CircularTypeMaster();
    this.api.getAllCircularType(0, 0, 'SEQ_NO', 'desc', '').subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQ_NO = 1;

      } else {
        this.drawerData.SEQ_NO = data['data'][0]['SEQ_NO'] + 1;
      }

    }, err => {
      console.log(err);
    })

    this.drawerVisible = true;
  }

  edit(data: CircularTypeMaster): void {
    this.drawerTitle = 'Update Circular Type';
    this.drawerData = Object.assign({}, data);
    localStorage.setItem('editData', JSON.stringify(data.ID));
    this.drawerVisible = true;
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  onStatusChange(data: CircularTypeMaster, status) {
    data.STATUS = status;

    this.api.updateCircularType(data).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Status", "");

      this.search(false);
    });
  }
}