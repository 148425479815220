import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { federation } from 'src/app/Models/federstion';
import { TotalUnites } from 'src/app/Models/TotalUnites';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-listunites',
  templateUrl: './listunites.component.html',
  styleUrls: ['./listunites.component.css']
})
export class ListunitesComponent implements OnInit {

  formTitle = "Unites";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";

  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [["WEB_UNIT_STATUS", "Show In Web"], ["NAME", "Unit Name"], ["FEDERATION_NAME", "Federation Name"], ["BO_DATE", "Date"],];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: TotalUnites = new TotalUnites();
  isSpinning = false;
  filterClass: string = 'filter-invisible';

  federation: federation[] = []
  FEDERATION_ID: any;

  startValue: any = new Date();
  endValue: any = new Date();
  mode = 0;
  isFilterApplied: string = 'default';

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search(true);
    this.getname();
  }
  keyup(event: any) {
    this.search();
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      this.sortValue = this.sortValue.startsWith("a") ? "asc" : "desc";
      console.log("an", this.sortValue);
    }
    catch (error) { sort = ""; }


    if (this.FEDERATION_ID != undefined && this.FEDERATION_ID > 0) {
      this.filterQuery =
        this.filterQuery + ' AND FEDERATION_ID=' + "'" + this.FEDERATION_ID + "'";
    }

    var likeQuery = ""; if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    this.loadingRecords = true;

    this.api.getunit(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, this.filterQuery + likeQuery).
      subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
  }



  sort(sort: { key: string; value: string }):
    void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    console.log("SORT", this.sortValue);
    this.search(true);
  }




  today = new Date().setDate(new Date().getDate() + 4);

  // add(): void {
  //   this.drawerTitle = "Add Group Target Area";
  //   this.drawerData = new letestnews();
  //   this.drawerVisible = true;
  //   this.drawerData.STATUS = true;
  // }

  // edit(data: letestnews): void {
  //   this.drawerTitle = "Update Group Target Area";
  //   this.drawerData = Object.assign({}, data);
  //   this.drawerVisible = true;
  // }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }

  onStatusChange(data: TotalUnites, status) {
    data.WEB_UNIT_STATUS = status;

    if ( data.WEB_UNIT_STATUS == true) {

      data.IS_ALL_UPDATE = false;
      
    } else {
      data.IS_ALL_UPDATE = true;
    }

    this.api.updateunite(data).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Status", "");

      this.search();
    });
  }

  // 

  allStatusChange(status) {
    this.drawerData.IS_ALL_UPDATE = status;

    if ( this.drawerData.IS_ALL_UPDATE == true) {
      this.drawerData.WEB_UNIT_STATUS = true;
    } else {
      this.drawerData.WEB_UNIT_STATUS = false; 
    }

    // data.WEB_UNIT_STATUS = status;

    this.api.updateunite(this.drawerData).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Status", "");

      this.search();
    });
  }



  getname() {

    this.api.getDropdownfederation(0, 0, 'NAME', 'asc', "").subscribe(data => {
      this.federation = data['data'];
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }


  // getname() {

  //   this.api.getDropdownfederation(0, 0, 'NAME', 'asc', "AND WEB_UNIT_STATUS").subscribe(data => {
  //     this.federation = data['data'];
  //   }, err => {
  //     console.log(err);
  //     this.isSpinning = false;
  //   });
  // }

  showFilter(): void {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';
   

  }



  applyFilter() {

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    this.filterQuery = '';
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd');


    if (this.FEDERATION_ID != undefined && this.FEDERATION_ID > 0) {
      this.filterQuery =
        this.filterQuery + ' AND FEDERATION_ID=' + "'" + this.FEDERATION_ID + "'";
    }


    var likeQuery = '';

    if (this.searchText != '') {
      likeQuery = ' AND (';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ' )';
    }
    this.api
      .getunit(
        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        this.filterQuery

      )
      .subscribe(
        (data) => {
          console.log(data);
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        },
        (err) => {
          console.log(err);
        }
      );


  

  }



  clearFilter() {

    this.FEDERATION_ID = null;
    this.filterQuery = '';
    this.applyFilter();


  }

}
