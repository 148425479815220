export class TrainerMaster {
    ID: number;
    NAME: string;
    MOBILE_NO: number;
    EMAIL_ID: string;
    SUBJECT_ID: number = 1;
    IS_ACTIVE: Boolean = true;
    POSITION_ID: number = 0;
    PASSWORD: string;
    ROLE_ID: any = 18;
    ROLE_NAME: string;
}