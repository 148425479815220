import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzButtonType } from 'ng-zorro-antd/button';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { GroupMeetAttendance } from 'src/app/Models/GroupMeetAttendance';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-groupmeetattendiesmap',
  templateUrl: './groupmeetattendiesmap.component.html',
  styleUrls: ['./groupmeetattendiesmap.component.css']
})

export class GroupmeetattendiesmapComponent implements OnInit {
  @Input() drawerClose!: Function;
  @Input() data: GroupMeetAttendance = new GroupMeetAttendance();
  @Input() trainattendiesData: any[] = [];
  @Input() MEETING_ID: any;
  @Input() drawerVisible: boolean = false;
  isSpinning = false
  formTitle = "Training Attendies Mapping";
  loadingRecords = false;
  isOk = true;
  filterQuery: string = "";
  filterValue = []
  isFilterApplied: NzButtonType = "default";
  isloadSpinning = false;
  exportSpinning = false;
  filterClass: string = "filter-invisible";
  current = new Date();
  isStatusSpinning = false;
  pageIndex = 1;
  pageSize = 10;
  @Input() totalRecords = 1;
  dataList: any = [];
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  DESIGNSTION_ID = 0;
  DEPARTMENT_ID = 0;
  departmentnm: any = [];
  designationm: any = [];
  a: any = [];
  b: any = [];
  dataList2 = [];
  column = [['MEETING_ID', 'Meeting Id'], ['MEMBER_NAME', 'Member Name'], ['P_A', 'Is Attending'], ["CLIENT_ID", "Client Id"]];

  constructor(private api: ApiService, private router: Router, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() { }

  close(): void {
    this.drawerClose();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.dataList = [];
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND";
      this.column.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    var filter = "";

    if (likeQuery)
      filter = this.filterQuery + likeQuery;
    else
      filter = this.filterQuery;

    this.a = this.departmentnm.toString();
    this.b = this.designationm.toString();

    this.api.getAllgroupMeetingAttendanceDetails(this.MEETING_ID).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        this.trainattendiesData = this.dataList;
      }

    }, err => {
      console.log(err);
    });
  }

  CLIENT_ID = 1;

  save() {
    this.isSpinning = false;

    this.api.addBulkgroupMeetingAttendance(Number(this.CLIENT_ID), Number(this.MEETING_ID), this.trainattendiesData).subscribe(data => {
      if (data['code'] == 200) {
        this.message.success("Group Meeting Attendance Created Successfully", "");
        this.drawerClose();
        this.isSpinning = false;
        this.drawerVisible = false;

      } else {
        this.message.error("Group Meeting Attendance Creation Failed", "");
        this.isSpinning = false;
      }

    }, err => {
      this.isSpinning = false;
    });
  }
}
