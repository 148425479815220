import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ModelMaster } from 'src/app/Models/ModelMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-model-master',
  templateUrl: './model-master.component.html',
  styleUrls: ['./model-master.component.css']
})

export class ModelMasterComponent implements OnInit {
  formTitle = "Manage Models";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [["NAME", "Name"], ["CODE", "Code"], ["SEQUENCE_NO", "Sequence No."], ["STATUS", "Active"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: ModelMaster = new ModelMaster();
  isSpinning = false;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search(true);
    this.getSequenceNo();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    this.api.getAllModels(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  today = new Date().setDate(new Date().getDate() + 4);

  add(): void {
    this.drawerTitle = "Add Model";
    this.drawerData = new ModelMaster();
    this.drawerVisible = true;
    this.drawerData.STATUS = true;
    this.drawerData.SEQUENCE_NO = this.seqNo;
  }

  seqNo: number;

  getSequenceNo() {
    this.api.getAllModels(1, 1, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200 && data['count'] > 0) {
        this.seqNo = Number(data['data'][0]['SEQUENCE_NO']) + 1;

      } else {
        this.seqNo = 1;
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  edit(data: ModelMaster): void {
    this.drawerTitle = "Update Model Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search(true);
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }

  onStatusChange(data: ModelMaster, status) {
    data.STATUS = status;

    this.api.updateModel(data).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Status", "");

      this.search();
    });
  }
}
