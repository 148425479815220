import { Component, OnInit, ElementRef } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../Service/api.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Membermaster } from '../Models/MemberMaster';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.css'],
  providers: [DatePipe]
})

export class ResigsterComponent implements OnInit {
  years = []
  colleges: any[] = [];
  branches: any[] = [];
  mobileVerified: boolean = false;
  emailVerified: boolean = false;
  mobileOTP: boolean = false;
  emailOTP: boolean = false;
  emailLoding: boolean = false;
  mobileLoading: boolean = false;
  MOBILE_OTP_TEXT: string = "Get OTP";
  EMAIL_OTP_TEXT: string = "Get OTP";
  data = new Membermaster();
  EXPIRY_MSG = ""
  checked = false;
  eopt: string = ""
  mopt: string = ""

  maxTimeEmail: any = 180
  timerEmail: any;
  maxTimeMobile: any = 180
  timerMobile: any;
  actualMobile = "";
  actualEmail = "";
  isSpinning = false
  EMAIL_OTP_NUMBER = "";
  MOBILE_OTP_NUMBER = "";
  registerationDone = false;

  expiryDate = "2023-1-31 23:55:00"
  expirtVisible = false
  currentDate: Date
  currentDate1: string
  deviceId = ""

  states: any[] = [];
  showInfo = true;
  windowScrolled = false;
  isAlreadyemail = false;
  RESUME_URL = null;
  isokfile = false;
  RESUME_FILE_URL: string;
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss');
  namePattern = "([A-Za-z0-9 \s]){1,}";
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  mobpattern = /^[6-9]\d{9}$/;
  constructor(private el: ElementRef, private route: ActivatedRoute, private datePipe: DatePipe, private api: ApiService, private cookie: CookieService, private message: NzNotificationService) {
  }

  ngOnInit() {
    this.getExpiry();
    this.getKey();

    window.addEventListener('scroll', () => {
      if (window.pageYOffset > window.innerHeight - 50)
        this.windowScrolled = true;
    });

  }

  // increaseVisitorCount() {
  //   this.api.increaseVisitorCount({ IS_FIRST: 1, IS_SECOND: 0 }).subscribe(localName => {
  //   }, err => {
  //   });
  // }

  // increaseVisitorCount2() {
  //   this.api.increaseVisitorCount({ IS_FIRST: 0, IS_SECOND: 1 }).subscribe(localName => {
  //   }, err => {
  //   });
  // }

  randomstring(L) {
    var s = '';
    var randomchar = function () {
      var n = Math.floor(Math.random() * 62);
      if (n < 10) return n; //1-10
      if (n < 36) return String.fromCharCode(n + 55); //A-Z
      return String.fromCharCode(n + 61); //a-z
    }
    while (s.length < L) s += randomchar();
    return s;
  }

  getKey() {
    // let url = window.location.href
    // var arr = url.split("/")
    this.deviceId = this.randomstring(16);
  }

  loadFederations() {
    this.isSpinning = true;
    var likeQuery = "";

    this.api.getAllFederations2(likeQuery).subscribe(localName => {
      this.states = localName['data'];
      this.isSpinning = false;

    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  stateChange(stateId) {
    this.loadColleges(stateId);
  }

  loadColleges(stateId) {
    this.isSpinning = true;
    var likeQuery = " AND FEDERATION_ID=" + stateId;

    this.colleges = [];
    this.data.UNIT_ID = undefined;

    this.branches = [];
    this.data.GROUP_ID = undefined;

    this.api.getAllUnits2(likeQuery).subscribe(localName => {
      this.colleges = localName['data'];
      this.isSpinning = false;

    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  loadColleges2(stateId) {
    this.isSpinning = true;
    var likeQuery = " AND  FEDERATION_ID=" + stateId;

    this.colleges = [];
    this.data.UNIT_ID = undefined;

    this.branches = [];
    this.data.GROUP_ID = undefined;

    this.api.getAllUnits2(likeQuery).subscribe(localName => {
      this.colleges = localName['data'];
      this.isSpinning = false;

    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  loadBranches(UNIT_ID) {
    this.isSpinning = true;
    var likeQuery = " AND UNIT_ID=" + UNIT_ID;

    this.branches = [];
    this.data.GROUP_ID = undefined;

    this.api.getAllGroup2(likeQuery).subscribe(localName => {
      this.branches = localName['data'];
      this.isSpinning = false;

    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  generateRandomNumber(n) {
    return Math.floor(Math.pow(10, n - 1) + Math.random() * (Math.pow(10, n) - Math.pow(10, n - 1) - 1));
    // return Math.floor(Math.random() * (9 * Math.pow(10, n - 1))) + Math.pow(10, n - 1);
  }

  isValidEmail(email) {
    const expression = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return expression.test(String(email).toLowerCase())
  }

  isValidMobile(mobile) {
    const expression = /^[6-9]\d{9}$/;
    return expression.test(String("" + mobile).toLowerCase())
  }
  isValidOTP(mobile) {
    const expression = /^\d{6}$/;
    return expression.test(String("" + mobile).toLowerCase())
  }

  StartTimerEmail() {
    this.timerEmail = setTimeout(x => {
      if (this.maxTimeEmail <= 0) { }
      this.maxTimeEmail -= 1;

      if (this.maxTimeEmail > 0) {
        this.EMAIL_OTP_TEXT = "(" + this.maxTimeEmail + ")";
        this.StartTimerEmail();
      }

      else {
        this.maxTimeEmail = 180;
        this.emailLoding = false;
        this.EMAIL_OTP_TEXT = "Resend";
      }
    }, 1000);
  }

  checkEmailOtp() {
    if (!this.isValidOTP(this.eopt)) {
      this.message.error("Please enter valid OTP...", "");
    } else {
      if (this.eopt.length == 6) {
        if (this.EMAIL_OTP_NUMBER == this.eopt) {
          this.emailVerified = true;
          this.emailOTP = false;
          this.data.EMAIL_ID = this.actualEmail;
          if (this.isAlreadyemail == true)
            this.message.success("You are already Registered with us. check your details below & fill out the empty fields if any.", "");
          else
            this.message.success("Your email id verified successfully", "");
        }
        else {
          this.eopt = "";
          document.getElementById('eopt').focus();
          this.message.error("OTP is incorrect", "");
        }
      }
      else {
        document.getElementById('eopt').focus();
        this.message.error("Please enter valid OTP", "");
      }
    }
  }

  isValid(name) {
    const expression = /^[A-Za-z\s]*$/;
    return expression.test(String("" + name).toLowerCase())
  }

  register() {
    var isOk = true;

    if (this.mobileVerified == false) {
      isOk = false;
      this.message.error('Please Verify Your Mobile Number', '');

    } else {
      if (this.data.FEDERATION_ID == undefined || this.data.FEDERATION_ID == null) {
        isOk = false;
        this.message.error('Please Select Federation', '');
      }

      if (this.data.UNIT_ID == undefined || this.data.UNIT_ID == null) {
        isOk = false;
        this.message.error('Please Select Unit', '');
      }

      if (this.data.GROUP_ID == undefined || this.data.GROUP_ID == null) {
        isOk = false;
        this.message.error('Please Select Group', '');
      }

      if (this.data.NAME != undefined && this.data.NAME != null) {
        if (this.data.NAME.trim() != '') {
          if (!this.api.checkTextBoxIsValid(this.data.NAME)) {
            isOk = false;
            this.message.error("Please Enter Valid Name", "");
          }

        } else {
          isOk = false;
          this.message.error("Please Enter Valid Name", "");
        }

      } else {
        isOk = false;
        this.message.error("Please Enter Valid Name", "");
      }

      if (this.data.GENDER == undefined || this.data.GENDER == null) {
        isOk = false;
        this.message.error('Please Select Gender', '');
      }

      if (this.data.DOB == undefined || this.data.DOB == null) {
        isOk = false;
        this.message.error('Please Select Birth Date', '');
      }

      if (this.data.ADDRESS1 != undefined && this.data.ADDRESS1 != null) {
        if (this.data.ADDRESS1.trim() == '') {
          isOk = false;
          this.message.error("Please Enter Valid Address", "");
        }

      } else {
        isOk = false;
        this.message.error("Please Enter Valid Address", "");
      }

      if (this.data.CITY != undefined && this.data.CITY != null) {
        if (this.data.CITY.trim() == '') {
          isOk = false;
          this.message.error("Please Enter Valid City", "");
        }

      } else {
        isOk = false;
        this.message.error("Please Enter Valid City", "");
      }

      if ((this.data.PINCODE != undefined) && (this.data.PINCODE.toString().trim() != '')) {
        if (/^[1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3}$/.test(this.data.PINCODE.toString()) == false) {
          isOk = false;
          this.message.error('Please Fill Valid Pincode', '');
        }

      } else {
        isOk = false;
        this.message.error('Please Fill Valid Pincode', '');
      }
    }

    if (isOk) {
      this.isSpinning = true;
      this.data.IS_FEDERATION_OFFICER = true;
      this.data.ACTIVE_STATUS = 'P';
      this.data.INCHARGE_OF = null;
      this.data.PASSWORD = '';
      this.data.DOB = this.datePipe.transform(this.data.DOB, "yyyy-MM-dd");

      if (this.data.ANNIVERSARY_DATE)
        this.data.ANNIVERSARY_DATE = this.datePipe.transform(this.data.ANNIVERSARY_DATE, "yyyy-MM-dd");

      else {
        let defaultDate = new Date(1900, 0, 1);
        this.data.ANNIVERSARY_DATE = this.datePipe.transform(defaultDate, "yyyy-MM-dd");
      }

      this.data.MEMBERSHIP_DATE = null;
      this.data.EXPIRY_DATE = null;

      this.api.createMember2(this.data).subscribe(successCode => {
        console.log(successCode);

        if (successCode['code'] == "200") {
          this.message.success("Registration done successfully...", "");
          this.registerationDone = true;
          this.isSpinning = false;

        } else {
          this.message.error("Failed to register, please contact +91 9623276360", "");
          this.isSpinning = false;
        }
      });
    }
  }

  getMobileOTP() {
    if (this.isValidMobile(this.data.MOBILE_NUMBER)) {
      if (this.MOBILE_OTP_TEXT == "Get OTP") {
        this.isSpinning = true;
        // sendMobile
        this.MOBILE_OTP_NUMBER = "" + this.generateRandomNumber(6);

        this.api.sendSMS(this.data.MOBILE_NUMBER).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("OTP sent successfully to your mobile number, please check your whatsapp", "");
            this.mobileLoading = true;
            this.MOBILE_OTP_TEXT = "(" + this.maxTimeMobile + ")";
            this.mobileOTP = true;
            this.mopt = "";
            this.StartTimerMobile();
            this.actualMobile = this.data.MOBILE_NUMBER;
            this.isSpinning = false;

          } else if (successCode['code'] == "300") {
            this.message.error("This mobile no. is already registered with us.Please use another mobile number.", "");
            this.isSpinning = false;

          } else {
            this.message.error(successCode['message'], "");
            this.isSpinning = false;
          }
        });

      } else {
        // this.MOBILE_OTP_NUMBER = "" + this.generateRandomNumber(6);
        // resend OTP
        this.isSpinning = true;

        this.api.resendSMS(this.data.MOBILE_NUMBER).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("OTP sent successfully to your mobile number, please check your whatsapp", "");
            this.mobileLoading = true;
            this.MOBILE_OTP_TEXT = "(" + this.maxTimeMobile + ")";
            this.mobileOTP = true;
            this.mopt = "";
            this.StartTimerMobile();
            this.actualMobile = this.data.MOBILE_NUMBER;
            this.isSpinning = false;

          } else if (successCode['code'] == "300") {
            this.message.error("This mobile no. is already registered with us.Please use another mobile no.", "");
            this.isSpinning = false;

          } else {
            this.message.error(successCode['message'], "");
            this.isSpinning = false;
          }
        });
      }

    } else {
      this.message.error("Please enter valid mobile number", "");
    }
  }

  StartTimerMobile() {
    this.timerMobile = setTimeout(x => {

      if (this.maxTimeMobile <= 0) { }
      this.maxTimeMobile -= 1;

      if (this.maxTimeMobile > 0) {
        this.MOBILE_OTP_TEXT = "(" + this.maxTimeMobile + ")";
        this.StartTimerMobile();
      }

      else {
        this.maxTimeMobile = 180;
        this.mobileLoading = false;
        this.MOBILE_OTP_TEXT = "Resend";
      }
    }, 1000);
  }

  checkMobileOtp() {
    if (!this.isValidOTP(this.mopt)) {
      this.message.error("Please enter valid OTP...", "");
    } else {
      if (this.mopt.length == 6) {
        this.api.verifyOTP(this.data.MOBILE_NUMBER, this.mopt)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.mobileVerified = true;
              this.mobileOTP = false;
              this.data.MOBILE_NUMBER = this.actualMobile;
              this.message.success("Your mobile no. verified successfully", "");


            } else if (successCode['code'] == "300") {
              this.mopt = "";
              document.getElementById('mopt').focus();
              this.message.error("OTP is incorrect", "");
            } else {
              document.getElementById('mopt').focus();
              this.message.error("Something went wrong!", "");
            }
          });
      }
      else {
        this.mopt = "";
        document.getElementById('mopt').focus();
        this.message.error("Please enter valid OTP", "");
      }
    }
  }

  getExpiry() {
    // this.expiryDate=
    this.currentDate = new Date()
    this.currentDate1 = this.datePipe.transform(this.currentDate, "yyyy-MM-dd HH:mm:ss")
    //this.currentDate1="2020-05-30 10:30:00" 

    //  if(this.currentDate1>this.expiryDate || (this.currentDate1>"2020-04-20 09:30:00" && this.currentDate1<"2020-04-20 10:30:00"))
    //  {
    //   this.expirtVisible=false
    //  if(this.currentDate1>this.expiryDate)
    //  {
    //   this.EXPIRY_MSG="Registrations are closed, Please contact your TPO for further details";
    //  }
    //  else
    //  {
    //   this.EXPIRY_MSG="Registration is kept on hold, please check again after 10:30am, 20 April";
    //  }
    //  }
    //  else
    //  { 
    //    this.expirtVisible=true
    //   this.loadColleges();
    //   this.loadBranches();
    //  }

    if (this.currentDate1 > "2020-09-27 23:00:00" && this.currentDate1 < this.expiryDate) {
      this.expirtVisible = true;
      this.loadFederations();

    } else {
      if (this.currentDate1 > this.expiryDate)
        this.EXPIRY_MSG = "Registrations are closed, Please contact your TPO for further details";
      else
        this.EXPIRY_MSG = "Registrations open from Tomorrow 10:00am To 1:00pm, please check again after 10:00am, 30th May";
    }
  }

  numberOnly(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  today = new Date().setDate(new Date().getDate());

  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) > 0;

  disabledExpiryDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  changeMarritalStatus(status) {
    this.data.ANNIVERSARY_DATE = undefined;
  }

  todayForBirth = new Date().setFullYear(new Date().getFullYear() - 18);

  disabledBirthDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.todayForBirth) > 0;

  dobOnOpen(status) {
    if (status)
      this.data.DOB = this.datePipe.transform(this.todayForBirth, "yyyy-MM-dd");;
  }
}
