import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { GroupMaster } from 'src/app/Models/GroupMaster';
import { ApiService } from 'src/app/Service/api.service';
import { ManageGroupMembersComponent } from '../manage-group-members/manage-group-members.component';

@Component({
  selector: 'app-assign-group-member',
  templateUrl: './assign-group-member.component.html',
  styleUrls: ['./assign-group-member.component.css']
})

export class AssignGroupMemberComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: GroupMaster;
  @Input() drawerVisible: boolean;
  isSpinning = false;
  leaveTypes = [];
  namePattern = "([A-Za-z0-9 \s]){1,}";

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getMembers();
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  numberOnly(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  isVisible = false;
  isConfirmLoading = false;
  roleID: number;

  showModal(rID: number): void {
    this.roleID = rID;
    this.isVisible = true;
  }

  NEW_MEMBER_ID: number;

  handleOk(data: GroupMaster): void {
    this.isConfirmLoading = true;

    this.api.assignGroup(this.roleID, data, this.NEW_MEMBER_ID).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Updated Successfully", "");
        this.isConfirmLoading = false;
        this.isVisible = false;

        if (this.roleID == 1)
          data.PRESIDENT = this.NEW_MEMBER_ID;
        else if (this.roleID == 2)
          data.VPI = this.NEW_MEMBER_ID;
        else if (this.roleID == 3)
          data.VPE = this.NEW_MEMBER_ID;
        else if (this.roleID == 4)
          data.SECRETORY = this.NEW_MEMBER_ID;
        else if (this.roleID == 5)
          data.TREASURER = this.NEW_MEMBER_ID;
        else if (this.roleID == 6)
          data.DIRECTOR1 = this.NEW_MEMBER_ID;
        else if (this.roleID == 7)
          data.DIRECTOR2 = this.NEW_MEMBER_ID;
        else if (this.roleID == 8)
          data.DIRECTOR3 = this.NEW_MEMBER_ID;
        else if (this.roleID == 9)
          data.DIRECTOR4 = this.NEW_MEMBER_ID;
        else if (this.roleID == 10)
          data.DIRECTOR5 = this.NEW_MEMBER_ID;

        this.getData1(data);
        this.NEW_MEMBER_ID = undefined;

      } else
        this.message.error("Failed to Update", "");
    });
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  members = [];
  memberLoading = false;

  getMembers() {
    this.memberLoading = true;

    this.api.getAllMembers(0, 0, "NAME", "asc", "").subscribe(data => {
      if (data['code'] == 200) {
        this.memberLoading = false;
        this.members = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  BOD: string = "";
  memberList = [];

  presidentPhoto: string = "";
  presidentName: string = "";
  presidentMobile: string = "";
  presidentFederation: string = "";
  presidentUnit: string = "";
  presidentGroup: string = "";

  vpiPhoto: string = "";
  vpiName: string = "";
  vpiMobile: string = "";
  vpiFederation: string = "";
  vpiUnit: string = "";
  vpiGroup: string = "";

  vpePhoto: string = "";
  vpeName: string = "";
  vpeMobile: string = "";
  vpeFederation: string = "";
  vpeUnit: string = "";
  vpeGroup: string = "";

  secretaryPhoto: string = "";
  secretaryName: string = "";
  secretaryMobile: string = "";
  secretaryFederation: string = "";
  secretaryUnit: string = "";
  secretaryGroup: string = "";

  treasurerPhoto: string = "";
  treasurerName: string = "";
  treasurerMobile: string = "";
  treasurerFederation: string = "";
  treasurerUnit: string = "";
  treasurerGroup: string = "";

  director1Photo: string = "";
  director1Name: string = "";
  director1Mobile: string = "";
  director1Federation: string = "";
  director1Unit: string = "";
  director1Group: string = "";

  director2Photo: string = "";
  director2Name: string = "";
  director2Mobile: string = "";
  director2Federation: string = "";
  director2Unit: string = "";
  director2Group: string = "";

  director3Photo: string = "";
  director3Name: string = "";
  director3Mobile: string = "";
  director3Federation: string = "";
  director3Unit: string = "";
  director3Group: string = "";

  director4Photo: string = "";
  director4Name: string = "";
  director4Mobile: string = "";
  director4Federation: string = "";
  director4Unit: string = "";
  director4Group: string = "";

  director5Photo: string = "";
  director5Name: string = "";
  director5Mobile: string = "";
  director5Federation: string = "";
  director5Unit: string = "";
  director5Group: string = "";

  presidentYesNo: boolean = false;
  vpiYesNo: boolean = false;
  vpeYesNo: boolean = false;
  secretaryYesNo: boolean = false;
  treasurerYesNo: boolean = false;
  director1YesNo: boolean = false;
  director2YesNo: boolean = false;
  director3YesNo: boolean = false;
  director4YesNo: boolean = false;
  director5YesNo: boolean = false;

  clearValues() {
    this.presidentPhoto = "assets/anony.png";
    this.presidentName = "";
    this.presidentMobile = "";
    this.presidentFederation = "";
    this.presidentUnit = "";
    this.presidentGroup = "";

    this.vpiPhoto = "assets/anony.png";
    this.vpiName = "";
    this.vpiMobile = "";
    this.vpiFederation = "";
    this.vpiUnit = "";
    this.vpiGroup = "";

    this.vpePhoto = "assets/anony.png";
    this.vpeName = "";
    this.vpeMobile = "";
    this.vpeFederation = "";
    this.vpeUnit = "";
    this.vpeGroup = "";

    this.secretaryPhoto = "assets/anony.png";
    this.secretaryName = "";
    this.secretaryMobile = "";
    this.secretaryFederation = "";
    this.secretaryUnit = "";
    this.secretaryGroup = "";

    this.treasurerPhoto = "assets/anony.png";
    this.treasurerName = "";
    this.treasurerMobile = "";
    this.treasurerFederation = "";
    this.treasurerUnit = "";
    this.treasurerGroup = "";

    this.director1Photo = "assets/anony.png";
    this.director1Name = "";
    this.director1Mobile = "";
    this.director1Federation = "";
    this.director1Unit = "";
    this.director1Group = "";

    this.director2Photo = "assets/anony.png";
    this.director2Name = "";
    this.director2Mobile = "";
    this.director2Federation = "";
    this.director2Unit = "";
    this.director2Group = "";

    this.director3Photo = "assets/anony.png";
    this.director3Name = "";
    this.director3Mobile = "";
    this.director3Federation = "";
    this.director3Unit = "";
    this.director3Group = "";

    this.director4Photo = "assets/anony.png";
    this.director4Name = "";
    this.director4Mobile = "";
    this.director4Federation = "";
    this.director4Unit = "";
    this.director4Group = "";

    this.director5Photo = "assets/anony.png";
    this.director5Name = "";
    this.director5Mobile = "";
    this.director5Federation = "";
    this.director5Unit = "";
    this.director5Group = "";

    this.presidentYesNo = false;
    this.vpiYesNo = false;
    this.vpeYesNo = false;
    this.secretaryYesNo = false;
    this.treasurerYesNo = false;
    this.director1YesNo = false;
    this.director2YesNo = false;
    this.director3YesNo = false;
    this.director4YesNo = false;
    this.director5YesNo = false;
  }

  getData1(dataParam: GroupMaster) {
    this.isSpinning = true;
    this.BOD = "";
    this.clearValues();

    this.BOD += dataParam.PRESIDENT ? dataParam.PRESIDENT + "," : "";
    this.BOD += dataParam.VPI ? dataParam.VPI + "," : "";
    this.BOD += dataParam.VPE ? dataParam.VPE + "," : "";
    this.BOD += dataParam.SECRETORY ? dataParam.SECRETORY + "," : "";
    this.BOD += dataParam.TREASURER ? dataParam.TREASURER + "," : "";
    this.BOD += dataParam.DIRECTOR1 ? dataParam.DIRECTOR1 + "," : "";
    this.BOD += dataParam.DIRECTOR2 ? dataParam.DIRECTOR2 + "," : "";
    this.BOD += dataParam.DIRECTOR3 ? dataParam.DIRECTOR3 + "," : "";
    this.BOD += dataParam.DIRECTOR4 ? dataParam.DIRECTOR4 + "," : "";
    this.BOD += dataParam.DIRECTOR5 ? dataParam.DIRECTOR5 + "," : "";

    if (this.BOD.length > 0) {
      this.BOD = this.BOD.substring(0, this.BOD.length - 1);

      this.api.getAllMembers(0, 0, "", "", " AND ID IN (" + this.BOD + ")").subscribe(data => {
        if (data['code'] == 200) {
          this.isSpinning = false;
          this.memberList = data['data'];

          if (dataParam.PRESIDENT) {
            this.presidentYesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.PRESIDENT);
            });

            this.presidentPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.presidentPhoto != null) && (this.presidentPhoto != ''))
              this.presidentPhoto = this.api.retriveimgUrl + "profileImage/" + this.presidentPhoto;

            else
              this.presidentPhoto = "assets/anony.png";

            this.presidentName = member.length > 0 ? member[0]["NAME"] : "";
            this.presidentMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.presidentFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.presidentUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.presidentGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.VPI) {
            this.vpiYesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.VPI);
            });

            this.vpiPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.vpiPhoto != null) && (this.vpiPhoto != ''))
              this.vpiPhoto = this.api.retriveimgUrl + "profileImage/" + this.vpiPhoto;

            else
              this.vpiPhoto = "assets/anony.png";

            this.vpiName = member.length > 0 ? member[0]["NAME"] : "";
            this.vpiMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.vpiFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.vpiUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.vpiGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.VPE) {
            this.vpeYesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.VPE);
            });

            this.vpePhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.vpePhoto != null) && (this.vpePhoto != ''))
              this.vpePhoto = this.api.retriveimgUrl + "profileImage/" + this.vpePhoto;

            else
              this.vpePhoto = "assets/anony.png";

            this.vpeName = member.length > 0 ? member[0]["NAME"] : "";
            this.vpeMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.vpeFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.vpeUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.vpeGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.SECRETORY) {
            this.secretaryYesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.SECRETORY);
            });

            this.secretaryPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.secretaryPhoto != null) && (this.secretaryPhoto != ''))
              this.secretaryPhoto = this.api.retriveimgUrl + "profileImage/" + this.secretaryPhoto;

            else
              this.secretaryPhoto = "assets/anony.png";

            this.secretaryName = member.length > 0 ? member[0]["NAME"] : "";
            this.secretaryMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.secretaryFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.secretaryUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.secretaryGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.TREASURER) {
            this.treasurerYesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.TREASURER)
            });

            this.treasurerPhoto = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.treasurerPhoto != null) && (this.treasurerPhoto != ''))
              this.treasurerPhoto = this.api.retriveimgUrl + "profileImage/" + this.treasurerPhoto;

            else
              this.treasurerPhoto = "assets/anony.png";

            this.treasurerName = member.length > 0 ? member[0]["NAME"] : "";
            this.treasurerMobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.treasurerFederation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.treasurerUnit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.treasurerGroup = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.DIRECTOR1) {
            this.director1YesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.DIRECTOR1);
            });

            this.director1Photo = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.director1Photo != null) && (this.director1Photo != ''))
              this.director1Photo = this.api.retriveimgUrl + "profileImage/" + this.director1Photo;

            else
              this.director1Photo = "assets/anony.png";

            this.director1Name = member.length > 0 ? member[0]["NAME"] : "";
            this.director1Mobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.director1Federation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.director1Unit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.director1Group = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.DIRECTOR2) {
            this.director2YesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.DIRECTOR2);
            });

            this.director2Photo = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.director2Photo != null) && (this.director2Photo != ''))
              this.director2Photo = this.api.retriveimgUrl + "profileImage/" + this.director2Photo;

            else
              this.director2Photo = "assets/anony.png";

            this.director2Name = member.length > 0 ? member[0]["NAME"] : "";
            this.director2Mobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.director2Federation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.director2Unit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.director2Group = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.DIRECTOR3) {
            this.director3YesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.DIRECTOR3);
            });

            this.director3Photo = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.director3Photo != null) && (this.director3Photo != ''))
              this.director3Photo = this.api.retriveimgUrl + "profileImage/" + this.director3Photo;

            else
              this.director3Photo = "assets/anony.png";

            this.director3Name = member.length > 0 ? member[0]["NAME"] : "";
            this.director3Mobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.director3Federation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.director3Unit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.director3Group = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.DIRECTOR4) {
            this.director4YesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.DIRECTOR4);
            });

            this.director4Photo = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.director4Photo != null) && (this.director4Photo != ''))
              this.director4Photo = this.api.retriveimgUrl + "profileImage/" + this.director4Photo;

            else
              this.director4Photo = "assets/anony.png";

            this.director4Name = member.length > 0 ? member[0]["NAME"] : "";
            this.director4Mobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.director4Federation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.director4Unit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.director4Group = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }

          if (dataParam.DIRECTOR5) {
            this.director5YesNo = true;

            var member = this.memberList.filter(obj => {
              return (obj.ID == dataParam.DIRECTOR5);
            });

            this.director5Photo = member.length > 0 ? member[0]["PROFILE_IMAGE"] : "";
            if ((this.director5Photo != null) && (this.director5Photo != ''))
              this.director5Photo = this.api.retriveimgUrl + "profileImage/" + this.director5Photo;

            else
              this.director5Photo = "assets/anony.png";

            this.director5Name = member.length > 0 ? member[0]["NAME"] : "";
            this.director5Mobile = member.length > 0 ? member[0]["MOBILE_NUMBER"] : "";
            this.director5Federation = member.length > 0 ? member[0]["FEDERATION_NAME"] : "";
            this.director5Unit = member.length > 0 ? member[0]["UNIT_NAME"] : "";
            this.director5Group = member.length > 0 ? member[0]["GROUP_NAME"] : "";
          }
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else {
      this.isSpinning = false;
    }
  }

  memberDrawerTitle: string;
  memberDrawerData: any;
  memberDrawerVisible = false;
  BOD_Position: number;
  @ViewChild(ManageGroupMembersComponent, { static: false }) ManageGroupMembersComponentVar: ManageGroupMembersComponent;

  addMembers(BODPosition: any): void {
    this.BOD_Position = BODPosition;

    let role = "";
    if (this.BOD_Position == 1)
      role = "President";
    else if (this.BOD_Position == 2)
      role = "VPI";
    else if (this.BOD_Position == 3)
      role = "VPE";
    else if (this.BOD_Position == 4)
      role = "Secretary";
    else if (this.BOD_Position == 5)
      role = "Treasurer";
    else if (this.BOD_Position == 6)
      role = "Director 1";
    else if (this.BOD_Position == 7)
      role = "Director 2";
    else if (this.BOD_Position == 8)
      role = "Director 3";
    else if (this.BOD_Position == 9)
      role = "Director 4";
    else if (this.BOD_Position == 10)
      role = "Director 5";

    this.memberDrawerTitle = "Add Group Members for " + role;
    this.memberDrawerVisible = true;
    this.ManageGroupMembersComponentVar.sortKey = "id";
    this.ManageGroupMembersComponentVar.sortValue = "desc";
    this.ManageGroupMembersComponentVar.search(true, BODPosition, this.data.ID);
  }

  memberDrawerClose(): void {
    this.memberDrawerVisible = false;

    this.api.getAllGroups(0, 0, "", "", " AND ID=" + this.data.ID).subscribe(data => {
      if (data['code'] == 200) {
        var updatedData = data['data'][0];
        this.getData1(updatedData);
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  get memberDrawerCloseCallback() {
    return this.memberDrawerClose.bind(this);
  }
}