export class UnitMaster {
    ID: number;
    CLIENT_ID: number;
    FEDERATION_ID: number;
    NAME: string;
    STATUS: boolean = true;
    BO_DATE: string;
    DIRECTOR: number;
    OFFICER1: number;
    OFFICER2: number;
    VP: number;
}