import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { UnitMembershipSummary } from 'src/app/Models/unitmembershipsummary';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { UnitBOD } from 'src/app/Models/unitbod';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-unitmembershipsummary',
  templateUrl: './unitmembershipsummary.component.html',
  styleUrls: ['./unitmembershipsummary.component.css']
})

export class UnitmembershipsummaryComponent implements OnInit {
  drawerVisible!: boolean;
  drawerTitle!: string;
  drawerData: UnitMembershipSummary = new UnitMembershipSummary();
  formTitle = " Unit Membership Summary Report";
  dataList = [];
  loadingRecords = true;
  totalRecords = 1;
  pageIndex = 1;
  pageSize = 10;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  groupname: string = '';
  filterQuery: string = "";
  isFilterApplied: any = "default";
  filterClass: string = 'filter-invisible';
  isSpinning = false;
  assessment: any;
  selectedDate: Date[] = [];
  value1: any;
  value2: any;
  selectedUnitName: any = '';
  current = new Date();
  columns: string[][] = [["UNIT_NAME", "Unit Name"], ["GROUP_COUNT", " Group Count"], ["_20_21_MEMBER_COUNT", "20-21 Member Count"], ["_21_22_MEMBER_COUNT", "21-22 Member Count"], ["CURRENT_TOTAL", "Current Total"]];
  federationID = this._cookie.get("FEDERATION_ID");
  unitID = this._cookie.get("UNIT_ID");
  groupID = this._cookie.get("GROUP_ID");

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private _cookie: CookieService) { }

  ngOnInit(): void {
    this.getUnits();
    this.loadingRecords = false;
    this.search();
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  units = [];

  getUnits() {
    var unitFilter = "";
    if (this.unitID != "0") {
      unitFilter = " AND ID=" + this.unitID;
    }

    var groupFilter = "";
    if (this.groupID != "0") {
      groupFilter = " AND ID=(SELECT UNIT_ID FROM group_master WHERE ID=" + this.groupID + ")";
    }

    this.units = [];
    this.UNIT_ID = [];

    this.api.getAllUnits(0, 0, "NAME", "asc", " AND STATUS=1" + unitFilter + groupFilter).subscribe(data => {
      if (data['code'] == 200) {
        this.units = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  onKeypressEvent(event: any) {
    document.getElementById('button').focus();
    this.search();
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    var federationFilter = "";
    if (this.federationID != "0") {
      federationFilter = " AND FEDERATION_ID=" + this.federationID;
    }

    var unitFilter = "";
    if (this.unitID != "0") {
      unitFilter = " AND UNIT_ID=" + this.unitID;
    }

    var groupFilter = "";
    if (this.groupID != "0") {
      groupFilter = " AND GROUP_ID=" + this.groupID;
    }

    console.log(this.UNIT_ID);

    var unitFilterStr = "";
    if (this.UNIT_ID.length > 0) {
      unitFilterStr = " AND UNIT_ID IN (" + this.UNIT_ID + ")";
    }

    this.loadingRecords = true;

    this.api.unitMembershipReport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + federationFilter + unitFilter + groupFilter + unitFilterStr).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  UNIT_ID = [];

  clearFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.UNIT_ID = [];
    this.search(true);
  }

  applyFilter() {
    if ((this.UNIT_ID.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }
}