import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { federation } from 'src/app/Models/federstion';
import { GroupCount } from 'src/app/Models/GroupCount';
import { TotalMembers } from 'src/app/Models/TotalMembers';
import { TotalUnites } from 'src/app/Models/TotalUnites';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-listmember',
  templateUrl: './listmember.component.html',
  styleUrls: ['./listmember.component.css']
})
export class ListmemberComponent implements OnInit {

  formTitle = "Members";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  columns: string[][] = [["WEB_MEMBER_STATUS", "Show In Web"], ["GROUP_NAME", "Group Name"], ["NAME", "Name"], ["EMAIL_ID", "Email Id"], ["MOBILE_NUMBER", "Mobile Number"], ["ADDRESS1", "Venue"],];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: TotalMembers = new TotalMembers();
  isSpinning = false;
  isFilterApplied: string = 'default';
  filterClass: string = 'filter-invisible';
  FEDERATION_ID: any;
  ID: any
  UNIT_ID: any;
  federation: federation[] = []
  TotalUnites: TotalUnites[] = []
  GroupCount: GroupCount[] = []

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search(true);
    this.getname();
  }
  keyup(event: any) {
    this.search();
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      this.sortValue = this.sortValue.startsWith("a") ? "asc" : "desc";
      console.log("an", this.sortValue);
    }
    catch (error) { sort = ""; }


    if (this.FEDERATION_ID != undefined && this.FEDERATION_ID > 0) {
      this.filterQuery =
        this.filterQuery + ' AND FEDERATION_ID=' + "'" + this.FEDERATION_ID + "'";

    }
    if (this.UNIT_ID != undefined && this.UNIT_ID > 0) {

      this.filterQuery =
        this.filterQuery + ' AND UNIT_ID=' + "'" + this.UNIT_ID + "'";


    }


    if (this.ID != undefined && this.ID > 0) {

      this.filterQuery =
        this.filterQuery + ' AND GROUP_ID=' + "'" + this.ID + "'";


    }

    var likeQuery = ""; if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }

    this.loadingRecords = true;

    this.api.getmember(this.pageIndex, this.pageSize, this.sortKey, this.sortValue,this.filterQuery + likeQuery ).
     
      subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }
      },
        err => {
          if (err['ok'] == false)
            this.message.error("Server Not Found", "");
        });
  }



  sort(sort: { key: string; value: string }):
    void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    console.log("SORT", this.sortValue);
    this.search(true);
  }




  today = new Date().setDate(new Date().getDate() + 4);


  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onSearching() {
    document.getElementById("button1").focus();
    this.search(true);
  }

  onStatusChange(data: TotalMembers, status) {
    data.WEB_MEMBER_STATUS = status;

    if (data.WEB_MEMBER_STATUS == true) {

      data.IS_ALL_MEMBER_UPDATE = false;

    } else {
      data.IS_ALL_MEMBER_UPDATE = true;
    }

    this.api.updatemember(data).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Status", "");

      this.search();
    });
  }


  // 



  allStatusChange(status) {
    this.drawerData.IS_ALL_MEMBER_UPDATE = status;

    if (this.drawerData.IS_ALL_MEMBER_UPDATE == true) {

      this.drawerData.WEB_MEMBER_STATUS = true;
    } else {
      this.drawerData.WEB_MEMBER_STATUS = false;
    }

    this.api.updatemember(this.drawerData).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Status", "");

      this.search();
    });
  }




  applyFilter() {

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }
    this.filterQuery = '';






    if (this.FEDERATION_ID != undefined && this.FEDERATION_ID > 0) {
      this.filterQuery =
        this.filterQuery + ' AND FEDERATION_ID=' + "'" + this.FEDERATION_ID + "'";


    }


    if (this.UNIT_ID != undefined && this.UNIT_ID > 0) {

      this.filterQuery =
        this.filterQuery + ' AND UNIT_ID=' + "'" + this.UNIT_ID + "'";


    }


    if (this.ID != undefined && this.ID > 0) {

      this.filterQuery =
        this.filterQuery + ' AND GROUP_ID =' + "'" + this.ID + "'";


    }







    var likeQuery = '';

    if (this.searchText != '') {
      likeQuery = ' AND (';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ' )';
    }
    this.api
      .getmember(

        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        this.filterQuery

     

      )



      .subscribe(
        (data) => {
          console.log(data);
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
        },
        (err) => {
          console.log(err);
        }
      );

  }

  clearFilter() {
    
    this.FEDERATION_ID = null;
    this.UNIT_ID = null;
    this.ID=null;
    this.filterQuery = '';
    this.applyFilter();
  }



  getname() {

    this.api.getDropdownfederation(0, 0, 'NAME', 'asc', "").subscribe(data => {
      this.federation = data['data'];
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  showFilter(): void {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';
  }

  demo(Event: any) {

    this.api.getunitdropdown(0, 0, 'NAME', 'asc', " AND FEDERATION_ID = " + Event).subscribe(data => {
      this.TotalUnites = data['data'];
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });


    // FEDERATION_ID
  }

  demo1(Eventt: any) {

    this.api.getgroup(0, 0, 'NAME', 'asc', " AND UNIT_ID = " + Eventt).subscribe(data => {
      this.GroupCount = data['data'];
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });



  }





}
