// import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
// import { appkeys } from 'src/app/app.constant';
// import { AboutMaster } from 'src/app/Models/aboutmaster';
import { ApiService } from 'src/app/Service/api.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { cmsPress } from 'src/app/Models/cmsPress';
import { pressreleseee } from 'src/app/Models/pressrelese';
// import { ClientmasterService } from 'src/app/Service/clientmaster.service';

@Component({
  selector: 'app-addpressrelease',
  templateUrl: './addpressrelease.component.html',
  styleUrls: ['./addpressrelease.component.css']
})
export class AddpressreleaseComponent implements OnInit {

  @Input()
  drawerClose!: Function;
  @Input()
  data: cmsPress = new cmsPress;
  @Input()
  drawerVisible: boolean = false;
  isSpinning = false;
  isOk = true;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  namepatt = /[a-zA-Z][a-zA-Z ]+/
  mobpattern = /^[6-9]\d{9}$/
  fileURL: any;
  height: any;
  width: any;

  pressrelese: pressreleseee[] = [];

  imgurl = this.api.retriveimgUrl + "/pressRelease/";

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '300px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
    ],
    customClasses: [],
    uploadUrl: '',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fonts', 'uploadUrl'],
      ['video']
    ]
  };



  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    // this.loadalbume();
    this.getname();
  }


  close(): void {
    this.drawerClose();
  }
  resetDrawer(websitebannerPage: NgForm) {
    // websitebannerPage.form.reset();
    this.data=new cmsPress();
    websitebannerPage.form.markAsPristine();
    websitebannerPage.form.markAsUntouched();
  }

  //save
  save(addNew: boolean, websitebannerPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;

    if (this.data.TITLE.trim() == "" 
   && this.data.NEWS_CATEGORY_ID == 0 
      && this.data.DESCRIPTION.trim() == ""
      && this.data.SEQUENCE_NO != undefined && this.data.IMG_URL.trim() == "") {
      this.isOk = false
      this.message.error("Please Fill All The Information", "");
    } else
      if (this.data.TITLE == null || this.data.TITLE.trim() == '') {
        this.isOk = false
        this.message.error('Please Fill Title', '')

      }
      else
      if (this.data.NEWS_CATEGORY_ID <= 0 || this.data.NEWS_CATEGORY_ID == undefined) {
        this.isOk = false
        this.message.error('Please select Event Type', '')
  
      }
      else
        if (this.data.DESCRIPTION == null || this.data.DESCRIPTION.trim() == '') {
          this.isOk = false
          this.message.error('Please Fill Description', '')
        }
        else
          if (this.data.SEQUENCE_NO == undefined || this.data.SEQUENCE_NO <= 0) {
            this.isOk = false
            this.message.error('Please Enter Sequence No', '')
          } else
            if (this.data.IMG_URL == null || this.data.IMG_URL.trim() == '') {
              this.isOk = false
              this.message.error('Please Select Image', '')
            }

    if (this.isOk) {
      // this.isSpinning=false;

      this.isSpinning = true;

      {
        if (this.fileURL != null) {
          var number = Math.floor(100000 + Math.random() * 900000);
          var fileExt = this.fileURL.name.split('.').pop();
          var d = this.datePipe.transform(new Date(), 'yyyyMMdd');
          var url = '';
          url = d == null ? '' : d + number + '.' + fileExt;
          if (
            this.data.IMG_URL != undefined &&
            this.data.IMG_URL.trim() != ''
          ) {
            var arr = this.data.IMG_URL.split('/');
            if (arr.length > 1) {
              url = arr[5];
            }
          }

          this.api
            .onUpload2('pressRelease', this.fileURL, url)
            .subscribe((successCode) => {
              if (successCode["code"] == 200) {
                this.data.IMG_URL = url;
                // appkeys.retriveimgUrl + 'WebsiteBannerImage/' + url;
                if (this.data.ID) {
                  this.api
                    .updatePressRelease(this.data)
                    .subscribe((successCode) => {
                      if (successCode["code"] == 200) {
                        this.message.success(
                          'Information updated successfully', ''
                        );
                        this.fileURL=null;
                        if (!addNew) this.drawerClose();
                        this.isSpinning = false;
                      } else {
                        this.message.error('Failed to information', '');
                        this.isSpinning = false;
                      }
                    });
                } else {
                  this.api
                    .createPressRelease(this.data)
                    .subscribe((successCode) => {
                      if (successCode.code == '200') {
                        this.message.success('Information added successfully', '');
                        this.fileURL=null;
                        if (!addNew) this.drawerClose();
                        else {
                          this.data = new cmsPress();
                          this.resetDrawer(websitebannerPage);

                          this.data.STATUS == true;
                          // this.data.IMG_URL==null;
                          this.fileURL = '';
                          this.api.getPressRelease(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
                            if (data['count'] == 0) {
                              this.data.SEQUENCE_NO = 1;
                            } else {
                              this.data.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
                            }
                          }, err => {
                            console.log(err);
                          })
                        }
                        this.isSpinning = false;
                      } else {
                        this.message.error('Failed to store information', '');
                        this.isSpinning = false;
                      }
                    });
                }
              } else {
                this.message.error('Failed to add image', '');
                this.isSpinning = false;
              }
            });
        } else if (this.data.IMG_URL == null || this.data.IMG_URL == '') {
          this.message.error('Please select image ', '');
          this.isSpinning = false;
        } else {
          if (this.data.ID) {
            this.api.updatePressRelease(this.data).subscribe((successCode) => {
              if (successCode["code"] == 200) {
                this.message.success('Information updated successfully', '');
                if (!addNew) this.drawerClose();
                this.isSpinning = false;
              } else {
                this.message.error('Failed to update information', '');
                this.isSpinning = false;
              }
            });
          } else {
            this.api.createPressRelease(this.data).subscribe((successCode) => {
              if (successCode.code == '200') {
                this.message.success('Information added successfully', '');
                if (!addNew) this.drawerClose();
                else {
                  this.data = new cmsPress();
                }
                this.isSpinning = false;
              } else {
                this.message.error('Failed to store information', '');
                this.isSpinning = false;
              }
            });
          }
        }
      }
    }

    //  else
    //  {
    //    this.message.error("Please Fill All Required Fields...","");
    //    this.isSpinning = false;

    //  }
  }
  ////
  ////
  //Choose image
  // onFileSelected(event: any) {
  //   console.log(event);
  //   let imgs = new Image()
  //   let isLtsize = false;
  //   imgs.src = window.URL.createObjectURL(event.target.files[0]);

  //   imgs.onload = () => {
  //     console.log(imgs.height);
  //     console.log(imgs.width);
  //     if (376 == imgs.height && imgs.width == 1500) {
  //       isLtsize = true;

  //       if (
  //         event.target.files[0].type == 'image/jpeg' ||
  //         event.target.files[0].type == 'image/jpg' ||
  //         event.target.files[0].type == 'image/png'
  //       ) {
  //         this.fileURL = <File>event.target.files[0];
  //       } else {
  //         this.message.error('Please select only JPEG/ JPG/ PNG file.', '');
  //         this.fileURL = null;
  //         this.data.IMG_URL = '';
  //       }

  //     } else {
  //       this.fileURL = null;
  //       this.data.IMG_URL = ' ';
  //       this.message.error('The image will not fit between the dimensions of ' + 376 + ' ' + 'px Height  ' + ' And ' + ' ' + 1500 + ' px Width ', '');
  //     }
  //   }
  // }


  onFileSelected(event:any) {
    console.log(event);

    if (event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/png") {
      this.fileURL = <File>event.target.files[0];

    } else {
      this.message.error("Select Only JPEG/ JPG/ PNG File.", "");
      this.fileURL = null;
      this.data.IMG_URL= '';

    }
  }
  removeImage() {
    this.data.IMG_URL = '';
    this.fileURL = '';

  }



  
  getname() {

    this.api.getDropdownpressrelesr(0, 0, 'NAME', 'asc', "").subscribe(data => {
      this.pressrelese = data['data'];
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) { return false; } return true;
  }


}
